<template>
    <div class="bg-white last:rounded-b-md border-gray-100">
        <button
            class="flex items-center justify-between hover:bg-cyan-50 text-left p-4 pl-6 w-full"
            @click="showLessons = !showLessons"
        >
            <div>{{ module.title }}</div>
            <div>
                <SvgIcon
                    v-if="!showLessons"
                    icon="chevron-right"
                    size="xs"
                    weight="extra-bold"
                />
                <SvgIcon
                    v-if="showLessons"
                    icon="chevron-down"
                    size="xs"
                    weight="extra-bold"
                />
            </div>
        </button>
        <div v-if="showLessons" class="divide-y">
            <div
                v-for="lesson in module.lessons"
                class="bg-gray-50 border-gray-200 p-4 pl-8"
            >
                <a class="cta" :href="path + lesson.slug">
                    {{ lesson.title }}
                </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        courseSlug: String,
        module: Object,
    },
    data(): any {
        return {
            showLessons: false,
        };
    },
    computed: {
        path() {
            return "/" + this.courseSlug + "/" + this.module.slug + "/";
        },
    },
};
</script>
