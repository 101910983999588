import { defineComponent, Transition, PropType } from "vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { ChevronRightIcon } from "@heroicons/vue/24/solid";
import { RouterLink } from "vue-router";

// we always use tailwind css
// This is the AdminBreadcrumbs component
export const AdminBreadcrumbs = defineComponent({
    name: "AdminBreadcrumbs",
    setup(props, { slots }) {
        return () => (
            <nav>
                <ol class="flex flex-row flex-wrap gap-y-2">
                    {slots.default?.()}
                </ol>
            </nav>
        );
    },
});

export type BreadcrumbNavigationItem = {
    label: string;
    url: string;
};

// This is the BreadcrumbItem component
export const BreadcrumbItem = defineComponent({
    name: "BreadcrumbItem",
    props: {
        label: {
            type: String,
            required: true,
        },
        siblings: {
            type: Array as PropType<BreadcrumbNavigationItem[]>,
            default: () => [],
        },
    },
    setup(props, ctx) {
        return () => (
            <div class="relative mr-2">
                <Menu as="div">
                    <MenuButton class="text-sm font-medium text-gray-500 hover:text-gray-700 flex flex-row items-center">
                        {props.label}
                        <ChevronRightIcon class="w-3 h-3 bold ml-2" />
                    </MenuButton>
                    <Transition
                        enter-active-class="transition duration-100 ease-out"
                        enter-from-class="transform scale-95 opacity-0"
                        enter-to-class="transform scale-100 opacity-100"
                        leave-active-class="transition duration-75 ease-in"
                        leave-from-class="transform scale-100 opacity-100"
                        leave-to-class="transform scale-95 opacity-0"
                    >
                        <MenuItems class="absolute mt-2 origin-top-right divide-y divide-gray-100 w-60 md:w-80 rounded-md h-auto max-h-64 overflow-auto bg-slate-800 text-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-100">
                            <ul class="py-1 w-full flex flex-col h-full overflow-auto">
                                {ctx.slots.default?.()}
                                {props.siblings.map((item) => (
                                    <MenuItem as="li" class="w-full">
                                        {({ active }) => (
                                            <RouterLink
                                                to={item.url}
                                                activeClass="bg-slate-600"
                                                class="px-4 block py-1 text-sm w-full hover:bg-slate-700 hover:text-white hover:no-underline"
                                            >
                                                {item.label}
                                            </RouterLink>
                                        )}
                                    </MenuItem>
                                ))}
                            </ul>
                        </MenuItems>
                    </Transition>
                </Menu>
            </div>
        );
    },
});
