<template>
    <div class="search-reveal" :class="[size, { revealed: showSearch }]">
        <input
            v-model="searchTerm"
            type="text"
            class="search-term"
            ref="searchBox"
            @keyup="keyUp"
        />
        <button v-if="!showSearch" @click="open">
            <svg-icon icon="search" :size="size" />
        </button>
        <button v-if="showSearch" @click="close">
            <svg-icon icon="close" size="xs" weight="bold" />
        </button>
    </div>
</template>

<script lang="ts">
import { size } from "lodash";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        size: {
            type: String,
            default: "sm",
        },
        value: {
            type: String,
        },
    },
    data(): any {
        return {
            showSearch: false,
            searchTerm: "",
        };
    },
    methods: {
        open() {
            this.showSearch = true;
            this.$refs.searchBox.focus();
        },
        close() {
            this.showSearch = false;
            this.searchTerm = "";
            this.$emit("input", this.searchTerm);
        },
        keyUp(event) {
            this.$emit("input", event?.target.value);
        },
    },
    mounted() {
        this.searchTerm = this.value;
    },
    watch: {
        value(newValue) {
            this.searchTerm = newValue;
        },
    },
});
</script>

<style scoped>
.search-reveal {
    @apply relative flex items-center ml-4 w-8 h-8;

    transition: width 200ms;
}

.search-reveal.revealed {
    @apply w-48;
}

.search-reveal .search-term {
    @apply opacity-0 h-8;

    transition: opacity 200ms;
}

.search-reveal.revealed .search-term {
    @apply opacity-100;
}

.search-reveal button {
    @apply absolute p-2 right-0 z-50;
}

.search-reveal.revealed svg {
    @apply mr-1;
}

.search-reveal.md {
    @apply w-12 h-12;
}
.search-reveal.md.revealed {
    @apply w-64;
}
.search-reveal.md .search-term {
    @apply h-12;
}
.search-reveal.md button {
    @apply p-4;
}
</style>
