<template>
    <div
        class="inline-flex justify-start w-full"
        :class="{ 'font-medium': !normal }"
    >
        <img
            v-if="image"
            class="hidden w-10 h-10 mr-2 rounded-full lg:block"
            :src="image"
            :alt="'Profile photo for ' + name"
        />
        <span class="self-center">{{ name }}</span>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        forename: {
            type: String,
            required: true,
        },
        surname: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            required: false,
            default: "",
        },
        normal: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        name() {
            return this.surname + ", " + this.forename;
        },
    },
};
</script>
