<template>
    <div class="relative z-0 inline-flex h-10 rounded-md">
        <button
            v-for="(view, index) in views"
            :key="index"
            @click="updateState(view.value)"
            type="button"
            class="relative inline-flex items-center px-4 py-2 text-sm font-medium border border-l-0 border-gray-300 hover:bg-gray-50 focus:z-10 focus:outline-none focus-visible:ring-1 first:rounded-l-md first:border-l last:rounded-r-md"
            :class="[active === view.value ? 'bg-white' : 'bg-gray-50']"
            v-tippy="{ placement: 'top' }"
            ref="tip"
            :content="generateLabel(view.value)"
            :aria-label="generateLabel(view.value)"
        >
            <SvgIcon
                :class="[
                    active === view.value ? 'text-blue-600' : 'text-gray-700',
                ]"
                :icon="view.icon"
                size="md"
                weight="semibold"
            />
        </button>
    </div>
</template>

<script lang="ts">
import { mapState } from "vuex";
import _ from "lodash";

export default {
    data(): any {
        return {
            views: {
                learners: {
                    value: "learners",
                    icon: "learner-alt",
                },
                structured: {
                    value: "structured",
                    icon: "sort-alt",
                },
                // timeline: {
                //     value: 'timeline',
                //     icon: 'clock-simple-alt',
                // },
            },
        };
    },
    computed: {
        ...mapState(["view"]),
        active() {
            return this.view;
        },
        url() {
            let url = new URL(window.location.href);
            url.searchParams.set("view", this.view);
            url.searchParams.set("page", "1");

            return url.toString();
        },
    },
    methods: {
        updateState(newValue) {
            this.$store.commit("registerState", {
                view: newValue,
            });
            this.updateWindowHistory();
        },
        updateWindowHistory() {
            window.history.pushState("", "", this.url);
        },
        generateLabel(value) {
            return _.capitalize(value) + " View";
        },
    },
};
</script>
