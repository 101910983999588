import { createStore } from "vuex";
import adminStore from "./adminStore";

const state = {
    location: {
        app:
            window.location.pathname.split("/").length > 1
                ? "/" + window.location.pathname.split("/")[1]
                : "",
        page:
            window.location.pathname.split("/").length > 2
                ? "/" + window.location.pathname.split("/")[2]
                : "",
    },
    token: null,
    user: null,
    websitePath: null,
    bladePage: true,
};

const getters = {
    appPath: (state) => {
        return state.location.app + state.location.page;
    },
};

const mutations = {
    registerState(state, payload) {
        for (const [key, value] of Object.entries(payload)) {
            state[key] = value;
        }
    },
    hideBladePage(state) {
        state.bladePage = false;
    },
};

const actions = {};

const adminModule = adminStore;
export default createStore({
    state,
    getters,
    mutations,
    actions,
    modules: {
        admin: adminModule,
    },
});
