<template>
    <div class="mt-8">
        <div class="flex items-center justify-between">
            <div class="flex flex-grow justify-start">
                <h3 class="text-sm text-gray-600 font-medium uppercase ml-4">
                    Learners
                </h3>
            </div>
            <div class="hidden sm:flex justify-center w-22">
                <div class="text-sm text-gray-700 uppercase font-medium">#</div>
            </div>
            <template v-if="type != 'lessons'">
                <div class="hidden sm:flex justify-center w-32">
                    <div class="text-sm text-gray-700 uppercase font-medium">
                        Avg Score
                    </div>
                </div>
                <div class="hidden sm:flex justify-center w-24">
                    <div class="text-sm text-gray-700 uppercase font-medium">
                        Avg Time
                    </div>
                </div>
            </template>
        </div>
        <div class="card">
            <PulseExploreDetailsListingLearnersRow
                v-for="row in rows"
                :key="row.uuid"
                :legacy="legacy"
                v-bind="row"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { mapState } from "vuex";
import PulseExploreDetailsListingLearnersRow from "./PulseExploreDetailsListingLearnersRow.vue";

export default {
    components: { PulseExploreDetailsListingLearnersRow },
    props: {
        legacy: Boolean,
        rows: Array as PropType<any[]>,
    },
    computed: {
        ...mapState(["type"]),
    },
};
</script>
