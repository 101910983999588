<script lang="ts">
import { PropType } from "vue";
import { convertSecondsToMinutes } from "../../../utils";

export default {
    props: {
        expanded: Boolean,
        learners: Array as PropType<any[]>,
    },
    computed: {
        totalComplete() {
            return (
                this.learners?.filter(
                    (learner) => learner.lesson_views_complete
                ).length ?? 0
            );
        },
    },
    methods: {
        convertSecondsToMinutes,
    },
};
</script>
<template>
    <PulseActivityTableRow row-grid-template="minmax(140px, 15%) auto" nested>
        <PulseActivityTableCell>
            <Pill :background-color="'green-150'" class="inline-flex">
                Lessons
            </Pill>
        </PulseActivityTableCell>

        <PulseActivityTableCell :padded="false">
            <PulseLearnCompletionsRow
                :expanded="expanded"
                :completed-count="totalComplete"
                :total-count="learners?.length"
            >
                <PulseLearnCompletionsRowTable
                    :columns="[
                        {
                            key: 'status',
                            label: 'Status',
                        },
                        {
                            key: 'views',
                            label: 'Views',
                        },
                        {
                            key: 'total_time',
                            label: 'Total Time',
                        },
                    ]"
                    :data="learners"
                >
                    <template v-slot:status="{ row }">
                        <CompletionStatus
                            :completed="row.lesson_views_complete"
                        />
                    </template>
                    <template v-slot:total_time="{ row }">
                        <span class="font-semibold">{{
                            convertSecondsToMinutes(row.lesson_total_time)
                        }}</span>
                    </template>
                    <template v-slot:views="{ row }">
                        <span class="font-semibold">{{
                            row.lesson_views
                        }}</span>
                    </template>
                </PulseLearnCompletionsRowTable>
            </PulseLearnCompletionsRow>
        </PulseActivityTableCell>
    </PulseActivityTableRow>
</template>
