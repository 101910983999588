<template>
    <div
        class="flex flex-col justify-end w-full py-4 text-sm text-gray-700 sm:pt-20 sm:flex-row"
    >
        <p>Data last synced {{ hrsSinceSynced }} hours ago</p>
        <p class="text-blue-600 cursor-pointer sm:ml-4" @click="sync">
            {{ syncing ? "Syncing Data" : "Update Data" }}
            <SvgIcon
                icon="refresh"
                weight="bold"
                class="w-4 h-4 ml-1"
                :class="[syncing ? 'spin' : '']"
            />
        </p>
    </div>
</template>

<script lang="ts">
export default {
    data(): any {
        return {
            hrsSinceSynced: 18,
            syncing: false,
        };
    },
    methods: {
        sync() {
            this.syncing = true;

            // do sync operation and update `hrsSinceSynced`
            setTimeout(() => {
                this.syncing = false;
                this.hrsSinceSynced = 0;
            }, 2000);
        },
    },
};
</script>

<style scoped>
.spin {
    animation: spin 800ms infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
