<template>
    <div>
        <div>
            <a href="View "></a>
        </div>
        <div class="flex flex-col sm:flex-row gap-8">
            <div class="sm:w-1/4">
                <SavedFoldersList
                    :enrollmentId="enrollment.id"
                    :folders="folders"
                    :baseUrl="redirectUrl"
                />
            </div>
            <div class="sm:w-3/4">
                <div class="bg-white rounded-md shadow">
                    <div
                        class="flex justify-between items-center border-gray-100 rounded-t-md p-6"
                        :class="{
                            'border-b-2': currentFolder.saved_items_count > 0,
                        }"
                    >
                        <div
                            class="flex w-full items-center justify-between gap-2"
                        >
                            <div
                                class="flex items-center text-base sm:text-xl leading-9 font-medium space-x-2"
                            >
                                <SvgIcon
                                    v-if="currentFolder.order == 1"
                                    class="text-yellow-300"
                                    icon="star"
                                    size="base"
                                    type="fill"
                                />
                                <div>{{ name }}</div>
                            </div>
                            <a
                                v-if="false"
                                class="text-sm text-blue-700 underline hover:no-underline"
                                href="/learn/bookmarks/"
                                >View All Bookmarks</a
                            >
                        </div>
                        <div
                            v-if="currentFolder.order != 1"
                            class="flex items-center space-x-2"
                        >
                            <SavedItemsFolderModal
                                :enrollmentId="enrollment.id"
                                @updated-folder="updateFolder()"
                            >
                                <template #default="{ openModal }">
                                    <button
                                        class="flex items-center justify-center hover:bg-cyan-50 rounded-lg w-9 h-9"
                                        type="button"
                                        @click="openModal(currentFolder)"
                                    >
                                        <SvgIcon icon="pencil" size="sm" />
                                    </button>
                                </template>
                            </SavedItemsFolderModal>
                            <ConfirmDeleteSavedItemsFolderModal
                                :folder="currentFolder"
                                :item-count="currentFolder.saved_items_count"
                                :redirectUrl="redirectUrl"
                            >
                                <template #default="{ openModal }">
                                    <button
                                        class="flex items-center justify-center hover:bg-cyan-50 rounded-lg w-9 h-9"
                                        type="button"
                                        @click="openModal"
                                    >
                                        <SvgIcon
                                            class="text-red-700"
                                            icon="remove"
                                            size="sm"
                                        />
                                    </button>
                                </template>
                            </ConfirmDeleteSavedItemsFolderModal>
                        </div>
                    </div>
                    <SavedItemsList :items="currentFolder.saved_items" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        currentFolder: Object,
        enrollment: Object,
        folders: Array,
        redirectUrl: String,
    },
    data(): any {
        return {
            name: null,
        };
    },
    methods: {
        updateHistory(slug) {
            window.history.pushState({}, "", this.redirectUrl + "/" + slug);
        },
    },
    mounted() {
        this.name = this.currentFolder.name;

        this.$events.$on("updatedFolder", (data) => {
            this.name = data.name;
            this.updateHistory(data.slug);
        });
    },
};
</script>
