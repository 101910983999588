<template>
    <TitleBar
        :action="action"
        :actionPath="actionPath"
        :app="app"
        :icon="icon"
        :moduleTitle="'test'"
        :resourceTitle="'test2'"
        :searchable="searchable"
        :searchAddition="searchAddition"
        :searchClear="searchClear"
        :section="'test3'"
        :sectionPath="sectionPath"
        :showBilling="showBilling"
        :impersonating="impersonating"
    />
</template>

<script lang="ts">
import { mapState } from "vuex";

export default {
    props: {
        action: String,
        actionPath: String,
        app: String,
        icon: String,
        moduleTitle: String,
        resourceTitle: String,
        searchable: Array,
        searchAddition: String,
        searchClear: Boolean,
        section: String,
        sectionPath: String,
        showBilling: Boolean,
        impersonating: Boolean,
    },
    computed: {
        ...mapState(["activePath", "user"]),
    },
    methods: {},
};
</script>
