import { defineStore, storeToRefs } from "pinia";
import { ref } from "vue";

export const useLoadingStore = defineStore("isFullPageLoading", () => {
    const loading = ref<boolean>(false);

    return { loading };
});

export function useIsLoading() {
    const loadingStore = useLoadingStore();
    const { loading } = storeToRefs(loadingStore);
    return loading;
}
