<template>
    <div>
        <slot v-bind="{ openModal }"></slot>
        <base-modal
            :heading="`${folderId ? 'Edit' : 'Add'} Folder`"
            :opened="isOpen"
            @close="close"
        >
            <template v-slot:body>
                <label class="text-xs uppercase font-bold ml-2 mb-1"
                    >Folder name</label
                >
                <input
                    class="bg-gray-50 border border-gray-200 text-base leading-6 rounded-lg p-3 w-full"
                    v-model="name"
                />
                <div v-if="errors.name" class="text-xs text-red-600 mt-1">
                    {{ errors.name[0] }}
                </div>
            </template>
            <template v-slot:actions>
                <button type="button" class="primary button" @click="submit">
                    Submit
                </button>
            </template>
        </base-modal>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import BaseModal from "./BaseModal.vue";

export default {
    props: {
        enrollmentId: {
            type: Number,
            required: true,
        },
    },

    data(): any {
        return {
            folderId: null,
            isOpen: false,
            isSaving: false,
            name: "",
            errors: {},
        };
    },

    methods: {
        open() {
            this.isOpen = true;
        },
        close() {
            this.isOpen = false;
            this.reset();
        },
        reset() {
            this.name = "";
            this.errors = {};
        },
        openModal(folder) {
            if (folder) {
                this.name = folder.name;
                this.folderId = folder.id;
            }

            this.open();
        },
        sendRequest() {
            if (this.folderId) {
                return axios.put(
                    `/api/learn/enrollments/${this.enrollmentId}/saved-items-folders/${this.folderId}`,
                    {
                        name: this.name,
                    }
                );
            }

            return axios.post(
                `/api/learn/enrollments/${this.enrollmentId}/saved-items-folders`,
                {
                    name: this.name,
                }
            );
        },
        submit() {
            this.isSaving = true;
            this.sendRequest()
                .then((response) => {
                    this.isSaving = false;
                    if (!this.folderId) {
                        this.$emit("addedFolder", response.data);
                    } else {
                        this.$emit("updatedFolder", response.data);
                        this.$events.$emit("updatedFolder", response.data);
                    }
                    this.close();
                })
                .catch(({ response }) => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                        this.isSaving = false;
                    }
                });
        },
    },

    components: {
        BaseModal,
    },
};
</script>
