import { add, format, formatDistance, intervalToDuration } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export default {
    install(app, options) {
        app.mixin({
            methods: {
                optional: (o, path) =>
                    path
                        .replace(/\[(\d+)\](?=[[.]|$)/g, ".$1")
                        .split(".")
                        .reduce((o, key) => (o == null ? null : o[key]), o),
            },
        });
    },
};
