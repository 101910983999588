<template>
    <div>
        <div class="list-item justify-between">
            <div class="mr-8">
                <a class="cta" :href="link">
                    {{ row.name }}
                </a>
            </div>
            <div class="w-32 text-center mr-8">
                {{ row.views }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { mapState, mapGetters } from "vuex";

export default {
    props: {
        row: {
            type: Object as PropType<any>,
        },
    },
    computed: {
        ...mapState(["period", "type", "typeId"]),
        link() {
            return (
                "/pulse/explore/learner/" +
                this.period +
                "/" +
                this.row.uuid +
                "/" +
                this.type +
                "/" +
                this.typeId
            );
        },
    },
};
</script>
