<template>
    <div class="card">
        <div class="bg-gray-50 border-b border-gray-100 rounded-t-md p-8">
            <h2 class="font-bold">Feedback</h2>
        </div>
        <table class="w-full">
            <tbody>
                <ContentQuestionFeedbackCollectionItem
                    v-for="item in feedback"
                    :feedback-item="item"
                    :key="item.id"
                />
                <tr class="history-item" v-if="!feedback?.length">
                    <td>
                        <div class="italic p-8">No feedback items</div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import { PropType } from "vue";

export default {
    props: {
        feedback: Array as PropType<any[]>,
    },
};
</script>
