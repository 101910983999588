<template>
    <div class="flex flex-col items-center justify-center sm:w-full sm:h-full">
        <div class="text-gray-500 text-3xl font-light" v-if="label">
            {{ label }}
        </div>
        <slot />
    </div>
</template>

<script lang="ts">
export default {
    props: {
        label: String,
    },
};
</script>
