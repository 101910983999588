<template>
    <div>
        <ContentQuestionsListItem
            v-for="question in questions"
            :question="question"
            :questionType="questionType"
            :key="question.uuid"
        />
        <EmptyPanel
            v-if="questions.length === 0"
            icon="question-cloud"
            heading="No results"
            content="Your search returned no results"
        />
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { PropType } from "vue";

export default {
    props: {
        questions: {
            type: Array as PropType<any[]>,
            default: () => [],
        },
        questionType: {
            type: String,
            default: "prep",
        },
    },
};
</script>
