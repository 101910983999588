<script lang="ts">
import { PropType } from "vue";

export default {
    props: {
        expanded: Boolean,
        row: Object as PropType<any>,
        rowGridTemplate: String,
    },
    methods: {
        moduleLink(moduleUuid) {
            return {
                path: "/module/" + moduleUuid + "/structure",
                query: { ...this.$route.query, page: 1 },
            };
        },
    },
};
</script>
<template>
    <PulseActivityTableRow :row-grid-template="rowGridTemplate">
        <PulseActivityTableCell v-slot="{ asLink }" :row-span="20">
            <span class="font-medium">
                {{ row.lesson.title }}
            </span>
        </PulseActivityTableCell>

        <StructuredModuleLessonRows
            :learners="row.learners"
            :expanded="expanded"
        />
        <StructuredModuleQuizRows
            :learners="row.learners"
            :expanded="expanded"
        />
    </PulseActivityTableRow>
</template>
