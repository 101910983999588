<template>
    <div>
        <div class="list-item justify-between">
            <div class="w-72 mr-8">
                <a
                    class="flex items-center leading-none text-xs"
                    :href="link"
                    :title="row.created_at"
                >
                    <svg-icon
                        class="text-pear-600 mr-2"
                        icon="clock"
                        size="sm"
                    />
                    <span
                        class="text-blue-600 underline"
                        v-text="dateTime(row.created_at, user.timezone)"
                    />
                </a>
            </div>
            <div class="w-32 text-center mr-8">
                <span v-html="grade(row.grade, settings.pulse_grade)" />
            </div>
            <div class="w-32 text-center mr-8">
                {{ elapsed(row.time_elapsed) }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { mapState, mapGetters } from "vuex";
import { defineComponent } from "vue";
import { filters as $filters } from "../../../vue-filters";

export default defineComponent({
    props: {
        row: {
            type: Object as any,
        },
    },
    methods: {
        ...$filters,
    },
    computed: {
        ...mapState(["settings", "type", "typeId", "user"]),
        ...mapGetters(["context"]),
        link() {
            return (
                "/pulse/explore/" +
                this.context +
                "/" +
                this.type +
                "/" +
                this.typeId +
                "/" +
                this.row?.uuid
            );
        },
    },
});
</script>
