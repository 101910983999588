<template>
    <div
        class="fixed bottom-0 inset-x-0 p-4 sm:p-6 sm:flex sm:items-center sm:justify-center z-top"
        :class="{
            'inset-0': interactiveImage,
            'sm:inset-0': !interactiveImage,
        }"
        v-show="active"
    >
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div class="fixed inset-0 transition-opacity" v-if="active">
                <div class="absolute inset-0 bg-gray-900 opacity-75"></div>
            </div>
        </transition>
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="transition ease-in duration-200"
            leave-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
            <template v-if="active">
                <div
                    class="bg-white rounded px-4 pt-5 pb-4 shadow-md transform transition-all sm:w-full sm:p-6"
                    :class="{
                        'sm:max-w-lg overflow-hidden': !interactiveImage,
                        'h-full': interactiveImage,
                    }"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                    v-click-away="close"
                >
                    <div v-if="interactiveImage" class="relative w-full h-full">
                        <button
                            class="flex absolute top-0 right-0 bg-white p-2 rounded-full shadow -mr-7 -mt-7 sm:-mr-9 sm:-mt-9"
                            @click="close"
                        >
                            <SvgIcon icon="close" size="sm" weight="bold" />
                        </button>
                        <iframe
                            class="w-full h-full"
                            :src="interactiveImage"
                            frameborder="0"
                        ></iframe>
                    </div>
                    <div v-else class="sm:flex sm:items-start">
                        <div
                            class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10"
                            :class="theme.background"
                        >
                            <SvgIcon :class="theme.color" :icon="theme.icon" />
                        </div>
                        <div
                            class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"
                        >
                            <h3
                                class="text-lg leading-6 font-medium text-gray-900"
                                id="modal-headline"
                            >
                                {{ heading }}
                            </h3>
                            <p
                                class="text-sm leading-5 text-gray-500"
                                v-html="message"
                            ></p>
                        </div>
                    </div>
                    <div
                        v-if="!interactiveImage"
                        class="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex"
                    >
                        <span class="mt-3 flex w-full sm:mt-0 sm:w-auto">
                            <button
                                type="button"
                                class="inline-flex justify-center w-full rounded-lg border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus-visible:ring transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                                @click="close"
                            >
                                Close
                            </button>
                        </span>
                    </div>
                </div>
            </template>
        </transition>
    </div>
</template>

<script lang="ts">
import SvgIcon from "../SvgIcon.vue";
import { defineComponent } from "vue";

export default defineComponent({
    components: { SvgIcon },
    data(): any {
        return {
            active: false,
            heading: null,
            message: null,
            type: "info",
            interactiveImage: null,
        };
    },
    computed: {
        theme() {
            switch (this.type) {
                case "info":
                    return {
                        background: "bg-blue-100",
                        color: "text-blue-600",
                        icon: "info",
                    };
                    break;
                case "warning":
                    return {
                        background: "bg-orange-100",
                        color: "text-orange-600",
                        icon: "warning",
                    };
                    break;
            }
        },
    },
    methods: {
        close() {
            this.active = false;
        },
        registerInteractiveImageClose(event) {
            const imageBody = event.target.contentDocument.body;

            const handler = ({ target }) => {
                if (Array.from(target.classList).includes("courseExit")) {
                    removeHandler();
                    this.close();
                }
            };

            const removeHandler = function () {
                imageBody.removeEventListener("click", handler);
            };

            imageBody.addEventListener("click", handler);
        },
    },
    mounted() {
        this.$events.$on("openModal", (data) => {
            this.type = data.type;
            this.heading = data.heading;
            this.message = data.message;
            this.active = true;
            this.interactiveImage = data.interactiveImage;
        });
    },
});
</script>
