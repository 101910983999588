import { defineStore, storeToRefs } from "pinia";
import { ref, watch } from "vue";
import algoliasearch from "algoliasearch";
import { useLocalStorage } from "@vueuse/core";
import { useUserStore } from "./userStore";
import algoliaSearchInsights from "search-insights";

const algoliaApp = (import.meta as any).env.VITE_ALGOLIA_APP_ID;
const algoliaKey = (import.meta as any).env.VITE_ALGOLIA_API_KEY;
const algoliaIndex = "courses";

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useSearchStore = defineStore("search", () => {
    const userStore = useUserStore();
    userStore.init().then(async (user) => {
        algoliaSearchInsights("init", {
            appId: algoliaApp,
            apiKey: algoliaKey,
        });
        algoliaSearchInsights("setUserToken", user?.user?.uuid ?? "unknown");
    });

    const { user } = storeToRefs(userStore);
    const isOpen = ref(false);
    const searchQuery = ref("");
    const searchResults = ref<any[]>([]);
    const searchClient = ref(algoliasearch(algoliaApp, algoliaKey));
    const index = ref(searchClient.value.initIndex(algoliaIndex));
    const currentQueryId = ref<string | null>(null);

    const itemRefMap = ref(new Map());
    const selectedListItem = ref<any | null>(null);

    const open = () => {
        isOpen.value = true;
    };

    const close = () => {
        isOpen.value = false;
        searchQuery.value = "";
    };

    const goToResult = (path, index, objectID) => {
        // navigate to result
        if (currentQueryId.value === null) {
            return;
        }
        const payload = {
            userToken: user.value?.user?.uuid ?? "unknown",
            eventName: "search-result_clicked",
            index: algoliaIndex,
            queryID: currentQueryId.value,
            objectIDs: [objectID],
            positions: [index + 1],
        };
        algoliaSearchInsights("clickedObjectIDsAfterSearch", payload);
        window.location.href = path;
    };

    const goToSelectedResult = () => {
        const result = getResultAtIndex(selectedListItem.value);
        if (result) {
            goToResult(
                `/learn/courses/${result.fullSlug}`,
                selectedListItem.value,
                result.objectID
            );
        }
    };

    function debounce(func, wait) {
        let timeout;
        return function executedFunction(...args) {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    }

    const search = debounce((query) => {
        const options: any = {
            hitsPerPage: 10,
            attributesToSnippet: ["description:20"],
            snippetEllipsisText: "...",
            clickAnalytics: true,
        };
        index.value.search(query, options).then(({ hits, queryID }) => {
            unselectAtIndex(selectedListItem.value);
            currentQueryId.value = queryID ?? "";
            selectedListItem.value = 0;
            searchResults.value = hits;
            if (searchResults.value.length > 0) {
                // wrap in setTimeout to allow for DOM to update
                try {
                    setTimeout(() => {
                        selectAtIndex(0);
                    }, 50);
                } catch (e) {}
            }
        });
    }, 500);

    // watch search query and search
    watch(searchQuery, (query) => {
        if (query.length > 2) {
            search(query);
        }
        if (query.length === 0) {
            searchResults.value = [];
        }
    });

    const addListItemRef = (key, methods) => {
        itemRefMap.value.set(key, methods);
    };

    const removeListItemRef = (key) => {
        itemRefMap.value.delete(key);
    };

    const selectAtIndex = (index) => {
        try {
            const item = searchResults.value[index];
            if (item) {
                itemRefMap.value?.get(item.objectID).select();
            }
        } catch (e) {}
    };

    const unselectAtIndex = (index) => {
        try {
            const item = searchResults.value[index];
            if (item) {
                itemRefMap.value?.get(item.objectID).unselect();
            }
        } catch (e) {}
    };

    const getResultAtIndex = (index) => {
        return searchResults.value[index];
    };

    const selectListItem = (direction) => {
        // unselect the current one
        unselectAtIndex(selectedListItem.value);

        if (selectedListItem.value === null) {
            selectedListItem.value = 0;
        } else {
            selectedListItem.value += direction;
        }
        if (selectedListItem.value < 0) {
            selectedListItem.value = searchResults.value.length - 1;
        } else if (selectedListItem.value >= searchResults.value.length) {
            selectedListItem.value = 0;
        }

        selectAtIndex(selectedListItem.value);
    };

    const selectNextListItem = () => {
        selectListItem(1);
    };

    const selectPreviousListItem = () => {
        selectListItem(-1);
    };

    const clearCurrentSelection = () => {
        unselectAtIndex(selectedListItem.value);
        selectedListItem.value = null;
    };

    return {
        isOpen,
        searchQuery,
        searchResults,

        open,
        close,
        addListItemRef,

        selectNextListItem,
        selectPreviousListItem,
        removeListItemRef,
        clearCurrentSelection,
        goToSelectedResult,
        goToResult,
    };
});
