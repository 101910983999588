<template>
    <button v-tippy content="Revoke" @click.prevent="revoke">
        <span class="px-2 py-2 bg-red-50 rounded-l text-red-500 cursor-pointer">
            <svg-icon
                icon="close"
                class="bold"
                style="width: 16px; height: 16px"
            ></svg-icon>
        </span>
    </button>
</template>

<script lang="ts">
import axios from "axios";
import { defineComponent } from "vue";
import $events from "../../event-bus";

export default defineComponent({
    props: {
        route: {
            type: String,
            required: true,
        },
        redirect: {
            type: String,
            required: true,
        },
    },
    methods: {
        revoke() {
            axios.post(this.route).then((data) => {
                $events.$emit("toastNotification", {
                    action: "saved",
                    heading: "Data access revoked!",
                    status: "saved",
                });
                window.location.href = this.redirect;
            });
        },
    },
});
</script>
