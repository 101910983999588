<template>
    <div>
        <PulseExploreLayout :legacy="legacy" v-bind="$attrs">
            <PulseExploreOverviewTable :legacy="legacy" />
        </PulseExploreLayout>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        legacy: Boolean,
    },
};
</script>
