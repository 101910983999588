<template>
    <div class="flex flex-col items-start mr-4 sm:mr-8 mb-4">
        <strong
            class="text-navy-600 text-xs uppercase font-semibold leading-6 ml-2"
            >Report Type</strong
        >
        <div class="relative" v-click-away="hide">
            <button
                class="relative bg-white flex items-center justify-between rounded-lg shadow py-3 px-4 min-w-48 h-12"
                :class="{
                    'ring ring-blue-300 ring-opacity-50': show,
                }"
                @click="show = !show"
            >
                <div class="flex items-center">
                    <SvgIcon class="mr-3" :icon="types[type].icon" size="sm" />
                    <span class="text-sm">{{ types[type].label }}</span>
                </div>
                <div class="flex items-center ml-6 w-3">
                    <SvgIcon
                        v-if="!show"
                        icon="dropdown"
                        size="xs"
                        weight="extra-bold"
                    />
                    <SvgIcon
                        v-if="show"
                        icon="close"
                        size="xs"
                        weight="extra-bold"
                    />
                </div>
            </button>
            <fade
                classes="absolute top-0 left-0 mt-14 z-10 w-full"
                :show="show"
            >
                <div class="flex flex-col bg-white rounded-md shadow">
                    <PulseExploreReportTypeNavItem
                        icon="overview"
                        label="Overview"
                        :path="appPath + '/overview' + context"
                        :active="type == 'overview'"
                    />
                    <PulseExploreReportTypeNavParent
                        icon="controls"
                        label="Details"
                    >
                        <PulseExploreReportTypeNavChild
                            icon="book"
                            label="Lessons"
                            :path="appPath + '/lessons' + context"
                            :active="type == 'lessons'"
                        />
                        <PulseExploreReportTypeNavChild
                            icon="checklist"
                            label="Quizzes"
                            :path="appPath + '/quizzes' + context"
                            :active="type == 'quizzes'"
                        />
                        <PulseExploreReportTypeNavChild
                            icon="exam"
                            label="Assessments"
                            :path="appPath + '/assessments' + context"
                            :active="type == 'assessments'"
                        />
                    </PulseExploreReportTypeNavParent>
                    <PulseExploreReportTypeNavItem
                        icon="clock-sync"
                        label="Timeline"
                        :path="appPath + '/timeline' + context"
                        :active="type == 'timeline'"
                    />
                    <PulseExploreReportTypeNavItem
                        icon="summit"
                        label="Prep"
                        :path="appPath + '/prep' + context"
                        :active="type == 'prep'"
                    />
                </div>
            </fade>
        </div>
    </div>
</template>

<script lang="ts">
import { mapState, mapGetters } from "vuex";

export default {
    props: {
        legacy: Boolean,
    },
    data(): any {
        return {
            show: false,
            types: {
                assessments: {
                    icon: "exam",
                    label: "Assessments",
                },
                lessons: {
                    icon: "book",
                    label: "Lessons",
                },
                overview: {
                    icon: "overview",
                    label: "Overview",
                },
                prep: {
                    icon: "summit",
                    label: "Prep",
                },
                quizzes: {
                    icon: "checklist",
                    label: "Quizzes",
                },
                timeline: {
                    icon: "clock-sync",
                    label: "Timeline",
                },
            },
        };
    },
    computed: {
        ...mapState(["period", "subject", "type"]),
        ...mapGetters(["appPath"]),
        context() {
            return "?subject=" + this.subject + "&period=" + this.period;
        },
    },
    methods: {
        hide() {
            this.show = false;
        },
    },
};
</script>

<style scoped>
button:focus {
    @apply outline-none;
}
</style>
