<template>
    <div
        class="hidden sm:flex flex-col bg-white rounded-md shadow sm:overflow-hidden sm:w-24 md:w-40 lg:w-60 xl:w-68"
    >
        <template v-if="timer">
            <div
                class="flex items-center justify-between border-b border-gray-100 p-4"
            >
                <div class="h-8">
                    <PauseButton
                        v-if="pausable"
                        class="text-gray-500 mr-4"
                        :paused="paused"
                        @changed="pauseExam"
                    />
                </div>
                <vue-countdown
                    :time="timer * 1000"
                    :transform="transform"
                    ref="countdown"
                    @end="endExam"
                    v-slot="{ days, hours, minutes, seconds }"
                >
                    <div
                        class="flex items-center leading-6 font-bold font-mono"
                    >
                        <div v-if="hours != '00'">{{ hours }}:</div>
                        <div>{{ minutes }}:</div>
                        <div>
                            {{ seconds }}
                        </div>
                    </div>
                </vue-countdown>
            </div>
        </template>
        <div class="prep-exam-overview overflow-auto">
            <div class="border-b border-gray-100 p-4">
                <ProgressBar
                    class="mt-2 mb-2"
                    label="Progress"
                    :percent="percentComplete"
                    :complete="['completed', 'expired'].includes(status)"
                    size="md"
                />
            </div>
            <PrepExamOverviewNavigation
                :active="active"
                :path="path"
                :responses="responses"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { usePrepExamStore } from "../../../stores/prepStore";
import { mapActions, mapState } from "pinia";

export default {
    props: {
        active: Number,
        exam: String,
        examTarget: String,
        altPath: String,
        pausable: Boolean,
        percentComplete: Number,
        responses: Array,
        score: Number,
        status: String,
        timer: Number,
    },
    data(): any {
        return {
            offset: new Date().getTimezoneOffset() * 60 * 1000,
            showPanel: false,
        };
    },
    computed: {
        ...mapState(usePrepExamStore, ["paused"]),
        path() {
            return this.altPath ?? "/prep/exams/" + this.exam;
        },
    },
    methods: {
        ...mapActions(usePrepExamStore, ["setPaused"]),
        pauseExam(_paused) {
            console.log(`pauseExam(${_paused})`);
            this.setPaused(_paused);
        },
        endExam() {
            // @ts-ignore
            window.location = this.path + "/" + this.active + "/expire";
        },
        hidePanel() {
            this.showPanel = false;
        },
        transform(props) {
            Object.entries(props).forEach(([key, value]: any) => {
                const digits = value < 10 ? `0${value}` : value;
                props[key] = `${digits}`;
            });
            return props;
        },
    },
    watch: {
        paused(_paused) {
            if (_paused) {
                this.$refs.countdown.abort();
            } else {
                this.$refs.countdown.start();
            }
        },
    },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Fira+Code&display=swap");
</style>
