<template xmlns="http://www.w3.org/1999/html">
    <div>
        <div class="flex flex-wrap md:items-center justify-between mb-6">
            <h2 class="text-2xl font-semibold tracking-tight">
                Manage Courses
            </h2>
            <div class="flex items-center gap-2">
                <Menu as="div" class="relative" v-slot="{ open }">
                    <MenuButton
                        class="button main small group inline-flex items-center rounded-md text-base font-medium"
                        :class="{
                    }"
                    >+ Add Course</MenuButton>
                    <Transition
                        enter-active-class="transition duration-100 ease-out"
                        enter-from-class="transform opacity-0 -translate-y-4"
                        enter-to-class="transform opacity-100 translate-y-0"
                        leave-active-class="transition duration-75 ease-out"
                        leave-from-class="transform opacity-100 translate-y-0"
                        leave-to-class="transform opacity-0 -translate-y-4"
                    >
                        <MenuItems
                            class="absolute divide-y left-1/2 z-10 mt-3 flex flex-col w-screen max-w-[200px] -translate-x-1/2 transform overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                        >
                            <!-- Use the `active` state to conditionally style the active item. -->
                            <MenuItem
                                as="template"
                                v-slot="{ active }"
                            >
                                <button
                                    class="!bg-none p-3.5 hover:bg-blue-50 hover:no-underline hover:text-blue-700 text-sm font-medium text-gray-900 flex items-center gap-2"
                                    @click="handleCreateCourseClick"
                                >
                                    <PlusIcon class="w-6 h-6"/> Create Course
                                </button>
                            </MenuItem>
                            <MenuItem
                                as="template"
                                v-slot="{ active }"
                            >
                                <button
                                    class="hover:bg-blue-50 p-3.5 hover:text-blue-700 hover:no-underline text-sm font-medium text-gray-600 flex items-center gap-2"
                                    @click="handleFileUploadInputClick"
                                >
                                    <input @change="handleFileUploadInputChange" ref="file" id="file" type="file" style="display:none" />
                                    <ArrowDownTrayIcon class="w-6 h-6"/> Upload Launch Doc
                                </button>
                            </MenuItem>
                        </MenuItems>
                    </Transition>
                </Menu>
                <select-dropdown
                    size="sm"
                    theme="flat"
                    v-model="statusFilter"
                    name="statusFilter"
                >
                    <option v-for="s in courseStatuses" :value="s">
                        {{ s }}
                    </option>
                </select-dropdown>
            </div>
        </div>
        <div class="rounded-md shadow divide-y">
            <div
                class="flex items-center bg-gray-200 text-xs text-gray-600 uppercase font-semibold leading-none rounded-t-md pt-6 pb-5"
            >
                <div class="flex items-center justify-between px-6"
                    :class="{ 'w-1/3': statusFilter === 'Published', 'w-1/4': statusFilter === 'Draft' }">
                    Name
                </div>
                <div
                    class="flex items-center justify-between px-6"
                    :class="{ 'w-1/3': statusFilter === 'Published', 'w-1/4': statusFilter === 'Draft' }">
                    Slug
                </div>
                <div
                    class="flex items-center justify-between px-6"
                    :class="{ 'w-1/3': statusFilter === 'Published', 'w-1/4': statusFilter === 'Draft' }">
                  Status
                </div>
              <div v-if="statusFilter === 'Draft'"
                  class="flex items-center justify-between px-6 w-1/4">
                Preview
              </div>
            </div>
            <div>
                <div
                    v-for="(c) in courses" :key="c.slug"
                    class="flex items-center bg-white text-sm even:bg-gray-50 last:rounded-b-md"
                >
                    <div class="p-6"
                         :class="{ 'w-1/3': statusFilter === 'Published', 'w-1/4': statusFilter === 'Draft' }"
                    >
                        <router-link
                            class="cta"
                            :title="c.title"
                            :to="{ name: 'course', params: { uuid: c.uuid } }"
                        >
                            {{ c.title }}
                        </router-link>
                    </div>
                    <div class="font-mono p-6"
                         :class="{ 'w-1/3': statusFilter === 'Published', 'w-1/4': statusFilter === 'Draft' }"
                    >
                        {{ c.slug }}
                    </div>
                    <div class="font-mono p-6"
                         :class="{ 'w-1/3': statusFilter === 'Published', 'w-1/4': statusFilter === 'Draft' }"
                    >
                      <SelectDropdown
                          v-model="c.status"
                          variant="status"
                          @input="(e) => handleStatusUpdate(e.target.value, c)"
                      >
                        <option value="published">Published</option>
                        <option value="draft">Draft</option>
                        <option value="archived">Archived</option>
                      </SelectDropdown>
                    </div>
                  <div v-if="statusFilter === 'Draft'" class="font-mono p-6"
                       :class="{ 'w-1/3': statusFilter === 'Published', 'w-1/4': statusFilter === 'Draft' }"
                  >
                    <Button @click="handlePreviewClick(c.slug)">Preview Course</Button>
                  </div>
                </div>
            </div>
            <div class="flex justify-end">
                <Pagination
                    class="p-6"
                    :model-value="pagination.currentPage"
                    :total="pagination.total"
                    :perPage="pagination.perPage"
                    @update:modelValue="getCourses"
                />
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import axios from "axios";
import Pagination from "../../../_elements/Pagination.vue";
import { mapMutations } from "vuex";
import {defineComponent, ref} from "vue";
import Button from "@/components/ui/ButtonLinks/Button.vue";
import Link from "@/components/ui/ButtonLinks/Link.vue";
import SelectDropdown from "@/components/_elements/SelectDropdown.vue";
import $events from "@/event-bus.ts";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { PlusIcon, ArrowDownTrayIcon } from "@heroicons/vue/20/solid";

export default defineComponent({
    components: {ArrowDownTrayIcon, PlusIcon, Menu, MenuButton, MenuItems, MenuItem, SelectDropdown, Pagination, Button, Link },
    data(): any {
        return {
            courses: [],
            path: window.location.pathname + "/",
            pagination: {
                perPage: 1,
                total: 0,
                currentPage: 1,
            },
            courseStatuses: ["Published", "Archived", "Draft"],
            statusFilter: "Published",
            open: true,
        };
    },
    methods: {
        handleCreateCourseClick() {
            this.$router.push('/content/learn/courses/new');
        },
        handleFileUploadInputClick() {
            this.$refs.file.click();
        },
        async handleFileUploadInputChange(e) {
            const formData = new FormData();
            const file = e.target.files[0];
            formData.append("launch-sheet", file);

            return axios.post('/api/admin/content/courses/upload-launch-sheet', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(res => {
                    if (res.status === 202) {
                        $events.$emit("toastNotification", {
                            action: "saved",
                            heading: "Course Launch Sheet Submitted Successfully",
                            message: "You'll receive an email when the upload process is complete.",
                            status: "saved",
                        });
                    }
                })
                .catch(e => {
                    console.error(e)
                    $events.$emit("toastNotification", {
                        action: "error",
                        heading: `Error Uploading Course Launch Sheet`,
                        message: `${Object.values(e.response.data.errors).join(" ")}`,
                        status: "error",
                    });
                });
        },
        handlePreviewClick(slug) {
          const url = `${window.location.origin}/learn/courses/${slug}`
          window.open(url, "_blank")
        },
        getCourses(pageNumber) {
            axios
                .post(`/api/admin/content/learn/courses`, {
                    page: pageNumber,
                    status: this.statusFilter,
                })
                .then((response) => {
                    this.courses = response.data.data;
                    this.pagination.perPage = response.data.per_page;
                    this.pagination.total = response.data.total;
                    this.pagination.currentPage = response.data.current_page;
                });
        },
        handleStatusUpdate(status, course) {
          if (course.status === status) {
            return;
          }

          course.categories = course.categories.map((c) => c.slug)

          axios
              .put(`/api/admin/content/learn/courses/${course.uuid}`, { ...course, status })
              .then((response) => {
                  if (response.status < 400) {
                    $events.$emit("toastNotification", {
                      action: "saved",
                      heading: "Course saved",
                      status: "success",
                    });
                      this.getCourses(this.pagination.currentPage)
                  }
              })
              .catch((e) => {
                $events.$emit("toastNotification", {
                  action: "saved",
                  heading: "Error Saving Course",
                  status: "error",
                  message: e.response.data.message
                });
              });
        },
        ...mapMutations(["hideBladePage"]),
    },
    watch: {
        statusFilter() {
            this.getCourses(this.pagination.currentPage);
        },
    },
    mounted() {
        this.hideBladePage();
        this.getCourses(1);
    },
});
</script>
