<template>
    <div>
        <section class="section-head">
            <page-title
                app="Prep"
                section="Exams"
                sectionPath="/prep/exams"
                action="Show"
                icon="binocular"
            />
        </section>
        <section class="section-main">
            <section id="blueprintSelect">
                <h2 class="font-medium ml-4">Saved Configurations</h2>
                <!-- if no blueprints, say something about how they _would_ be here and have a button for going to the builder -->
                <div v-for="blueprint in blueprints" class="card"></div>
            </section>
        </section>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        blueprints: {
            type: Array,
            default: () => {},
        },
    },
};
</script>
