<template>
    <div class="flex flex-col bg-white rounded-md shadow p-8">
        <div class="flex items-center justify-end">
            {{ current + 1 }}/{{ flashcards.length }}
        </div>
        <div class="flex items-center justify-center h-120">
            <transition
                enter-active-class="transition ease-out duration-200"
                enter-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition ease-in duration-200"
                leave-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <Flashcard
                    class="absolute"
                    :key="flashcards[current].id"
                    :flashcard="flashcards[current]"
                    @result="setResult"
                    @flip="incrementCardInteraction"
                />
            </transition>
        </div>
        <div class="flex items-center justify-center space-x-4 py-4">
            <button
                type="button"
                class="flex items-center justify-center text-white rounded-full w-6 h-6"
                :class="{
                    'bg-blue-400': current > 0,
                    'bg-gray-200 cursor-not-allowed': current == 0,
                }"
                @click="displayPrevCard"
            >
                <SvgIcon icon="chevron-left" size="xs" weight="bold" />
            </button>
            <ul class="flex space-x-2">
                <li v-for="(flashcard, i) in flashcards">
                    <button
                        type="button"
                        class="rounded-full h-4 w-4 flex items-center justify-center"
                        :class="{
                            'bg-gray-400': i == current,
                            'bg-gray-200': i != current,
                        }"
                        @click="goto(i)"
                    />
                </li>
            </ul>
            <button
                type="button"
                class="flex items-center justify-center text-white rounded-full w-6 h-6"
                :class="{
                    'bg-blue-400': current < flashcards.length - 1,
                    'bg-gray-200 cursor-not-allowed':
                        current == flashcards.length - 1,
                }"
                @click="displayNextCard"
            >
                <SvgIcon icon="chevron-right" size="xs" weight="bold" />
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";

export default {
    name: "Flashcards",

    props: {
        flashcards: {
            type: Array,
            required: true,
        },
        lessonInstanceId: {
            type: Number,
            required: true,
        },
    },

    data(): any {
        return {
            current: 0,
            results: [],
        };
    },

    methods: {
        setResult(result) {
            this.$set(this.results, this.current, result.status);
            this.displayNextCard();

            if (result.didFlip) {
                this.incrementCardInteraction();
            }
        },
        displayNextCard() {
            if (this.current < this.flashcards.length - 1) {
                this.current = this.current + 1;
            }
        },
        displayPrevCard() {
            if (this.current > 0) {
                this.current = this.current - 1;
            }
        },
        goto(i) {
            this.current = i;
        },
        incrementCardInteraction() {
            axios.post(
                `/api/learn/lessons/${this.lessonInstanceId}/flashcard-interactions`
            );
        },
    },
};
</script>
