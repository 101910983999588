<template>
    <input type="checkbox" v-model="checked" @change="toggle" />
</template>

<script lang="ts">
export default {
    props: {
        name: String,
    },
    data(): any {
        return {
            checked: false,
        };
    },
    methods: {
        toggle() {
            document
                .getElementsByName(this.name)
                .forEach((e: any) => (e.checked = this.checked));
        },
        masterToggle() {
            let checkboxes = document.getElementsByName(this.name);
            let checked = Array.prototype.filter.call(
                checkboxes,
                (c) => c.checked
            );

            this.checked = checkboxes.length === checked.length;
        },
    },
    mounted() {
        document
            .getElementsByName(this.name)
            .forEach((e) =>
                e.addEventListener("click", () => this.masterToggle(), false)
            );
    },
};
</script>
