<template>
    <PulseStandardRowTable
        :rows="tableData"
        :columns="columns"
        :filters="['status', 'type']"
        title="Exam Activity"
        :exportable="true"
        :loading="loading"
        :table-min-width="982"
        row-as="pulse-exams-table-row"
        :selectable="true"
    />
</template>

<script lang="ts">
import axios from "axios";

export default {
    data(): any {
        return {
            tableData: [],
            loading: true,
            columns: [
                {
                    header: "Date Submitted",
                    width: "15",
                },
                {
                    header: "Name",
                    width: "20",
                },
                {
                    header: "Exam Name",
                    width: "15",
                },
                {
                    header: "Type",
                    width: "10",
                },
                {
                    header: "Categories",
                    width: "20",
                },
                {
                    header: "Time",
                    width: "10",
                },
                {
                    header: "Score",
                    width: "10",
                },
            ],
        };
    },
    mounted() {
        axios
            .get(
                "https://my.api.mockaroo.com/pulse_prep_exams_data.json?key=189cc610"
            )
            .then((response) => {
                this.tableData = response.data;
                this.loading = false;
            });
    },
};
</script>
