<template>
    <li
        class="relative px-3 py-2 text-gray-900 cursor-default select-none"
        role="option"
        :aria-selected="selected"
    >
        <div class="flex items-center">
            <img
                v-if="image"
                :src="image"
                :alt="ariaLabel"
                class="flex-shrink-0 w-6 h-6 mr-3 rounded-full"
            />
            <span
                v-if="selectable"
                class="w-full truncate"
                :class="{ 'font-medium': selected }"
            >
                <slot />
            </span>

            <button
                v-else
                class="flex items-center w-full text-left no-underline capitalize truncate cta"
            >
                <slot />
            </button>
        </div>

        <span
            v-if="selectable"
            class="absolute inset-y-0 right-0 flex items-center pr-3 text-indigo-600"
        >
            <input
                v-model="selected"
                class="cursor-pointer"
                name="searchable-select-checkbox"
                type="checkbox"
                :aria-checked="selected"
                :aria-label="ariaLabel"
                @change="(e) => $emit('change', selected)"
            />
        </span>
    </li>
</template>

<script lang="ts">
export default {
    props: {
        image: {
            type: String,
            required: false,
            default: null,
        },
        dropdownHasSelections: {
            type: Boolean,
            required: false,
            default: false,
        },
        selectable: {
            type: Boolean,
            required: false,
            default: false,
        },
        checkedOnInit: {
            type: Boolean,
            required: false,
            default: false,
        },
        ariaLabel: {
            type: String,
            required: false,
            default: "",
        },
    },
    data(): any {
        return {
            selected: false,
        };
    },
    computed: {
        dropdownStatePristine() {
            return !(this.selectable && this.dropdownHasSelections);
        },
    },
    watch: {
        dropdownStatePristine(updated) {
            if (!updated) {
                return;
            }

            this.selected = false;
        },
    },
    mounted() {
        if (this.checkedOnInit) {
            this.selected = true;
        }
    },
};
</script>
