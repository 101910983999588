import { cva } from "class-variance-authority";

export const buttonStyles = cva(
    [
        "inline-flex w-full sm:w-auto items-center justify-center rounded-md border border-transparent font-semibold no-underline hover:no-underline outline-none transition sm:w-auto",
    ],
    {
        variants: {
            intent: {
                primary: [
                    "bg-blue-700 text-white hover:bg-blue-800 hover:text-white hover:shadow-none shadow",
                ],
                secondary: [
                    "bg-blue-100 text-blue-700 hover:bg-blue-200 hover:text-blue-800 hover:shadow-none shadow",
                ],
                cta: [
                    "bg-yellow-500 text-gray-900 hover:bg-yellow-400 hover:text-gray-900 hover:shadow-none shadow",
                ],
                danger: ["bg-red-500 text-white"],
                warning: ["bg-yellow-500 text-white"],
                success: ["bg-green-500 text-white"],
                flat: [
                    "bg-transparent text-gray-500 hover:text-gray-700 hover:bg-gray-200",
                ],
            },
            size: {
                xs: ["text-xs py-2 px-4"],
                sm: ["text-sm py-2 px-4"],
                md: ["text-base py-3 px-5"],
                lg: ["text-lg py-3 px-5"],
            },
            full: {
                true: ["w-full"],
            },
            round: {
                true: ["rounded-full"],
                false: ["rounded"],
            },
            isDisabled: {
                true: ["disabled:opacity-75 disabled:cursor-not-allowed"]
            }
        },
        defaultVariants: {
            intent: "primary",
            size: "md",
            full: false,
            round: false,
        },
    }
);
