<template>
    <div class="relative" v-click-away="(isShow = false)">
        <div>
            {{ label }}
        </div>
        <div class="relative">
            <div
                @click="isShow = !isShow"
                class="border border-gray-200 rounded-lg p-3 w-98 relative"
            >
                <div style="min-height: 22px">{{ defaultText }}</div>
                <div
                    class="flex justify-end absolute w-full px-6 py-4 top-0 left-1"
                >
                    <SvgIcon
                        icon="chevron-down"
                        size="xs"
                        weight="extra-bold"
                    />
                </div>
            </div>
            <div
                v-show="isShow"
                class="select-dropdown w-98 px-3 pb-3 absolute border border-gray-200 rounded-b-lg border-t-0 bg-white z-10"
            >
                <div v-for="(result, key) in results">
                    <div v-if="result.title" class="py-2">
                        <span>
                            <SelectAll :name="`check${key}[]`" />
                        </span>
                        <span class="font-semibold">{{ result.title }}</span>
                    </div>
                    <div
                        v-for="option in result.options"
                        class="py-2 pl-3 flex align-content-center"
                    >
                        <span class="mr-3">
                            <input
                                type="checkbox"
                                :id="option.id"
                                :name="`check${key}[]`"
                                :value="option.id"
                                :checked="option.selected"
                            />
                        </span>
                        <span class="mt-1/2">{{ option.label }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
/*
This component accepts data in this format:

[{title: 'Title 1', options: [{label: 'test 1', selected: true, id: 1}, {label: 'test 2', selected: false, id: 2}]},
    {title: 'Title 2', options: [{label: 'test 3', selected: true, id: 3}, {label: 'test 4', selected: false, id: 4}]},
    { options: [{label: 'test 5', selected: true, id: 5}, {label: 'test 6', selected: false, id: 6}]}]

note third entry has no title
 */
import SelectAll from "./SelectAll.vue";

export default {
    name: "MulticheckboxSelect",
    components: { SelectAll },
    props: {
        label: String,
        defaultText: String,
        res: String,
    },
    data(): any {
        return {
            results: JSON.parse(this?.res || "[]"),
            isShow: false,
        };
    },
};
</script>

<style scoped>
.select-dropdown {
    left: 0;
    top: 2.7rem;
    max-height: 250px;
    overflow: scroll;
}
</style>
