<template>
    <div>
        <template v-if="assignments.length > 0">
            <div class="flex items-center justify-between mb-2">
                <h2 class="text-xl font-medium px-4 w-128">Assignments</h2>
                <div class="hidden sm:flex items-center">
                    <div
                        class="flex justify-start text-gray-600 text-sm font-medium uppercase pl-6 w-42"
                    >
                        Due date
                    </div>
                    <div
                        class="flex justify-center text-gray-600 text-sm font-medium uppercase w-20"
                    >
                        ?
                    </div>
                    <div
                        class="flex justify-center text-gray-600 text-sm font-medium uppercase w-36"
                    >
                        Timer
                    </div>
                    <div class="w-48"></div>
                </div>
            </div>
            <div class="bg-white rounded-md shadow">
                <div class="flex flex-col">
                    <PrepAssignmentAssignedCollectionResource
                        v-for="assignment in assignments"
                        :assignment="assignment"
                        :key="assignment.uuid"
                    />
                </div>
            </div>
        </template>
        <template v-else>
            <div
                class="flex items-center justify-center text-gray-500 text-3xl font-light h-40"
            >
                No assignments
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { PropType } from "vue";
export default {
    props: {
        assignments: {
            type: Array as PropType<any[]>,
            default: () => [],
        },
    },
};
</script>
