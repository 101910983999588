<template>
    <div
        class="flex flex-wrap items-center justify-between border-b border-gray-100 last:border-b-0"
    >
        <template v-if="!instanceId && type != 'lessons'">
            <a
                class="cta text-sm p-4"
                :href="path"
                v-text="dateTime(time, user.timezone)"
            />
        </template>
        <template v-else>
            <div class="text-sm p-4" v-text="dateTime(time, user.timezone)" />
        </template>
        <template v-if="type != 'lessons'">
            <div
                class="flex flex-wrap sm:flex-wrap-none items-center justify-center border sm:border-none border-dashed border-gray-400 rounded-lg sm:rounded-none mb-4 mx-4 sm:m-0 p-4 sm:p-0 w-full sm:w-auto"
            >
                <div
                    class="flex flex-col sm:flex-row items-center justify-center leading-6 sm:border-l border-gray-100 px-4 w-1/2 sm:w-32 h-18"
                >
                    <span class="sm:hidden text-sm font-bold underline"
                        >Score</span
                    >
                    <span v-html="grade(score, settings.pulse_grade)" />
                </div>
                <div
                    class="flex flex-col sm:flex-row items-center justify-center leading-6 sm:border-l border-gray-100 px-4 w-1/2 sm:w-24 h-18"
                >
                    <span class="sm:hidden text-sm font-bold underline"
                        >Time</span
                    >
                    <span v-html="elapsed(duration)" />
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { mapState } from "vuex";
import { defineComponent } from "vue";
import { filters } from "../../../../../vue-filters";

export default defineComponent({
    props: {
        legacy: Boolean,
    },
    methods: {
        ...filters,
    },
    computed: {
        ...mapState([
            "instanceId",
            "period",
            "settings",
            "subject",
            "type",
            "typeId",
            "user",
        ]),
        base() {
            return this.legacy ? "/pulse/explore-legacy/" : "/pulse/explore/";
        },
        instance() {
            return this.legacy ? this.$attrs.uuid : this.$attrs.instance;
        },
        path() {
            return (
                this.base +
                this.type +
                "?subject=" +
                this.subject +
                "&period=" +
                this.period +
                "&id=" +
                this.typeId +
                "&instance=" +
                this.instance
            );
        },
        score() {
            return this.legacy
                ? this.$attrs.grade
                : // @ts-ignore
                  this.$attrs.properties.score;
        },
        duration() {
            return this.legacy
                ? this.$attrs.time_elapsed
                : // @ts-ignore
                  this.$attrs.properties.duration;
        },
        time() {
            return this.$attrs.timestamp || this.$attrs.updated_at;
        },
    },
});
</script>
