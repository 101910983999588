<template>
    <div
        class="fixed right-0 bottom-0 z-100 flex flex-col items-end space-y-2 p-4 w-96"
    >
        <Toast
            v-for="(toast, index) in toasts"
            :key="index"
            :action="toast.action"
            :heading="toast.heading"
            :message="toast.message"
            :status="toast.status"
        />
    </div>
</template>

<script lang="ts">
export default {
    data(): any {
        return {
            toasts: [],
        };
    },
    mounted() {
        this.$events.$on("toastNotification", (data) => {
            this.toasts.push(data);
        });
    },
};
</script>
