<template>
    <div class="relative" v-click-away="blur">
        <input
            class="text-gray-800 border-gray-200 p-3 placeholder-gray-400 border rounded-lg"
            autocomplete="off"
            ref="searchBox"
            @focus="focused = true"
            name="searchinput[]"
            type="text"
            :placeholder="placeholderText"
            v-model="search"
        />
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-class="opacity-0 translate-y-2"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="transition ease-in duration-200"
            leave-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 translate-y-2"
        >
            <div
                class="fixed sm:absolute top-0 left-0 sm:left-auto mt-36 sm:mt-13 sm:-ml-16 w-full sm:w-136 transform transition-all"
                v-show="focused"
                style="z-index: 1"
            >
                <div
                    class="overflow-hidden sm:rounded-md shadow-lg sm:mt-1 sm:-ml-px"
                >
                    <div class="bg-white">
                        <div
                            class="flex items-center justify-between bg-gradient-to-tr from-blue-500 to-blue-400 text-white font-medium p-4"
                        >
                            <div class="leading-7">
                                Searching
                                <span class="font-bold">{{ searchable }}</span>
                            </div>
                            <div>
                                <SvgIcon
                                    v-if="status == 'searching'"
                                    class="animate-pulse"
                                    icon="circles"
                                />
                                <div
                                    v-if="search && status == 'ready'"
                                    class=""
                                >
                                    {{ results.length }} results
                                </div>
                            </div>
                        </div>
                        <div class="overflow-y-auto max-h-64" ref="results">
                            <a
                                v-for="(result, key) in results"
                                class="block hover:no-underline focus:bg-gray-50 border-b border-gray-100 last:border-b-0 focus:font-medium cursor-pointer p-4"
                                @mouseover="highlight(key)"
                                @click="makeText(result)"
                                href="#"
                            >
                                {{ result.term }}
                            </a>
                            <div
                                class="p-4"
                                v-if="search && results.length === 0"
                            >
                                No results found
                            </div>
                            <div class="p-4" v-if="!search">
                                Start typing to search
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import debounce from "debounce";
import { mapState } from "vuex";

export default {
    props: {
        app: String,
        searchable: String,
        searchAddition: String,
        valueText: String,
        displayText: String,
    },
    data(): any {
        return {
            focused: false,
            results: [],
            search: this.displayText,
            showSearch: false,
            status: "ready",
            placeholderText: this.displayText ? this.displayText : "Search",
        };
    },
    computed: {
        ...mapState(["period"]),
    },
    methods: {
        blur() {
            this.showSearch = false;
            this.focused = false;
            this.$refs.searchBox.blur();
        },
        getResults() {
            if (this.search.length > 2) {
                this.status = "searching";
                axios
                    .get(
                        "/api/search" +
                            "?app=" +
                            this.app +
                            "&type=" +
                            this.searchable +
                            "&q=" +
                            this.search +
                            this.searchAddition
                    )
                    .then((response) => {
                        this.status = "ready";
                        this.results = response.data;
                    });
            } else {
                this.results = [];
            }
        },
        highlight(index) {
            this.highlighted = index;
            if (index == -1) {
                this.$refs.searchBox.focus();
            } else {
                this.$refs.results.getElementsByTagName("a")[index].focus();
            }
        },
        show() {
            this.showSearch = true;
            this.focused = true;
        },
        makeText(text) {
            this.search = text.term;
            this.blur();
            //emit event which is listened for on calling page/component
            this.$emit("selectedInput", text);
        },
    },
    mounted() {
        this.getResults = debounce(this.getResults, 200);

        this.$events.$on("focusSearch", (data) => {
            this.$nextTick(() => {
                this.$refs.searchBox.focus();
            });
        });
    },
    watch: {
        search() {
            this.getResults();
        },
    },
};
</script>
