<template>
    <div class="flex flex-col space-y-16">
        <div class="bg-white rounded-md shadow">
            <PrepCategoryReport
                :showBankDropdown="true"
                :showPulseLink="true"
                :showTitle="true"
                :bank="bank"
                period="all"
                :rows="[]"
                :subject="user.uuid"
                @updateBank="updateBank"
            />
        </div>
        <PrepExamCollection
            :settings="user.settings"
            :exams="exams"
            :complete="true"
        />
    </div>
</template>

<script lang="ts">
import { mapState } from "vuex";

export default {
    props: {
        exams: Array,
    },
    data(): any {
        return {
            bank: "Radiography",
        };
    },
    computed: {
        ...mapState(["user"]),
    },
    methods: {
        updateBank(bank) {
            this.bank = bank;
        },
    },
};
</script>
