<script lang="ts">
import ReportingTabs from "../shared/ReportingTabs.vue";
import ReportingRouteMixin from "../ReportingRouteMixin.vue";
import PulseReportPeriod from "../../Pulse/_shared/PulseReportPeriod.vue";
import { mapState } from "vuex";
import { getModuleStats } from "../../../api/pulse-requests";

export default {
    components: {
        PulseReportPeriod,
        ReportingTabs,
    },
    data(): any {
        return {
            moduleStats: null,
        };
    },
    computed: {
        moduleId: function () {
            return this.$route.params.moduleId;
        },
        tabs: function () {
            return [];
        },
        watchContext: function () {
            return {
                partner: this.partner,
                courseId: this.courseId,
                period: this.period,
                group: this.group,
            };
        },
        breadcrumbs() {
            const isStructuredView = this.$route.path.includes("/structured");
            return [
                {
                    title: "All Courses",
                    to: {
                        path: isStructuredView
                            ? "/courses/activity/structured"
                            : "/courses/activity/learner",
                        query: { ...this.$route.query, page: 1 },
                    },
                },
                {
                    title: this.moduleStats?.course?.title ?? "",
                    to: {
                        path: isStructuredView
                            ? `/course/${this.moduleStats?.course?.uuid}/activity/structured`
                            : `/course/${this.moduleStats?.course?.uuid}/activity/learner`,
                        query: { ...this.$route.query, page: 1 },
                    },
                },
                {
                    title: this.moduleStats?.module?.title ?? "",
                },
            ];
        },
        ...mapState(["partner", "group", "period", "selectedLearners"]),
    },
    methods: {
        getModuleStats: function () {
            if (this.partner || this.group) {
                getModuleStats(
                    this.partner,
                    this.group,
                    this.period,
                    this.moduleId,
                    this.selectedLearners
                )
                    .then((response) => {
                        this.moduleStats = response.data;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
    },
    watch: {
        watchContext: {
            handler: function () {
                this.getModuleStats();
            },
            deep: true,
            immediate: true,
        },
    },
    mixins: [ReportingRouteMixin],
};
</script>
<template>
    <div>
        <section class="mb-8 section-title">
            <PulseSubjectHeader />
        </section>
        <section class="mb-8 section-title">
            <PulseCourseEngagementCard
                :image="moduleStats?.course?.asset ?? null"
                :image-alt="moduleStats?.course?.title ?? ''"
                :breadcrumbs="breadcrumbs"
                :active-learners="moduleStats?.stats?.active_learners"
                class="mb-9"
                :course="{}"
            >
                <template v-slot:stats>
                    <PulseContentEngagementStats
                        v-if="moduleStats"
                        :lesson-views="moduleStats.stats.lesson_views"
                        :assessment-attempts="
                            moduleStats.stats.module_quiz_attempts
                        "
                        :assessment-average-score="
                            moduleStats.stats.module_quiz_average_score
                        "
                        :quiz-attempts="moduleStats.stats.lesson_quiz_attempts"
                        :quiz-average-score="
                            moduleStats.stats.lesson_quiz_average_score
                        "
                    />
                </template>
            </PulseCourseEngagementCard>
        </section>
        <section class="flex flex-wrap justify-end mb-6 section-title">
            <PulseReportPeriod />
        </section>
        <router-view></router-view>
    </div>
</template>
