<template>
    <PulseContentEngagementCard>
        <div class="flex flex-col w-full space-y-5">
            <div class="lg:flex lg:justify-between">
                <div class="sm:flex sm:items-center">
                    <router-link
                        :to="moduleUrl"
                        class="mr-4 text-lg font-medium text-blue-600 truncate lg:text-xl"
                    >
                        {{ mod.title }}
                    </router-link>
                    <PulseContentCountBadges
                        class="mt-1 mb-2 sm:my-0"
                        :lessons="mod.lesson_count"
                    />
                </div>

                <p
                    class="text-sm italic font-medium text-gray-500 md:text-base"
                >
                    {{ mod.active_learners }}
                    {{ pluralizeLabel("active learner", mod.active_learners) }}
                </p>
            </div>

            <PulseContentEngagementStats
                :lesson-views="mod.lesson_views"
                :assessment-attempts="mod.module_quiz_attempts"
                :assessment-average-score="mod.module_quiz_average_score"
                :quiz-attempts="mod.lesson_quiz_attempts"
                :quiz-average-score="mod.lesson_quiz_average_score"
            />
        </div>
    </PulseContentEngagementCard>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import { filters } from "../../../../vue-filters";

export default {
    props: {
        mod: {
            type: Object,
            required: true,
        },
    },
    methods: {
        ...filters,
    },
    computed: {
        ...mapGetters(["context"]),
        moduleUrl() {
            const queryParams = this.$route.query;
            return {
                path: `/module/${this.mod.uuid}/structured`,
                query: queryParams,
            };
        },
    },
};
</script>
