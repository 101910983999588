<template>
    <transition
        enter-active-class="transition transform ease-out duration-200"
        enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
    >
        <div
            v-show="show"
            class="flex bg-white border-l-4 rounded-lg shadow-md pointer-events-none p-2 sm:p-4 w-full"
            :class="theme.border"
        >
            <div class="flex items-start w-full">
                <div class="flex flex-shrink-0 mt-1/2">
                    <SvgIcon
                        :class="theme.color"
                        :icon="theme.icon"
                        size="base"
                    />
                </div>
                <div class="ml-3 w-0 flex-1 pt-0.5">
                    <p class="text-sm leading-5 font-medium text-gray-900">
                        {{ heading }}
                    </p>
                    <p class="mt-1 text-sm leading-5 text-gray-500">
                        {{ message }}
                    </p>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
export default {
    props: {
        action: String,
        heading: String,
        message: String,
        status: String,
        timeout: {
            type: Number,
            default: 6000,
        },
    },
    data(): any {
        return {
            show: false,
        };
    },
    computed: {
        theme() {
            switch (this.status) {
                case "complete":
                case "correct":
                case "saved":
                case "success":
                    return {
                        icon: "check",
                        color: "text-green-400",
                        border: "border-green-500",
                    };
                    break;

                case "sent":
                    return {
                        icon: "message",
                        color: "text-green-400",
                        border: "border-green-500",
                    };
                    break;

                case "alert":
                case "incorrect":
                case "error":
                    return {
                        icon: "wrong",
                        color: "text-red-400",
                        border: "border-red-500",
                    };
                    break;
                case "deleted":
                    return {
                        icon: "remove",
                        color: "text-red-400",
                        border: "border-red-500",
                    };
                    break;

                case "removed":
                    return {
                        icon: "close-circle",
                        color: "text-red-400",
                        border: "border-red-500",
                    };
                    break;

                case "synced":
                    return {
                        icon: "sync",
                        color: "text-blue-400",
                        border: "border-blue-500",
                    };
                default:
                    return {
                        icon: "info",
                        color: "text-blue-400",
                        border: "border-blue-500",
                    };
            }
        },
    },
    methods: {
        reveal() {
            this.show = true;
            setTimeout(() => (this.show = false), this.timeout);
        },
    },
    mounted() {
        this.reveal();
    },
};
</script>
