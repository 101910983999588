<template>
    <div class="flex flex-col items-start mb-4">
        <strong
            class="text-navy-600 text-xs uppercase font-semibold leading-6 ml-2"
            >Report Period</strong
        >
        <div class="relative" v-click-away="hide">
            <button
                class="relative bg-white flex items-center justify-between rounded-lg shadow py-3 px-4 min-w-48 h-12"
                :class="{
                    'ring ring-blue-300 ring-opacity-50': show,
                }"
                @click="show = !show"
            >
                <div class="flex items-center">
                    <SvgIcon
                        class="mr-3"
                        :icon="calendarIcon"
                        size="sm"
                        weight="medium"
                    />
                    <span class="text-sm">{{ periodLabel }}</span>
                </div>
                <div class="flex items-center ml-6 w-3">
                    <SvgIcon
                        v-if="!show"
                        icon="dropdown"
                        size="xs"
                        weight="extra-bold"
                    />
                    <SvgIcon
                        v-if="show"
                        icon="close"
                        size="xs"
                        weight="extra-bold"
                    />
                </div>
            </button>
            <fade
                classes="absolute top-0 left-0 sm:left-auto sm:right-0 -ml-4 mb-4 sm:ml-0 sm:mb-0 mt-14 z-80 sm:z-10 w-screen sm:w-auto"
                :show="show"
            >
                <div class="bg-white rounded-md shadow">
                    <div class="flex flex-wrap sm:flex-nowrap items-start">
                        <div
                            class="grid grid-cols-2 sm:inline-flex sm:flex-col text-sm whitespace-nowrap pt-8 sm:pt-12 px-6 w-full"
                        >
                            <a
                                class="flex items-center justify-start sm:justify-end sm:mt-2 rounded-lg mb-1 p-2"
                                :href="link('1w')"
                                :class="{
                                    'bg-blue-100 text-blue-600 font-medium':
                                        period == '1w',
                                }"
                            >
                                <SvgIcon
                                    class="mr-2"
                                    icon="calendar-1w"
                                    size="sm"
                                    weight="medium"
                                />
                                Past week
                            </a>
                            <a
                                class="flex items-center justify-start sm:justify-end rounded-lg mb-1 p-2"
                                :href="link('1m')"
                                :class="{
                                    'bg-blue-100 text-blue-600 font-medium':
                                        period == '1m',
                                }"
                            >
                                <SvgIcon
                                    class="mr-2"
                                    icon="calendar-1m"
                                    size="sm"
                                    weight="medium"
                                />
                                Past month
                            </a>
                            <a
                                class="flex items-center justify-start sm:justify-end rounded-lg mb-1 p-2"
                                :href="link('3m')"
                                :class="{
                                    'bg-blue-100 text-blue-600 font-medium':
                                        period == '3m',
                                }"
                            >
                                <SvgIcon
                                    class="mr-2"
                                    icon="calendar-3m"
                                    size="sm"
                                    weight="medium"
                                />
                                Past 3 months
                            </a>
                            <a
                                class="flex items-center justify-start sm:justify-end rounded-lg mb-1 p-2"
                                :href="link('1y')"
                                :class="{
                                    'bg-blue-100 text-blue-600 font-medium':
                                        period == '1y',
                                }"
                            >
                                <SvgIcon
                                    class="mr-2"
                                    icon="calendar-1y"
                                    size="sm"
                                    weight="medium"
                                />
                                Past year
                            </a>
                            <div
                                class="flex items-center justify-end bg-blue-50 text-blue-600 font-medium p-2 rounded-lg text-right cursor-pointer"
                                v-show="periodIsCustom"
                            >
                                <SvgIcon
                                    class="mr-2"
                                    icon="calendar-custom"
                                    size="sm"
                                    weight="medium"
                                />
                                Custom
                            </div>
                        </div>
                        <div
                            class="flex flex-wrap sm:flex-nowrap items-center justify-center sm:pr-2"
                        >
                            <div
                                class="flex flex-col border-b sm:border-b-0 sm:border-l border-gray-100 pt-8 px-2"
                            >
                                <div class="flex flex-col mx-4 mb-2">
                                    <div
                                        class="text-sm font-medium leading-5 mb-1"
                                    >
                                        Start Date
                                    </div>
                                    <input
                                        class="border border-gray-200 rounded p-2 focus-visible:ring focus-visible:ring-blue-300 focus-visible:ring-opacity-50"
                                        v-model="startDateString"
                                        @change="updateStart"
                                    />
                                </div>
                                <v-date-picker
                                    ref="start"
                                    v-model="startDate"
                                />
                            </div>
                            <div
                                class="flex flex-col sm:border-l border-gray-100 pt-8 pl-2"
                            >
                                <div class="flex flex-col mx-4 mb-2">
                                    <div
                                        class="text-sm font-medium leading-5 mb-1"
                                    >
                                        End Date
                                    </div>
                                    <input
                                        class="border border-gray-200 rounded p-2 focus-visible:ring focus-visible:ring-blue-300 focus-visible:ring-opacity-50"
                                        v-model="endDateString"
                                        @change="updateEnd"
                                    />
                                </div>
                                <v-date-picker ref="end" v-model="endDate" />
                            </div>
                        </div>
                    </div>
                    <div
                        class="flex justify-end bg-gray-50 border-t border-gray-100 rounded-b-md p-6"
                    >
                        <button
                            class="small primary button"
                            type="button"
                            @click="update"
                        >
                            Update
                        </button>
                    </div>
                </div>
            </fade>
        </div>
    </div>
</template>

<script lang="ts">
import { format, parse, sub } from "date-fns";
import { mapState } from "vuex";

export default {
    props: {
        legacy: Boolean,
    },
    data(): any {
        return {
            show: false,
            startDate: null,
            startDateString: null,
            endDate: null,
            endDateString: null,
        };
    },
    computed: {
        ...mapState(["period", "type"]),
        calendarIcon() {
            switch (this.period) {
                case "1w":
                    return "calendar-1w";
                    break;
                case "1m":
                    return "calendar-1m";
                    break;
                case "3m":
                    return "calendar-3m";
                    break;
                case "1y":
                    return "calendar-1y";
                    break;
                default:
                    return "calendar-custom";
            }
        },
        periodIsCustom() {
            switch (this.period) {
                case "1w":
                case "1m":
                case "3m":
                case "1y":
                case "mtd":
                case "ytd":
                    return false;
                    break;
                default:
                    return true;
            }
        },
        periodLabel() {
            switch (this.period) {
                case "1w":
                    return "Past week";
                    break;
                case "1m":
                    return "Past month";
                    break;
                case "3m":
                    return "Past 3 months";
                    break;
                case "1y":
                    return "Past year";
                    break;
                case "mtd":
                    return "Month-to-date";
                    break;
                case "ytd":
                    return "Year-to-date";
                    break;
                default:
                    return (
                        this.periodStart.month +
                        "/" +
                        this.periodStart.day +
                        "/" +
                        this.periodStart.year +
                        " – " +
                        this.periodEnd.month +
                        "/" +
                        this.periodEnd.day +
                        "/" +
                        this.periodEnd.year
                    );
            }
        },
        periodStart() {
            let date;

            if (this.periodIsCustom) {
                date = parse(
                    this.period.split("-")[0].toString(),
                    "yyyyMMdd",
                    new Date()
                );
            } else {
                switch (this.period) {
                    case "1w":
                        date = sub(Date.now(), { days: 7 });
                        break;
                    case "1m":
                        date = sub(Date.now(), { months: 1 });
                        break;
                    case "3m":
                        date = sub(Date.now(), { months: 3 });
                        break;
                    case "1y":
                        date = sub(Date.now(), { years: 1 });
                        break;
                }
            }

            return {
                year: format(date, "yyyy"),
                month: format(date, "M"),
                day: format(date, "d"),
            };
        },
        periodEnd() {
            let date;

            if (this.periodIsCustom) {
                date = parse(
                    this.period.split("-")[1].toString(),
                    "yyyyMMdd",
                    new Date()
                );
            } else {
                date = Date.now();
            }

            return {
                year: format(date, "yyyy"),
                month: format(date, "M"),
                day: format(date, "d"),
            };
        },
        dateRoute() {
            if (!this.startDate || !this.endDate) {
                return null;
            }
            return (
                format(this.startDate, "yyyyMMdd") +
                "-" +
                format(this.endDate, "yyyyMMdd")
            );
        },
        path() {
            return this.legacy ? "/pulse/explore-legacy/" : "/pulse/explore/";
        },
    },
    methods: {
        hide() {
            this.show = false;
        },
        link(period) {
            let params = new URLSearchParams(window.location.search);
            params.set("period", period);

            return this.path + this.type + "?" + params.toString();
        },
        update() {
            window.location = this.link(this.dateRoute);
        },
        updateEnd() {
            let end = new Date(this.endDateString);
            if (end instanceof Date) {
                this.$refs.endf.focusDate(end);
                this.endDate = end;
            }
        },
        updateStart() {
            let start = new Date(this.startDateString);
            if (start instanceof Date) {
                this.$refs.start.focusDate(start);
                this.startDate = start;
            }
        },
    },
    mounted() {
        let start = new Date(
            this.periodStart.year,
            this.periodStart.month - 1,
            this.periodStart.day
        );
        this.startDate = start;
        this.$refs.start.$refs.calendar.move(start);

        let end = new Date(
            this.periodEnd.year,
            this.periodEnd.month - 1,
            this.periodEnd.day
        );
        this.endDate = end;
        this.$refs.end.$refs.calendar.move(end);
    },
    watch: {
        endDate(value) {
            this.endDateString = format(value, "MM/dd/yyyy");
        },
        startDate(value) {
            this.startDateString = format(value, "MM/dd/yyyy");
        },
    },
};
</script>

<style>
.vc-container {
    border: none !important;
}
.vc-highlight {
    background-color: theme("colors.blue.500") !important;
}
</style>
