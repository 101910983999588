<template>
    <div>
        <PulseExploreLayout :legacy="legacy" v-bind="$attrs">
            <PulseExploreChart :legacy="legacy" />
            <PulseExploreDetailsCourseOverview
                api="lessons"
                :legacy="legacy"
                :courses="$attrs.courses"
                :course="$attrs.course"
            />
        </PulseExploreLayout>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        legacy: Boolean,
    },
};
</script>
