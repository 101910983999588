<template>
    <div>
        <div class="overflow-x-auto w-full md:w-auto">
            <h1
                class="flex items-center text-gray-900 text-base md:text-xl leading-8 w-max md:w-full"
            >
                <strong class="font-bold">{{ app }}</strong>
                <div
                    class="flex items-center sm:bg-gray-50 sm:border border-gray-100 text-sm font-medium sm:rounded-full ml-3 sm:ml-4 sm:py-2 sm:px-6"
                >
                    <SvgIcon class="text-gray-600" :icon="icon" size="md" />
                    <a
                        v-if="sectionPath"
                        class="text-blue-600 underline ml-2 sm:ml-3 line-clamp-2"
                        :href="sectionPath"
                        v-text="section"
                    />
                    <span
                        class="ml-2 sm:ml-3 sm:hidden md:flex line-clamp-2"
                        v-if="!sectionPath && section"
                        v-text="section"
                    />
                    <div
                        v-if="action"
                        class="flex items-center font-normal sm:hidden md:flex"
                    >
                        <SvgIcon
                            class="mx-2 sm:mx-3"
                            icon="chevron-right"
                            size="xs"
                            weight="extra-bold"
                        />
                        <a
                            v-if="actionPath"
                            class="cta line-clamp-2"
                            :href="actionPath"
                            v-text="action"
                        />
                        <div v-text="action" class="line-clamp-2" />
                        <template v-if="resourceTitle">
                            <SvgIcon
                                class="mx-2 sm:mx-3"
                                icon="chevron-right"
                                size="xs"
                                weight="extra-bold"
                            />
                            <div v-text="resourceTitle" class="line-clamp-2" />
                        </template>
                    </div>
                </div>
            </h1>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        action: String,
        actionPath: String,
        app: String,
        icon: String,
        resourceTitle: String,
        searchable: Array,
        section: String,
        sectionPath: String,
    },
};
</script>
