<template>
    <BillingSection title="Update your payment method">
        <form
            id="payment-form"
            v-on:submit="submit"
            v-show="initComplete && subscriptionStatus !== 'active'"
        >
            <div class="text-small tracking-tight text-red-700">
                Your latest payment has failed. Update your payment method to
                continue this plan.
                <br />
                The selected payment will be charged immediately and become the
                new default payment method.
            </div>
            <div class="flex my-4">
                <div class="flex-1 space-y-4">
                    <div
                        v-for="paymentMethod in sortedList"
                        class="form-check flex-1"
                    >
                        <input
                            class="form-check-input appearance-none rounded-full h-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            type="radio"
                            name="paymentMethod"
                            :id="'payment_method_' + paymentMethod.id"
                            :value="paymentMethod.id"
                            v-model="selectedPaymentMethod"
                        />
                        <label
                            class="form-check-label flex flex-row text-gray-800 flex-1"
                            :for="'payment_method_' + paymentMethod.id"
                        >
                            <div class="card-desc card-details">
                                {{
                                    capitalizeFirstLetter(
                                        paymentMethod.card.brand
                                    )
                                }}
                                •••• {{ paymentMethod.card.last4 }}
                                <div
                                    v-if="isDefault(paymentMethod)"
                                    class="inline-flex items-center rounded-full text-2xs font-semibold uppercase leading-6 px-3 space-x-2 bg-gray-50 text-gray-600 ml-2"
                                >
                                    Default
                                </div>
                            </div>
                            <div class="card-expires card-details">
                                Expires at {{ paymentMethod.card.exp_month }}/{{
                                    paymentMethod.card.exp_year
                                }}
                            </div>
                        </label>
                    </div>
                    <div class="form-check">
                        <input
                            class="form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                            type="radio"
                            name="paymentMethod"
                            id="addPaymentMethod"
                            value="new-card"
                            v-model="selectedPaymentMethod"
                        />
                        <label
                            class="form-check-label inline-block text-gray-800"
                            for="addPaymentMethod"
                        >
                            Add Payment Method
                        </label>
                    </div>
                </div>
            </div>
            <div
                id="payment-element"
                class="payment-element-design"
                v-show="selectedPaymentMethod === 'new-card'"
            >
                <!-- Elements will create form elements here -->
            </div>
            <div id="error-message">
                <!-- Display error message to your customers here -->
            </div>
            <button
                class="button main flex-1 disabled:opacity-75 disabled:cursor-not-allowed mt-4"
                :disabled="processing || !isValid"
                v-on:click="submit"
                id="submit"
            >
                Update & Save
                <LoadingSpinner
                    class="mt-2"
                    placement="button"
                    :show="processing"
                />
            </button>
        </form>
        <div
            v-on:submit="submit"
            v-show="initComplete && subscriptionStatus === 'active'"
        >
            Your subscription is in good standing. You do not need to update
            your payment method.
        </div>
    </BillingSection>
</template>

<script lang="ts">
import { mapState } from "vuex";
import BillingSection from "./BillingSection.vue";
import axios from "axios";
import {
    getPaymentMethods,
    getSubscriptions,
    makeSubscriptionPayment,
} from "../../../api/billing";
import { defineComponent } from "vue";

declare const Stripe: any;
declare const window: any;

export default defineComponent({
    components: { BillingSection },
    data(): any {
        return {
            isDefaultPaymentMethod: false,
            elements: null,
            stripe: new Stripe(window.stripe_key),
            paymentMethods: [],
            initComplete: false,
            selectedPaymentMethod: "",
            processing: false,
            cardComplete: false,
            cardError: null,
        };
    },
    computed: {
        ...mapState(["user"]),
        isValid() {
            return (
                (this.selectedPaymentMethod === "new-card" &&
                    !this.cardError &&
                    this.cardComplete) ||
                this.selectedPaymentMethod.length > 0
            );
        },
        sortedList() {
            return this.paymentMethods.sort((a, b) => {
                if (a.id === this.defaultPaymentId) {
                    return -1;
                }
                if (b.id === this.defaultPaymentId) {
                    return 1;
                }
                return 0;
            });
        },
        subscriptionStatus() {
            return this.subscription?.status ?? "";
        },
    },
    methods: {
        isDefault(paymentMethod) {
            return this.defaultPaymentId === paymentMethod.id;
        },
        capitalizeFirstLetter(type) {
            return type.charAt(0).toUpperCase() + type.slice(1);
        },
        getPaymentMethods() {
            return getPaymentMethods(this.user.uuid).then((methods) => {
                this.paymentMethods = methods;
            });
        },
        async submit(event) {
            event.preventDefault();
            this.processing = true;

            if (
                this.selectedPaymentMethod !== "new-card" &&
                this.selectedPaymentMethod.length > 0
            ) {
                try {
                    await makeSubscriptionPayment(
                        this.user.uuid,
                        this.subscription.latest_invoice.id,
                        this.selectedPaymentMethod
                    );
                    this.processing = false;
                    window.location = "/account/billing";
                } catch (e) {
                    this.processing = false;
                    alert("There was an error processing your payment.");
                }
                return;
            }

            try {
                const result = await this.stripe.confirmSetup({
                    //`Elements` instance that was used to create the Payment Element
                    elements: this.elements,
                    confirmParams: {
                        return_url:
                            window.location.origin +
                            `/account/billing?isDefault=${this.isDefaultPaymentMethod}`,
                    },
                    redirect: "if_required",
                });

                if (result.error) {
                    // This point will only be reached if there is an immediate error when
                    // confirming the payment. Show error to your customer (for example, payment
                    // details incomplete)
                    const messageContainer =
                        document.querySelector("#error-message");
                    // @ts-ignore
                    messageContainer.textContent = result.error.message;
                } else {
                    await makeSubscriptionPayment(
                        this.user.uuid,
                        this.subscription.latest_invoice.id,
                        result.setupIntent.payment_method
                    );
                    // Payment method has been saved.
                    // Pay invoice and mark as default
                    console.log(result);
                }
                this.processing = false;
            } catch (e) {
                this.processing = false;
                alert("There was an error processing your payment.");
            }
        },
        setupStripe() {
            return axios
                .get(`/api/users/${this.user.uuid}/stripe/setup-intent`)
                .then((response) => {
                    let clientSecret = response.data.client_secret;
                    this.elements = this.stripe.elements({
                        clientSecret,
                        appearance: {
                            theme: "stripe",
                            variables: {},
                        },
                    });
                    // Create and mount the Payment Element
                    this.paymentElement = this.elements.create("payment");
                    this.paymentElement.mount("#payment-element");
                    this.paymentElement.on("change", (event) => {
                        this.cardComplete = event.complete;
                        this.cardError = event.error
                            ? event.error.message
                            : null;
                    });
                });
        },
        async getSubscriptions() {
            this.subscriptions = await getSubscriptions(this.user.uuid);
            this.subscription = this.subscriptions.find(
                (subscription) =>
                    subscription.stripe_subscription_id ===
                    window.stripe_subscription_id
            );
        },
    },
    mounted() {
        Promise.all([
            this.getSubscriptions(),
            this.getPaymentMethods(),
            this.setupStripe(),
        ]).then(() => {
            this.initComplete = true;
        });
    },
});
</script>
<style scoped>
.payment-element-design {
    width: 560px;
}

.card-desc {
    flex-basis: 280px;
}

.card-details {
    display: flex;
    align-items: center;
}

.card-expires {
    flex-basis: 160px;
}
</style>
