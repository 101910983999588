<template>
    <div
        :aria-sort="sortDirection"
        class="flex items-center py-5 pl-3 space-x-1 text-sm font-medium"
    >
        <slot name="checkbox" />

        <span>{{ label }}</span>
        <span
            v-if="sortable"
            @click="updateSort(label)"
            class="text-blue-600 hover:cursor-pointer"
        >
            <SvgIcon :icon="sortIcon" :size="sortSize" weight="bold" />
        </span>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        primary: {
            type: Boolean,
            default: false,
            required: false,
        },
        sortable: {
            type: Boolean,
            default: false,
            required: false,
        },
        initialSortDir: {
            type: String,
            default: "",
            required: false,
        },
    },
    data(): any {
        return {
            sorted: false,
            sortCount: 0,
            sortDirection: "none",
        };
    },
    computed: {
        sortSize() {
            return this.sorted ? "xs" : "sm";
        },
        sortIcon() {
            if (!this.sorted) {
                return "arrow-up-down";
            }

            return this.sortDirection === "ascending"
                ? "chevron-up"
                : "chevron-down";
        },
    },
    methods: {
        getDirectionAbbr() {
            switch (this.sortDirection) {
                case "ascending":
                    return "asc";
                case "descending":
                    return "desc";
            }
        },
        getDirectionFromAbbr(abbr) {
            switch (abbr) {
                case "asc":
                    return "ascending";
                case "desc":
                    return "descending";
            }
        },
        updateSort(label) {
            if (this.sortCount === 2) {
                this.resetSort(label);
                return;
            }

            this.sortDirection =
                this.sortDirection === "ascending" ? "descending" : "ascending";
            this.sorted = true;
            this.sortCount++;

            this.$events.$emit("pulseTableSorted", {
                column: label.toLowerCase(),
                direction: this.getDirectionAbbr(),
                isPrimary: this.primary,
            });
        },
        resetSort(label, emitEvent = true) {
            this.sorted = false;
            this.sortCount = 0;
            this.sortDirection = "none";

            if (!emitEvent) return;

            this.$events.$emit("pulseTableSorted", {
                column: label.toLowerCase(),
                direction: null,
                isPrimary: this.primary,
            });
        },
    },
    mounted() {
        if (this.initialSortDir) {
            this.sortDirection = this.getDirectionFromAbbr(this.initialSortDir);
            this.sorted = true;
            this.sortCount++;
        }

        this.$events.$on("pulseTableSorted", (data) => {
            if (data.column !== this.label.toLowerCase() && !this.primary) {
                this.resetSort(this.label, false);
            }
        });
    },
};
</script>
