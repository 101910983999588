<template>
    <a
        class="flex flex-col items-center no-underline hover:no-underline whitespace-nowrap my-4"
        :class="{
            'nav-item': naked,
            'nav-parent': !naked,
        }"
        :rel="external ? 'noopener' : ''"
        :href="path"
        :target="target"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
    >
        <div
            class="flex"
            :class="{
                'nav-item-icon p-2': naked,
                'nav-parent-icon rounded-md p-3': !naked,
            }"
        >
            <SvgIcon :icon="icon" />
        </div>
        <div
            class="nav-parent-label text-xs tracking-tight"
            :class="{
                'nav-item-label': naked,
                'nav-parent-label mt-1': !naked,
            }"
        >
            {{ label }}
        </div>
    </a>
</template>

<script lang="ts">
export default {
    props: {
        admin: Boolean,
        external: Boolean,
        icon: String,
        label: String,
        naked: Boolean,
        path: {
            type: String,
            default: "#",
        },
        active: Boolean,
    },
    data(): any {
        return {
            hover: false,
        };
    },
    computed: {
        target() {
            return this.external ? "_blank" : "_self";
        },
    },
};
</script>
