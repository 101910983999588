<template>
    <tr class="history-item">
        <td>
            <div class="flex items-center text-sm w-56">
                <SvgIcon class="text-blue-600 mr-2" icon="clock" size="sm" />
                <div
                    v-text="
                        $filters.dateTime(
                            feedbackItem.created_at,
                            user.timezone
                        )
                    "
                />
            </div>
        </td>
        <td>
            <div class="text-sm w-48">
                {{ feedbackUser.name }}
            </div>
        </td>
        <td class="note text-sm">
            <div>
                <strong class="mr-2">{{ feedbackItem.issue }}</strong>
            </div>
            <div>
                {{ feedbackItem.description }}
            </div>
        </td>
    </tr>
</template>

<script lang="ts">
import { mapState } from "vuex";
import axios from "axios";

export default {
    props: {
        feedbackItem: Object,
    },
    data(): any {
        return {
            feedbackUser: {
                name: "Anonymous user",
            },
        };
    },
    computed: {
        ...mapState(["user"]),
    },
    methods: {},
    mounted() {
        this.$nextTick(
            () =>
                (this.feedbackUser =
                    this.feedbackItem.user || this.feedbackUser)
        );
    },
};
</script>

<style>
.history-item {
    @apply border-b w-full;

    vertical-align: top;
}

.history-item td {
    @apply p-4;
}

.history-item td.note {
    @apply w-full;
}

.history-item:last-child {
    @apply border-b-0;
}
</style>
