<template>
    <div>
        <template v-if="exams.length > 0">
            <div class="flex items-center justify-between mb-2">
                <div class="flex items-center">
                    <div
                        class="flex justify-start text-gray-600 text-sm font-medium uppercase pl-4 w-96"
                    ></div>
                    <div
                        class="flex justify-center text-gray-600 text-sm font-medium uppercase w-36"
                    >
                        Bank
                    </div>
                    <div
                        class="flex justify-center text-gray-600 text-sm font-medium uppercase w-24"
                    >
                        Access
                    </div>
                    <div
                        class="flex justify-center text-gray-600 text-sm font-medium uppercase w-48"
                    >
                        Categories
                    </div>
                    <div
                        class="flex justify-center text-gray-600 text-sm font-medium uppercase w-24"
                    >
                        ?
                    </div>
                    <div
                        class="flex justify-center text-gray-600 text-sm font-medium uppercase w-36"
                    >
                        Timer
                    </div>
                </div>
                <div class="flex items-center">
                    <div class="w-14"></div>
                </div>
            </div>
            <div class="bg-white rounded-md shadow">
                <div class="flex flex-col">
                    <PrepExamLibraryCollectionResource
                        v-for="exam in exams"
                        :highlighted="exam.uuid == highlighted"
                        :archived="archived"
                        :exam="exam"
                        :root-categories="allCategories"
                        :key="exam.uuid"
                    />
                </div>
            </div>
        </template>
        <template v-else>
            <div
                class="flex items-center justify-center text-gray-500 text-3xl font-light h-40"
            >
                {{ emptyLabel }}
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import axios from "axios";

export default {
    props: {
        archived: Boolean,
        emptyLabel: String,
        exams: {
            type: Array as PropType<any[]>,
            default: () => [],
        },
        highlighted: String,
    },
    data() {
        return {
            allCategories: []
        }
    },
    methods: {
        getAllCategories: async function () {
            const data = await axios.get(
                '/api/prep/exam-categories?bank=Radiography,CT,LMRT,2024 Radiography,Medication Aide Certification 2024,2024 Limited Scope of Practice Radiography,California Radiography Supervisor and Operator Examination&type=all&access=open&excludeChildren=true'
            );
            this.allCategories = data.data.categories ?? [];
        }
    },
    async created() {
        if (this.exams.length) await this.getAllCategories();
    },
};
</script>
