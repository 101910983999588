<template>
    <div class="mt-8">
        <div class="flex items-center justify-between">
            <div class="flex flex-grow items-center justify-start">
                <h3
                    v-if="!showSingle"
                    class="text-sm text-gray-700 font-medium uppercase ml-4 pr-4"
                >
                    <template v-if="type == 'lessons'"> Lesson Info </template>
                    <template v-else> Exam Info </template>
                </h3>
                <div class="hidden sm:flex items-center text-sm ml-4">
                    <a
                        v-if="typeData.course"
                        class="cta"
                        :href="courseLink"
                        v-text="courseTitle"
                    />
                    <template v-if="typeData.module">
                        <div class="text-gray-400 mx-3">&rarr;</div>
                        <div>
                            {{ typeData.module }}
                        </div>
                    </template>
                </div>
            </div>
            <div class="flex items-center">
                <template v-if="showSingle">
                    <div class="hidden sm:flex justify-center w-48">
                        <div
                            class="text-sm text-gray-700 uppercase font-medium"
                        >
                            Date
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="hidden sm:flex justify-center w-22">
                        <div
                            class="text-sm text-gray-700 uppercase font-medium"
                        >
                            #
                        </div>
                    </div>
                </template>
                <template v-if="type != 'lessons'">
                    <div class="hidden sm:flex justify-center w-32">
                        <div
                            class="text-sm text-gray-700 uppercase font-medium"
                        >
                            Avg Score
                        </div>
                    </div>
                    <div class="hidden sm:flex justify-center w-24">
                        <div
                            class="text-sm text-gray-700 uppercase font-medium"
                        >
                            Avg Time
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div
            class="flex flex-col sm:flex-row sm:items-center sm:justify-between bg-white rounded-md shadow mt-4"
        >
            <div class="sm:hidden pt-6 px-6">
                <div v-if="typeData.course" class="mb-6">
                    <a class="cta" :href="courseLink" v-text="courseTitle" />
                </div>
                <div v-if="typeData.module">
                    {{ typeData.module }}
                </div>
            </div>
            <div class="flex sm:items-center py-6 sm:py-0 px-6">
                <div
                    :class="{
                        'font-bold': type != 'lessons',
                    }"
                >
                    {{ typeData.lesson }}
                </div>
                <template v-if="type == 'lessons' && legacy">
                    <div class="text-gray-400 mx-3">&rarr;</div>
                    <div class="font-bold">{{ typeData.name }}</div>
                </template>
                <template v-if="type != 'lessons' && !legacy">
                    <div class="font-bold">{{ typeData.name }}</div>
                </template>
            </div>
            <div class="flex items-center">
                <template v-if="showSingle">
                    <div
                        class="flex items-center justify-center border sm:border-t-0 sm:border-b-0 sm:border-r-0 border-gray-400 border-dashed sm:border-solid sm:border-gray-100 rounded-lg sm:rounded-none mx-6 mb-6 sm:mx-0 sm:mb-0 sm:w-48"
                    >
                        <div
                            class="flex flex-col sm:flex-row text-center leading-6 sm:leading-10 p-4"
                        >
                            <div class="sm:hidden text-sm font-bold underline">
                                Date
                            </div>
                            <div
                                class="text-xs leading-10"
                                v-html="dateTime(date)"
                            />
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div
                        class="flex items-center justify-center border sm:border-t-0 sm:border-b-0 sm:border-r-0 border-gray-400 border-dashed sm:border-solid sm:border-gray-100 rounded-lg sm:rounded-none mx-6 mb-6 sm:mx-0 sm:mb-0 sm:w-22"
                    >
                        <div
                            class="flex flex-col sm:flex-row text-center leading-6 sm:leading-10 p-4"
                        >
                            <div class="sm:hidden text-sm font-bold underline">
                                Count
                            </div>
                            <div>{{ count }}</div>
                        </div>
                    </div>
                </template>
                <template v-if="type != 'lessons'">
                    <div
                        class="flex items-center justify-center border sm:border-t-0 sm:border-b-0 sm:border-r-0 border-gray-400 border-dashed sm:border-solid sm:border-gray-100 rounded-lg sm:rounded-none mx-6 mb-6 sm:mx-0 sm:mb-0 sm:w-32"
                    >
                        <div
                            class="flex flex-col sm:flex-row text-center leading-6 sm:leading-10 p-4"
                        >
                            <div class="sm:hidden text-sm font-bold underline">
                                Avg Score
                            </div>
                            <div
                                v-html="grade(avgScore, settings.pulse_grade)"
                            />
                        </div>
                    </div>
                    <div
                        class="flex items-center justify-center border sm:border-t-0 sm:border-b-0 sm:border-r-0 border-gray-400 border-dashed sm:border-solid sm:border-gray-100 rounded-lg sm:rounded-none mx-6 mb-6 sm:mx-0 sm:mb-0 sm:w-24"
                    >
                        <div
                            class="flex flex-col sm:flex-row text-center leading-6 sm:leading-10 p-4"
                        >
                            <div class="sm:hidden text-sm font-bold underline">
                                Avg Time
                            </div>
                            <div v-html="elapsed(avgTime)" />
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState, mapGetters } from "vuex";
import { defineComponent, PropType } from "vue";
import { filters as $filters } from "../../../../../vue-filters";

export default defineComponent({
    props: {
        count: Number,
        legacy: Boolean,
        rows: Array as PropType<Array<any>>,
        showSingle: Boolean,
        typeData: Object as PropType<any>,
    },
    methods: {
        ...$filters,
    },
    computed: {
        ...mapState(["settings", "type"]),
        ...mapGetters(["context"]),
        totalScore() {
            if (this.legacy) {
                return this.rows?.reduce((carry, item) => {
                    let score;
                    let count = 1;

                    if (typeof item.grade != "undefined") score = item.grade;
                    if (typeof item.score != "undefined") score = item.score;

                    if (typeof item.count != "undefined") count = item.count;

                    return carry + score * count;
                }, 0);
            } else {
                return this.rows?.reduce((carry, item) => {
                    let count = 1;

                    let score =
                        typeof item.properties != "undefined"
                            ? item.properties.score
                            : item.score;

                    if (typeof item.count != "undefined") count = item.count;

                    return carry + score * count;
                }, 0);
            }
        },
        totalTime() {
            if (this.legacy) {
                return this.rows?.reduce((carry, item) => {
                    let time;
                    let count = 1;

                    if (typeof item.time != "undefined") time = item.time;
                    if (typeof item.time_elapsed != "undefined")
                        time = item.time_elapsed;

                    if (typeof item.count != "undefined") count = item.count;

                    return carry + time * count;
                }, 0);
            } else {
                return this.rows?.reduce((carry, item) => {
                    let count = 1;

                    let duration =
                        typeof item.properties != "undefined"
                            ? item.properties.duration
                            : item.duration;

                    if (typeof item.count != "undefined") count = item.count;

                    return carry + duration * count;
                }, 0);
            }
        },
        totalCount() {
            return this.rows?.reduce((carry, item) => {
                let count = 1;

                if (typeof item.count != "undefined") count = item.count;

                return carry + count;
            }, 0);
        },
        avgScore() {
            return this.rows && this.totalCount > 0
                ? this.totalScore / this.totalCount
                : null;
        },
        avgTime() {
            return this.rows && this.totalCount > 0
                ? this.totalTime / this.totalCount
                : null;
        },
        courseLink() {
            return this.context + "&course=" + this.typeData?.course_id;
        },
        courseTitle() {
            if (this.legacy) {
                return this.type == "lessons"
                    ? this.typeData?.course
                    : this.typeData?.course.title;
            }
        },
        date() {
            if (this.showSingle && this.rows && this.rows.length > 0) {
                return this.rows[0].updated_at;
            }
        },
    },
});
</script>
