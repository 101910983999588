<template>
    <div class="flex items-center">
        <SelectDropdown v-model="assignmentGroup" theme="flat">
            <option value="">Please choose a group</option>
            <option value="cohort">Cohort</option>
            <option value="user">Learner</option>
        </SelectDropdown>
        <SelectDropdown
            v-if="assignmentGroup != ''"
            v-model="assignmentSubject"
            theme="flat"
        >
            <option value="">Please choose a {{ assignmentGroup }}</option>
            <option
                v-for="item in assignmentGroupItems"
                :value="item.uuid"
                v-text="item.name"
            />
        </SelectDropdown>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState } from "vuex";

export default {
    data(): any {
        return {
            assignmentGroup: "",
            assignmentSubject: "",
            cohorts: [],
            learners: [],
        };
    },
    computed: {
        ...mapState(["user"]),
        assignmentGroupItems() {
            return this.assignmentGroup == "user"
                ? this.learners
                : this.cohorts;
        },
    },
    methods: {
        fetchAssignmentGroups() {
            if (this.user.account_id) {
                axios
                    .get("/api/accounts/" + this.user.account_id)
                    .then((response) => {
                        this.cohorts = response.data.cohorts;
                        this.learners = response.data.learners;
                    });
            }
        },
    },
    mounted() {
        this.fetchAssignmentGroups();
    },
    watch: {
        assignmentGroup() {
            this.assignmentSubject = "";
        },
        assignmentSubject(val) {
            if (val) {
                this.$emit(
                    "update:modelValue",
                    this.assignmentGroup + "|" + this.assignmentSubject
                );
            } else {
                this.$emit("update:modelValue", null);
            }
        },
    },
};
</script>
