import axios from "axios";
import { createUrlParams, notEmpty } from "../utils";

export function getCoursesEngagement(
    type,
    partner,
    group,
    period,
    course,
    learners
) {
    let urlParams = createUrlParams({ type, partner, group, period, course });
    let base = "/api/reporting/learn/engagement";
    let url = base + "?" + urlParams.toString();
    return axios.post(url, {
        learners: learners,
    });
}

export function getLearnerReport(
    partner,
    group,
    period,
    course,
    module,
    learners,
    page,
    event_type
) {
    console.log("Get Learner Report");
    // console log all props
    console.dir({
        partner,
        group,
        period,
        course,
        module,
        learners,
        page,
        event_type,
    });

    let urlParams = createUrlParams({
        partner,
        group,
        period,
        course,
        module,
        page,
        event_type,
    });
    let base = "/api/reporting/learn/learner";
    let url = base + "?" + urlParams.toString();
    return axios.post(url, {
        learners: learners,
    });
}

export function getStructuredReport(
    partner,
    group,
    period,
    course,
    learners,
    page,
    event_type
) {
    console.log("Get Structured Report");
    let urlParams = createUrlParams({
        partner,
        group,
        period,
        course,
        page,
        event_type,
    });
    let base = "/api/reporting/learn/structured";
    let url = base + "?" + urlParams.toString();
    return axios.post(url, {
        learners: learners,
    });
}

export function getCourseStats(partner, group, period, course, learners) {
    let urlParams = createUrlParams({ partner, group, period });
    let base = `/api/reporting/course/${course}/stats`;
    let url = base + "?" + urlParams.toString();
    return axios.post(url, {
        learners: learners,
    });
}

export function getModuleStats(partner, group, period, module, learners) {
    let urlParams = createUrlParams({ partner, group, period });
    let base = `/api/reporting/module/${module}/stats`;
    let url = base + "?" + urlParams.toString();
    return axios.post(url, {
        learners: learners,
    });
}

export function getCourseStructuredData(
    partner,
    group,
    period,
    course,
    learners,
    page,
    event_type
) {
    console.log("Get Structured Report for course: " + course);
    let urlParams = createUrlParams({
        partner,
        group,
        period,
        course,
        page,
        event_type,
    });
    let base = `/api/reporting/course/${course}/structured`;
    let url = base + "?" + urlParams.toString();
    return axios.post(url, {
        learners: learners,
    });
}

export function getModuleStructuredData(
    partner,
    group,
    period,
    module,
    learners,
    page,
    event_type
) {
    console.log("Get Structured Report for module: " + module);
    let urlParams = createUrlParams({
        partner,
        group,
        period,
        module,
        page,
        event_type,
    });
    let base = `/api/reporting/module/${module}/structured`;
    let url = base + "?" + urlParams.toString();
    return axios.post(url, {
        learners: learners,
    });
}

export async function logVideoView(
    lessonInstanceId,
    uniqueSeconds,
    totalSeconds,
    percentWatched
) {
    let url = `/api/lesson-instance/${lessonInstanceId}/view-event`;

    const response = await fetch(url, {
        method: "POSt",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            uniqueSeconds: uniqueSeconds,
            totalSeconds: totalSeconds,
            percentWatched: percentWatched,
        }),
        keepalive: true,
    });

    return response.json();
}

export function updateVideoView(
    lessonInstanceId,
    activityId,
    uniqueSeconds,
    totalSeconds,
    percentWatched
) {
    let url = `/api/lesson-instance/${lessonInstanceId}/view-event/${activityId}`;
    // use fetch instead of axios
    return fetch(url, {
        method: "PUT",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            uniqueSeconds: uniqueSeconds,
            totalSeconds: totalSeconds,
            percentWatched: percentWatched,
        }),
        keepalive: true,
    });
}

export function videoStats(instanceId, data) {
    let url = `/api/learn/lessons/${instanceId}/video-statistics`;
    // use fetch instead of axios
    return fetch(url, {
        method: "PUT",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        keepalive: true,
    });
}
