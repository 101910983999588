<template>
    <div
        :content="content"
        v-tippy="{ placement: 'bottom' }"
        class="relative flex items-center justify-center rounded-full w-10 h-10 cursor-default"
        :class="
            'bg-' +
            label?.color +
            '-100 text-' +
            label?.color +
            '-600 ' +
            optionClasses
        "
    >
        {{ label?.ab }}
    </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";

interface Category {
    category: string;
}

export default defineComponent({
    props: {
        categories: Array as PropType<Category[]>,
        options: String,
        parent: String, // parent category id
    },
    computed: {
        content() {
            let cats =
                this.categories?.map((o) => {
                    return "<br>• " + o.category;
                }) ?? [];
            return (
                "<div class='text-left'><strong>" +
                (this.label?.name ?? "") +
                "</strong>" +
                cats.join("") +
                "</div>"
            );
        },
        label() {
            switch (this.parent) {
                case "1":
                case "1324":
                    return {
                        color: "indigo",
                        name: "Patient Care",
                        ab: "PC",
                    };
                    break;
                case "124":
                case "1421":
                    return {
                        color: "cyan",
                        name: "Safety",
                        ab: "Sa",
                    };
                    break;
                case "250":
                case "1522":
                    return {
                        color: "pink",
                        name: "Image Production",
                        ab: "IP",
                    };
                    break;
                case "376":
                case "1622":
                    return {
                        color: "orange",
                        name: "Procedures",
                        ab: "Pr",
                    };
                    break;
                default:
                    return {
                        color: "orange",
                        name: "Procedures",
                        ab: "Pr",
                    };
            }
        },
        optionClasses() {
            switch (this.options) {
                case "stacked":
                    return "border-2 border-white text-sm -ml-2 first:ml-0";
                    break;
                default:
                    return "mx-1";
            }
        },
    },
});
</script>
