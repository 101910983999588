<template>
    <div>
        <section class="section-main">
            <div class="flex items-start space-x-8">
                <div
                    class="flex flex-col space-y-8 sm:w-120 md:w-128 lg:w-172 xl:w-228"
                >
                    <PrepExamResults
                        v-if="['completed', 'expired'].includes(status)"
                        :bank="bank"
                        :examId="examId"
                        :score="score"
                        :completedAt="completedAt"
                        :taker="taker"
                        :time="time"
                        :status="status"
                    />
                    <PrepExamQuestion
                        :active="active"
                        :answers="answers"
                        :category="questionCategory"
                        :correct="correct"
                        :editable="editable"
                        :exam="props.exam"
                        :feedback="feedback"
                        :hasMultipleAnswers="hasMultipleAnswers"
                        :last-question="lastQuestion"
                        :getPercentComplete="percentComplete"
                        :paused="paused"
                        :position="position"
                        :question="question"
                        :questionFlagged="questionFlagged"
                        :questionId="questionId"
                        :questionUuid="questionUuid"
                        :questionImage="questionImage"
                        :getResponse="response"
                        :getResponses="responses"
                        :set="set"
                        :showFeedback="showFeedback"
                        :status="status"
                        :totalQuestions="totalQuestions"
                    />
                </div>
                <PrepExamOverview
                    :active="position"
                    :exam="exam"
                    :examTarget="examTarget"
                    :percentComplete="percentComplete"
                    :pausable="pausable"
                    :paused="paused"
                    :responses="responses"
                    :score="score"
                    :status="status"
                    :timer="timer"
                />
            </div>
        </section>
    </div>
</template>

<script setup lang="ts">
import axios from "axios";
import { usePrepExamStore } from "../../../stores/prepStore";
import { mapStores, storeToRefs } from "pinia";
import { defineProps, onMounted, onUnmounted, watch } from "vue";
import $events from "../../../event-bus";

const prepStore = usePrepExamStore();

const {
    exam,
    paused,
    answers,
    correct,
    feedback,
    percentComplete,
    response,
    responses,
    score,
    set,
    status,
} = storeToRefs(prepStore);

const props = defineProps({
    active: Number as any,
    getCorrect: Boolean,
    completedAt: String,
    bank: String,
    editable: Boolean,
    exam: String,
    examId: Number,
    examTarget: String,
    getAnswers: Array as any,
    getFeedback: {
        type: String,
        required: true,
    },
    getPaused: Boolean,
    getPercentComplete: Number,
    getResponse: Array,
    getResponses: Array,
    getScore: Number,
    getSet: Boolean,
    getStatus: String,
    hasMultipleAnswers: Boolean,
    lastQuestion: Boolean,
    pausable: Boolean,
    position: Number,
    question: String,
    questionCategory: Object,
    questionFlagged: Boolean,
    questionId: Number,
    questionUuid: String,
    questionImage: String,
    showFeedback: Boolean,
    taker: Object,
    time: String,
    timer: Number,
    totalQuestions: Number,
    user: Object,
});

const refreshResponses = () => {
    axios
        .get("/api/prep/exams/" + props.exam + "/responses")
        .then((response) => {
            responses.value = response.data;
        });
};

onMounted(() => {
    // registerListeners()
    paused.value = props.getPaused;
    answers.value = props.getAnswers;
    correct.value = props.getCorrect;
    feedback.value = props.getFeedback;
    percentComplete.value = props.getPercentComplete ?? 0;
    response.value = props.getResponse ?? [];
    responses.value = props.getResponses ?? [];
    score.value = props.getScore;
    set.value = props.getSet;
    status.value = props.getStatus ?? "";
    exam.value = props.exam;
    // setState();
    $events.$on("questionFlagged", refreshResponses);
});

onUnmounted(() => {
    $events.$off("questionFlagged", refreshResponses);
});
</script>
