<template>
    <div class="relative" v-click-away="hide">
        <button
            class="flex items-center justify-center bg-gray-100 text-gray-600 rounded-full w-6 h-6"
            @click="toggle"
        >
            <SvgIcon icon="more" size="sm" />
        </button>
        <transition
            enter-active-class="ease-out duration-200"
            enter-class="opacity-0 translate-y-1"
            enter-to-class="opacity-100 translate-y-0"
            leave-active-class="ease-in duration-200"
            leave-class="opacity-100 translate-y-0"
            leave-to-class="opacity-0 translate-y-1"
        >
            <div
                v-if="show"
                class="absolute bottom-0 transition transform bg-white shadow rounded-lg whitespace-nowrap mb-8 -ml-1/2"
            >
                <slot />
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
export default {
    data(): any {
        return {
            show: false,
        };
    },
    methods: {
        hide() {
            this.show = false;
        },
        toggle() {
            this.show = !this.show;
        },
    },
};
</script>
