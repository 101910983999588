import { defineStore } from "pinia";
import { ref, watch } from "vue";
import axios from "axios";

export const useVideoStore = defineStore("video-store", () => {
    const timestamp = ref<number>(0);
    return {
        timestamp,
    };
});
