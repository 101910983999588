<template>
    <div class="flex flex-col items-start sm:items-center sm:flex-row">
        <template v-if="pageSearch">
            <h1 class="my-2 text-xl leading-8 text-gray-900 w-max md:w-full">
                <strong class="font-bold">{{ app }}</strong>
            </h1>
            <div class="pt-2 sm:hidden"></div>
            <component
                v-for="(component, index) in pageSearch"
                class="mb-4 sm:ml-3 sm:mb-0 last:mb-0"
                :class="getZIndex(index)"
                :key="component"
                :is="component"
            />
        </template>
    </div>
</template>

<script lang="ts">
import { mapState } from "vuex";

export default {
    props: {
        app: String,
    },
    computed: {
        ...mapState(["pageSearch"]),
    },
    methods: {
        getZIndex(index) {
            const interval = 10;
            const startingIndex = this.pageSearch.length * interval;

            return `z-${startingIndex - index * interval}`;
        },
    },
};
</script>
