<script setup lang="ts">
import StyledModal from "@/components/_elements/modals/StyledModal.vue";
import CloverFullLogo from "../ui/images/CloverFullLogo.vue";
import { DialogTitle } from "@headlessui/vue";
import CloverIcon from "../ui/images/CloverIcon.vue";

const localKey = "clover.new-course-modal";
// show if not set
const show = localStorage.getItem(localKey) !== "false";

const onClose = () => {
    localStorage.setItem(localKey, "false");
};

const redirect = (to) => {
    onClose();
    window.location.href = to;
};
</script>

<template>
    <StyledModal :start-open="show" @on-close="onClose">
        <template v-slot:panel="slotProps">
            <!-- <CloverFullLogo /> -->
            <!-- <CloverIcon /> -->

            <img
                src="/img/fluoroscopy-positioning-and-procedures.jpg"
                alt="Fluoroscopy Positioning and Procedures"
            />

            <div class="text-left">
                <h2 class="mt-0">🎉 New Course Launched! 🎉</h2>
                <p class="text-base text-gray-700">
                    We are excited to announce the launch of our highly
                    anticipated
                    <span class="font-bold"
                        >Fluoroscopy Procedures and Positioning</span
                    >
                    course.
                </p>
                <div class="flex mt-8 gap-2 items-center justify-end">
                    <button
                        class="button rounded-md bg-gray-100 shadow-none border-none hover:bg-gray-200"
                        @click="slotProps.events?.closeModal()"
                    >
                        Close
                    </button>
                    <a
                        @click="
                            redirect(
                                '/learn/courses/fluoroscopy-positioning-and-procedures'
                            )
                        "
                        class="button no-underline bg-blue-700 text-white rounded-md border-none hover:bg-blue-800 hover:text-white"
                        >View Course Now</a
                    >
                </div>
            </div>
        </template>
    </StyledModal>
</template>
