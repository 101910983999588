<template>
    <div class="relative" v-click-away="blur">
        <div class="relative flex sm:hidden">
            <button type="button" @click="show">
                <SvgIcon icon="search" />
            </button>
            <transition
                enter-active-class="transition duration-200 ease-out"
                enter-class="translate-y-2 opacity-0"
                enter-to-class="translate-y-0 opacity-100"
                leave-active-class="transition duration-200 ease-in"
                leave-class="translate-y-0 opacity-100"
                leave-to-class="translate-y-2 opacity-0"
            >
                <div
                    v-if="showSearch"
                    class="fixed top-0 left-0 flex items-center w-full p-4 transition-all transform bg-white shadow mt-17"
                >
                    <SelectDropdown
                        class="w-40"
                        theme="blank"
                        v-model="selectedType"
                    >
                        <option
                            v-for="type in searchable"
                            :value="type"
                            :key="type"
                        >
                            {{ type }}
                        </option>
                    </SelectDropdown>
                    <input
                        class="px-4 py-2 leading-7 text-gray-800 placeholder-gray-400 bg-white sm:w-64 focus-visible:ring focus-visible:ring-blue-300 focus-visible:ring-opacity-50"
                        ref="searchBox"
                        @focus="focused = true"
                        placeholder="Search"
                        v-model="search"
                    />
                </div>
            </transition>
        </div>
        <div
            class="items-center hidden bg-white border border-gray-200 rounded-full sm:flex"
        >
            <div class="flex pl-4">
                <SvgIcon icon="search" size="sm" />
            </div>
            <SelectDropdown
                class="w-28 xl:w-40"
                theme="blank"
                v-model="selectedType"
            >
                <option v-for="type in searchable" :value="type" :key="type">
                    {{ type }}
                </option>
            </SelectDropdown>
            <input
                class="w-48 px-4 py-2 leading-8 text-gray-800 placeholder-gray-400 border-l border-gray-200 rounded-r-full bg-gray-50 xl:w-64 focus-visible:ring focus-visible:ring-blue-300 focus-visible:ring-opacity-50"
                ref="searchBox"
                @focus="focused = true"
                placeholder="Search"
                v-model="search"
            />
            <div
                class="absolute right-0 w-6 mr-6 text-center text-gray-400 border border-gray-300 rounded pointer-events-none"
                style="
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                "
                v-if="!focused"
            >
                /
            </div>
        </div>
        <transition
            enter-active-class="transition duration-200 ease-out"
            enter-class="translate-y-2 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="transition duration-200 ease-in"
            leave-class="translate-y-0 opacity-100"
            leave-to-class="translate-y-2 opacity-0"
        >
            <div
                class="fixed top-0 left-0 w-full transition-all transform sm:absolute sm:left-auto mt-36 sm:mt-13 sm:-ml-16 sm:w-136"
                v-show="focused"
            >
                <div
                    class="overflow-hidden shadow-lg sm:rounded-md sm:mt-1 sm:-ml-px"
                >
                    <div class="bg-white">
                        <div
                            class="flex items-center justify-between p-4 font-medium text-white bg-gradient-to-tr from-blue-500 to-blue-400"
                        >
                            <div class="leading-7">
                                Searching
                                <span class="font-bold">{{
                                    selectedType
                                }}</span>
                            </div>
                            <div>
                                <SvgIcon
                                    v-if="status == 'searching'"
                                    class="animate-pulse"
                                    icon="circles"
                                />
                                <div
                                    v-if="search && status == 'ready'"
                                    class=""
                                >
                                    {{ results.length }} results
                                </div>
                            </div>
                        </div>
                        <div class="overflow-y-auto max-h-64" ref="results">
                            <a
                                v-for="(result, key) in results"
                                class="block p-4 border-b border-gray-100 cursor-pointer hover:no-underline focus:bg-gray-50 last:border-b-0 focus:font-medium"
                                @mouseover="highlight(key)"
                                :href="result.path"
                                :key="key"
                            >
                                {{ result.term }}
                            </a>
                            <div
                                class="p-4"
                                v-if="search && results.length == 0"
                            >
                                No results found
                            </div>
                            <div class="p-4" v-if="!search">
                                Start typing to search
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import debounce from "debounce";
import { mapState } from "vuex";

export default {
    props: {
        app: String,
        section: String,
        searchable: Array,
        searchAddition: String,
        searchClear: {
            type: Boolean,
            default: true,
        },
    },
    data(): any {
        return {
            focused: false,
            highlighted: -1,
            results: [],
            search: "",
            selectedType: "",
            showSearch: false,
            status: "ready",
        };
    },
    computed: {
        ...mapState(["period"]),
    },
    methods: {
        blur() {
            this.showSearch = false;
            this.focused = false;
            this.highlighted = -1;
            this.$refs.searchBox.blur();
            setTimeout(() => (this.search = ""), 250);
        },
        focusNext() {
            let value =
                this.highlighted < this.results.length - 1
                    ? this.highlighted + 1
                    : this.results.length - 1;
            this.highlight(value);
        },
        focusPrev() {
            let value = this.highlighted > -1 ? this.highlighted - 1 : -1;
            this.highlight(value);
        },
        getResults() {
            if (this.search.length > 2) {
                this.status = "searching";
                axios
                    .get(
                        "/api/search" +
                            "?app=" +
                            this.app +
                            "&type=" +
                            this.selectedType +
                            "&section=" +
                            this.section +
                            "&q=" +
                            this.search +
                            this.searchAddition
                    )
                    .then((response) => {
                        this.status = "ready";
                        this.results = response.data;
                    });
            } else {
                this.results = [];
            }
        },
        highlight(index) {
            this.highlighted = index;
            if (index == -1) {
                this.$refs.searchBox.focus();
            } else {
                this.$refs.results.getElementsByTagName("a")[index].focus();
            }
        },
        registerShortcuts() {
            let self = this;
            document.addEventListener("keydown", (event) => {
                if (event.isComposing || event.keyCode === 229) {
                    return;
                }
                // `esc` key
                if (event.keyCode === 27) {
                    self.blur();
                }
                // '/' key
                if (event.keyCode === 191) {
                    if (
                        // @ts-ignore
                        document.activeElement.constructor == HTMLInputElement
                    ) {
                        return;
                    }

                    self.$refs.searchBox.focus();
                    if (event.preventDefault) {
                        event.preventDefault();
                    } else {
                        event.returnValue = false;
                    }
                }
                // down arrow
                if (event.keyCode === 40) {
                    if (self.search) {
                        event.preventDefault();
                        self.focusNext();
                    }
                }
                // up arrow
                if (event.keyCode === 38) {
                    if (self.search) {
                        event.preventDefault();
                        self.focusPrev();
                    }
                }
            });
        },
        setDefaultType() {
            if (this.selectedType == "") {
                this.selectedType = this.searchable[0];
            }
        },
        show() {
            this.showSearch = true;
            this.focused = true;
        },
    },
    created() {
        this.setDefaultType();
    },
    mounted() {
        this.registerShortcuts();

        this.getResults = debounce(this.getResults, 200);

        this.$events.$on("focusSearch", (data) => {
            this.$nextTick(() => {
                this.selectedType = data.type;
                this.$refs.searchBox.focus();
            });
        });
    },
    watch: {
        search() {
            this.getResults();
        },
        selectedType() {
            if (this.searchClear) {
                this.search = "";
            } else {
                this.getResults();
            }
        },
    },
};
</script>
