<template>
    <div>
        <PulseExploreLayout :legacy="legacy" v-bind="$attrs">
            <PulseExploreChart :legacy="legacy" />
            <PulseExploreDetailsCourseOverview
                api="assessments"
                :courses="courses"
                :course="$attrs.course"
                :legacy="legacy"
            />
        </PulseExploreLayout>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        courses: Array,
        legacy: Boolean,
    },
};
</script>
