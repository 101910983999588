<template>
    <div
        :content="content"
        v-tippy="{ placement: 'bottom', onShow: () => !!parent?.name }"
        class="relative flex items-center justify-center rounded-full w-10 h-10 cursor-default"
        :class="
            'bg-' +
            parent?.color +
            '-100 text-' +
            parent?.color +
            '-600 ' +
            optionClasses
        "
    >
        {{ parent?.ab }}
    </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import {BankCategory, MappedRootCategory} from "@/types";

interface Category {
    category: string;
}

export default defineComponent({
    props: {
        categories: Array as PropType<BankCategory[]>,
        options: String,
        parent: Object as PropType<MappedRootCategory>,
    },
    computed: {
        content() {
            let cats =
                this.categories?.map((o) => {
                    return "<br>• " + o.category;
                }) ?? [];
            return (
                "<div class='text-left'><strong>" +
                (this.parent?.name ?? "") +
                "</strong>" +
                cats.join("") +
                "</div>"
            );
        },
        optionClasses() {
            switch (this.options) {
                case "stacked":
                    return "border-2 border-white text-sm -ml-2 first:ml-0";
                    break;
                default:
                    return "mx-1";
            }
        },
    },
});
</script>
