<script lang="ts" setup>
import { ref } from "vue";
import { useTippy } from "vue-tippy";
const btn = ref();

const props = withDefaults(defineProps<Props>(), {
    hasPendingChange: false,
});

interface Props {
    href: string;
    hasPendingChange?: boolean;
    tipMessage?: string;
}

useTippy(btn, {
    content: props.tipMessage,
    onShow() {
        if (!props.hasPendingChange) {
            return false;
        }
    },
});
</script>
<template>
    <a
        ref="btn"
        :class="{
            'hover:cursor-pointer': !hasPendingChange,
            'hover:cursor-pointer opacity-60': hasPendingChange,
        }"
        :href="hasPendingChange ? undefined : href"
        ><slot
    /></a>
</template>
