<template>
    <div>
        <template v-if="(blueprints?.length ?? 0) > 0">
            <div class="flex items-center justify-between mb-2">
                <div class="flex items-center">
                    <div
                        class="flex justify-start text-gray-600 text-sm font-medium uppercase pl-4 w-80"
                    ></div>
                    <div
                        class="flex justify-center text-gray-600 text-sm font-medium uppercase w-36"
                    >
                        Bank
                    </div>
                    <div
                        class="flex justify-center text-gray-600 text-sm font-medium uppercase w-48"
                    >
                        Categories
                    </div>
                    <div
                        class="flex justify-center text-gray-600 text-sm font-medium uppercase w-20"
                    >
                        ?
                    </div>
                    <div
                        class="flex justify-center text-gray-600 text-sm font-medium uppercase w-36"
                    >
                        Timer
                    </div>
                </div>
            </div>
            <div class="bg-white rounded-md shadow">
                <div class="flex flex-col">
                    <PrepBlueprintCollectionResource
                        v-for="blueprint in blueprints"
                        :assignment="assignment"
                        :blueprint="blueprint"
                        :key="blueprint?.uuid"
                        :taken="taken"
                    />
                </div>
            </div>
        </template>
        <template v-else>
            <div
                class="flex items-center justify-center text-gray-500 text-3xl font-light h-40"
            >
                No saved exams
            </div>
        </template>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        assignment: Boolean,
        blueprints: Array as any,
        taken: Array,
    },
};
</script>
