<script lang="ts">
import ReportingRouteMixin from "../ReportingRouteMixin.vue";

export default {
    data(): any {
        return {
            tabs: [
                { name: "Courses", path: "/courses" },
                { name: "Activity", path: "/courses/activity/structured" },
            ],
        };
    },
    mixins: [ReportingRouteMixin],
};
</script>
<template>
    <div>
        <section class="mb-8 section-title">
            <PulseSubjectHeader />
        </section>
        <section class="flex flex-wrap justify-end mb-6 section-title">
            <PulseReportPeriod />
        </section>
        <ReportingTabs :tabs="tabs" />
        <router-view></router-view>
    </div>
</template>
