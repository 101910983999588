<template>
    <div>
        <div
            class="sm:hidden bg-gray-900 opacity-75 fixed top-0 transition duration-300 right-0 bottom-0 left-0 z-70"
            v-if="open"
            @click="open = false"
        />
        <div
            class="sm:hidden fixed top-0 left-0 transform transition duration-200 z-75"
            :class="{
                'translate-x-64': open,
                '-translate-x-64': !open,
            }"
        >
            <button
                class="text-white rounded-full ml-1 mt-1 p-2"
                @click="open = false"
            >
                <SvgIcon icon="close" size="base" weight="medium" />
            </button>
        </div>
        <section
            ref="sidebar"
            class="sidebar flex flex-col justify-between bg-white shadow fixed left-0 h-full z-80 transform transition-all ease-out duration-200 sm:translate-x-0 sm:w-20"
            :class="{
                '-translate-x-64': !open,
                'translate-x-0 w-64 sm:w-64': open,
            }"
        >
            <div>
                <div
                    class="logo flex items-center overflow-hidden transition-all ease-out duration-200 w-auto sm:w-10 m-5"
                    :class="{ 'w-auto sm:w-auto': open }"
                >
                    <a href="/">
                        <SvgIcon
                            class="text-green-700"
                            icon="clover-filled"
                            size="xl"
                        />
                    </a>
                </div>
                <nav>
                    <SidebarNavItem
                        label="Home"
                        path="/"
                        icon="dashboard"
                        :active="active == '/'"
                    />
                    <SidebarNavParent
                        label="Resources"
                        icon="content2"
                        :active="active.split('/')[0] == 'content'"
                    >
                        <SidebarNavChild
                            v-if="user.permissions.prep_questions"
                            label="Modules"
                            path="/prep/questions"
                            icon="questions"
                            :active="
                                active.split('/')[0] == 'prep' &&
                                active.split('/')[1] == 'questions'
                            "
                        />
                        <SidebarNavChild
                            v-if="user.permissions.prep_questions"
                            label="Lessons"
                            path="/prep/questions"
                            icon="questions"
                            :active="
                                active.split('/')[0] == 'prep' &&
                                active.split('/')[1] == 'questions'
                            "
                        />
                        <SidebarNavChild
                            v-if="user.permissions.prep_questions"
                            label="Questions"
                            path="/prep/questions"
                            icon="questions"
                            :active="
                                active.split('/')[0] == 'prep' &&
                                active.split('/')[1] == 'questions'
                            "
                        />
                        <SidebarNavChild
                            v-if="user.permissions.prep_questions"
                            label="Questions"
                            path="/prep/questions"
                            icon="questions"
                            :active="
                                active.split('/')[0] == 'prep' &&
                                active.split('/')[1] == 'questions'
                            "
                        />
                    </SidebarNavParent>
                    <SidebarNavParent
                        v-if="user.permissions.pulse"
                        label="Pulse"
                        icon="pulse"
                        :active="active.split('/')[0] == 'pulse'"
                    >
                        <SidebarNavChild
                            v-if="user.permissions.pulse_explore"
                            label="Explore"
                            path="/pulse/explore"
                            icon="map"
                            :active="
                                active.split('/')[0] == 'pulse' &&
                                active.split('/')[1] == 'explore'
                            "
                        />
                        <SidebarNavChild
                            v-if="user.permissions.pulse_lookup"
                            label="Lookup"
                            path="/pulse/lookup"
                            icon="telescope"
                            :active="
                                active.split('/')[0] == 'pulse' &&
                                active.split('/')[1] == 'lookup'
                            "
                        />
                        <SidebarNavChild
                            v-if="user.permissions.pulse_builder"
                            label="Report Builder"
                            path="/pulse/builder"
                            icon="build"
                            :active="
                                active.split('/')[0] == 'pulse' &&
                                active.split('/')[1] == 'builder'
                            "
                        />
                    </SidebarNavParent>
                    <SidebarNavParent
                        v-if="user.permissions.admin"
                        label="Admin"
                        icon="crown"
                        :active="active.split('/')[0] == 'admin'"
                    >
                        <SidebarNavChild
                            v-if="user.permissions.admin"
                            label="Thought"
                            path="/admin/thought"
                            icon="ti"
                            :active="
                                active.split('/')[0] == 'admin' &&
                                active.split('/')[1] == 'thought'
                            "
                        />
                        <SidebarNavChild
                            v-if="user.permissions.admin"
                            label="Permissions"
                            path="/admin/permissions"
                            icon="binocular"
                            :active="
                                active.split('/')[0] == 'admin' &&
                                active.split('/')[1] == 'permissions'
                            "
                        />
                        <SidebarNavChild
                            v-if="user.permissions.super"
                            label="Horizon"
                            path="/horizon"
                            icon="horizon"
                            :external="true"
                            :active="null"
                        />
                        <SidebarNavChild
                            v-if="user.permissions.super"
                            label="Flare"
                            path="https://flareapp.io/projects/3243-meadow"
                            icon="flare"
                            :external="true"
                            :active="null"
                        />
                        <SidebarNavChild
                            v-if="user.permissions.super"
                            label="Logger"
                            path="/admin/logger"
                            icon="logging-truck"
                            :active="
                                active.split('/')[0] == 'admin' &&
                                active.split('/')[1] == 'logger'
                            "
                        />
                    </SidebarNavParent>
                </nav>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        active: {
            type: String,
            default: "/",
        },
        user: {
            type: Object,
        },
    },
    data(): any {
        return {
            open: false,
        };
    },
    created() {
        this.$store.commit("registerState", {
            user: this.user,
            permissions: this.permissions,
            token: document
                .querySelector('meta[name="token"]')
                ?.getAttribute("content"),
        });
    },
    mounted() {
        this.$events.$on("toggleNav", () => (this.open = !this.open));
    },
};
</script>
