<template>
    <div>
        <input
            type="hidden"
            name="questions"
            :value.json="JSON.stringify(questionIds)"
        />
        <draggable
            v-if="questions.length > 0"
            class="border rounded-lg mb-4"
            v-model="questions"
            handle=".drag-handle"
            group="questions"
            @start="drag = true"
            @end="drag = false"
        >
            <template #item="{ element: question, index }">
                <div
                    class="odd:bg-gray-50 flex items-center justify-between first:rounded-t-lg last:rounded-b-lg"
                >
                    <div class="flex items-center">
                        <div
                            class="flex items-center justify-center flex-shrink-0 drag-handle text-gray-400 ml-3 w-9 h-9"
                        >
                            <SvgIcon icon="drag" size="sm" />
                        </div>
                        <div
                            class="examQuestion prose text-sm py-6 pl-10 pr-6"
                            :data-position="index + 1"
                            v-html="question.question"
                        />
                    </div>
                    <div class="flex items-center space-x-4 px-6">
                        <div
                            class="bg-white flex items-center justify-center rounded-full shadow px-2 space-x-2 h-6"
                        >
                            <div :class="'status ' + question.status"></div>
                            <div
                                class="text-2xs text-gray-500 font-semibold uppercase"
                                v-text="question.status"
                            ></div>
                        </div>
                        <ActionMenu>
                            <ActionMenuAction
                                type="link"
                                label="Edit"
                                icon="edit"
                                iconColor="text-cyan-200"
                                :action="
                                    '/admin/content/learn/questions/' +
                                    question.uuid
                                "
                            />
                            <ActionMenuAction
                                type="button"
                                label="Remove"
                                icon="remove"
                                iconColor="text-red-200"
                                :argument="question"
                                :action="removeQuestion"
                            />
                        </ActionMenu>
                    </div>
                </div>
            </template>
        </draggable>
        <div class="flex items-center space-x-2">
            <div class="w-84">
                <input
                    class="text-sm font-mono"
                    type="text"
                    v-model="newQuestion"
                />
            </div>
            <button
                type="button"
                class="transition duration-300 flex items-center bg-gray-100 hover:bg-gray-50 border border-gray-200 text-gray-800 px-4 text-sm leading-8 rounded-lg space-x-2"
                @click="addQuestion"
            >
                <SvgIcon icon="add" size="xxs" weight="extra-bold" />
                <div>Add question</div>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import draggable from "vuedraggable";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
        draggable,
    },
    props: {
        items: Array,
    },
    data(): any {
        return {
            questions: [],
            newQuestion: null,
        };
    },
    computed: {
        questionIds() {
            if (this.questions.length == 0) {
                return [];
            }
            return this.questions.map((q) => q.id);
        },
    },
    methods: {
        addQuestion() {
            axios
                .get("/api/admin/content/learn/questions/" + this.newQuestion)
                .then((response) => {
                    if (this.questionIds.includes(response.data.id)) {
                        this.$events.$emit("toastNotification", {
                            action: "error",
                            heading: "Error!",
                            message: "Question is already present in exam",
                            status: "error",
                        });
                    } else {
                        this.questions.push(response.data);
                    }
                });
        },
        removeQuestion(question) {
            // Remove question from questions array
            this.questions = this.questions.filter((q) => {
                console.log(q);
                console.log(question);
                return q.id != question.id;
            });
        },
    },
    mounted() {
        this.questions = this.items;
    },
});
</script>

<style scoped>
.examQuestion:before {
    @apply absolute text-right font-bold -ml-10 w-8;

    content: attr(data-position) ")";
}
</style>
