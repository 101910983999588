<template>
    <div v-if="!hidden">
        <div class="list-item justify-between">
            <div class="w-72 truncate pr-2 mr-8">
                <a class="cta text-lg font-light" :href="link">
                    {{ row.lesson }}
                </a>
                <div class="flex items-center mt-1">
                    <strong
                        class="text-xs uppercase font-semibold tracking-wide"
                    >
                        {{ row.module }}
                    </strong>
                </div>
            </div>
            <div class="text-center w-32 px-2 mr-8">
                <Skeleton :loading="loading">
                    {{ assessmentData.taken }}
                </Skeleton>
            </div>
            <div class="text-center w-32 px-2 mr-8">
                <Skeleton :loading="loading">
                    <span
                        v-html="
                            $filters.grade(
                                assessmentData.avgScore,
                                settings.pulse_grade
                            )
                        "
                    />
                </Skeleton>
            </div>
            <div class="text-center w-32 px-2 mr-8">
                <Skeleton :loading="loading">
                    <span
                        v-text="$filters.elapsed(assessmentData.avgDuration)"
                    />
                </Skeleton>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState, mapGetters } from "vuex";

export default {
    props: {
        row: {
            type: Object,
        },
        hideEmpty: {
            type: Boolean,
        },
    },
    data(): any {
        return {
            assessmentData: [],
            loading: true,
        };
    },
    computed: {
        ...mapState(["settings", "type"]),
        ...mapGetters(["context"]),
        hidden() {
            if (this.hideEmpty && this.assessmentData.taken === 0) {
                return true;
            }
            return false;
        },
        link() {
            return (
                "/pulse/explore/" +
                this.context +
                "/" +
                this.type +
                "/" +
                this.row.uuid
            );
        },
    },
    methods: {
        async getData() {
            this.loading = true;
            axios
                .get(
                    "/api/pulse/assessment/" +
                        this.context +
                        "/" +
                        this.type +
                        "/" +
                        this.row.uuid
                )
                .then((response) => {
                    this.assessmentData = response.data;
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getData();
    },
};
</script>
