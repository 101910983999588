<script setup lang="ts">
import { ref, defineProps, onMounted } from "vue";
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
} from "@headlessui/vue";

const isOpen = ref(false);
const dontShowAgain = ref(false);

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    cacheKey: {
        type: String,
        required: true,
    },
    onClose: {
        type: Function,
        required: true,
    },
});

const storageKey = `clover.temp-dialog${props.cacheKey}`;

onMounted(() => {
    // use the key property to see if localStorage variable is set
    // if it is, then don't show the modal
    const shouldShow = localStorage.getItem(storageKey) === "true";
    if (shouldShow) {
        return;
    }
    isOpen.value = true;
});

function closeModal() {
    isOpen.value = false;
    if (dontShowAgain.value) {
        localStorage.setItem(storageKey, "true");
    }
}

function closePrevent() {
    isOpen.value = false;
    localStorage.setItem(storageKey, "true");
}

function openModal() {
    isOpen.value = true;
}
</script>
<template>
    <TransitionRoot appear :show="isOpen" as="template">
        <Dialog as="div" class="relative z-100">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div
                    class="flex min-h-full items-center justify-center p-4 text-center"
                >
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                            class="w-full max-w-lg transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all"
                        >
                            <DialogTitle
                                as="h3"
                                class="text-lg font-medium leading-6 text-gray-900"
                            >
                                {{ title }}
                            </DialogTitle>
                            <div class="mt-2 prose">
                                <p class="text-sm text-gray-500">
                                    Due to recent developments by the ARRT on
                                    continuing education (CE) and the
                                    CTtechBootCamp pending renewal, we have made
                                    modifications and added some additional
                                    content to our courses. The ASRT, one of
                                    ARRT’s recognized continuing education
                                    evaluation mechanisms, or RCEEM for short,
                                    has reviewed the updates within
                                    CTtechBootCamp and approved each course for
                                    CE.
                                </p>

                                <p class="text-sm text-gray-500">
                                    To make these course updates, there will be
                                    downtime to the post-course assessment from
                                    <strong
                                        >February 1st, 2023 between 12:01 am - 5
                                        pm EST.</strong
                                    >
                                </p>

                                <p class="text-sm text-gray-500">
                                    Effective
                                    <strong
                                        >February 1st, 2023, 12 am EST, any
                                        incomplete (OPEN) post-course
                                        assessments will be automatically closed
                                        and counted towards an attempt.</strong
                                    >
                                </p>

                                <p class="text-sm text-gray-500">
                                    If you have any questions or concerns,
                                    please email support at
                                    <a href="mailto:support@cloverlearning.com"
                                        >support@cloverlearning.com</a
                                    >
                                </p>
                            </div>

                            <div
                                class="mt-4 flex w-full flex-row items-center justify-end space-x-1.5"
                            >
                                <!-- Add checkbox that says Don't show me again -->
                                <div class="flex flex-row items-center">
                                    <button
                                        class="ml-2 block text-xs text-gray-900"
                                        @click="closePrevent"
                                    >
                                        Don't show me again
                                    </button>
                                </div>
                                <button
                                    type="button"
                                    class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                    @click="closeModal"
                                >
                                    OK
                                </button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
