<template>
    <router-link
        class="nav-child flex items-center border-b last:border-b-0 no-underline hover:no-underline whitespace-nowrap p-4 pr-8 first:rounded-t last:rounded-b"
        :class="{
            active: active,
        }"
        :rel="{
            noopener: external,
        }"
        :to="path"
    >
        <div
            class="nav-child-icon flex items-center justify-center rounded w-8 h-8"
        >
            <SvgIcon :icon="icon" size="base" />
        </div>
        <div class="nav-child-label tracking-tight ml-3">
            {{ label }}
        </div>
    </router-link>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import { defineComponent } from "vue";
export default defineComponent({
    props: {
        admin: Boolean,
        external: Boolean,
        icon: String,
        label: String,
        path: {
            type: String,
            default: "#",
        },
    },
    computed: {
        ...mapGetters(["appPath"]),
        active() {
            return window.location.href.indexOf(this.path) > -1;
            return this.appPath.indexOf(this.path) > -1;
        },
        target() {
            return this.external ? "_blank" : "_self";
        },
    },
});
</script>
