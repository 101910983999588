<template>
    <div
        class="fixed bottom-0 inset-x-0 p-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-top"
        v-show="active"
    >
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div class="fixed inset-0 transition-opacity" v-if="active">
                <div class="absolute inset-0 bg-gray-900 opacity-75"></div>
            </div>
        </transition>
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="transition ease-in duration-200"
            leave-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
            <div
                class="scroll bg-gray-100 rounded-md overflow-x-hidden overflow-y-auto shadow-md transform transition-all sm:max-w-6xl sm:w-full max-h-full"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
                v-if="active"
                v-click-away="close"
            >
                <div class="p-6">
                    <div class="flex items-start justify-between">
                        <h3
                            class="text-lg leading-6 font-medium text-gray-900"
                            id="modal-headline"
                        >
                            {{ parentName }} Details
                        </h3>
                        <button @click="close">
                            <SvgIcon icon="close" size="sm" weight="bold" />
                        </button>
                    </div>
                    <div class="mt-6">
                        <div
                            v-for="cat in category.rows"
                            class="flex bg-white rounded-md shadow mb-4 last:mb-0"
                        >
                            <div class="flex items-center justify-center w-1/3">
                                <div class="p-6">
                                    <div
                                        class="flex items-center justify-center"
                                    >
                                        <div class="w-40 h-40">
                                            <PrepCategoryReportDonut
                                                :name="parentName"
                                                :correct="cat.questions_correct"
                                                :total="cat.questions_total"
                                            />
                                        </div>
                                    </div>
                                    <div class="flex flex-col space-y-2 mt-4">
                                        <div
                                            class="flex items-center justify-center"
                                        >
                                            <div class="text-center font-bold">
                                                {{ cat.name }}
                                            </div>
                                        </div>
                                        <div
                                            class="flex items-center justify-center"
                                        >
                                            <div
                                                v-if="cat.questions_total > 0"
                                                class="inline-flex items-center justify-center bg-gray-100 border border-gray-200 text-sm leading-8 rounded-md space-x-1 px-4"
                                            >
                                                <div
                                                    v-text="
                                                        cat.questions_correct
                                                    "
                                                />
                                                <div>/</div>
                                                <div
                                                    v-text="cat.questions_total"
                                                />
                                                <div class="font-medium pl-1">
                                                    correct
                                                </div>
                                            </div>
                                            <div
                                                v-else
                                                class="inline-flex items-center justify-center bg-gray-100 border border-gray-200 text-sm leading-8 rounded-md space-x-1 px-4"
                                            >
                                                No questions
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-2/3">
                                <div class="grid grid-cols-2 gap-6 p-6">
                                    <div
                                        v-for="child in cat.children"
                                        class="flex flex-col rounded-xl shadow"
                                    >
                                        <div
                                            class="bg-gray-50 border-b border-gray-100 rounded-t-xl text-sm p-4"
                                        >
                                            {{ child.name }}
                                        </div>
                                        <div
                                            class="flex items-center justify-center rounded-b-xl space-x-6 p-4"
                                        >
                                            <div class="w-20">
                                                <PrepCategoryReportDonut
                                                    :mini="true"
                                                    :name="parentName"
                                                    :correct="
                                                        child.questions_correct
                                                    "
                                                    :total="
                                                        child.questions_total
                                                    "
                                                />
                                            </div>
                                            <div
                                                class="flex items-center justify-start"
                                            >
                                                <div
                                                    v-if="
                                                        child.questions_total >
                                                        0
                                                    "
                                                    class="inline-flex items-center justify-center bg-gray-100 border border-gray-200 text-sm leading-8 rounded-xl space-x-1 px-4"
                                                >
                                                    <div
                                                        v-text="
                                                            child.questions_correct
                                                        "
                                                    />
                                                    <div>/</div>
                                                    <div
                                                        v-text="
                                                            child.questions_total
                                                        "
                                                    />
                                                    <div
                                                        class="font-medium pl-1"
                                                    >
                                                        correct
                                                    </div>
                                                </div>
                                                <div
                                                    v-else
                                                    class="inline-flex items-center justify-center bg-gray-100 border border-gray-200 text-sm leading-8 rounded-xl space-x-1 px-4"
                                                >
                                                    No questions
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { PropType } from "vue";

export default {
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        category: Object as PropType<any>,
    },
    data(): any {
        return {};
    },
    computed: {
        parentName() {
            return this.category.name || "";
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
    },
};
</script>

<style scoped>
.scroll {
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: radial-gradient(white, black);
}
</style>
