<template>
    <div class="flex flex-col space-y-12">
        <div class="flex bg-gray-200 shadow-inner rounded-md p-4">
            <div class="flex items-center justify-between text-sm w-full">
                <div
                    class="flex items-center justify-between flex-wrap space-y-4 sm:space-y-0 sm:space-x-4 w-full"
                >
                    <div class="flex space-x-4">
                        <div
                            v-if="exams.length > 0 || assignments.length > 0"
                            class="flex flex-col"
                        >
                            <label
                                class="font-semibold text-xs uppercase leading-6 ml-2"
                                >Filter By</label
                            >
                            <SelectDropdown v-model="filterBy">
                                <option value="">Show All</option>
                                <option value="exam">Exam</option>
                                <option
                                    v-if="assignments.length > 0"
                                    value="assignment"
                                >
                                    Assignment
                                </option>
                            </SelectDropdown>
                        </div>
                        <div
                            v-if="exams.length > 0 && filterBy == 'exam'"
                            class="flex flex-col"
                        >
                            <label
                                class="font-semibold text-xs uppercase leading-6 ml-2"
                                >Exam</label
                            >
                            <SelectDropdown v-model="filterExam">
                                <option value="">Show All</option>
                                <option value="mock">Mock Registry Exam</option>
                                <option
                                    v-for="exam in exams"
                                    :value="exam.uuid"
                                >
                                    {{ exam.name }}
                                </option>
                            </SelectDropdown>
                        </div>
                        <div
                            v-if="
                                assignments.length > 0 &&
                                filterBy == 'assignment'
                            "
                            class="flex flex-col"
                        >
                            <label
                                class="font-semibold text-xs uppercase leading-6 ml-2"
                                >Assignment</label
                            >
                            <SelectDropdown v-model="filterAssignment">
                                <option value="">Show All</option>
                                <option
                                    v-for="assignment in assignments"
                                    :value="assignment.uuid"
                                >
                                    {{ assignment.name }}
                                </option>
                            </SelectDropdown>
                        </div>
                    </div>
                    <div class="flex space-x-4">
                        <div class="flex flex-col">
                            <label
                                class="font-semibold text-xs uppercase leading-6 ml-2"
                                >Status</label
                            >
                            <SelectDropdown v-model="filterStatus">
                                <option value="">Show All</option>
                                <option value="completed">Completed</option>
                                <option value="started">Started</option>
                            </SelectDropdown>
                        </div>
                        <div class="flex flex-col">
                            <label
                                class="font-semibold text-xs uppercase leading-6 ml-2"
                                >Keyword</label
                            >
                            <div class="w-72">
                                <input
                                    class="border-none leading-5 rounded-lg shadow p-3 w-full h-12 focus-visible:ring focus-visible:ring-blue-300 focus-visible:ring-opacity-50"
                                    type="text"
                                    v-model="filterSearchable"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <label
                            class="font-semibold text-xs uppercase leading-6 ml-2"
                            >Page Size</label
                        >
                        <SelectDropdown class="w-28" v-model="perPage">
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </SelectDropdown>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showCategoryReport" class="bg-white rounded-md shadow">
            <PrepCategoryReport
                :showTitle="true"
                :showClose="true"
                :rows="rows"
                :bank="filterBank"
                :showBankDropdown="true"
                :subject="subject"
                :period="period"
                @closeCategoryBreakdown="showCategoryBreakdown = false"
            />
        </div>
        <template v-if="!loading">
            <template v-if="rows.length > 0">
                <div>
                    <div
                        v-if="pages > 1"
                        class="flex items-center justify-end mb-4"
                    >
                        <div class="flex items-center space-x-4 px-4">
                            <Pagination
                                :total="total"
                                :perPage="perPage"
                                v-model="page"
                            />
                        </div>
                    </div>
                    <div class="flex items-center mb-2">
                        <div class="flex justify-start pl-4 w-48">
                            <div
                                class="text-gray-700 text-xs uppercase font-semibold"
                            >
                                Date submitted
                            </div>
                        </div>
                        <div class="flex justify-start pl-4 w-52">
                            <div
                                class="text-gray-700 text-xs uppercase font-semibold"
                            >
                                Learner
                            </div>
                        </div>
                        <div class="flex justify-start flex-grow pl-14">
                            <div
                                class="text-gray-700 text-xs uppercase font-semibold"
                            >
                                Name
                            </div>
                        </div>
                        <div class="flex justify-center w-24">
                            <div
                                class="text-gray-700 text-xs uppercase font-semibold"
                            >
                                Time
                            </div>
                        </div>
                        <div class="flex justify-center w-28">
                            <div
                                class="text-gray-700 text-xs uppercase font-semibold"
                            >
                                Score
                            </div>
                        </div>
                        <div class="w-20"></div>
                    </div>
                    <div class="sm:bg-white sm:rounded-md sm:shadow">
                        <PulseExplorePrepRow
                            v-for="row in rows"
                            :key="row.uuid"
                            :legacy="legacy"
                            :row="row"
                        />
                    </div>
                </div>
            </template>
            <div v-if="rows.length === 0" class="h-40">
                <Placeholder label="No data" />
            </div>
        </template>
        <Loader :show="loading" />
    </div>
</template>

<script lang="ts">
import axios from "axios";
import debounce from "debounce";
import { mapState, mapGetters } from "vuex";

export default {
    props: {
        exams: Array,
        getExam: String,
        assignments: Array,
        getAssignment: String,
        legacy: Boolean,
    },
    data(): any {
        return {
            loading: true,
            rows: [],
            filterBy: "",
            filterAssignment: "",
            filterExam: "",
            filterResourceId: null,
            filterSearchable: "",
            filterStatus: "",
            sortBy: "timestamp",
            sortOrder: "desc",
            page: 1,
            perPage: "10",
            showCategoryBreakdown: true,
            total: null,
        };
    },
    computed: {
        ...mapState(["period", "subject", "user"]),
        ...mapGetters(["context"]),
        pages() {
            return Math.ceil(this.total / this.perPage);
        },
        filterGroup() {
            if (this.filterBy != "") {
                return this.filterBy == "assignment"
                    ? this.assignments.filter(
                          (o) => o.uuid == this.filterAssignment
                      )
                    : this.exams.filter((o) => o.uuid == this.filterExam);
            }
            return [];
        },
        filterBank() {
            if (this.filterGroup.length > 0) {
                return this.filterGroup[0].bank;
            }
            return "";
        },
        path() {
            return (
                "/api/pulse/prep" +
                this.context +
                "&by=" +
                this.filterBy +
                "&exam=" +
                this.filterExam +
                "&assignment=" +
                this.filterAssignment +
                "&search=" +
                encodeURIComponent(this.filterSearchable) +
                "&status=" +
                this.filterStatus +
                "&page=" +
                this.page +
                "&perPage=" +
                this.perPage +
                "&legacy=" +
                this.legacy
            );
        },
        showCategoryReport() {
            return this.showCategoryBreakdown && this.rows.length > 0;
            // && (this.subject == this.user.uuid || this.filterAssignment != '' || this.filterExam != '')
        },
    },
    methods: {
        getData() {
            axios.get(this.path).then((response) => {
                this.rows = response.data.data;
                this.total = response.data.total;
                this.loading = false;
            });
        },
    },
    mounted() {
        this.getData = debounce(this.getData, 200);
        this.getData();

        if (this.getAssignment) {
            this.filterBy = "assignment";
            this.filterAssignment = this.getAssignment;
        }

        if (this.getExam) {
            this.filterBy = "exam";
            this.filterExam = this.getExam;
        }
    },
    watch: {
        filterBy(value) {
            if (value == "") {
                this.filterAssignment = "";
                this.filterExam = "";
            }
            if (value == "exam") {
                this.filterAssignment = "";
            }
            if (value == "assignment") {
                this.filterExam = "";
            }
        },
        path() {
            this.loading = true;
            this.rows = [];
            this.getData();
        },
        perPage() {
            this.page = 1;
        },
    },
};
</script>
