<template>
    <CourseForm :course="course" @save="updateCourse" v-if="course" />
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import axios from "axios";
import { Course } from "@/types";
import CourseForm from "./CourseForm.vue";
import $events from "@/event-bus.ts";

const course = ref<Partial<Course> | null>(null);
const props = defineProps({
    uuid: String,
});

async function getCourse(uuid) {
    axios.get(`/api/admin/content/learn/courses/${uuid}`).then((response) => {
        const courseResponse = response.data;
        courseResponse.categories = courseResponse.categories.map((c) => c.slug)
        course.value = courseResponse;
    });
}

async function updateCourse(updatedCourse: Course, postCourseExam) {
    if (postCourseExam?.uuid) {
        await axios.post(
            `/api/admin/content/learn/courses/${updatedCourse.id}/exams/${postCourseExam.uuid}`,
            { course_id: updatedCourse.id }
        )
            .then((response) => {
                if (response.status === 200) {
                    $events.$emit("toastNotification", {
                        action: "saved",
                        heading: "Post course exam saved successfully",
                        status: "saved",
                    });
                }
            })
            .catch((e) => {
                $events.$emit("toastNotification", {
                    action: "error",
                    heading: `Error saving post course exam: ${e}`,
                    status: "error",
                });
            });
    }

    await axios
        .put(`/api/admin/content/learn/courses/${props.uuid}`, updatedCourse)
        .then((response) => {
            course.value = response.data;
            $events.$emit("toastNotification", {
              action: "saved",
              heading: "Course saved",
              status: "saved",
            });
        })
        .catch((e) => {
          $events.$emit("toastNotification", {
            action: "error",
            heading: `Error updating course: ${e}`,
            status: "error",
          });
        });
}

onMounted(async () => {
    await getCourse(props.uuid);
});
</script>
