<template>
    <dropdown-item
        :icon-left="type.icon"
        :icon-right="active ? 'check-line' : undefined"
        :path="path"
        :variant="variant"
    >
        {{ type.label }}
    </dropdown-item>
</template>

<script lang="ts">
import { PropType } from "vue";

export default {
    props: {
        active: Boolean,
        type: Object as PropType<any>,
        path: String,
        variant: String,
    },
};
</script>
