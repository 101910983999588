<template>
    <div v-show="show_form">
        <slot />
    </div>
</template>

<script lang="ts">
import axios from "axios";

export default {
    data(): any {
        return {
            show_form: false,
        };
    },
    mounted() {
        this.$events.$on("displayLoginForm", (data) => {
            this.show_form = true;

            // @ts-ignore
            document.getElementById("emailLoginInput").value =
                data.email_address;
            // @ts-ignore
            document.getElementById("hiddenEmail").value = data.email_address;
        });
    },
};
</script>
