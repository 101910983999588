<template>
	<div
		class="flex gap-2 h-100 w-full items-center py-3 px-5"
		:class="{
			'bg-green-100': type === 'success',
			'border-green-300 border rounded':
				type === 'success' && floating === true,
			'bg-blue-100': type === 'info',
			'border-blue-300 border rounded':
				type === 'info' && floating === true,
			'bg-yellow-100': type === 'warning',
			'border-yellow-300 border rounded':
				type === 'warning' && floating === true,
			'bg-red-100': type === 'danger',
			'border-red-300 border rounded':
				type === 'danger' && floating === true,
			hidden: alertStorage[alertName],
		}">
		<slot name="icon" v-if="showIcon">
			<CheckCircleIcon
				v-if="type === 'success'"
				class="w-6 h-6 text-green-600" />
			<InformationCircleIcon
				v-if="type === 'info'"
				class="w-6 h-6 text-blue-600" />
			<ExclamationCircleIcon
				v-if="type === 'warning'"
				class="w-6 h-6 text-yellow-600" />
			<ExclamationTriangleIcon
				v-if="type === 'danger'"
				class="w-6 h-6 text-red-600" />
		</slot>
		<div class="text-sm font-medium flex-1">
			<slot />
		</div>
		<div v-if="dismissable">
			<button @click="dismiss" class="flex items-center justify-center">
				<XMarkIcon class="w-6 h-6 text-gray-900" />
			</button>
		</div>
	</div>
</template>

<script setup lang="ts">
import { XMarkIcon } from "@heroicons/vue/24/solid";
import { useLocalStorage } from "@vueuse/core";
import {
	CheckCircleIcon,
	ExclamationCircleIcon,
	ExclamationTriangleIcon,
	InformationCircleIcon,
} from "@heroicons/vue/20/solid";

interface AlertProps {
	alertName: any;
	type: string;
	showIcon: boolean;
	dismissable: boolean;
	floating: boolean;
}

const props = withDefaults(defineProps<AlertProps>(), {
	alertName: "alert-banner",
	type: "info",
	showIcon: true,
	dismissable: false,
	floating: false,
});
const alertStorage = useLocalStorage("clp/banners/alert", {
	[props.alertName]: false,
});

function dismiss() {
	alertStorage.value[props.alertName] = true;
}
</script>
