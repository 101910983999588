<template>
    <div>
        <lesson-note
            :noteId="null"
            :note="null"
            :show="true"
            :updatedAt="null"
            :lesson-instance-id="instance_id"
            @save-new-note="addNote"
        ></lesson-note>
        <div v-for="(note, index) in lesson_notes" :key="note.id">
            <lesson-note
                :noteId="note.id"
                :note="note.note"
                :show="false"
                :updatedAt="note.updated_at"
            ></lesson-note>
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import draggable from "vuedraggable";

export default {
    name: "LessonNotes",
    components: {
        draggable,
    },
    props: {
        notes: {
            required: false,
            default: null,
            type: Array,
        },
        lessonInstanceId: {
            required: false,
            default: null,
            type: Number,
        },
    },
    data(): any {
        return {
            lesson_notes: this.notes,
            instance_id: this.lessonInstanceId,
            new_note: false,
        };
    },
    methods: {
        toggleNewNote() {
            this.new_note = !this.new_note;
        },
        addNote(payload) {
            this.lesson_notes = [payload, ...this.lesson_notes];
        },
    },
};
</script>
