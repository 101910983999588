<template>
    <Tabs :initial="activeTab" :tabs="tabs">
        <template #exams>
            <slot name="exams" />
        </template>

        <template #assignments>
            <slot name="assignments" />
        </template>
    </Tabs>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters, mapState } from "vuex";
import $events from "../../../event-bus";

export default defineComponent({
    computed: {
        ...mapGetters(["appPath", "context"]),
        ...mapState(["activePath", "tab"]),
        activeTab() {
            const isPrepTab = Boolean(
                this.tabs.find((tab) => tab.name === this.tab)
            );

            if (!isPrepTab) {
                return "exams";
            }

            // @ts-ignore
            return this.tab;
        },
        tabs() {
            return [
                {
                    name: "exams",
                    label: "Exams",
                },
                {
                    name: "assignments",
                    label: "Assigned Exams",
                },
            ];
        },
    },
    created() {
        $events.$on("tabChanged", (tab) => {
            // @ts-ignore
            this.$store.commit("registerState", {
                tab: tab,
            });
        });
    },
});
</script>
