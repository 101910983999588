<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "ToggleVisibility",

    props: {
        initiallyVisible: {
            type: Boolean,
            required: false,
            default: true,
        },
        moduleDivId: {
            type: String,
            required: false,
            default: null,
        },
    },

    data(): any {
        return {
            isVisible: this.initiallyVisible,
            module_div_id: this.moduleDivId,
        };
    },

    methods: {
        toggleVisibility() {
            this.isVisible = !this.isVisible;
        },
    },

    render() {
        return this.$slots.default({
            isVisible: this.isVisible,
            toggleVisibility: this.toggleVisibility,
        });
    },
    mounted() {
        this.$events.$on("highlightLesson", (data) => {
            if (this.module_div_id == data.module_div_id) {
                this.isVisible = true;
            }
        });
    },
});
</script>
