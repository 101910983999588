<template>
    <slot />
</template>
<script lang="ts">
declare const window: any;

export default {
    props: {
        id: String,
    },

    created() {
        window._wq = window._wq || [];

        let configuration = {
            id: this.id,

            options: {
                playbar: true,
                resumable: true,
                seo: false,
                videoFoam: true,
                wmode: "transparent",
            },
        };

        window._wq.push(configuration);
    },

    mounted() {
        let jsonP = document.createElement("script");
        jsonP.setAttribute(
            "src",
            "https://fast.wistia.com/embed/medias/" + this.id + ".jsonp"
        );
        document.head.appendChild(jsonP);

        let wistia = document.createElement("script");
        wistia.setAttribute(
            "src",
            "https://fast.wistia.com/assets/external/E-v1.js"
        );
        document.head.appendChild(wistia);
    },
};
</script>
