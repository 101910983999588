<template>
    <div>
        <div class="flex items-end justify-between px-4">
            <div class="w-72 mr-8">
                <div class="flex items-center">
                    <h3 class="font-bold">Date</h3>
                    <sort-button
                        emit="sortAssessment"
                        initial="asc"
                        variable="created_at"
                    />
                </div>
            </div>
            <div class="w-32 text-center mr-8">
                <div class="flex items-center justify-center">
                    <h3 class="font-bold">Score</h3>
                    <sort-button
                        emit="sortAssessment"
                        initial="asc"
                        variable="grade"
                    />
                </div>
            </div>
            <div class="w-32 text-center mr-8">
                <div class="flex items-center justify-center">
                    <h3 class="font-bold">Duration</h3>
                    <sort-button
                        emit="sortAssessment"
                        initial="asc"
                        variable="time_elapsed"
                    />
                </div>
            </div>
        </div>
        <div class="card">
            <pulse-assessment-attempts-row
                v-for="row in sorted"
                :key="row.uuid"
                :row="row"
            />
        </div>
    </div>
</template>

<script lang="ts">
import _ from "lodash";
export default {
    props: {
        rows: {
            type: Array,
        },
    },
    data(): any {
        return {
            sortBy: "created_at",
            sortOrder: "desc",
        };
    },
    computed: {
        sorted() {
            return _.orderBy(
                this.rows,
                [
                    (row: any) => {
                        return row[this.sortBy] || "";
                    },
                ],
                this.sortOrder
            );
        },
    },
    mounted() {
        this.$events.$on("sortAssessment", (response) => {
            this.sortBy = response.sortBy;
            this.sortOrder = response.sortOrder;
        });

        this.$events.$on("hydrateRow", (response) => {
            let rowIndex = this.rows.findIndex((o) => o.uuid == response.uuid);
            this.$set(this.rows[rowIndex], "taken", response.taken);
            this.$set(this.rows[rowIndex], "date", response.date);
            this.$set(this.rows[rowIndex], "latestScore", response.latestScore);
            this.$set(
                this.rows[rowIndex],
                "latestDuration",
                response.latestDuration
            );
            this.$set(
                this.rows[rowIndex],
                "averageScore",
                response.averageScore
            );
            this.$set(
                this.rows[rowIndex],
                "averageDuration",
                response.averageDuration
            );
        });
    },
};
</script>
