<template>
    <div>
        <BillingSection title="Billing Address">
            <div>
                <p class="text-gray-700 text-sm">
                    Clover Learning is required by law to collect applicable
                    transaction taxes for purchases made in certain tax
                    jurisdictions.
                </p>
                <div class="mt-4">
                    <div class="flex flex-row space-x-3">
                        <div class="flex-1">
                            <label
                                for="coupon"
                                class="block text-sm font-medium text-gray-700 pl-2"
                                >Country
                                <span class="text-xs">(required)</span></label
                            >
                            <div
                                class="relative flex items-stretch flex-grow focus-within:z-10"
                            >
                                <select
                                    name="address.country"
                                    class="focus-visible:ring-blue-500 focus:border-blue-500 block font-mono w-full rounded sm:text-sm border-gray-300"
                                    v-model="address.country"
                                >
                                    <option value="">Choose a Country</option>
                                    <option
                                        :value="country['alpha-2']"
                                        v-text="country.name"
                                        v-for="country in countries"
                                        v-bind:selected="
                                            address.country ===
                                            country['alpha-2']
                                        "
                                    ></option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div v-if="address.country === 'US'">
                        <div>
                            <div class="flex flex-row space-x-3 mt-2">
                                <div class="flex-1">
                                    <label
                                        for="coupon"
                                        class="block text-sm font-medium text-gray-700 pl-2"
                                        >Address Line 1
                                        <span class="text-xs"
                                            >(required)</span
                                        ></label
                                    >
                                    <div class="mt-1 flex rounded-md shadow-sm">
                                        <div
                                            class="relative flex items-stretch flex-grow focus-within:z-10"
                                        >
                                            <input
                                                type="text"
                                                name="billing_address.line1"
                                                class="focus-visible:ring-blue-500 focus:border-blue-500 block font-mono w-full rounded sm:text-sm border-gray-300"
                                                v-model="address.line1"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-1">
                                    <label
                                        for="coupon"
                                        class="block text-sm font-medium text-gray-700 pl-2"
                                        >Suite / Unit</label
                                    >
                                    <div class="mt-1 flex rounded-md shadow-sm">
                                        <div
                                            class="relative flex items-stretch flex-grow focus-within:z-10"
                                        >
                                            <input
                                                type="text"
                                                name="billing_address.line2"
                                                class="focus-visible:ring-blue-500 focus:border-blue-500 block font-mono w-full rounded sm:text-sm border-gray-300"
                                                v-model="address.line2"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-row space-x-3 mt-2">
                                <div class="flex-1">
                                    <label
                                        for="coupon"
                                        class="block text-sm font-medium text-gray-700 pl-2"
                                        >City
                                        <span class="text-xs"
                                            >(required)</span
                                        ></label
                                    >
                                    <div class="mt-1 flex rounded-md shadow-sm">
                                        <div
                                            class="relative flex items-stretch flex-grow focus-within:z-10"
                                        >
                                            <input
                                                type="text"
                                                name="billing_address.city"
                                                class="focus-visible:ring-blue-500 focus:border-blue-500 block font-mono w-full rounded sm:text-sm border-gray-300"
                                                v-model="address.city"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-1">
                                    <label
                                        for="coupon"
                                        class="block text-sm font-medium text-gray-700 pl-2"
                                        >State
                                        <span class="text-xs"
                                            >(required)</span
                                        ></label
                                    >
                                    <div class="mt-1 flex rounded-md shadow-sm">
                                        <div
                                            class="relative flex items-stretch flex-grow focus-within:z-10"
                                        >
                                            <select
                                                name="state"
                                                class="focus-visible:ring-blue-500 focus:border-blue-500 block font-mono w-full rounded sm:text-sm border-gray-300"
                                                v-model="address.state"
                                            >
                                                <option
                                                    :key="state.abbreviation"
                                                    :value="state.abbreviation"
                                                    v-text="state.name"
                                                    :selected="
                                                        address.state ===
                                                        state.abbreviation
                                                    "
                                                    v-for="state in states"
                                                ></option>
                                                >
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-1">
                                    <label
                                        for="coupon"
                                        class="block text-sm font-medium text-gray-700 pl-2"
                                        >Zip
                                        <span class="text-xs"
                                            >(required)</span
                                        ></label
                                    >
                                    <div class="mt-1 flex rounded-md shadow-sm">
                                        <div
                                            class="relative flex items-stretch flex-grow focus-within:z-10"
                                        >
                                            <input
                                                type="text"
                                                name="billing_address.zip"
                                                class="focus-visible:ring-blue-500 focus:border-blue-500 block font-mono w-full rounded sm:text-sm border-gray-300"
                                                v-model="address.postal_code"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="space-y-2">
                    <div class="plan-details flex flex-col">
                        <div class="flex flex-col flex-1 space-y-2 mt-6 w-48">
                            <button
                                class="button main flex-1"
                                :disabled="processing"
                                @click="submit"
                            >
                                Save
                                <LoadingSpinner
                                    class="mt-2"
                                    placement="button"
                                    :show="processing"
                                />
                            </button>
                            <a
                                class="button text-center flat flex-1"
                                href="/account/billing"
                            >
                                Go back
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </BillingSection>
    </div>
</template>

<script lang="ts">
import { mapState } from "vuex";
import BillingSection from "./BillingSection.vue";
import { getSubscription, renewSubscription } from "../../../api/billing";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import axios from "axios";

export default {
    components: { BillingSection },
    props: {},
    data(): any {
        return {
            processing: false,
            states: [],
            countries: [],
            address: {
                address1: "",
                address2: "",
                city: "",
                state: "",
                postal_code: "",
                country: "",
            },
        };
    },
    computed: {
        ...mapState(["user"]),

        formIsValid() {
            // check to make sure billing address is valid
            let addressValid = true;
            if (this.address.country === "US") {
                // check to make sure address1, state, zip are populated
                if (
                    this.address.line1 === "" ||
                    this.address.state === "" ||
                    this.address.postal_code === ""
                ) {
                    addressValid = false;
                }
            }
            if (this.address.country === "") {
                addressValid = false;
            }
            return addressValid;
        },
    },
    methods: {
        getBillingInfo() {
            axios
                .get(`/api/users/${this.user.uuid}/billing-info`, {})
                .then((response) => {
                    this.address = { ...response.data.address };
                });
        },
        submit() {
            this.processing = true;
            axios
                .post(
                    `/api/users/${this.user.uuid}/billing-info`,
                    this.address,
                    {}
                )
                .then((response) => {
                    this.address = { ...response.data.address };
                    this.processing = false;
                    // @ts-ignore
                    window.location = "/account/billing";
                });
        },
    },
    mounted() {
        axios
            .get("/data/countries.json")
            .then((res) => (this.countries = res.data));
        axios.get("/data/states.json").then((res) => (this.states = res.data));
        this.getBillingInfo();
    },
};
</script>
<style scoped>
.plan-details {
    width: 464px;
}
</style>
