import {CourseEnrollment} from "@/types";
import {backend} from "./utils";
import {Category, Discipline} from "@/types/content";

export async function getCourses(): Promise<CourseEnrollment[]> {
    const response = await backend.get("/api/learn/course-enrollments");
    return await response.json();
}

export async function getCategories(): Promise<Category[]> {
    const response = await backend.get("/learn/taxonomies?type=category&only_populated_categories=true&for_enrolled_courses=true");
    return await response.json();
}

export async function getDisciplines(): Promise<Discipline[]> {
    const response = await backend.get("/learn/taxonomies?type=discipline&for_enrolled_courses=true");
    return await response.json();
}
