<template>
    <div class="inline-flex">
        <div v-if="!text" class="loading" />
        <div v-else v-text="text" />
    </div>
</template>

<script lang="ts">
import axios from "axios";

export default {
    props: {
        url: String,
    },
    data(): any {
        return {
            text: null,
        };
    },
    methods: {
        async getData() {
            return await axios
                .get(this.url)
                .then((res) => (this.text = res.data));
        },
    },
    mounted() {
        window.addEventListener("load", () => this.getData());
    },
};
</script>

<style scoped>
.loading {
    @apply relative inline-flex w-2 h-2 rounded-full bg-gray-800 text-gray-800 mx-3;
    animation: loading 400ms infinite linear alternate;
    animation-delay: 200ms;
}

.loading::before,
.loading::after {
    @apply absolute top-0 inline-block;
    content: "";
}

.loading::before {
    @apply w-2 h-2 -ml-3 rounded-full bg-gray-800 text-gray-800;
    animation: loading 400ms infinite alternate;
    animation-delay: 0ms;
}

.loading::after {
    @apply w-2 h-2 ml-3 rounded-full bg-gray-800 text-gray-800;
    animation: loading 400ms infinite alternate;
    animation-delay: 400ms;
}

@keyframes loading {
    0% {
        @apply bg-gray-800;
    }
    50%,
    100% {
        @apply bg-white;
    }
}
</style>
