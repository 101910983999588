import z from "zod";
import { ref, watch, watchEffect } from "vue";

type UseFormArgs<T> = {
    schema: z.Schema<T>;
    submit: (data: z.infer<z.Schema<T>>) => Promise<void>;
    initValues: T;
    errorsIfDirty?: boolean;
};

export function useForm<T>(args: UseFormArgs<T>) {
    const errorsIfDirty = args.errorsIfDirty ?? true;
    const isValid = ref(false);
    const isDirty = ref(false);
    const isSubmitting = ref(false);
    const isSubmitted = ref(false);
    const errors = ref<z.inferFlattenedErrors<z.Schema<T>>["fieldErrors"]>({});

    const submit = async (event: Event) => {
        isSubmitting.value = true;
        isDirty.value = true;
        await validate();
        try {
            await args.submit(data.value as T);
        } finally {
            isSubmitted.value = true;
            isSubmitting.value = false;
        }
    };

    const data = ref<T>(args.initValues);

    const validate = async () => {
        const results = await args.schema.safeParseAsync(data.value);
        if (results.success) {
            isValid.value = true;
            errors.value = null;
        } else {
            isValid.value = false;
            errors.value = results.error.formErrors.fieldErrors;
        }
    };

    watch(
        data,
        async (newData) => {
            isDirty.value = true;
            if (errorsIfDirty) {
                await validate();
            }
        },
        {
            deep: true,
        }
    );

    return {
        isValid,
        isDirty,
        isSubmitting,
        isSubmitted,
        submit,
        data,
        errors,
    };
}
