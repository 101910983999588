<template>
    <div class="rounded-md shadow">
        <div class="bg-white border-b border-gray-100 rounded-t-md p-4">
            <div class="font-bold">Course map</div>
        </div>
        <div>
            <div class="bg-white border-b-2 border-gray-100 font-bold p-4">
                {{ course.title }}
            </div>
            <div class="divide-y">
                <LearnCourseMapModule
                    v-for="module in course.modules"
                    :courseSlug="course.slug"
                    :key="module.uuid"
                    :module="module"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType } from "vue";
export default {
    props: {
        course: Object as PropType<any>,
    },
};
</script>
