<script setup lang="ts">
import { ref, defineProps, watch } from "vue";
import Button from "@/components/ui/ButtonLinks/Button.vue";
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
} from "@headlessui/vue";
import {Course, Module} from "../../../../types";
import {
  adminAddModuleToCourse,
  adminSearchModules,
} from "@/api/admin.ts";
import _ from "lodash";
import $events from "@/event-bus.ts";

const isOpen = ref(false);
const search = ref("");
const modules = ref<Module[]>([]);

const props = defineProps<{
    course: Course;
}>();

const emit = defineEmits<{
    (event: "close"): void;
  (event: "moduleAddSuccess"): void;
}>();

const searchForLessons = _.debounce((value) => {
    adminSearchModules(value).then((response) => {
        modules.value = response.data;
    });
}, 500);

watch(search, (value) => {
    if (value.length > 2) {
        searchForLessons(value);
    }
});

function closeModal() {
    isOpen.value = false;
}

function openModal() {
    isOpen.value = true;
}

async function addModuleToCourse(module: Module) {
    return adminAddModuleToCourse(props.course.uuid, module.uuid).then((res) => {
      if (res.status === 200) {
        $events.$emit("toastNotification", {
          action: "saved",
          heading: "Module successfully added to course",
          status: "saved",
        });
        emit('moduleAddSuccess')
      }
    })
    .catch((e) => {
      console.error(e)
      $events.$emit("toastNotification", {
        action: "error",
        heading: `Error attaching module to course: ${e}`,
        status: "error",
      });
    }).finally(() => closeModal());
}
</script>
<template>
    <div class="inset-0 flex items-center justify-center">
        <Button type="button" @click="openModal" size="sm">
            &plus; Existing
        </Button>
    </div>
    <TransitionRoot appear :show="isOpen" as="template">
        <Dialog as="div" @close="closeModal" class="relative z-10">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div
                    class="flex min-h-full items-center justify-center p-4 text-center"
                >
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                            class="w-full max-w-lg transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all"
                        >
                            <DialogTitle
                                as="h3"
                                class="text-lg font-medium leading-6 text-gray-900"
                            >
                                Add Existing Module
                            </DialogTitle>
                            <div class="mt-2">
                                <label for="search" class="sr-only">
                                    Search
                                </label>
                                <input
                                    type="text"
                                    name="search"
                                    id="search"
                                    placeholder="Search"
                                    class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    v-model="search"
                                />
                            </div>
                            <div class="flex flex-col my-4">
                                <div
                                    v-for="module in modules"
                                    class="flex gap-2 flex-row justify-between items-center even:bg-gray-100 p-2"
                                >
                                    <div>
                                        <span class="font-medium">{{
                                            module.title
                                        }}</span>
                                        <div
                                            class="text-gray-500 text-xs flex flex-wrap gap-2"
                                        >
                                            <div>
                                                <strong>ID:</strong>
                                                {{ module.id }}
                                            </div>
                                            <div>
                                                <strong>UUID:</strong>
                                                {{ module.uuid }}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            @click="addModuleToCourse(module)"
                                            class="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                        >
                                            Add
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
