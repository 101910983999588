<script lang="ts" setup>
import { onMounted, ref } from "vue";
import Button from "@/components/ui/ButtonLinks/Button.vue";
import { Category, NewCategory } from "@/types/content";
import { toast } from "@/components/utils/toast";

const props = defineProps<{
    type: string;
    get: Function;
    save: Function;
}>();

const categories = ref<(NewCategory | Category)[]>([]);

const addCategory = () => {
    categories.value.unshift({ slug: "", name: "" });
};
const removeCategory = (index) => {
    categories.value.splice(index, 1);
};
const saveCategories = async () => {
    categories.value = await props.save(categories.value);
    toast({
        heading: "Saved",
        message: "Categories saved successfully",
        status: "success",
    });
};

onMounted(async () => {
    categories.value = await props.get();
});
</script>
<template>
    <div class="container mx-auto p-4 space-y-2">
        <div class="flex flex-row justify-between">
            <h1 class="text-xl font-bold mb-4">{{ props.type }}</h1>

            <Button
                @click="saveCategories"
                class="bg-blue-500 text-white p-2 rounded"
            >
                Save
            </Button>
        </div>
        <Button @click="addCategory" intent="secondary"> Add New </Button>
        <div class="flex flex-col space-y-2">
            <div
                class="flex flex-row"
                v-for="(category, index) in categories"
                :key="index"
            >
                <input
                    type="text"
                    v-model="category.name"
                    class="border-2 border-gray-300 p-2 mr-2"
                    placeholder="Name"
                    @change="
                        category.slug = category.name
                            .toLowerCase()
                            .replace(/\s+/g, '-')
                    "
                />
                <input
                    type="text"
                    v-model="category.slug"
                    :disabled="category.id"
                    :class="[
                        'border-2 border-gray-300 p-2 mr-2',
                        category.id ? '!bg-gray-200' : 'bg-gray-900',
                    ]"
                    placeholder="Slug"
                />
                <Button
                    v-if="!category.id"
                    intent="danger"
                    size="sm"
                    @click="removeCategory(index)"
                    class="bg-red-500 text-white p-2 rounded"
                >
                    Delete
                </Button>
            </div>
        </div>
    </div>
</template>
