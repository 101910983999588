<template>
    <div class="relative">
        <ChartDonut :chartData="chartData" :options="options" />
        <div
            class="flex items-center justify-center absolute top-0 left-0 w-full h-full"
        >
            <div class="text-3xl text-gray-600 font-extralight">
                {{ grade }}<span class="text-xl font-normal">%</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        correct: {
            type: Number,
            required: true,
        },
        incorrect: {
            type: Number,
            required: true,
        },
        unanswered: {
            type: Number,
            required: true,
        },
    },
    data(): any {
        return {
            options: {
                cutout: "84%",
                elements: {
                    arc: {
                        hoverBorderColor: "#fff",
                    },
                },
                legend: {
                    display: false,
                },
                tooltips: {
                    enabled: false,
                },
            },
            percentage:
                (this.correct /
                    (this.correct + this.incorrect + this.unanswered)) *
                100,
        };
    },
    computed: {
        chartData() {
            return {
                datasets: [
                    {
                        data: [this.correct, this.incorrect, this.unanswered],
                        backgroundColor: ["#bbf7d0", "#fecaca", "#d1d5db"],
                        hoverBackgroundColor: ["#c6f6d5", "#fed7d7", "#fffff0"],
                    },
                ],
            };
        },
        grade() {
            if (this.percentage === 0) {
                return "0%";
            }
            return this.percentage.toFixed(1);
            return this.$filters.grade(parseFloat(this.percentage));
        },
    },
};
</script>
