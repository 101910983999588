<template>
    <div
        class="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-80"
        v-show="active"
    >
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div class="fixed inset-0 transition-opacity" v-if="active">
                <div class="absolute inset-0 bg-gray-900 opacity-75"></div>
            </div>
        </transition>
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="transition ease-in duration-200"
            leave-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
            <div
                class="bg-gray-100 rounded overflow-hidden shadow-md transform transition-all sm:max-w-6xl sm:w-full max-h-full"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
                v-if="active"
                v-click-away="close"
            >
                <div class="w-full relative">
                    <div class="top-panel">
                        <div class="flex-none" v-if="allowUpload">
                            <button
                                type="button"
                                class="flex items-center bg-blue-600 text-blue-50 leading-5 rounded shadow py-2 px-4 mr-4"
                                title="Upload Image"
                                @click="pane = 'upload'"
                            >
                                <SvgIcon
                                    class="mr-2"
                                    icon="upload"
                                    size="sm"
                                    weight="bold"
                                />
                                <span>Upload</span>
                            </button>
                        </div>
                        <div class="flex-grow flex">
                            <input
                                class="p-2 w-full rounded"
                                v-model="query"
                                placeholder="Search"
                                @keydown.enter.prevent
                            />
                        </div>
                    </div>
                    <div
                        class="grid gap-4 thumbnail-container p-4 transition-all duration-200"
                        :class="{
                            'grid-cols-1 w-full': loading && !pane,
                            'grid-cols-5 w-full': !loading && !pane,
                            'grid-cols-1 w-3/5': loading && pane,
                            'grid-cols-3 w-3/5': !loading && pane,
                        }"
                    >
                        <div
                            v-if="!loading"
                            v-for="photo in images"
                            :key="photo.id"
                            @click="select(photo)"
                        >
                            <div class="bg-white shadow cursor-pointer">
                                <div
                                    class="w-full flex items-center justify-center thumbnail"
                                >
                                    <img
                                        v-bind:data-src="photo.path"
                                        :title="photo.name"
                                        class="mg-photo"
                                    />
                                </div>
                                <div
                                    class="w-full flex bg-white justify-between text-gray-600 text-xs p-2"
                                >
                                    <span class="truncate">{{
                                        photo.name
                                    }}</span>
                                </div>
                            </div>
                        </div>
                        <div
                            class="flex items-center justify-center w-full h-full"
                            v-if="loading"
                            style="max-height: 75vh"
                        >
                            <div class="dark loader"></div>
                        </div>
                    </div>
                    <div
                        class="absolute bg-gray-900 right-0 bottom-0 w-2/5 p-4 transform duration-200 origin-right overflow-y-auto"
                        style="top: 4.5rem"
                        :class="{
                            'scale-x-100': pane === 'photo',
                            'scale-x-0': pane !== 'photo',
                        }"
                    >
                        <div class="flex items-center justify-between">
                            <button
                                type="button"
                                class="text-gray-50 ml-2"
                                @click="closePane"
                            >
                                <SvgIcon icon="close" weight="medium" />
                            </button>
                            <div class="flex items-center">
                                <a
                                    v-if="allowEdit"
                                    class="bg-blue-50 text-blue-600 leading-4 rounded-full p-2 mr-2"
                                    :href="'/uploads/' + selectedPhoto.uuid"
                                    target="_blank"
                                >
                                    <SvgIcon icon="edit" size="base" />
                                </a>
                                <button
                                    class="bg-blue-600 text-blue-50 leading-4 rounded-full p-2 mr-2"
                                    type="button"
                                    @click="choose"
                                    v-if="allowSelect"
                                >
                                    <SvgIcon icon="check" size="base" />
                                </button>
                            </div>
                        </div>
                        <div
                            class="bg-white flex items-center justify-center rounded my-4 p-2"
                        >
                            <img
                                v-if="photoIsSelected"
                                :src="selectedPhoto.path"
                                :title="selectedPhoto.name"
                                class="mg-photo"
                            />
                        </div>
                        <div>
                            <div class="w-full text-sm bg-white rounded">
                                <table class="w-full table-auto image-info">
                                    <tr v-for="(pk, pv) in imageProperties">
                                        <td
                                            class="p-2 uppercase text-xs font-semibold text-gray-600"
                                        >
                                            {{ pk.toUpperCase() }}
                                        </td>
                                        <td class="p-2 font-mono">
                                            {{ selectedPhoto[pv] }}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div
                        class="absolute bg-gray-900 right-0 bottom-0 w-2/5 p-4 transform duration-200 origin-right overflow-y-auto"
                        style="top: 4.5rem"
                        :class="{
                            'scale-x-100': pane === 'upload',
                            'scale-x-0': pane !== 'upload',
                        }"
                    >
                        <div class="flex items-center justify-between">
                            <button
                                type="button"
                                class="text-gray-50 ml-2"
                                @click="closePane"
                            >
                                <SvgIcon icon="close" weight="medium" />
                            </button>
                        </div>
                        <div class="mt-4">
                            <FilePond
                                ref="filepond"
                                label-idle="<SvgIcon> Choose Files"
                                :onprocessfiles="closePane"
                                :allow-multiple="true"
                                :server="server"
                                :files="files"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import debounce from "debounce";
import { mapState } from "vuex";
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import { defineComponent } from "vue";

export default defineComponent({
    components: {
        FilePond: vueFilePond() as any,
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        allowEdit: {
            type: Boolean,
            default: false,
        },
        allowUpload: {
            type: Boolean,
            default: true,
        },
        saveUrl: {
            type: String,
            default: "/",
        },
        allowSelect: {
            type: Boolean,
            default: true,
        },
        enableLazyLoad: {
            type: Boolean,
            default: true,
        },
        modelValue: {
            type: String,
        },
    },
    data(): any {
        return {
            images: [],
            imageProperties: {
                uuid: "ID",
                name: "Name",
                gallery: "Gallery",
            },
            loading: true,
            message: null,
            query: "",
            pane: null,
            selectedPhoto: {},
            server: {
                process: {
                    url: "/api/prep/images",
                    method: "POST",
                    headers: {},
                    onload: (response) => {
                        (this.images as any).push(JSON.parse(response));
                    },
                },
                revert: {
                    url: "/api/prep/images",
                    method: "DELETE",
                    headers: {},
                    onload: null,
                },
            },
            files: [],
            copyLinkText: "Copy Link",
        };
    },
    computed: {
        ...mapState(["token"]),
        photoIsSelected() {
            return Object.keys(this.selectedPhoto).length > 0;
        },
    },
    methods: {
        getImages() {
            this.loading = true;
            axios
                .get("/api/prep/images?search=" + this.query)
                .then((response) => {
                    this.images = response.data;
                    this.loading = false;
                    this.$nextTick(() => {
                        this.enableLazyLoading();
                    });
                });
        },
        close() {
            this.$emit("close");
        },
        closePane() {
            this.pane = null;
            this.selectedPhoto = {};
        },
        select(photo) {
            this.pane = "photo";
            this.selectedPhoto = photo;
        },
        choose() {
            this.pane = null;
            this.$emit("update:modelValue", this.selectedPhoto.path);
            this.$emit("close");
        },
        enableLazyLoading() {
            let images: any = document.querySelectorAll(".mg-photo");

            const config = {
                root: null,
                rootMargin: "0px 0px 50px 0px",
            };

            if (!("IntersectionObserver" in window)) {
                Array.from(images).forEach(function (image: any) {
                    if (!image.src) image.src = image.dataset.src;
                });
            } else {
                let observer = new IntersectionObserver(function (entries) {
                    entries.forEach((image: any) => {
                        if (image.isIntersecting) {
                            image.target.src = image.target.dataset.src;
                            observer.unobserve(image.target);
                        }
                    });
                }, config);

                images.forEach((image) => {
                    if (!image.src) {
                        observer.observe(image);
                    }
                });
            }
        },
    },
    created() {
        let headers = {
            "X-CSRF-TOKEN": this.token,
        };
        this.server.process.headers = headers;
        this.server.revert.headers = headers;

        this.$nextTick(() => {
            this.enableLazyLoading();
        });
    },
    mounted() {
        this.getImages = debounce(this.getImages, 500);
        this.getImages();
    },
    updated() {
        this.$nextTick(() => {
            this.enableLazyLoading();
        });
    },
    watch: {
        query() {
            this.getImages();
        },
    },
});
</script>

<style scoped>
@media only screen and (min-width: 640px) {
    .thumbnail {
        height: 300px;
        overflow: hidden;
    }
}

@media only screen and (min-width: 768px) {
    .thumbnail {
        height: 250px;
        overflow: hidden;
    }
}

@media only screen and (min-width: 1024px) {
    .thumbnail {
        height: 200px;
        overflow: hidden;
    }
}

@media only screen and (min-width: 1280px) {
    .thumbnail {
        height: 120px;
        overflow: hidden;
    }
}

.top-panel {
    @apply flex flex-nowrap items-center justify-between bg-white shadow p-4 w-full;
}

.square:after {
    @apply block;

    content: "";
    padding-bottom: 100%;
}

.mg-photo {
    max-width: 100%;
}

.thumbnail-container,
.postcard-container {
    @apply overflow-y-auto;

    height: 75vh;
}

.thumbnail-container > div:last-child {
    @apply mb-4;
}

.image-info tr {
    @apply border-b;
}

.image-info tr:last-child {
    @apply border-none;
}
</style>
