<template>
    <dropdown
        :modelValue="types[active]"
        :variant="variant"
        class="mb-6 md:mb-auto"
    >
        <pulse-context-toggle-nav-item
            v-for="(type, index) in types"
            :key="index"
            :active="active === type.label.toLowerCase()"
            :type="type"
            :path="`${type.url_segment}${context}`"
            :variant="variant"
        >
            {{ type.label }}
        </pulse-context-toggle-nav-item>
    </dropdown>
</template>

<script lang="ts">
import { mapGetters } from "vuex";

export default {
    data(): any {
        return {
            show: false,
            variant: "blue",
            types: {
                learn: {
                    icon: "booklight",
                    label: "Learn",
                    url_segment: "/pulse/learn/courses",
                },
                prep: {
                    icon: "summit",
                    label: "Prep",
                    url_segment: "/pulse/prep/exams",
                },
            },
            active: undefined,
        };
    },
    computed: {
        ...mapGetters(["context", "viewContext"]),
    },
    mounted() {
        this.active =
            this.viewContext in this.types ? this.viewContext : "learn";
    },
};
</script>
