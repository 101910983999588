<template>
    <div class="relative z-0 inline-flex h-10 rounded-md">
        <router-link
            v-for="(view, index) in views"
            :key="index"
            :to="generateUrl(view.value)"
            type="button"
            class="relative inline-flex items-center px-4 py-2 text-sm font-medium border border-l-0 border-gray-300 hover:bg-gray-50 focus:z-10 focus:outline-none focus-visible:ring-1 first:rounded-l-md first:border-l last:rounded-r-md"
            :class="[active === view.value ? 'bg-white' : 'bg-gray-50']"
            v-tippy="{ placement: 'top' }"
            ref="tip"
            :content="generateLabel(view.value)"
            :aria-label="generateLabel(view.value)"
        >
            <SvgIcon
                :class="[
                    active === view.value ? 'text-blue-600' : 'text-gray-700',
                ]"
                :icon="view.icon"
                size="md"
                weight="semibold"
            />
        </router-link>
    </div>
</template>

<script lang="ts">
import { mapGetters, mapState } from "vuex";
import _ from "lodash";

export default {
    props: {
        basePath: {
            type: String,
            required: true,
        },
    },
    data(): any {
        return {
            views: {
                structured: {
                    value: "structured",
                    icon: "sort-alt",
                },
                learners: {
                    value: "learner",
                    icon: "learner-alt",
                },
                // timeline: {
                //     value: 'timeline',
                //     icon: 'clock-simple-alt',
                // },
            },
        };
    },
    computed: {
        active() {
            if (this.$route.path.includes("structured")) {
                return "structured";
            }
            if (this.$route.path.includes("learner")) {
                return "learner";
            }
            return "learner";
        },
        ...mapGetters(["watchContext"]),
        queryParams() {
            return this.$route.query;
        },
    },
    watch: {
        watchContext: {
            handler() {
                console.log("FORCE UPDATE!");
                this.$forceUpdate();
            },
            deep: true,
        },
    },
    methods: {
        generateLabel(value) {
            return _.capitalize(value) + " View";
        },
        generateUrl(value) {
            // get query params from query
            let query = this.$route.query;
            return {
                path: this.basePath + "/" + value,
                query: {
                    ...query,
                    page: 1,
                },
            };
        },
    },
};
</script>
