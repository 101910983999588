import { responseMatch } from "./response-match";
import { backend } from "./utils";

export const appApi = {
    async submitFeedback(
        feedback: string,
        location_key: string,
        rating?: number
    ) {
        const response = await backend.post("/api/submit-feedback", {
            feedback,
            rating,
            location_key,
        });
        const { data, error } = await responseMatch<any, any>(response, {
            200: async (response) => {
                return { data: await response.json(), error: null };
            },
            default: async (response) => {
                return { data: null, error: await response.json() };
            },
        });

        return data!;
    },
} as const;
