<script setup lang="ts">
import { useUserStore } from "@/stores/userStore";
import { storeToRefs } from "pinia";
import { computed, watch } from "vue";
import { parse } from "date-fns";
import { User } from "@/types/user";
import Link from "@/components/ui/ButtonLinks/Link.vue";
import { siteUrl } from "@/utils";

function daysUntilDate(carbonDate?: string | null): number {
    if (!carbonDate) return 0;
    // date is a carbon date in format example: 2023-05-24T19:11:07.000000Z
    const targetDate = parse(
        carbonDate,
        "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'",
        new Date()
    );
    const today = new Date();
    const timeDiff = targetDate.getTime() - today.getTime();
    const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return dayDiff - 1;
}
const userStore = useUserStore();
const { userObj, loaded } = storeToRefs(userStore);
const user = computed(() => userObj.value as User);
</script>
<template>
    <template v-if="loaded && user.is_in_trial">
        <div
            class="relative top-16 sm:top-20 sm:ml-24 w-full sm:w-[calc(100%-6rem)] flex justify-center items-center bg-yellow-50 p-3 text-sm"
        >
            <div
                class="max-w-prose text-center flex flex-col xs:flex-row gap-2 items-center"
            >
                <span class="md:flex"
                    ><strong
                        >{{ daysUntilDate(user.trial_expires_at) }} days
                        left&nbsp;
                    </strong>
                    of your Free Trial!</span
                >
                <Link
                    class="!min-w-none"
                    :href="`${siteUrl}/pricing`"
                    intent="cta"
                    size="xs"
                    :round="false"
                    >Upgrade Now</Link
                >
            </div>
        </div>
    </template>
</template>
