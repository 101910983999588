<template>
    <div
        class="flex flex-col space-y-4 text-sm lg:flex-row lg:items-center lg:space-x-6 lg:space-y-0"
    >
        <div class="flex flex-wrap items-center">
            <div
                class="relative justify-between px-0.5 py-0.5 bg-green-150 rounded-md mr-2.5"
            >
                <SvgIcon icon="film" size="md" weight="bold" />
            </div>

            <p class="mr-2 font-bold">Lesson:</p>

            <div class="flex items-center space-x-4 whitespace-nowrap">
                <p>
                    <span>Views:</span>
                    <span class="font-bold align-middle">{{
                        lessonViews
                    }}</span>
                </p>
            </div>
        </div>

        <div class="flex flex-wrap items-center">
            <div
                class="relative items-center justify-between px-0.5 py-0.5 bg-blue-150 rounded-md mr-2.5"
            >
                <SvgIcon icon="cog-alt" size="md" weight="bold" />
            </div>

            <p class="mr-2 font-bold">Quizzes:</p>

            <div class="flex items-center space-x-4 whitespace-nowrap">
                <p>
                    <span>Attempts:</span>
                    <span class="font-bold align-middle">{{
                        quizAttempts
                    }}</span>
                </p>
                <p>
                    <span>Avg Score:</span>
                    <span
                        class="font-bold align-middle"
                        :class="getScoreColor(quizAverageScore)"
                    >
                        {{
                            quizAverageScore
                                ? formatPercent(quizAverageScore)
                                : "&mdash;"
                        }}
                    </span>
                </p>
            </div>
        </div>

        <div class="flex flex-wrap items-center">
            <div
                class="relative items-center justify-between px-0.5 py-0.5 bg-butter-300 rounded-md mr-2.5"
            >
                <SvgIcon icon="pencil-alt" size="md" weight="bold" />
            </div>

            <p class="mr-2 font-bold">Assessments:</p>

            <div class="flex items-center space-x-4 whitespace-nowrap">
                <p>
                    <span>Attempts:</span>
                    <span class="font-bold align-middle">{{
                        assessmentAttempts
                    }}</span>
                </p>
                <p>
                    <span>Avg Score:</span>
                    <span
                        class="font-bold align-middle"
                        :class="getScoreColor(assessmentAverageScore)"
                    >
                        {{
                            assessmentAverageScore
                                ? formatPercent(assessmentAverageScore)
                                : "&mdash;"
                        }}
                    </span>
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        assessmentAttempts: {
            type: Number,
            required: false,
            default: 0,
        },
        assessmentAverageScore: {
            type: Number,
            required: false,
            default: 0,
        },
        quizAttempts: {
            type: Number,
            required: false,
            default: 0,
        },
        quizAverageScore: {
            type: Number,
            required: false,
            default: 0,
        },
        lessonViews: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    methods: {
        formatPercent(value) {
            // decimal to percent with 2 decimal places
            return `${(value * 100).toFixed(2)}%`;
        },
        getScoreColor(score) {
            if (!score) return "";

            return score >= 0.75 ? "text-emerald-600" : "text-red-650";
        },
    },
};
</script>
