<template>
    <div class="flex justify-center mx-auto flex-column">
        <div
            v-tippy="{
                placement: 'top',
            }"
            class="w-8 h-8 rounded-full overflow-clip"
            ref="tip"
            :content="percent + '%'"
        >
            <div
                class="relative w-12 h-12 -mt-2 -ml-2 rounded-full"
                :class="[percent === 100 ? 'full-pie' : 'pie']"
                :style="cssVars"
            ></div>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        percent: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        cssVars() {
            return {
                "--complete-percent": this.percent + "%",
                "--end-grads": this.percent * 4 + "grad",
                "--pie-complete-color":
                    this.percent >= 50 ? "var(--emerald)" : "var(--gray)",
            };
        },
    },
};
</script>

<style scoped>
* {
    --emerald: theme("colors.emerald.450");
    --gray: theme("colors.gray.200");
}
.full-pie {
    background-color: var(--emerald);
}
.pie {
    background: conic-gradient(
        from 90deg,
        var(--emerald) var(--complete-percent),
        var(--gray) 0
    );
}
.pie::after {
    content: "";
    height: 2px;
    @apply absolute w-6 left-6 top-1/2 bg-white z-20;
}
.pie::before {
    content: "";
    height: 2px;
    transform: rotate(var(--end-grads));
    border-left: 1.5rem solid var(--pie-complete-color);
    @apply absolute w-12 top-1/2 bg-white z-10;
}
</style>
