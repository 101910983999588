<template>
    <BillingSection title="Payment Methods">
        <div class="space-y-4">
            <div v-for="paymentMethod in sortedList" class="flex flex-row">
                <div class="card-desc card-details">
                    {{ capitalizeFirstLetter(paymentMethod.card.brand) }} ••••
                    {{ paymentMethod.card.last4 }}
                    <div
                        v-if="isDefault(paymentMethod)"
                        class="inline-flex items-center rounded-full text-2xs font-semibold uppercase leading-6 px-3 space-x-2 bg-gray-50 text-gray-600 ml-2"
                    >
                        Default
                    </div>
                </div>
                <div class="card-expires card-details">
                    Expires at {{ paymentMethod.card.exp_month }}/{{
                        paymentMethod.card.exp_year
                    }}
                </div>
                <div
                    class="card-delete card-details"
                    v-if="isDefault(paymentMethod)"
                >
                    <div
                        class="ml-3"
                        v-tippy
                        content="You cannot delete your default payment method."
                    >
                        <SvgIcon icon="close" size="xs" weight="bold" />
                    </div>
                </div>
                <div class="flex-1" v-if="!isDefault(paymentMethod)">
                    <ActionMenu>
                        <ActionMenuAction
                            type="button"
                            label="Make Default"
                            :argument="paymentMethod"
                            :action="makeDefault"
                        />
                        <ActionMenuAction
                            type="button"
                            label="Delete"
                            :argument="paymentMethod"
                            :action="removePaymentMethod"
                        />
                    </ActionMenu>
                </div>
            </div>
            <div class="flex flex-row justify-start">
                <a
                    href="/account/billing/add-payment-method"
                    class="button small flex flex-row"
                    ><SvgIcon icon="add" size="sm" class="mr-1" />Add Payment
                    Method</a
                >
            </div>
        </div>
    </BillingSection>
</template>

<script lang="ts">
import { mapState } from "vuex";
import BillingSection from "./BillingSection.vue";
import axios from "axios";
import { getPaymentMethods } from "../../../api/billing";

export default {
    components: { BillingSection },
    props: {
        customer: {
            type: Object,
            required: true,
        },
    },
    data(): any {
        return {
            paymentMethods: [],
            defaultPaymentId:
                this.customer.invoice_settings.default_payment_method,
        };
    },
    computed: {
        ...mapState(["user"]),
        sortedList() {
            return this.paymentMethods.sort((a, b) => {
                if (a.id === this.defaultPaymentId) {
                    return -1;
                }
                if (b.id === this.defaultPaymentId) {
                    return 1;
                }
                return 0;
            });
        },
    },
    methods: {
        isDefault(paymentMethod) {
            return this.defaultPaymentId === paymentMethod.id;
        },
        capitalizeFirstLetter(type) {
            return type.charAt(0).toUpperCase() + type.slice(1);
        },
        getPaymentMethods() {
            getPaymentMethods(this.user.uuid).then((methods) => {
                this.paymentMethods = methods;
            });
        },
        makeDefault(paymentMethod) {
            const previousDefaultId = this.defaultPaymentId;
            this.defaultPaymentId = paymentMethod.id;
            axios
                .post(
                    `/api/users/${this.user.uuid}/payment-method/${paymentMethod.id}/make-default`
                )
                .then((response) => {
                    this.getPaymentMethods();
                })
                .catch(() => {
                    this.defaultPaymentId = previousDefaultId;
                });
        },
        removePaymentMethod(paymentMethod) {
            axios
                .delete(
                    `/api/users/${this.user.uuid}/payment-method/${paymentMethod.id}`
                )
                .then((response) => {
                    this.getPaymentMethods();
                });
        },
    },
    mounted() {
        this.getPaymentMethods();
    },
};
</script>
<style scoped>
.card-desc {
    flex-basis: 280px;
}

.card-details {
    display: flex;
    align-items: center;
}

.card-expires {
    flex-basis: 160px;
}
</style>
