import { format, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import classnames, { ArgumentArray } from "classnames";
import { twMerge } from "tailwind-merge";
import { nextTick } from "vue";

export function notEmpty(value) {
    return value !== undefined && value !== null && value !== "";
}

export function convertSecondsToMinutes(seconds) {
    return new Date(seconds * 1000).toISOString().slice(14, 19);
}

export function decimalToPercentLabel(decimal) {
    return `${(decimal * 100).toFixed(2)}%`;
}

export function createUrlParams(params) {
    let urlParams = new URLSearchParams();
    for (const [key, value] of Object.entries(params)) {
        if (notEmpty(value)) {
            urlParams.append(key, value as any);
        }
    }
    return urlParams;
}

export function copyToClipboard(text) {
    // check to see if the browser supports the copy command
    if (navigator?.clipboard?.writeText) {
        return navigator.clipboard.writeText(text);
    }
    // fallback to the old way of copying
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise<void>((resolve, reject) => {
        document.execCommand("copy") ? resolve() : reject();
        textArea.remove();
    });
}

export function currencyFormat(amount?: number | null) {
    if (amount !== null || typeof amount !== "undefined") {
        return ((amount ?? 0) / 100).toFixed(2);
    }
    return 0;
}

export function formatPrice(price, symbol = "") {
    if (price < 0) {
        return `-${symbol}${(Math.abs(price) / 100).toFixed(2)}`;
    } else {
        return `${symbol}${(price / 100).toFixed(2)}`;
    }
}
export function formatDate(date, timezone) {
    let addChar = date.charAt(date.length - 1) === "Z" ? "" : "Z";
    return format(
        utcToZonedTime(parseISO(date + addChar), timezone),
        "MMMM d, yyyy"
    );
}

export function capitalizeFirstLetter(type) {
    return type.charAt(0).toUpperCase() + type.slice(1);
}

export async function wait(ms: number) {
    await new Promise((resolve) => setTimeout(resolve, ms));
    await nextTick();
}

export function slugify(str) {
    return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "");
}

export const siteUrl = (import.meta as any).env.VITE_SITE_URL;

export const pricingUrl = siteUrl + "/pricing";

export function formatCeCredits(credits: number) {
    // if is a whole number, return remove decimal
    if (credits % 1 === 0) {
        return Math.floor(credits);
    }
    return credits.toString();
}

export function classes(...args: ArgumentArray) {
    return twMerge(classnames(...args));
}

export function carbonToDate(date?: string | null) {
    if (!date) {
        return null;
    }
    //2023-08-03T17:42:15.000000Z
    return parseISO(date);
}

/**
 *
 * @param start
 * @param end
 * @returns the difference in seconds between two dates
 */
export function timeDiff(start?: Date | null, end?: Date | null): number {
    if (!start || !end) {
        return 0;
    }
    const diff = end.getTime() - start.getTime();
    return Math.round(diff / 1000);
}
