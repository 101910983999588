function createUrlParams(params: Record<string, string>) {
    return Object.keys(params)
        .filter((key) => params[key] !== null)
        .map((key) => {
            return (
                encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
            );
        })
        .join("&");
}
export const getCsrfToken = () => {
    return (
        document
            ?.querySelector('meta[name="csrf-token"]')
            ?.getAttribute("content") ?? ""
    );
};

type RequestOptions = {
    responseType: "json" | "text";
};

export const backend = {
    async post<T, U>(
        url: string,
        data: U,
        queryParams = {},
        options: RequestOptions = { responseType: "json" }
    ) {
        const urlParams = createUrlParams(queryParams);
        const fullUrl = urlParams ? `${url}?${urlParams}` : url;

        const response = await fetch(fullUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-TOKEN": getCsrfToken(),
            },
            body: JSON.stringify(data),
        });
        return response;
    },

    async patch<T, U>(
        url: string,
        data: U,
        queryParams = {},
        options: RequestOptions = { responseType: "json" }
    ) {
        const urlParams = createUrlParams(queryParams);
        const fullUrl = urlParams ? `${url}?${urlParams}` : url;

        const response = await fetch(fullUrl, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-TOKEN": getCsrfToken(),
            },
            body: JSON.stringify(data),
        });
        if (options.responseType === "json") {
            return (await response.json()) as T;
        } else {
            return (await response.text()) as string;
        }
    },

    async get(url: string, queryParams = {}, abort?: AbortSignal) {
        const urlParams = createUrlParams(queryParams);
        const fullUrl = urlParams ? `${url}?${urlParams}` : url;

        const response = await fetch(fullUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-TOKEN": getCsrfToken(),
            },
            signal: abort,
        });

        return response;
    },
    async put<T, U>(url: string, data: U, queryParams = {}) {
        const urlParams = createUrlParams(queryParams);
        const fullUrl = urlParams ? `${url}?${urlParams}` : url;

        const response = await fetch(fullUrl, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-TOKEN": getCsrfToken(),
            },
            body: JSON.stringify(data),
        });

        return response;
    },

    async delete<T>(url: string, queryParams = {}) {
        const urlParams = createUrlParams(queryParams);
        const fullUrl = urlParams ? `${url}?${urlParams}` : url;

        const response = await fetch(fullUrl, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "X-CSRF-TOKEN": getCsrfToken(),
            },
        });

        return (await response.json()) as T;
    },
};

type StatusMatchFunction<T> = (response: Response) => Promise<T>;

interface ResponseStatusMatch<T> {
    [key: number]: StatusMatchFunction<T>;
    "200s"?: StatusMatchFunction<T>;
    "300s"?: StatusMatchFunction<T>;
    "400s"?: StatusMatchFunction<T>;
    "500s"?: StatusMatchFunction<T>;
    default: StatusMatchFunction<T>;
}

export function responseMatch<T>(
    response: Response,
    match: ResponseStatusMatch<T>
): Promise<T> {
    const responseCode = response.status;
    // first check if there is an exact match

    if (match[responseCode]) {
        return match[responseCode](response);
    }

    // now check ranges

    if (responseCode >= 200 && responseCode < 300 && match["200s"]) {
        return match["200s"](response);
    }

    if (responseCode >= 300 && responseCode < 400 && match["300s"]) {
        return match["300s"](response);
    }

    if (responseCode >= 400 && responseCode < 500 && match["400s"]) {
        return match["400s"](response);
    }

    if (responseCode >= 500 && responseCode < 600 && match["500s"]) {
        return match["500s"](response);
    }

    // if no match, return default
    return match.default(response);
}

type SuccessResult<T> = {
    success: true;
    data: T;
};

type FailedResult = {
    success: false;
    errors: any;
};

type Result<T> = SuccessResult<T> | FailedResult;

export async function succeed<T>(response: Response): Promise<Result<T>> {
    return {
        success: true,
        data: (await response.json()) satisfies T,
    } satisfies Result<T>;
}

export async function succeedText(response: Response): Promise<Result<string>> {
    return { success: true, data: await response.text() };
}

export async function fail<T>(response: Response): Promise<Result<T>> {
    return {
        success: false,
        errors: (await response.json()) satisfies T,
    } satisfies Result<T>;
}

type ResultHandler<T> = (response: Response) => Promise<Result<T>>;

export function failWithError(error: string): ResultHandler<any> {
    return async (response: Response) => {
        return { success: false, errors: error };
    };
}
