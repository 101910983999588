<template>
    <table class="min-w-full border-t divide-y divide-gray-200">
        <thead class="bg-gray-50">
            <tr>
                <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 uppercase tracking-tight w-auto min-w-[260px]"
                >
                    Learner Name
                </th>
                <th
                    v-for="(col, index) in columns"
                    :key="index"
                    scope="col"
                    class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 uppercase tracking-tight whitespace-nowrap w-1/3 xl:w-50"
                >
                    {{ col.label }}
                </th>
            </tr>
        </thead>

        <tbody class="bg-white divide-y divide-gray-200">
            <tr v-for="(row, index) in data" :key="index" height="50px">
                <td
                    class="px-4 py-1 text-sm border-r last:border-r-0 whitespace-nowrap"
                >
                    <PulseActivityTableLearner
                        :image="row.image_url"
                        :forename="row.first_name"
                        :surname="row.last_name"
                        normal
                    />
                </td>
                <template v-for="col in columns">
                    <td
                        class="px-3 py-2 text-sm text-center text-gray-700 border-r last:border-r-0 whitespace-nowrap"
                    >
                        <slot :name="col.key" :row="row" />
                    </td>
                </template>

                <!--                <td v-if="showColumn('status')" class="px-3 py-2 text-sm text-center text-gray-700 border-r last:border-r-0 whitespace-nowrap">-->
                <!--                    <div v-if="row.completed" class="w-5 h-5 mx-auto leading-tight rounded-full bg-emerald-450">-->
                <!--                        <SvgIcon-->
                <!--                            color="white"-->
                <!--                            icon="check-line"-->
                <!--                            weight="bold"-->
                <!--                            size="sm"-->
                <!--                        />-->
                <!--                    </div>-->
                <!--                </td>-->

                <!--                <td v-if="showColumn('views')" class="px-3 py-2 text-sm text-center text-gray-700 border-r last:border-r-0 whitespace-nowrap">-->
                <!--                    {{ row.views }}-->
                <!--                </td>-->

                <!--                <td v-if="showColumn('attempts')" class="px-3 py-2 text-sm text-center text-gray-700 border-r last:border-r-0 whitespace-nowrap">-->
                <!--                    {{ row.attempts }}-->
                <!--                </td>-->

                <!--                <td v-if="showColumn('max_score')" class="px-3 py-2 text-sm text-center text-gray-700 border-r last:border-r-0 whitespace-nowrap">-->
                <!--                    <ScoreData :score="row.max_score" />-->
                <!--                </td>-->

                <!--                <td v-if="showColumn('avg_score')" class="px-3 py-2 text-sm text-center text-gray-700 whitespace-nowrap">-->
                <!--                    <ScoreData :score="row.avg_score" />-->
                <!--                </td>-->

                <!--                <td v-if="showColumn('avg_score_ratio')" class="px-3 py-2 text-sm text-center text-gray-700 whitespace-nowrap">-->
                <!--                    {{ row.avg_score }}<span class="font-semibold">/{{ row.attempts }}</span>-->
                <!--                </td>-->

                <!--                <td v-if="showColumn('total_time')" class="px-3 py-2 text-sm text-center text-gray-700 whitespace-nowrap">-->
                <!--                    <span class="font-semibold">{{ convertSecondsToMinutes(row.total_time) }}</span>-->
                <!--                </td>-->
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts">
import { PropType } from "vue";
export default {
    props: {
        columns: Array as PropType<any[]>,
        data: Array as PropType<any[]>,
    },
};
</script>
