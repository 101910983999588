<template>
    <div v-if="show">
        <div
            class="flex items-center justify-center"
            :class="'placement-' + placement"
            v-if="look == 'default'"
        >
            <div class="loader" :class="color"></div>
        </div>
        <div
            class="flex items-center justify-center h-full"
            v-if="look == 'icon'"
        >
            <SvgIcon
                class="text-gray-500 animate-spin"
                icon="loader"
                size="xl"
                weight="light"
            />
        </div>
        <div
            class="flex items-center justify-center h-24"
            v-if="look == 'ping'"
        >
            <SvgIcon
                class="text-gray-500 animate-ping"
                icon="circles"
                size="xl"
                weight="light"
            />
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        color: {
            type: String,
            default: "dark",
        },
        show: {
            type: Boolean,
            default: true,
        },
        placement: {
            type: String,
            default: "page",
        },
        look: {
            type: String,
            default: "default",
        },
    },
};
</script>

<style scoped>
.loader,
.loader::after {
    @apply rounded-full;
}

.loader {
    animation: load 800ms infinite linear;
    border-style: solid;
    font-size: 16px;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
}

.loader.light {
    border-color: rgba(255, 255, 255, 0.15);
    border-left-color: rgba(255, 255, 255, 0.85);
}

.loader.dark {
    border-color: rgba(45, 55, 72, 0.15);
    border-left-color: rgba(45, 55, 72, 0.85);
}

.placement-page {
    @apply py-8;
}

.placement-page .loader,
.placement-page .loader::after {
    @apply w-12 h-12;
}

.placement-page .loader {
    border-width: 0.25rem;
}

.placement-button .loader,
.placement-button .loader::after {
    @apply w-4 h-4;
}

.placement-button .loader {
    border-width: 0.1rem;
}

@keyframes load {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
