<template>
    <transition
        enter-active-class="transition transform ease-out duration-200"
        enter-class="opacity-0 -translate-y-1"
        enter-to-class="opacity-100 -translate-y-0"
        leave-active-class="transition transform ease-in duration-200"
        leave-class="opacity-100 -translate-y-0"
        leave-to-class="opacity-0 -translate-y-1"
    >
        <div :class="classes" v-show="show">
            <slot />
        </div>
    </transition>
</template>

<script lang="ts">
export default {
    props: {
        classes: {
            type: String,
            default: "absolute top-0 left-0 mt-14 z-10",
        },
        show: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
