<template>
    <div class="md:flex-row md:items-center md:space-x-4 flex flex-col text-sm">
        <strong class="mb-2 md:mb-0">Sort By:</strong>
        <div
            class="sm:flex-row sm:items-center sm:space-y-0 md:space-x-6 sm:space-x-3 flex flex-col items-start space-y-2"
        >
            <button
                v-for="option in options"
                :key="option"
                class="hover:underline disabled:hover:no-underline capitalize"
                :class="{ 'text-blue-600': !(selected === option) }"
                :disabled="selected === option"
                @click="handleSortOptionClick(option)"
            >
                {{ option }}
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType } from "vue";

export default {
    props: {
        options: Array as PropType<any[]>,
        selected: String,
    },
    methods: {
        handleSortOptionClick(option) {
            console.log(option);
            this.$emit("sort", option);
        },
    },
};
</script>
