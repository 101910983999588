<template>
    <div :class="classes" role="cell" :style="styles">
        <slot :as-date="formatDate" :as-link="formatLink" />
    </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import { filters } from "../../../../vue-filters";

export default {
    props: {
        centered: {
            type: [Boolean, String],
            required: false,
            default: false,
        },
        padded: {
            type: Boolean,
            required: false,
            default: true,
        },
        rowSpan: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    computed: {
        ...mapGetters(["appPath", "context"]),
        classes() {
            let defaults =
                "text-sm border-l border-b border-gray-200 first:border-l-0";
            let centered = " inline-flex items-center";

            if (this.padded) {
                defaults += " p-2 lg:px-3 lg:py-3";
            }

            if (this.centered) {
                switch (typeof this.centered) {
                    case "boolean":
                        defaults += centered + " justify-center";
                        break;
                    case "string":
                        this.centered.toLowerCase() === "y"
                            ? (defaults += centered)
                            : (defaults += " text-center");
                        break;
                }
            }

            return defaults;
        },
        styles() {
            return this.rowSpan
                ? "grid-row: span " +
                      this.rowSpan +
                      " / span " +
                      this.rowSpan +
                      ";"
                : "";
        },
    },
    methods: {
        formatDate(string) {
            const date = new Date(string).toISOString();
            return filters.shortDateTime(date, "");
        },
        formatLink(text, appLevel, params) {
            const href =
                this.appPath + "/" + appLevel + this.context + "&" + params;

            return `
                <a href="${href}" class="font-medium text-blue-600 hover:text-blue-700">
                    ${text}
                </a>
            `;
        },
    },
};
</script>
