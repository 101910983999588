<template>
    <PulseActivityTable
        :columns="columns"
        :filters="viewFilters"
        :loading="loading"
        :rows="data"
        :rowAs="rowForView"
        :has-views="true"
        :pagination="pagination"
    />
</template>

<script lang="ts">
import axios from "axios";
import { mapState } from "vuex";

export default {
    data(): any {
        return {
            data: [],
            loading: true,
            pagination: {},
            viewColumns: [
                {
                    header: "Learner",
                    sort: true,
                    views: ["learners", "timeline"],
                },
                {
                    header: "Name",
                    views: ["structured"],
                    width: 20,
                },
                {
                    header: "Type",
                    views: ["learners", "structured", "timeline"],
                },
                {
                    header: "Lesson",
                    views: ["learners", "timeline"],
                    width: 20,
                },
                {
                    header: "Time",
                    views: ["timeline"],
                },
                {
                    header: "Viewed",
                    views: ["learners"],
                    width: 5,
                },
                {
                    header: "Score",
                    views: ["learners", "timeline"],
                    width: 5,
                },
                {
                    header: "Avg Score",
                    views: ["learners"],
                    width: 5,
                },
                {
                    header: "Date",
                    sort: true,
                    views: ["learners"],
                },
            ],
            viewFilters: [
                {
                    name: "type",
                    views: ["learners", "timeline", "structured"],
                },
                {
                    name: "lesson",
                    views: ["learners", "timeline", "structured"],
                },
            ],
        };
    },
    computed: {
        ...mapState(["view"]),
        columns() {
            return this.viewColumns.filter((column) =>
                column.views.includes(this.view)
            );
        },
        rowForView() {
            switch (this.view) {
                case "structured":
                    return "pulse-learn-module-structured-row";
                case "timeline":
                    return "";
                case "learners":
                default:
                    return "pulse-learn-module-learners-row";
            }
        },
    },
    methods: {
        getData() {
            axios.get(`/_temp/module_${this.view}.json`).then((response) => {
                this.data = response.data;
                this.loading = false;
            });
        },
    },
    watch: {
        view(value, prev) {
            if (value === prev) {
                return;
            }

            this.loading = true;
            this.getData();
        },
    },
    mounted() {
        this.getData();
    },
};
</script>
