<template>
    <div v-if="displayTemplate" class="bg-white rounded-md shadow my-4">
        <div>
            <div class="flex items-center justify-between border-b px-6 py-3">
                <div class="flex items-center justify-between text-lg">
                    <div
                        v-if="note_id"
                        class="text-gray-600 text-xs italic"
                        v-html="
                            $filters.dateTime(note_updated_at, user.timezone)
                        "
                    />
                    <div v-else class="font-medium">New note</div>
                </div>
                <div v-if="note_id" class="flex items-center space-x-1">
                    <button
                        v-if="!displayEditor"
                        type="button"
                        class="flex items-center justify-center hover:bg-cyan-50 text-gray-700 rounded-lg w-9 h-9"
                        v-tippy
                        content="Edit"
                        @click="toggleNoteVisibility"
                    >
                        <SvgIcon icon="edit" size="sm" />
                    </button>
                    <button
                        v-if="displayEditor"
                        type="button"
                        class="flex items-center justify-center hover:bg-cyan-50 text-gray-700 rounded-lg w-9 h-9"
                        @click="toggleNoteVisibility"
                    >
                        <SvgIcon
                            icon="chevron-up"
                            size="xs"
                            weight="extra-bold"
                        />
                    </button>
                    <button
                        type="button"
                        class="flex items-center justify-center hover:bg-cyan-50 text-gray-700 rounded-lg w-9 h-9"
                        v-tippy
                        content="Print"
                        @click="printNote"
                    >
                        <SvgIcon icon="print" size="sm" />
                    </button>
                    <button
                        type="button"
                        class="flex items-center justify-center hover:bg-red-50 text-red-500 rounded-lg w-9 h-9"
                        v-tippy
                        content="Delete"
                        @click="showDelete"
                    >
                        <SvgIcon icon="remove" size="sm" />
                    </button>
                    <ActionMenu v-if="false">
                        <ActionMenuAction
                            v-if="!displayEditor"
                            type="button"
                            label="Edit"
                            icon="edit"
                            iconColor="text-blue-200"
                            :action="toggleNoteVisibility"
                        />
                        <ActionMenuAction
                            v-if="displayEditor"
                            type="button"
                            label="Collapse Note"
                            icon="chevron-up"
                            iconColor="text-blue-200"
                            :action="toggleNoteVisibility"
                        />
                        <ActionMenuAction
                            type="button"
                            label="Print"
                            icon="print"
                            iconColor="text-red-200"
                            :action="printNote"
                        />
                        <ActionMenuAction
                            type="button"
                            label="Delete"
                            icon="remove"
                            iconColor="text-red-200"
                            :action="showDelete"
                        />
                    </ActionMenu>
                    <ConfirmDeleteModal
                        :active="deleteModalVisible"
                        @close="deleteModalVisible = false"
                        @confirm="confirmDelete"
                    />
                </div>
            </div>
            <input type="hidden" name="notes" :value="content" />
            <div v-if="displayEditor">
                <ContentEditor
                    theme="tiptap-inline"
                    v-model="content"
                    :key="noteId"
                    placeholder="Type your notes here..."
                    ref="primaryNoteComponent"
                />
                <div
                    class="bg-gray-50 border-t border-gray-200 rounded-b-md p-6 flex justify-between items-center"
                >
                    <button
                        class="small main button disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-500 disabled:hover:bg-gray-200 disabled:hover:border-gray-200 disabled:shadow-none"
                        @click="save"
                        :disabled="!isDirty"
                    >
                        Save
                    </button>
                </div>
            </div>
            <div class="editor-content tiptap-static">
                <div
                    v-if="!displayEditor"
                    v-html="formattedNote"
                    class="ProseMirror p-6"
                ></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState } from "vuex";

export default {
    name: "LessonNote",
    emits: ["saveNewNote"],
    props: {
        note: {
            required: false,
            default: null,
        },
        noteId: {
            required: false,
            default: null,
            type: Number,
        },
        lessonInstanceId: Number,
        show: {
            required: false,
            default: true,
            type: Boolean,
        },
        updatedAt: String,
    },
    data(): any {
        return {
            savedContent: this.note,
            content: this.note,
            displayEditor: this.show,
            note_updated_at: this.updatedAt,
            note_id: this.noteId,
            instance_id: this.lessonInstanceId,
            displayTemplate: true,
            deleteModalVisible: false,
        };
    },
    computed: {
        ...mapState(["user"]),
        isDirty() {
            return this.savedContent !== this.content;
        },
        formattedNote() {
            if (!this.savedContent) {
                return null;
            }
            let note = this.savedContent.replace(
                "<ul",
                '<ul class="list-disc"'
            );
            note = note.replace("<ol", '<ol class="list-decimal"');
            return note;
        },
    },
    methods: {
        save() {
            if (!this.note_id) {
                axios
                    .post(
                        `/api/learn/lessons/${this.instance_id}/note/create`,
                        {
                            note: this.content,
                        }
                    )
                    .then((response) => {
                        this.$emit("saveNewNote", {
                            note: this.content,
                            updated_at: response.data.updated_at,
                            id: response.data.id,
                        });
                        this.content = null;
                        this.savedContent = null;
                        this.$refs.primaryNoteComponent.resetValue();
                    });
            } else {
                axios
                    .post(`/api/learn/lessons/${this.note_id}/note`, {
                        note: this.content,
                        note_id: this.note_id,
                    })
                    .then((response) => {
                        this.savedContent = this.content;
                        this.note_updated_at = response.data.updated_at;
                    });
            }
        },
        confirmDelete() {
            axios
                .delete(`/api/learn/lessons/${this.note_id}/note/delete`, {})
                .then((response) => {
                    this.savedContent = null;
                    this.content = null;
                    this.note_updated_at = null;
                    this.note_id = null;
                    this.displayEditor = false;
                    this.displayTemplate = false;
                });
        },
        printNote() {
            var printwindow: any = window.open("");
            printwindow.document.write(this.formattedNote);
            printwindow.print();
        },
        showDelete() {
            this.deleteModalVisible = true;
        },
        toggleNoteVisibility() {
            this.displayEditor = !this.displayEditor;
        },
    },
};
</script>
