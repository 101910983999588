<template>
    <div>
        <div class="card flex items-center p-4">
            <div class="mr-4">
                <label>Account</label>
                <select-dropdown v-model="selectedAccount">
                    <option v-if="accounts.length > 1" value="">
                        Choose an account
                    </option>
                    <option
                        v-for="account in accounts"
                        :disabled="accounts.length == 1"
                        :value="account.uuid"
                    >
                        {{ account.name }}
                    </option>
                </select-dropdown>
            </div>
            <div>
                <label>Cohort</label>
                <select-dropdown v-model="selectedCohort">
                    <option value="">Choose a cohort</option>
                    <option v-for="cohort in cohorts" :value="cohort.uuid">
                        {{ cohort.name }}
                    </option>
                </select-dropdown>
            </div>
        </div>
        <div class="card" v-if="registrationCode">
            <div class="border-b p-4">
                <div class="flex items-center">
                    <div class="mr-4">Registration code:</div>
                    <input
                        tabindex="-1"
                        type="text"
                        id="registrationCode"
                        :value="registrationCode.code"
                        style="position: absolute; left: -9999rem"
                    />
                    <div
                        class="bg-gray-800 text-gray-100 font-mono rounded py-1 px-2"
                        style="cursor: pointer"
                        @click="copy"
                    >
                        {{ registrationCode.code }}
                    </div>
                    <div
                        class="bg-pear-100 text-sm text-pear-600 rounded ml-4 py-1 px-2"
                        :class="{ 'opacity-0': !copyMessage }"
                        style="transition: all 300ms"
                    >
                        Copied!
                    </div>
                </div>
            </div>
            <div class="flex items-start p-4">
                <div class="flex justify-center w-1/2">
                    <div
                        class="page border rounded shadow p-8"
                        style="max-width: 768px"
                    >
                        <img
                            class="w-64 mb-4 mx-auto"
                            src="https://s3.amazonaws.com/files.cloverlearning.com/site/radtechbootcamp.png"
                            alt="RadTechBootCamp"
                        />
                        <h2 class="text-2xl leading-tight mb-2">
                            Congratulations!
                        </h2>
                        <h3 class="text-xl leading-tight mb-6">
                            You may now access RadTechBootCamp&trade; through
                            your school&rsquo;s Academic License account.
                        </h3>
                        <h5 class="mb-4">
                            Institute of Technology | Cohort Access: January
                            2020 - September 2020
                        </h5>
                        <div class="bg-gray-200 rounded mb-6 p-4 text-center">
                            <h3 class="font-semibold uppercase mt-2 mb-2">
                                Your registration code
                            </h3>
                            <h3 class="italic mb-2">
                                You will need this code to create your student
                                account
                            </h3>
                            <code class="block text-2xl pt-2 px-2 mt-2">{{
                                registrationCode.code
                            }}</code>
                        </div>
                        <div class="bg-blue-100 text-blue-800 rounded p-2 mb-4">
                            If you have purchased RadTechBootCamp&trade; in the
                            past please use the &ldquo;Past User&rdquo; steps
                            below
                        </div>
                        <h4 class="font-semibold mb-4">
                            Steps to redeem code &ndash; New User:
                        </h4>
                        <ol class="list-decimal list-inside ml-4 mb-6">
                            <li>
                                Visit
                                <a
                                    class="text-blue-600 underline"
                                    href="https://www.cloverleaflearning.com/redeem"
                                    target="_blank"
                                    >www.cloverleaflearning.com/redeem</a
                                >.
                            </li>
                            <li>
                                Fill out your personal information, making sure
                                to use your <em>student</em> email address.
                            </li>
                            <li>
                                Enter the above registration code to validate
                                and redeem.
                            </li>
                        </ol>
                        <h4 class="font-semibold mb-4">
                            Steps to redeem code &ndash; Past User:
                        </h4>
                        <ol class="list-decimal list-inside ml-4 mb-6">
                            <li>
                                Visit
                                <a
                                    class="text-blue-600 underline"
                                    href="https://www.cloverleaflearning.com"
                                    target="_blank"
                                    >www.cloverleaflearning.com</a
                                >
                                and sign in to your account.
                            </li>
                            <li>
                                From your user dashboard, click the Clover
                                Learning icon (top left of screen).
                            </li>
                            <li>
                                Click &ldquo;Redeem Code&rdquo; on the main
                                menu.
                            </li>
                            <li>
                                Enter the above registration code to validate
                                and redeem.
                            </li>
                            <li>
                                Remember to update the email address on your
                                user account to your student email address.
                            </li>
                        </ol>
                        <h4 class="font-semibold mb-4">
                            Signing in to your account:
                        </h4>
                        <ol class="list-decimal list-inside ml-4 mb-6">
                            <li>
                                Visit
                                <a
                                    class="text-blue-600 underline"
                                    href="https://www.cloverleaflearning.com"
                                    target="_blank"
                                    >www.cloverleaflearning.com</a
                                >.
                            </li>
                            <li>
                                Select &ldquo;Sign In&rdquo; from main menu.
                            </li>
                        </ol>
                        <div class="flex justify-center mt-4">
                            <a
                                href="https://www.cloverleaflearning.com"
                                target="_blank"
                            >
                                <img
                                    class="w-32"
                                    src="https://s3.amazonaws.com/files.cloverlearning.com/site/clover-logo.png"
                                    alt="Clover Learning"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <div class="p-4 w-1/2">
                    <h2 class="text-2xl font-semibold mb-4">
                        Send introduction email
                    </h2>
                    <p></p>
                    <div class="field">
                        <label for="recipients">Recipients</label>
                        <input
                            id="recipients"
                            type="text"
                            v-model="recipients"
                            placeholder="List of comma-separated email addresses"
                        />
                    </div>
                    <div class="field">
                        <button
                            class="primary button"
                            @click="sendRegistrationCode"
                        >
                            Send email
                        </button>
                        <button
                            class="text-blue-700 underline ml-3"
                            @click="sendTest"
                        >
                            Send test to myself
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";

export default {
    data(): any {
        return {
            accounts: [],
            cohorts: [],
            copyMessage: false,
            recipients: "",
            registrationCode: "",
            selectedAccount: "",
            selectedCohort: "",
        };
    },
    methods: {
        copy() {
            (document.querySelector("#registrationCode") as any).select();
            if (document.execCommand("copy")) {
                this.flashCopySuccess();
            }
        },
        flashCopySuccess() {
            let self = this;
            this.copyMessage = true;
            setTimeout(() => {
                self.copyMessage = false;
            }, 3000);
        },
        getAccounts() {
            axios.get("/api/registration-codes/accounts").then((response) => {
                this.accounts = response.data;
            });
        },
        getCohorts() {
            axios
                .get(
                    "/api/registration-codes/accounts/" +
                        this.selectedAccount +
                        "/cohorts"
                )
                .then((response) => {
                    this.cohorts = response.data;
                });
        },
        getRegistrationCode() {
            axios
                .get("/api/registration-codes/cohort/" + this.selectedCohort)
                .then((response) => {
                    this.registrationCode = response.data;
                });
        },
        sendRegistrationCode() {},
        sendTest() {},
    },
    mounted() {
        this.getAccounts();
    },
    watch: {
        accounts() {
            if (this.accounts.length == 1) {
                this.selectedAccount = this.accounts[0].uuid;
            }
        },
        selectedAccount() {
            this.getCohorts();
        },
        selectedCohort() {
            this.getRegistrationCode();
        },
    },
};
</script>
