<template>
    <div
        class="flex flex-wrap items-center justify-between border-b border-gray-100 last:border-b-0 sm:h-20"
    >
        <div class="w-full lg:w-96 xl:w-112 pt-4 sm:pt-0 px-4">
            <Skeleton :loading="loading">
                <a
                    class="block cta capitalize truncate"
                    :href="path + 'overview' + linkContext"
                >
                    {{ row.name }}
                </a>
                <div class="flex items-center text-xs leading-6">
                    <div
                        v-if="inSubjectGroup(['organization'])"
                        class="flex items-center mr-4"
                    >
                        <div
                            class="text-gray-500 uppercase tracking-tight mr-2"
                        >
                            Cohorts
                        </div>
                        <div class="font-bold">
                            <span>{{ row.cohorts }}</span>
                        </div>
                    </div>
                    <div
                        v-if="inSubjectGroup(['organization', 'account'])"
                        class="flex items-center"
                    >
                        <div
                            class="text-gray-500 uppercase tracking-tight mr-2"
                        >
                            Learners
                        </div>
                        <div class="font-bold">
                            <span>{{ row.learners }}</span>
                        </div>
                    </div>
                    <div
                        v-if="inSubjectGroup(['cohort', 'learner'])"
                        class="flex items-center"
                    >
                        <div
                            class="text-gray-500 uppercase tracking-tight mr-2"
                        >
                            Last Active
                        </div>
                        <div class="font-bold">
                            <span
                                v-text="
                                    $filters.relative(updatedAt, user.timezone)
                                "
                            />
                        </div>
                    </div>
                </div>
            </Skeleton>
        </div>
        <div
            class="flex flex-wrap sm:flex-wrap-none items-center border sm:border-none border-dashed border-gray-400 rounded-lg sm:rounded-none m-4 sm:m-0 p-4 sm:p-0 w-full sm:w-auto"
        >
            <Skeleton
                class="flex flex-col sm:flex-row items-center justify-center leading-6 sm:border-l sm:border-r border-gray-100 px-4 w-1/2 lg:w-24 xl:w-32 h-20"
                :loading="loading"
            >
                <span class="sm:hidden text-sm font-bold underline"
                    >Visits</span
                >
                {{ $filters.quantity(row.visits) }}
            </Skeleton>
            <Skeleton
                class="flex flex-col sm:flex-row items-center justify-center leading-6 sm:border-r border-gray-100 px-4 w-1/2 lg:w-24 xl:w-32 h-20"
                :loading="loading"
            >
                <span class="sm:hidden text-sm font-bold underline">Views</span>
                <a class="cta" :href="path + 'lessons' + linkContext">
                    {{ $filters.quantity(row.lessonViews) }}
                </a>
            </Skeleton>
            <div
                class="sm:hidden border-t border-b border-dashed border-gray-200 text-center font-bold py-2 my-2 w-full"
            >
                Quizzes
            </div>
            <Skeleton
                class="flex flex-col sm:flex-row items-center justify-center leading-6 sm:border-r border-gray-100 px-4 w-1/2 lg:w-24 xl:w-32 h-20"
                :loading="loading"
            >
                <span class="sm:hidden text-sm font-bold underline">Taken</span>
                <a class="cta" :href="path + 'quizzes' + linkContext">
                    {{ $filters.quantity(row.quizzesTaken) }}
                </a>
            </Skeleton>
            <Skeleton
                class="flex flex-col sm:flex-row items-center justify-center leading-6 sm:border-r border-gray-100 px-4 w-1/2 lg:w-24 xl:w-32 h-20"
                :loading="loading"
            >
                <span class="sm:hidden text-sm font-bold underline"
                    >Avg Score</span
                >
                <span
                    v-html="
                        $filters.grade(row.avgQuizScore, settings.pulse_grade)
                    "
                />
            </Skeleton>
            <div
                class="sm:hidden border-t border-b border-dashed border-gray-200 text-center font-bold py-2 my-2 w-full"
            >
                Assessments
            </div>
            <Skeleton
                class="flex flex-col sm:flex-row items-center justify-center leading-6 sm:border-r border-gray-100 px-4 w-1/2 lg:w-24 xl:w-32 h-20"
                :loading="loading"
            >
                <span class="sm:hidden text-sm font-bold underline">Taken</span>
                <a class="cta" :href="path + 'assessments' + linkContext">
                    {{ $filters.quantity(row.assessmentsTaken) }}
                </a>
            </Skeleton>
            <Skeleton
                class="flex flex-col sm:flex-row items-center justify-center leading-6 px-4 w-1/2 lg:w-24 xl:w-32 h-20"
                :loading="loading"
            >
                <span class="sm:hidden text-sm font-bold underline"
                    >Avg Score</span
                >
                <span
                    v-html="
                        $filters.grade(
                            row.avgAssessmentScore,
                            settings.pulse_grade
                        )
                    "
                />
            </Skeleton>
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState } from "vuex";

export default {
    props: {
        legacy: Boolean,
        row: Object,
    },
    data(): any {
        return {
            loading: true,
        };
    },
    computed: {
        ...mapState(["settings", "period", "subject", "subjectGroup", "user"]),
        linkContext() {
            return "?subject=" + this.row.uuid + "&period=" + this.period;
        },
        path() {
            return this.legacy ? "/pulse/explore-legacy/" : "/pulse/explore/";
        },
        apiPath() {
            return "/api/pulse/engagement";
        },
        updatedAt() {
            return this.row.updated_at || this.row.last_active;
        },
    },
    methods: {
        getData() {
            this.loading = true;
            axios
                .get(this.apiPath, {
                    params: {
                        legacy: this.legacy,
                        subject: this.row.uuid,
                        period: this.period,
                    },
                })
                .then((response) => {
                    this.$emit("rowSync", {
                        uuid: this.row.uuid,
                        payload: response.data,
                    });
                    this.loading = false;
                });
        },
        getActivity(activity) {
            if (Object.values(activity).length > 0) {
                return Object.values(activity).reduce(
                    (carry: any, value: any) => carry + value.length,
                    0
                );
            }
            return "–";
        },
        avgGrade(assessments) {
            if (assessments.length > 0) {
                return (
                    assessments.reduce(
                        (carry, value) => carry + value.grade,
                        0
                    ) / assessments.length
                ).toFixed(1);
            }
            return "–";
        },
        inSubjectGroup(groups) {
            return groups.indexOf(this.subjectGroup) > -1;
        },
    },
    mounted() {
        this.getData();
    },
    watch: {
        period() {
            this.getData();
        },
    },
};
</script>

<style scoped>
.rowHeight {
    height: 89px;
}
</style>
