<template></template>

<script lang="ts">
export default {
    props: {
        questions: {
            type: Array,
            default: () => {},
        },
    },
};
</script>
