import { computed, defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";
import AddCategoryButtonDialog from "./AddCategoryButtonDialog.vue";
import { useQuery } from "@tanstack/vue-query";
import { adminApi } from "@/api/admin";
import {
    AdminBreadcrumbs,
    BreadcrumbItem,
} from "@/components/ui/breadcrumbs/AdminBreadCrumbs.tsx";

export const CategoryBreadcrumbs = defineComponent({
    setup() {
        const route = useRoute();
        const bankId = computed(() => parseInt(route.params.id as string));
        const categoryId = computed(() =>
            parseInt(route.params.categoryId as string)
        );

        const { data, isLoading } = useQuery({
            queryKey: ["bank", bankId, "category", categoryId, "breadcrumbs"],
            queryFn: async () => {
                const response = await adminApi.questionBank.getBreadcrumbs(
                    bankId.value,
                    categoryId.value
                );
                return response.data;
            },
        });

        return () => (
            <div>
                {isLoading.value ? (
                    <div>Loading</div>
                ) : (
                    <AdminBreadcrumbs>
                        {data.value?.ancestors.map((breadcrumb) => (
                            <BreadcrumbItem
                                label={breadcrumb.label}
                                siblings={breadcrumb.siblings}
                            ></BreadcrumbItem>
                        ))}
                        <BreadcrumbItem
                            label={"Select / Add Category"}
                            siblings={data.value?.children}
                        >
                            {{
                                default: () => (
                                    <AddCategoryButtonDialog
                                        questionBankId={bankId.value}
                                        parentCategoryId={categoryId.value}
                                    ></AddCategoryButtonDialog>
                                ),
                            }}
                        </BreadcrumbItem>
                    </AdminBreadcrumbs>
                )}
            </div>
        );
    },
});
