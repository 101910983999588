<template>
    <div>
        <PulseExploreLayout :legacy="legacy" v-bind="$attrs">
            <PulseExploreTimelineTable :courses="courses" :legacy="legacy" />
        </PulseExploreLayout>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        courses: Array,
        legacy: Boolean,
    },
};
</script>
