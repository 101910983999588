<script lang="ts">
import ReportingTabs from "../shared/ReportingTabs.vue";
import ReportingRouteMixin from "../ReportingRouteMixin.vue";
import PulseReportPeriod from "../../Pulse/_shared/PulseReportPeriod.vue";
import { getCourseStats } from "../../../api/pulse-requests";
import { mapState } from "vuex";

export default {
    components: {
        PulseReportPeriod,
        ReportingTabs,
    },
    data(): any {
        return {
            courseStats: null,
        };
    },
    computed: {
        courseId: function () {
            return this.$route.params.courseId;
        },
        allCoursesLink: function () {
            const isStructuredView = this.$route.path.includes("/structured");
            const isLearnerView = this.$route.path.includes("/learner");

            let path = "/courses";
            if (isStructuredView) {
                path += "/activity/structured";
            } else if (isLearnerView) {
                path += "/activity/learner";
            }

            return {
                path,
                query: { ...this.$route.query, page: 1 },
            };
        },
        breadcrumbs() {
            return [
                {
                    title: "All Courses",
                    to: this.allCoursesLink,
                },
                {
                    title: this.courseStats?.course?.title ?? "",
                },
            ];
        },
        tabs: function () {
            return [
                { name: "Modules", path: `/course/${this.courseId}` },
                {
                    name: "Activity",
                    path: `/course/${this.courseId}/activity/structured`,
                },
            ];
        },
        watchContext: function () {
            return {
                partner: this.partner,
                courseId: this.courseId,
                period: this.period,
                group: this.group,
            };
        },
        ...mapState(["partner", "group", "period", "selectedLearners"]),
    },
    methods: {
        getCourseStats: function () {
            if (this.partner || this.group) {
                getCourseStats(
                    this.partner,
                    this.group,
                    this.period,
                    this.courseId,
                    this.selectedLearners
                )
                    .then((response) => {
                        this.courseStats = response.data;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
    },
    watch: {
        watchContext: {
            handler: function () {
                this.getCourseStats();
            },
            deep: true,
            immediate: true,
        },
    },
    mixins: [ReportingRouteMixin],
};
</script>
<template>
    <div>
        <section class="mb-8 section-title">
            <PulseSubjectHeader />
        </section>
        <section class="mb-8 section-title">
            <PulseCourseEngagementCard
                :image="courseStats?.course?.asset ?? null"
                :image-alt="courseStats?.course?.title ?? ''"
                :breadcrumbs="breadcrumbs"
                class="mb-9"
                :active-learners="courseStats?.stats?.active_learners"
                :course="courseStats?.course"
            >
                <template v-slot:stats>
                    <PulseContentEngagementStats
                        v-if="courseStats"
                        :lesson-views="courseStats?.stats?.lesson_views"
                        :assessment-attempts="
                            courseStats?.stats?.module_quiz_attempts
                        "
                        :assessment-average-score="
                            courseStats?.stats?.module_quiz_average_score
                        "
                        :quiz-attempts="
                            courseStats?.stats?.lesson_quiz_attempts
                        "
                        :quiz-average-score="
                            courseStats?.stats?.lesson_quiz_average_score
                        "
                    />
                </template>
            </PulseCourseEngagementCard>
        </section>
        <section class="flex flex-wrap justify-end mb-6 section-title">
            <PulseReportPeriod />
        </section>
        <ReportingTabs :tabs="tabs" />
        <router-view></router-view>
    </div>
</template>
