<script setup lang="ts">
import StyledModal from "@/components/_elements/modals/StyledModal.vue";
import { XMarkIcon } from "@heroicons/vue/20/solid";
import NewBanksImg from "../ui/images/NewBanks.vue";

const localKey = "clover.announcement-modal";
const show = localStorage.getItem(localKey) !== "false";

const onClose = () => {
	localStorage.setItem(localKey, "false");
};

const redirect = (to) => {
	onClose();
	window.location.href = to;
};
</script>

<template>
	<StyledModal :start-open="show" @on-close="onClose">
		<template v-slot:panel="slotProps">
			<button
				class="fixed top-0 right-0"
				@click="slotProps.events?.closeModal()">
				<XMarkIcon
					class="rounded-full bg-white w-12 h-12 p-1 text-black"></XMarkIcon>
				<span class="sr-only">Close</span>
			</button>

			<NewBanksImg class="w-full h-auto" />

			<div class="text-center text-pretty px-2 mt-6">
				<h2 class="mt-0">
					New Radiography Certification Exam Prep QBank Now Available!
				</h2>
				<p class="text-base text-gray-700">
					We are excited to announce the launch of our new 2024 ARRT®
					Radiography Certification Exam Prep Question Bank for the
					Prep application!
					<a
						class="text-blue-700 hover:no-underline hover:text-blue-800"
						href="https://help.cloverlearning.com/hc/en-us/articles/27215431165467-Radiography-Question-Bank-Update-Learners-FAQ"
						target="_blank"
						>Click HERE to learn more<span class="sr-only">
							about our Radiography Question Bank Update</span
						></a
					>.
				</p>

				<div class="flex mt-8 gap-2 items-center justify-center">
					<button
						@click="redirect('/prep/exams/new')"
						class="hover:cursor-pointer button no-underline bg-blue-700 text-white rounded-md border-none hover:bg-blue-800 hover:text-white">
						Start A New Exam
					</button>
				</div>
			</div>
		</template>
	</StyledModal>
</template>
