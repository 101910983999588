<template>
    <div class="relative z-0" v-if="course">
        <div class="flex flex-col gap-2">
            <!-- Breadcrumbs -->
            <div class="text-sm text-gray-500 mb-4">
                <router-link to="/content/learn/courses">Courses</router-link>
                > {{ course.title }}
            </div>
            <div
                class="flex flex-col md:flex-row md:items-center md:justify-between gap-3 md:gap-0"
            >
                <div class="flex flex-col flex-wrap">
                    <h2
                        class="text-2xl font-semibold leading-none tracking-tight"
                    >
                        {{ course.title }}
                    </h2>
                    <div class="flex flex-wrap items-center gap-2 mt-1">
                        <div class="flex items-center">
                            <p class="inline-flex text-xs text-gray-600 italic">
                                {{ course.slug }}
                            </p>
                            <CopyAction
                                :value="course.slug"
                                :label="`${course.slug}`"
                            />
                        </div>
                        <div class="text-xs flex flex-row items-center">
                            <span class="font-semibold">ID</span>
                            <CopyAction
                                :value="course.id"
                                :label="`${course.id}`"
                            />
                        </div>
                        <div class="text-xs flex flex-row items-center">
                            <span class="font-semibold">UUID</span>
                            <CopyAction
                                :value="course.uuid"
                                :label="`${uuid}`"
                            />
                        </div>
                    </div>
                </div>

                <div class="flex items-center gap-4 flex-start flex-wrap">
                    <Link
                        intent="flat"
                        size="sm"
                        :to="`/content/learn/courses/${uuid}/edit`"
                        class="inline-flex !w-auto self-start items-center gap-2"
                    >
                        <PencilIcon class="h-4 w-4" />Edit Course
                    </Link>
                  <Link
                      intent="flat"
                      size="sm"
                      :to="{
                                name: 'course.edit.module-order',
                                params: { uuid },
                            }"
                      class="gap-2 !w-auto"
                  >
                    <ArrowsUpDownIcon class="w-4 h-4" /> Reorder
                  </Link>
                    <Link
                        :to="`/content/learn/courses/${uuid}/add-module`"
                        size="sm"
                        class="inline-flex !w-auto"
                    >
                        &plus; New
                    </Link>
                  <AddModuleToCourse :course="course" @module-add-success="getCourse(course.uuid)" />
                </div>
            </div>
        </div>
    </div>

    <div class="rounded-md shadow divide-y mt-6" v-if="modules.length > 0">
        <div
            class="flex items-center bg-gray-200 text-xs text-gray-600 uppercase font-semibold leading-none rounded-t-md pt-6 pb-5"
        >
            <div class="flex items-center justify-between px-6 w-1/4">
                Module Name
            </div>
            <div class="flex items-center justify-between px-6 w-1/4">Slug</div>
            <div class="flex items-center justify-between px-6 w-1/4">Status</div>
        </div>
        <div>
            <div
                v-for="m in modules"
                class="flex items-center bg-white text-sm even:bg-gray-50 last:rounded-b-md"
            >
                <div class="p-6 w-1/4">
                    <router-link
                        class="cta"
                        :title="m.title"
                        :to="{
                            name: 'module_lessons',
                            params: { uuid: m.uuid },
                        }"
                    >
                        {{ m.title }}
                    </router-link>
                </div>
                <div class="p-6 w-1/4">
                    {{ m.slug }}
                </div>
                <div class="p-6 w-1/4">
                  <SelectDropdown
                      v-model="m.status"
                      variant="status"
                      @input="(e) => handleStatusUpdate(e.target.value, m)"
                  >
                    <option value="published">Published</option>
                    <option value="draft">Draft</option>
                  </SelectDropdown>
                </div>
              <div class="p-6 w-1/4 flex justify-end">
                <ActionMenu>
                  <ActionMenuAction
                      type="submit"
                      label="Remove Module From Course"
                      icon="close-circle"
                      icon-color="text-red-500"
                      :use-modal="'true'"
                      :message="`Are you sure you want to remove ${m.title} module from ${course?.title} course? This will not delete the module permanently, just detach it from the course.`"
                      :action="handleRemoveModuleFromCourse"
                      :argument="{ 'courseUuid': course.uuid, 'moduleUuid': m.uuid }"
                  ></ActionMenuAction>
                </ActionMenu>
              </div>
            </div>
        </div>
        <div class="flex justify-end items-center">
            <Pagination
                class="p-6"
                :model-value="pagination.currentPage"
                :total="pagination.total"
                :perPage="pagination.perPage"
                @update:modelValue="updateModulePage"
            />
        </div>
    </div>
    <div
        v-else
        class="flex items-center bg-white text-sm even:bg-gray-50 last:rounded-b-md p-6 justify-center"
    >
        This course has 0 modules
    </div>
</template>
<script lang="ts">
import axios from "axios";
import Pagination from "../../../_elements/Pagination.vue";
import { defineComponent } from "vue";
import CopyAction from "../../../_elements/CopyAction.vue";
import Link from "@/components/ui/ButtonLinks/Link.vue";
import {ArrowsUpDownIcon, PencilIcon} from "@heroicons/vue/24/outline";
import SelectDropdown from "@/components/_elements/SelectDropdown.vue";
import $events from "@/event-bus.ts";
import AddModuleToCourse from "@/components/Admin/Content/modules/AddModuleToCourse.vue";
import ActionMenu from "@/components/_elements/ActionMenu.vue";
import ActionMenuAction from "@/components/_elements/ActionMenuAction.vue";

export default defineComponent({
    components: {ArrowsUpDownIcon, ActionMenuAction, ActionMenu, AddModuleToCourse, SelectDropdown, CopyAction, Pagination, Link, PencilIcon },
    data(): any {
        return {
            course: null,
            modules: [],
            path: window.location.pathname + "/",
            pagination: {
                perPage: 10,
                total: 0,
                currentPage: 1,
            },
        };
    },
    computed: {
        uuid(): string {
            return this.$route.params.uuid;
        },
    },
    methods: {
        handleRemoveModuleFromCourse(params) {
          axios.delete(`/api/admin/content/courses/${params.courseUuid}/modules/${params.moduleUuid}`)
              .then((response) => {
                // DELETE endpoints return 204 on success
                if (response.status === 204) {
                  this.getCourse(this.uuid);
                  $events.$emit("toastNotification", {
                    action: "saved",
                    heading: "Module successfully detached from course",
                    status: "saved",
                  });
                }
              })
              .catch((e) => {
                console.error(e)
                $events.$emit("toastNotification", {
                  action: "error",
                  heading: `Error detaching module from course: ${e}`,
                  status: "error",
                });
              });
        },
        getCourse(uuid) {
            axios
                .get(`/api/admin/content/learn/courses/${uuid}`)
                .then((response) => {
                    this.course = response.data;
                    this.pagination.total = this.course.modules.length;
                    this.updateModulePage(1);
                });
        },
        updateModulePage(pageNumber) {
            this.modules = this.course.modules.slice(
                (pageNumber - 1) * this.pagination.perPage,
                pageNumber * this.pagination.perPage
            );
        },
        handleStatusUpdate(status, module) {
          axios
              .put(`/api/admin/content/modules/${module.id}`, { ...module, status })
              .then((response) => {
                if (response.status < 400) {
                  $events.$emit("toastNotification", {
                    action: "saved",
                    heading: "Module saved",
                    status: "success",
                  });
                  this.getCourses(this.pagination.currentPage)
                }
              })
              .catch((e) => {
                $events.$emit("toastNotification", {
                  action: "saved",
                  heading: "Error Saving Module",
                  status: "error",
                  message: e.response.data.message
                });
          });
      },
    },
    mounted() {
        this.getCourse(this.uuid);
    },
});
</script>
