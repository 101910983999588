<template>
    <dropdown
        :modelValue="selected"
        variant="white"
        :context-string="filter"
        width-class="w-60"
    >
        <dropdown-item variant="white" @click.native="handleClick('all')">
            <span
                class="text-sm capitalize"
                :class="{ 'font-bold': isActive('all') }"
                >All</span
            >
        </dropdown-item>

        <dropdown-item
            v-for="(value, index) in values"
            :key="index"
            variant="white"
            @click.native="handleClick(value)"
        >
            <span
                class="text-sm capitalize"
                :class="{
                    'font-bold': isActive(useUuidInPath ? value.uuid : value),
                }"
            >
                {{ useUuidInPath ? value.title : value.replace("_", " ") }}
            </span>
        </dropdown-item>
    </dropdown>
</template>

<script lang="ts">
import axios from "axios";

export default {
    props: {
        filter: String,
        parentId: Number,
        useUuidInPath: Boolean,
    },
    data(): any {
        return {
            values: undefined,
            selected: "all",
        };
    },
    methods: {
        isActive(value) {
            return value === this.selected;
        },
        handleClick(value) {
            if (value === "all") {
                this.selected = "all";
            } else {
                this.selected = this.useUuidInPath ? value.title : value;
            }

            this.$events.$emit("pulseTableFiltered", {
                type: this.filter,
                term: this.getTermFromValue(value),
            });
        },
        getTermFromValue(value) {
            if (value === "all") {
                return "all";
            }

            return this.useUuidInPath ? value.uuid : value;
        },
    },
    mounted() {
        axios
            .post("/api/pulse/activity/filters", {
                filter: this.filter,
                parent_id: this.parentId,
            })
            .then((response) => (this.values = response.data));
    },
};
</script>
