<template>
    <div>
        <label
            for="days"
            class="flex items-center text-sm leading-7 font-medium ml-2 space-x-4"
        >
            <div>Type</div>
        </label>
        <div class="grid grid-cols-2 gap-6">
            <SelectDropdown theme="flat" v-model="type">
                <option value="date">Date</option>
                <option value="day"># of days</option>
            </SelectDropdown>
            <div>
                <input
                    v-if="type == 'date'"
                    class="h-12"
                    type="date"
                    name="date"
                />
                <input v-else class="h-12" type="number" name="days" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    data(): any {
        return {
            type: "date",
        };
    },
};
</script>
