<template>
    <div>
        <template v-if="!loading">
            <h3
                class="flex items-center justify-between bg-white text-gray-800 border-l-4 border-pear-600 p-4 rounded shadow mb-12"
            >
                <div class="flex items-center">
                    <div class="flex items-center mr-16">
                        <svg-icon class="text-pear-600 mr-2" :icon="typeIcon" />
                        <div>
                            {{ typeTitle }}:
                            <strong class="font-semibold">{{
                                assessment.assessment_title
                            }}</strong>
                        </div>
                    </div>
                    <div
                        class="flex items-center leading-none text-xs"
                        :title="assessment.created_at"
                    >
                        <SvgIcon
                            class="text-pear-600 mr-2"
                            icon="clock"
                            size="sm"
                        />
                        <div
                            v-text="
                                $filters.dateTime(
                                    assessment.created_at,
                                    user.timezone
                                )
                            "
                        />
                    </div>
                </div>
                <div class="flex items-center">
                    <div class="text-center text-lg px-8">
                        <strong class="text-pear-600 font-bold">{{
                            $filters.grade(assessment.grade)
                        }}</strong>
                        <h4 class="text-sm uppercase mt-2">Score</h4>
                    </div>
                    <div class="text-center text-lg px-8">
                        <strong class="text-pear-600 font-bold">{{
                            $filters.elapsed(assessment.time_elapsed)
                        }}</strong>
                        <h4 class="text-sm uppercase mt-2">Duration</h4>
                    </div>
                </div>
            </h3>
            <PulseAssessmentQuestions :assessment="assessment" :total="1" />
        </template>
        <LoadingSpinner :show="loading" />
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState, mapGetters } from "vuex";

export default {
    data(): any {
        return {
            assessment: {},
            loading: true,
        };
    },
    computed: {
        ...mapState(["instanceId", "type", "typeId", "user"]),
        ...mapGetters(["context"]),
        typeIcon() {
            switch (this.type) {
                case "quizzes":
                    return "checklist";
                    break;
                case "assessments":
                    return "exam";
                    break;
            }
        },
        typeTitle() {
            switch (this.type) {
                case "quizzes":
                    return "Quiz";
                    break;
                case "assessments":
                    return "Assessment";
                    break;
            }
        },
    },
    methods: {
        getData() {
            axios
                .get(
                    "/api/pulse/assessment/" +
                        this.context +
                        "/" +
                        this.type +
                        "/" +
                        this.typeId +
                        "/single/" +
                        this.instanceId
                )
                .then((response) => {
                    this.assessment = response.data;
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getData();
    },
};
</script>
