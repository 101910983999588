<template>
    <div
        v-if="viewSubject"
        class="relative flex flex-col justify-between max-w-full px-5 py-5 mb-4 bg-white rounded-md shadow md:px-8 shadow-gray-400 min-w-48 sm:flex-row sm:space-x-2"
    >
        <div
            class="flex flex-col space-y-2 xs:items-center xs:flex-row xs:space-x-3 xs:space-y-0"
        >
            <p class="font-medium">{{ viewSubject.name }}</p>
            <p class="text-xs font-medium leading-tight text-gray-500">
                {{ viewSubject.learner_count }}
                {{ pluralizeLabel("Learner", viewSubject.learner_count) }}
            </p>
        </div>
    </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import { filters } from "../../../vue-filters";

export default {
    computed: {
        ...mapGetters(["viewSubject"]),
    },
    methods: {
        ...filters,
    },
    data() {
        return {
            name: "",
            learnerCount: 0,
        };
    },
    mounted() {
        if (!this.viewSubject) return;

        this.name = this.viewSubject.name;
        this.learnerCount = this.viewSubject.learner_count;
    },
};
</script>
