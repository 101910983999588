<template>
    <div>
        <h1>Reports</h1>
        <PulsePrepExamReport v-if="type === 'exams'" />
        <PulsePrepAssignmentReport v-if="type === 'assignments'" />
    </div>
</template>

<script lang="ts">
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState(["type"]),
    },
};
</script>
