<template>
    <div>
        <slot v-bind="{ openEditModal }"></slot>
        <base-modal
            :heading="groupId ? 'Edit Group' : 'Add Group'"
            :opened="isOpen"
            @close="close"
        >
            <button
                slot="trigger"
                @click="open"
                class="bg-green-500 bg-gradient-to-r from-green-900 to-gray-700 py-3 rounded-md text-white hover:text-white px-4 hover:no-underline mt-4"
            >
                Add Group
            </button>
            <template v-slot:body>
                <input
                    class="bg-gray-100 text-base leading-5 rounded p-3 w-full"
                    placeholder="Group Name"
                    v-model="name"
                />
                <div v-if="errors.name" class="text-xs text-red-600 mt-1">
                    {{ errors.name[0] }}
                </div>
                <SelectDropdown class="mt-4 w-full" v-model="parentGroupId">
                    <option value="">Choose a parent group</option>
                    <option
                        v-for="group in groups"
                        :value="group.id.toString()"
                    >
                        {{ group.name }}
                    </option>
                </SelectDropdown>
                <div
                    v-if="errors.parent_group_id"
                    class="text-xs text-red-600 mt-1"
                >
                    {{ errors.parent_group_id[0] }}
                </div>
            </template>
            <template v-slot:actions>
                <button
                    type="button"
                    class="flex items-center justify-center primary button px-4 py-2 text-base sm:text-sm leading-6 w-32"
                    @click="submit"
                >
                    <SvgIcon
                        v-if="isSaving"
                        class="animate-spin"
                        icon="loader"
                    />
                    <span v-else>Submit</span>
                </button>
                <button
                    v-if="canDelete"
                    type="button"
                    class="flex items-center justify-center danger button px-4 py-2 text-base sm:text-sm leading-6 w-32 ml-4"
                    @click="deleteGroup"
                >
                    <SvgIcon
                        v-if="isSaving"
                        class="animate-spin"
                        icon="loader"
                    />
                    <span v-else>Delete</span>
                </button>
            </template>
        </base-modal>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import BaseModal from "./BaseModal.vue";

export default {
    name: "PartnerGroupModal",

    props: {
        partnerId: {
            type: Number,
            required: true,
        },
        groups: {
            type: Array,
            default: [],
        },
    },
    data(): any {
        return {
            isOpen: false,
            isSaving: false,
            groupId: null,
            name: "",
            parentGroupId: "",
            canDelete: false,
            errors: {},
        };
    },
    methods: {
        open() {
            this.isOpen = true;
        },
        close() {
            this.isOpen = false;
            this.reset();
        },
        reset() {
            this.name = "";
            this.parentGroupId = "";
        },
        openEditModal(groupId, name, parentGroupId, canDelete) {
            this.groupId = groupId;
            this.name = name;
            if (parentGroupId) {
                this.parentGroupId = parentGroupId.toString();
            }
            this.canDelete = canDelete;
            this.open();
        },
        sendRequest() {
            if (this.groupId) {
                return axios.put(
                    `/partners/${this.partnerId}/groups/${this.groupId}`,
                    {
                        name: this.name,
                        parent_group_id: this.parentGroupId,
                    }
                );
            }

            return axios.post(`/partners/${this.partnerId}/groups`, {
                name: this.name,
                parent_group_id: this.parentGroupId,
            });
        },
        submit() {
            this.isSaving = true;
            this.sendRequest()
                .then(() => {
                    this.isSaving = false;
                    this.close();
                    location.reload();
                })
                .catch(({ response }) => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    } else if (response.data.message) {
                        this.errors = {
                            parent_group_id: [response.data.message],
                        };
                    }
                    this.isSaving = false;
                });
        },
        deleteGroup() {
            this.isSaving = true;
            axios
                .delete(`/partners/${this.partnerId}/groups/${this.groupId}`)
                .then(() => {
                    this.isSaving = false;
                    this.close();
                    location.reload();
                });
        },
    },

    components: {
        BaseModal,
    },
};
</script>
