<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    data(): any {
        return {
            admin: false,
            app: "Reporting",
            icon: "chalkboard",
            searchAddition: "",
            searchClear: false,
            searchable: ["Partner"],
            section: "Reporting",
            action: "",
            actionPath: "",
            sectionPath: "",
            moduleTitle: "",
            resourceTitle: "",
            impersonating: false,
            showBilling: false,
            pageSearch: ["PulseGroupSearch", "PulseLearnerSearch"],
        };
    },
    mounted() {
        let params = new URLSearchParams(window.location.search);
        const course = params.get("course") ?? null;
        this.$store.commit("registerState", {
            pageSearch: this.pageSearch,
            course: course,
        });
    },
});
</script>
<template>
    <div>
        <Modal />
        <Toaster />
        <SiteRibbon />
        <SidebarUser v-if="!admin" theme="fresh" />
        <SidebarAdmin v-if="admin" />
        <TitleBar
            :action="action"
            :actionPath="actionPath"
            :app="app"
            :icon="icon"
            :moduleTitle="moduleTitle"
            :resourceTitle="resourceTitle"
            :searchable="searchable"
            :searchAddition="searchAddition"
            :searchClear="searchClear"
            :section="section"
            :sectionPath="sectionPath"
            :showBilling="showBilling"
            :impersonating="impersonating"
        />
        <section class="flex flex-col content pt-17 sm:pt-20 sm:pl-24 h-screen">
            <slot name="banner" />
            <div class="flex-grow py-4 sm:py-8" :class="'px-8'">
                <PageSearch
                    v-if="searchable.length && pageSearch"
                    class="sm:hidden pt-2 border-b border-gray-200 mb-6 pb-6"
                    v-bind="{
                        app,
                    }"
                />
                <PageTitle
                    v-else
                    class="flex justify-center sm:hidden border-b border-gray-200 mb-6 pb-6"
                    v-bind="{
                        action,
                        actionPath,
                        app,
                        icon,
                        section,
                        sectionPath,
                    }"
                />
                <main id="main">
                    <router-view> </router-view>
                </main>
            </div>
            <slot name="footer" />
        </section>
    </div>
</template>
