<template>
    <div class="flex items-center">
        <PrepCategoryCircle
            v-for="(children, parent) in categories"
            :options="options"
            :parent="parent"
            :categories="children"
            :key="parent"
        />
        <div
            class="hidden bg-indigo-100 text-indigo-600 bg-cyan-100 text-cyan-600 bg-pink-100 text-pink-600 bg-orange-100 text-orange-600"
        ></div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
export default defineComponent({
    props: {
        categories: Object as PropType<any>,
        options: String,
    },
});
</script>
