<template>
    <div v-click-away="hide" class="relative" @keyup.esc="hide">
        <div
            class="relative flex items-center bg-white border border-gray-200 rounded-full"
        >
            <SvgIcon
                v-if="icon"
                class="absolute z-10 text-gray-600 -translate-y-1/2 pointer-events-none left-6 top-1/2"
                style="
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                "
                :icon="icon"
            />

            <button
                aria-roledescription="Click to open combobox"
                class="flex items-center justify-between py-2 pr-6 leading-6 text-left text-gray-800 border-gray-200 rounded-full select-button bg-gray-50 w-72 focus-visible:ring focus-visible:ring-blue-300 focus-visible:ring-opacity-50"
                :class="{
                    'ring ring-blue-300 ring-opacity-50': show,
                    'pl-6': icon === '',
                    'pl-16': icon !== '',
                }"
                @click="show = !show"
                @keyup.down="show ? $refs.selectSearch.focus() : null"
            >
                <span class="pr-1 truncate" v-text="selectedItemsLabel"></span>
                <SvgIcon
                    class="text-blue-550"
                    :icon="show ? 'chevron-up' : 'chevron-down'"
                    size="sm"
                    weight="extra-bold"
                />
            </button>
        </div>

        <fade class="absolute top-0 left-0 z-10 w-full mt-14" :show="show">
            <div
                class="overflow-hidden rounded-md shadow-lg ring-1 ring-gray-200 focus:outline-none"
            >
                <div
                    class="w-full overflow-scroll text-base bg-white max-h-128 sm:text-sm"
                >
                    <label class="relative block px-3 py-4">
                        <SvgIcon
                            class="absolute z-10 -translate-y-1/2 pointer-events-none inset-x-7 inset-y-1/2"
                            style="
                                -webkit-user-select: none;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;
                            "
                            icon="search"
                            size="sm"
                        />
                        <input
                            v-model="search"
                            :aria-expanded="show"
                            :disabled="!canSearch"
                            aria-owns="searchable-select-options"
                            class="select-search disabled:cursor-not-allowed"
                            placeholder="Search"
                            ref="selectSearch"
                            role="combobox"
                            type="text"
                        />
                    </label>

                    <ul
                        id="searchable-select-options"
                        class="w-full"
                        role="listbox"
                        :class="{ 'pb-24': selectable, 'pb-10': !selectable }"
                    >
                        <slot
                            v-bind:selectable="selectable && canSearch"
                            v-bind:onSelected="updateSelectedItems"
                            v-bind:hasSelections="Boolean(selectedItemsCount)"
                            v-if="show"
                        />

                        <li
                            v-show="hasNoSearchResults || !searchable.length"
                            class="relative px-3 py-2 italic text-center text-gray-500 cursor-default select-none"
                            role="option"
                            tabindex="-1"
                        >
                            <span class="w-full truncate"
                                >No matching results</span
                            >
                        </li>
                    </ul>
                </div>

                <div
                    v-if="canSearch && selectable"
                    class="absolute inset-x-0 bottom-0 z-10 flex justify-end px-4 py-3 bg-gray-50 rounded-b-md"
                >
                    <button class="mr-5 no-underline cta" @click="reset">
                        Clear
                    </button>
                    <button
                        class="rounded-md button main small focus-visible:ring focus-visible:ring-blue-300 focus-visible:ring-opacity-50"
                        @click="save"
                    >
                        Save
                    </button>
                </div>
            </div>
        </fade>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        icon: {
            type: String,
            required: false,
            default: "",
        },
        searchable: {
            type: Array,
            required: true,
        },
        selectable: {
            type: Boolean,
            required: false,
            default: false,
        },
        selectableLabel: {
            type: String,
            required: false,
            default: "",
        },
        selectedLabelProp: {
            type: String,
            required: false,
        },
    },
    data(): any {
        return {
            search: "",
            selectedItems: [],
            selectionsRemoved: false,
            show: false,
        };
    },
    computed: {
        filteredSearchable() {
            return this.search === ""
                ? this.searchable
                : this.searchable.filter((item) =>
                      item.toLowerCase().match(this.search.toLowerCase())
                  );
        },
        fullSelectableLabel() {
            return this.selectableLabel ? `${this.selectableLabel}:` : "";
        },
        hasNoSearchResults() {
            return this.search.length && !this.filteredSearchable.length;
        },
        selectedItemsCount() {
            return this.selectedItems.length;
        },
        selectedItemsLabel() {
            if (!this.selectable) {
                return this.selectableLabel;
            }

            if (!this.selectedItemsCount) {
                return `${this.fullSelectableLabel} All`;
            }
            if (this.selectedItemsCount > 1) {
                return `${this.fullSelectableLabel} ${this.selectedItemsCount} Selected`;
            }

            const valueLabel = this.selectedLabelProp
                ? this.selectedItems[0].value[this.selectedLabelProp]
                : this.selectedItems[0].value;

            return `${this.fullSelectableLabel} ${valueLabel}`;
        },
        canSearch() {
            return this.searchable.length > 1;
        },
    },
    methods: {
        hide() {
            this.show = false;
        },
        reset() {
            this.search = "";
            this.selectedItems = [];
            this.$emit("reset");
        },
        save() {
            if (this.selectedItemsCount || this.selectionsRemoved) {
                this.selectionsRemoved = false;
                this.$emit("save", this.selectedItems);
            }

            this.hide();
        },
        updateSelectedItems(e, value) {
            if (!e) {
                return (this.selectedItems = this.selectedItems.filter(
                    (item) => item.value !== value
                ));
            }

            // make sure not already in the list
            if (this.selectedItems.find((item) => item.value === value)) {
                return;
            }
            this.selectedItems.push({ checked: e, value: value });
        },
    },
    watch: {
        filteredSearchable(updated) {
            this.$emit("searchable-filtered", updated);
        },
        show(dropdownExpanded) {
            this.$nextTick(() => {
                dropdownExpanded
                    ? this.$refs.selectSearch.focus()
                    : this.$refs.selectSearch.blur();
            });
        },
        selectedItemsCount(updated, previous) {
            this.selectionsRemoved = previous >= 1 && updated === 0;
        },
    },
    mounted() {
        this.$emit("searchable-filtered", this.searchable);
    },
};
</script>

<style scoped>
.select-button:active {
    @apply shadow-none top-0;
}

.select-search {
    @apply rounded-full px-4 pl-10;
    @apply bg-gray-50 text-gray-800 border-gray-200 placeholder-gray-400;
    @apply focus-visible:ring focus-visible:ring-blue-300 focus-visible:ring-opacity-50;
}
</style>
