<template>
    <div class="w-full" :class="size">
        <h4
            v-if="label"
            class="text-xs uppercase font-medium tracking-tight mb-1"
            v-text="labelText"
        />
        <div
            class="flex sm:flex-col md:flex-row sm:items-start md:items-center w-full"
        >
            <div class="container-bar relative w-full rounded-full bg-blue-200">
                <div
                    class="fill-bar absolute top-0 left-0 rounded-full transition-all duration-200"
                    :class="{
                        'bg-blue-600': !complete,
                        'bg-green-500': complete,
                    }"
                    :style="'width:' + percent + '%'"
                ></div>
            </div>
            <div
                v-if="!complete"
                class="text-xs font-medium uppercase sm:mt-2 md:mt-0 ml-2 sm:ml-0 md:ml-2"
            >
                <div>{{ percent }}%</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        complete: Boolean,
        label: String,
        percent: [Number, String],
        size: {
            type: String,
            default: "md",
        },
    },
    computed: {
        labelText() {
            return this.complete ? "Complete" : this.label;
        },
    },
};
</script>

<style scoped>
.sm .container-bar {
    @apply h-2;
}
.sm .fill-bar {
    @apply h-2;
    min-width: 0.5rem;
}
.md .container-bar {
    @apply h-3;
}
.md .fill-bar {
    @apply h-3;
    min-width: 0.75rem;
}
.lg .container-bar {
    @apply h-4;
}
.lg .fill-bar {
    @apply h-4;
    min-width: 1rem;
}
.xl .container-bar {
    @apply h-6;
}
.xl .fill-bar {
    @apply h-6;
    min-width: 1.5rem;
}
</style>
