<template>
    <div class="relative">
        <a
            class="nav-parent flex flex-col items-center no-underline hover:no-underline whitespace-nowrap my-4"
            :class="{
                active: active,
            }"
            :href="path"
            @click="toggle"
            v-click-away="close"
        >
            <div
                class="nav-parent-icon flex rounded p-3"
                :class="active ? colors : ''"
            >
                <SvgIcon class="w-7 h-7" :icon="icon" />
            </div>
            <div class="nav-parent-label text-xs tracking-tight mt-1">
                {{ label }}
            </div>
        </a>
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-class="opacity-0 translate-x-3"
            enter-to-class="opacity-100 translate-x-0"
            leave-active-class="transition ease-in duration-200"
            leave-class="opacity-100 translate-x-0"
            leave-to-class="opacity-0 translate-x-3"
        >
            <div
                v-if="opened"
                class="nav-parent-subnav absolute top-0 ml-22 rounded shadow transform"
                ref="children"
            >
                <slot />
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";

export default {
    props: {
        admin: Boolean,
        colors: String,
        icon: String,
        label: String,
        path: {
            type: String,
            default: "#",
        },
        noChildren: {
            type: Boolean,
            default: false,
        },
    },
    data(): any {
        return {
            opened: false,
        };
    },
    computed: {
        ...mapGetters(["appPath"]),
        active() {
            return this.appPath.indexOf(this.path) > -1;
        },
    },
    methods: {
        close() {
            this.opened = false;
        },
        open() {
            this.opened = true;
        },
        toggle(e) {
            if (this.noChildren) {
                return;
            }
            e.preventDefault();
            this.opened ? this.close() : this.open();
        },
    },
};
</script>
