<template>
    <div class="flex flex-col items-start">
        <div class="relative" v-click-away="hide">
            <button
                class="relative flex items-center justify-between h-12 px-4 py-3 rounded-md min-w-48h-10 max-h-10"
                :class="[
                    {
                        'ring ring-blue-300 ring-opacity-50': show,
                        shadow: variant === 'blue',
                    },
                    variantClasses.bgColorClass,
                    variantClasses.textColorClass,
                    variantClasses.fontWeightClass,
                    variantClasses.borderClass,
                    widthClass,
                ]"
                @click="show = !show"
            >
                <div class="flex items-center truncate">
                    <SvgIcon
                        v-if="typeof modelValue !== 'string' && modelValue.icon"
                        class="mr-3"
                        :icon="modelValue.icon"
                        weight="medium"
                        :color="variantClasses.textColorClass"
                    />
                    <span
                        v-if="contextString"
                        class="mr-2 capitalize"
                        :class="{ 'font-bold': contextStringIsBold }"
                        >{{ contextString }}:</span
                    >
                    <span class="capitalize truncate">{{
                        typeof modelValue !== "string"
                            ? modelValue.label
                            : modelValue
                    }}</span>
                </div>
                <div
                    class="flex items-center ml-6"
                    :class="[!show && variant !== 'blue' ? 'w-5' : 'w-3']"
                >
                    <SvgIcon
                        v-if="!show && variant === 'blue'"
                        icon="dropdown"
                        size="sm"
                        weight="extra-bold"
                        :color="variantClasses.textColorClass"
                    />
                    <SvgIcon
                        v-if="!show && variant !== 'blue'"
                        icon="arrow-up-down"
                        size="lg"
                        weight="medium"
                        color="text-gray-400"
                    />
                    <SvgIcon
                        v-if="show"
                        icon="close"
                        size="sm"
                        weight="extra-bold"
                        :color="variantClasses.textColorClass"
                    />
                </div>
            </button>
            <fade :show="show" :classes="fadeClasses">
                <div
                    class="flex flex-col rounded-md shadow"
                    :class="[
                        variantClasses.bgColorClass,
                        variantClasses.bgHoverColorClass,
                        variantClasses.textColorClass,
                    ]"
                >
                    <slot :variant="variantClasses" />
                </div>
            </fade>
        </div>
    </div>
</template>

<script lang="ts">
export const DropdownVariants = {
    white: {
        bgColorClass: "bg-white",
        bgHoverColorClass: "hover:bg-gray-50",
        textColorClass: "text-inherit",
        fontWeightClass: "font-normal",
        borderClass: "border border-gray-300",
    },
    period: {
        bgColorClass: "bg-white",
        bgHoverColorClass: "",
        textColorClass: "text-inherit",
        fontWeightClass: "font-normal",
        borderClass: "border border-gray-300",
    },
    blue: {
        bgColorClass: "bg-blue-600",
        bgHoverColorClass: "hover:bg-blue-700",
        textColorClass: "text-white",
        fontWeightClass: "font-semibold",
        borderClass: "",
    },
};

export default {
    props: {
        modelValue: {
            type: [Object, String],
            default() {
                return {};
            },
        },
        variant: {
            type: String,
            default: "white",
        },
        contextString: String,
        contextStringIsBold: Boolean,
        widthClass: String,
        fadeClasses: {
            type: String,
            default:
                "absolute top-0 left-0 mt-14 z-10 w-full overflow-y-auto max-h-72 drop-shadow-md rounded-md",
        },
    },
    data(): any {
        return {
            selected: null,
            show: false,
            variantClasses: DropdownVariants[this.variant],
        };
    },
    mounted() {
        this.selected = this.modelValue;
    },
    watch: {
        selected(value) {
            this.$emit("update:modelValue", value);
        },
        modelValue(value) {
            this.selected = value;
        },
        show(value) {
            this.$emit("show", value);
        },
    },
    methods: {
        hide() {
            this.show = false;
        },
    },
};
</script>
