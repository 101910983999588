<template>
    <div>
        <h1 class="text-2xl font-bold mb-8">Cancel your Subscription</h1>
        <BillingSection title="Current Subscription">
            <div
                class="space-y-2.5 bg-blue-50 p-6"
                v-if="subscription !== null"
            >
                <div class="plan-details flex flex-col">
                    <div class="font-bold text-xl mt-2">
                        {{ subscription.title }}
                    </div>
                    <div class="flex-grow my-2"></div>
                    <div class="text-lg">
                        ${{ formatPrice(subscription.price) }} per month
                    </div>
                    <p class="text-small tracking-tight my-2">
                        Your plan will be canceled, but is still available until
                        the end of your billing period on
                        <strong>{{
                            formatDate(subscription.current_period_end)
                        }}</strong
                        >.
                    </p>
                    <p class="text-small tracking-tight my-2">
                        If you change your mind, you can renew your
                        subscription.
                    </p>
                    <div
                        class="flex flex-col sm:flex-row items-center flex-1 gap-2 mt-6"
                    >
                        <Link
                            href="/account/billing"
                            intent="flat"
                            class="w-full sm:w-auto hover:bg-blue-100"
                        >
                            Go Back
                        </Link>
                        <button
                            class="w-full sm:w-auto text-center flex gap-2 items-center justify-center button rounded-md bg-red-700 text-white hover:bg-red-800 border-none"
                            :disabled="processing"
                            v-on:click="cancelPlan"
                        >
                            Cancel plan
                            <LoadingSpinner
                                color="light"
                                placement="button"
                                :show="processing"
                            />
                        </button>
                    </div>
                    <div class="mt-4">
                        <p class="text-xs tracking-tight">
                            By canceling your plan, you agree to Clover
                            Learning's
                            <strong
                                ><a
                                    href="https://cloverlearning.com/terms-of-service"
                                    target="_blank"
                                    >Terms of Service</a
                                ></strong
                            >
                            and
                            <strong
                                ><a
                                    href="https://cloverlearning.com/privacy"
                                    target="_blank"
                                    >Privacy Policy</a
                                ></strong
                            >.
                        </p>
                    </div>
                </div>
            </div>
        </BillingSection>
    </div>
</template>

<script lang="ts">
import { mapState } from "vuex";
import BillingSection from "./BillingSection.vue";
import {
    cancelSubscription,
    getSubscription,
    renewSubscription,
} from "../../../api/billing";
import { format, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import Link from "@/components/ui/ButtonLinks/Link.vue";

export default {
    components: { BillingSection, Link },
    props: {},
    data(): any {
        return {
            subscriptionId:
                // @ts-ignore
                window.location.pathname.match(/sub_[a-zA-Z0-9]+/)[0],
            subscription: null,
            processing: false,
        };
    },
    computed: {
        ...mapState(["user"]),
    },
    methods: {
        formatPrice(price) {
            return (price / 100).toFixed(2);
        },
        formatDate(date) {
            let addChar = date.charAt(date.length - 1) === "Z" ? "" : "Z";
            return format(
                utcToZonedTime(parseISO(date + addChar), this.user.timezone),
                "MMMM d, yyyy"
            );
        },
        cancelPlan() {
            this.processing = true;
            cancelSubscription(this.user.uuid, this.subscriptionId).then(
                (subscription) => {
                    this.processing = false;
                    // @ts-ignore
                    window.location = "/account/billing";
                }
            );
        },
    },
    mounted() {
        getSubscription(this.user.uuid, this.subscriptionId).then(
            (subscription) => {
                this.subscription = subscription;
            }
        );
    },
};
</script>
