<script setup lang="ts">
import ButtonDialog from "@/components/ui/dialogs/ButtonDialog.vue";
import Button from "@/components/ui/ButtonLinks/Button.vue";
import { DialogTitle } from "@headlessui/vue";
import { ref } from "vue";
import { adminApi } from "@/api/admin";
import { useQueryClient } from "@tanstack/vue-query";
const client = useQueryClient();

const props = defineProps<{
    questionBankId?: number;
    parentCategoryId?: number;
}>();

const catName = ref("");
const submit = async (e: Event, closeModal: Function) => {
    e.preventDefault();
    if (props.questionBankId && props.parentCategoryId) {
        await adminApi.questionBank.addCategoryToCategory(
            props.parentCategoryId,
            {
                category: catName.value,
            }
        );
        client.invalidateQueries(["bank"]);
    }
    if (props.questionBankId && !props.parentCategoryId) {
        await adminApi.questionBank.addCategoryToBank(props.questionBankId, {
            category: catName.value,
        });
        client.invalidateQueries(["bank"]);
    }
    closeModal();
};
</script>
<template>
    <ButtonDialog>
        <template v-slot:dialogTrigger="{ trigger }">
            <button
                @click="trigger"
                class="mx-4 my-2 text-sm text-left text-blue-400 hover:text-blue-200"
            >
                &plus; Add Category
            </button>
        </template>
        <template v-slot:content="{ closeModal }">
            <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
            >
                Add Category
                <p class="prose text-sm text-red-800">
                    There's a bug with this popup. You have to use your
                    keyboard. Type the word and press enter.
                </p>
            </DialogTitle>
            <form @submit="(e) => submit(e, closeModal)">
                <div class="mt-2">
                    <p class="text-sm text-gray-500">Add a new category</p>
                    <input
                        type="text"
                        class="mt-1 block w-full"
                        v-model="catName"
                    />
                </div>

                <div class="mt-4">
                    <Button type="'submit'" size="sm" intent="secondary">
                        Add
                    </Button>
                </div>
            </form>
        </template>
    </ButtonDialog>
</template>
