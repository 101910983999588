<template>
    <div>
        <div class="list-item justify-between">
            <div class="w-72 mr-8">
                <a class="cta" :href="link">
                    {{ row.name }}
                </a>
            </div>
            <div class="w-32 text-center mr-8">
                {{ rows.length }}
            </div>
            <div class="w-96 text-center mr-8">
                <div class="flex items-center">
                    <div
                        class="text-sm w-1/3"
                        v-text="$filters.relative(latestDate, user.timezone)"
                    />
                    <div class="w-1/3">
                        <span
                            v-html="
                                $filters.grade(
                                    latestScore,
                                    settings.pulse_grade
                                )
                            "
                        />
                    </div>
                    <div class="w-1/3">
                        {{ $filters.elapsed(latestDuration) }}
                    </div>
                </div>
            </div>
            <div class="w-64 text-center mr-8">
                <div class="flex items-center">
                    <div class="w-1/2">
                        <span
                            v-html="
                                $filters.grade(
                                    averageScore,
                                    settings.pulse_grade
                                )
                            "
                        />
                    </div>
                    <div class="w-1/2">
                        {{ $filters.elapsed(averageDuration) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import _ from "lodash";
import axios from "axios";
import { mapState, mapGetters } from "vuex";

export default {
    props: {
        row: {
            type: Object,
        },
    },
    data(): any {
        return {
            rows: [],
        };
    },
    computed: {
        ...mapState([
            "id",
            "period",
            "scope",
            "settings",
            "type",
            "typeId",
            "user",
        ]),
        ...mapGetters(["context"]),
        averageDuration() {
            if (this.rows.length > 0) {
                return _.meanBy(this.rows, (o: any) => o.time_elapsed);
            }
            return null;
        },
        averageScore() {
            if (this.rows.length > 0) {
                return _.meanBy(this.rows, (o: any) => o.grade);
            }
            return null;
        },
        latestDate() {
            if (this.rows.length > 0) {
                return this.rows[0].created_at;
            }
            return null;
        },
        latestDuration() {
            if (this.rows.length > 0) {
                return this.rows[0].time_elapsed;
            }
            return null;
        },
        latestScore() {
            if (this.rows.length > 0) {
                return this.rows[0].grade;
            }
            return null;
        },
        link() {
            return (
                "/pulse/explore/learner/" +
                this.period +
                "/" +
                this.row.uuid +
                "/" +
                this.type +
                "/" +
                this.typeId
            );
        },
    },
    methods: {
        getData() {
            axios
                .get(
                    "/api/pulse/assessment/learner/" +
                        this.period +
                        "/" +
                        this.row.uuid +
                        "/" +
                        this.type +
                        "/" +
                        this.typeId +
                        "/detail"
                )
                .then((response) => {
                    this.rows = response.data.rows;
                    this.loading = false;

                    this.$events.$emit("hydrateRow", {
                        uuid: this.row.uuid,
                        date: this.latestDate ? new Date(this.latestDate) : 0,
                        taken: this.rows.length,
                        latestScore: this.latestScore,
                        latestDuration: this.latestDuration,
                        averageScore: this.averageScore,
                        averageDuration: this.averageDuration,
                    });
                });
        },
    },
    mounted() {
        this.getData();
    },
};
</script>
