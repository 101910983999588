<template>
    <slot />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { mapState } from "vuex";

export default defineComponent({
    props: {
        action: String,
        actionPath: String,
        active: String,
        admin: Boolean,
        app: String,
        icon: String,
        learnerMode: Boolean,
        moduleTitle: String,
        resourceTitle: String,
        searchable: Array,
        searchAddition: String,
        searchClear: Boolean,
        section: String,
        sectionPath: String,
        showGlobalSearch: {
            type: Boolean,
            default: false,
        },
        showBilling: Boolean,
        theme: String,
        user: Object as PropType<any>,
        permissions: Object,
        settings: Object,
        websitePath: String,
        wide: Boolean,
        impersonating: Boolean,
        features: Object,
    },
    computed: {
        ...mapState(["pageSearch"]),
    },
    created() {
        this.user.permissions = this.permissions;
        this.user.settings = this.settings;
        this.user.learnerMode = this.learnerMode;
        // @ts-ignore
        this.$store.commit("registerState", {
            activePath: this.active,
            token: (document.querySelector('meta[name="token"]') as any)
                .content,
            user: this.user,
            websitePath: this.websitePath,
        });
    },
});
</script>
