<template>
    <button
        class="flex items-center justify-center rounded-full border w-12 h-12"
        :class="{
            'hover:bg-gray-100 border-gray-300': !flagged,
            'bg-red-50 hover:bg-red-100 text-red-600 border-red-300': flagged,
        }"
        type="button"
        v-tippy
        :content="label"
        @click="toggleFlag"
    >
        <SvgIcon
            v-if="flagged && status === 'ready'"
            type="fill line"
            icon="flag"
            size="base"
        />
        <SvgIcon
            v-if="!flagged && status === 'ready'"
            type="line"
            icon="flag"
            size="base"
        />
        <SvgIcon
            class="animate-ping"
            v-if="status === 'processing'"
            icon="flag"
            size="base"
        />
    </button>
</template>

<script lang="ts">
import axios from "axios";

export default {
    props: {
        isFlagged: Boolean,
        question: Number,
    },
    data(): any {
        return {
            status: "ready",
            flagged: false,
        };
    },
    computed: {
        label() {
            return this.flagged ? "Remove flag" : "Flag question";
        },
    },
    methods: {
        toggleFlag() {
            if (this.status !== "ready") {
                return;
            }
            this.status = "processing";
            axios
                .post("/api/prep/questions/flag", {
                    question: this.question,
                })
                .then((response) => {
                    this.flagged = response.data;
                    this.status = "ready";
                    this.$events.$emit("questionFlagged");
                });
        },
    },
    mounted() {
        this.flagged = this.isFlagged || this.flagged;
    },
};
</script>
