<template>
    <div
        class="relative hover:bg-gray-50 border-b border-gray-100 last:border-b-0 first:rounded-t-md last:rounded-b-md"
    >
        <a
            class="flex items-center justify-between w-full leading-tight p-4 hover:no-underline"
            :class="{
                'font-bold': active,
            }"
            :href="path"
        >
            <div class="flex items-center">
                <SvgIcon class="mr-2" :icon="icon" size="sm" />
                <span>{{ label }}</span>
            </div>
        </a>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        active: Boolean,
        icon: String,
        label: String,
        path: String,
    },
};
</script>
