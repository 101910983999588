<template>
    <span
        :class="
            parseFloat(score?.toString() ?? '') < 75
                ? 'text-red-650'
                : 'text-green-700'
        "
        class="font-medium underline"
    >
        {{ score }}%
    </span>
</template>

<script lang="ts">
export default {
    props: {
        score: Number,
    },
};
</script>
