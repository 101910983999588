<template>
    <div class="relative" v-click-away="hide">
        <button
            v-if="buttonText != ''"
            :class="
                buttonClass
                    ? buttonClass
                    : 'flex items-center justify-center hover:bg-yellow-50 rounded-lg w-9 h-9'
            "
            type="button"
            @click="show = !show"
        >
            <div v-text="buttonText"></div>
        </button>
        <button
            v-if="buttonText == ''"
            :class="
                buttonClass
                    ? buttonClass
                    : 'flex items-center justify-center hover:bg-yellow-50 rounded-lg w-9 h-9'
            "
            type="button"
            v-tippy
            content="Actions"
            @click="show = !show"
        >
            <SvgIcon class="text-gray-500" icon="dots" size="sm" />
        </button>
        <fade
            classes="absolute top-0 right-0 mt-10 z-10 min-w-full"
            :show="show"
        >
            <nav class="flex flex-col bg-white border rounded shadow-md">
                <slot />
            </nav>
        </fade>
    </div>
</template>

<script>
export default {
    props: {
        buttonText: {
            type: String,
            default: "",
        },
        buttonClass: String,
    },
    data: () => {
        return {
            show: false,
        };
    },
    methods: {
        hide() {
            this.show = false;
        },
    },
    mounted() {
        this.$events.$on("hide", (data) => {
            this.hide();
        });
    },
};
</script>
