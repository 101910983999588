<template>
    <div>
        <section class="section-head">
            <page-title
                app="Prep"
                section="Categories"
                sectionPath="/prep/categories"
                action="Create"
                icon="sort"
            />
        </section>
        <section class="section-main">
            <form action="/prep/categories" method="POST">
                <div class="card">
                    <div class="p-4">
                        <div class="field">
                            <label for="category_name">Category Name</label>
                            <input
                                id="category_name"
                                name="category"
                                type="text"
                                required
                                autofocus
                            />
                        </div>
                        <div class="field">
                            <label for="parent_id">Category Parent</label>
                            <select-dropdown required name="parent_id">
                                <option disabled>Select a Parent</option>
                                <option
                                    v-for="parent in parents"
                                    :value="parent.id"
                                >
                                    {{ parent.category }}
                                </option>
                            </select-dropdown>
                        </div>
                    </div>
                    <div class="bg-gray-100 shadow-inner rounded-b p-4">
                        <input type="hidden" name="_token" v-model="token" />
                        <button type="submit" class="button primary">
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </section>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        parents: {
            type: Array,
            default: () => {},
        },
    },
    data(): any {
        return {
            token: document
                .querySelector('meta[name="token"]')
                ?.getAttribute("content"),
        };
    },
};
</script>
