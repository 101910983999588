<template>
    <div>
        <PulseCourseEngagementCard
            :course="{
                asset: 'https://s3.amazonaws.com/files.cloverlearning.com/images/courses/rad-radiography-image-evaluation-and-quality-control.jpg',
                lessons: 21,
                modules: 3,
                title: 'Radiography Image Evaluation and Quality Control',
                uuid: '8c90f39a-fefb-4ecd-92d9-f247be1db382',
            }"
            :module="{
                title: 'Module 1: Introduction to Radiography',
                uuid: '8c90f39a-fefb-4ecd-92d9-f247be1db382',
            }"
            :viewingAs="type"
            class="mb-9"
        />

        <PulseLearnModuleActivity />
    </div>
</template>

<script lang="ts">
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState(["type"]),
    },
};
</script>
