<template>
    <div class="flex">
        <button
            v-if="!paused"
            type="button"
            v-tippy
            content="Pause"
            @click="pause"
        >
            <SvgIcon icon="pause" size="lg" weight="light" />
        </button>
        <button
            v-if="paused"
            type="button"
            v-tippy
            content="Resume"
            @click="resume"
        >
            <SvgIcon icon="play" size="lg" weight="light" />
        </button>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        paused: Boolean,
    },
    methods: {
        pause() {
            console.log("Pause");
            this.$emit("changed", true);
        },
        resume() {
            console.log("resume");
            this.$emit("changed", false);
        },
    },
};
</script>
