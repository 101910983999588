<template>
    <div
        role="checkbox"
        tabindex="0"
        aria-checked="false"
        class="relative inline-block flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus-visible:ring"
        :class="[
            look,
            {
                selected: selected,
            },
        ]"
        @click="selected = !selected"
    >
        <div
            aria-hidden="true"
            class="inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
            :class="{
                'translate-x-5': selected,
                'translate-x-0': !selected,
            }"
        />
    </div>
</template>

<script lang="ts">
export default {
    props: {
        look: {
            type: String,
            default: "default",
        },
        modelValue: {
            type: Boolean,
            default: false,
        },
    },
    data(): any {
        return {
            selected: false,
        };
    },
    mounted() {
        this.selected = this.modelValue;
    },
    watch: {
        selected(value) {
            this.$emit("update:modelValue", value);
        },
        modelValue(value) {
            this.selected = value;
        },
    },
};
</script>

<style scoped>
.default,
.flag,
.correct {
    @apply bg-gray-200;
}

.default.selected {
    @apply bg-blue-600;
}

.flag.selected {
    @apply bg-red-600;
}

.correct.selected {
    @apply bg-green-600;
}
</style>
