<template>
    <div>
        <section class="section-head">
            <page-title app="Pulse" section="Lookup" icon="telescope" />
        </section>
        <section class="section-title">
            <div>
                <div>
                    <label class="block font-bold uppercase text-xs mb-1">
                        Cohort
                    </label>
                    <select-dropdown class="shadow" v-model="cohort">
                        <option v-for="cohort in account.cohorts">
                            {{ cohort.name }}
                        </option>
                    </select-dropdown>
                </div>
            </div>
        </section>
        <section class="section-main"></section>
    </div>
</template>

<script lang="ts">
import axios from "axios";

export default {
    props: {
        account: {
            type: Object,
        },
    },
    data(): any {
        return {
            campus: null,
            cohort: null,
            id: null,
        };
    },
    methods: {
        getLearners() {
            axios.get("/api/lookup/cohort/" + this.cohort).then((response) => {
                this.learners = response.data;
            });
        },
    },
};
</script>
