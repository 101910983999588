<script lang="ts">
import ReportingTab from "./ReportingTab.vue";
import ReportingRouteMixin from "../ReportingRouteMixin.vue";

export default {
    components: { ReportingTab },
    mixins: [ReportingRouteMixin],
    props: {
        tabs: {
            type: Array,
            required: true,
        },
    },
    data(): any {
        return {};
    },
    methods: {
        isTabActive(tab) {
            if (tab.path.includes("activity")) {
                // return true if route contains activity
                return this.$route.path.includes("activity");
            } else {
                // return true if route does not contain activity
                return !this.$route.path.includes("activity");
            }
        },
    },
};
</script>
<template>
    <div class="hidden sm:block">
        <div class="border-b border-gray-200 mb-8 px-4">
            <div class="flex items-center justify-between">
                <div class="sm:flex sm:items-baseline">
                    <div class="mt-4 sm:mt-0">
                        <nav class="-mb-px flex space-x-8">
                            <ReportingTab
                                :to="createUrl(tab.path)"
                                :label="tab.name"
                                :active="isTabActive(tab)"
                                v-for="tab in tabs"
                                :key="tab.name"
                            />
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
