<template>
    <div class="relative">
        <div
            class="relative bg-white flex flex-wrap items-center rounded border p-2 z-20"
        >
            <div class="flex items-center mr-1" v-for="option in selected">
                <div
                    class="bg-blue-500 text-white py-1 px-2 rounded-l text-sm leading-5 font-medium"
                >
                    <div>{{ option.label }}</div>
                </div>
                <button
                    class="bg-blue-600 text-white p-2 rounded-r hover:bg-blue-700"
                    @click="remove(option)"
                >
                    <svg-icon icon="close" size="xs" weight="bold" />
                </button>
            </div>
            <input
                class="search"
                type="text"
                v-model="search"
                :keyup.down="next"
                @keyup.up="prev"
            />
        </div>
        <div
            class="absolute bg-gray-100 shadow rounded-b w-full overflow-y-scroll z-10 p-4"
            v-if="filtered.length > 0"
            style="max-height: 216px"
        >
            <button
                class="option block w-full text-left p-1"
                v-for="option in filtered"
                @click="select(option)"
            >
                <div>{{ option.label }}</div>
                <div
                    class="text-sm font-medium"
                    v-if="option.hint"
                    v-text="option.hint"
                ></div>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        modelValue: {
            type: Array,
            default: () => [],
        },
    },
    data(): any {
        return {
            selected: [],
            search: "",
        };
    },
    computed: {
        filtered() {
            if (this.search.length > 0) {
                return this.unselected.filter(
                    (option) =>
                        option.label
                            .toLowerCase()
                            .indexOf(this.search.toLowerCase()) > -1
                );
            }
            return [];
        },
        selectedIds() {
            return Object.keys(this.selected).map(
                (f) => this.selected[f].value
            );
        },
        unselected() {
            return this.options.filter(
                (option) => this.selectedIds.indexOf(option.value) == -1
            );
        },
    },
    methods: {
        next() {
            alert("next");
        },
        prev() {
            alert("prev");
        },
        remove(option) {
            this.selected.splice(this.selected.indexOf(option), 1);
        },
        select(option) {
            this.selected.push(option);
            this.search = "";
        },
    },
    mounted() {
        this.selected = this.modelValue;
    },
    watch: {
        selected(value) {
            this.$emit("update:modelValue", value);
        },
    },
};
</script>

<style scoped>
input.search {
    @apply bg-white w-auto flex-grow shadow-none p-2 outline-none !important;
}

.option:hover,
.option:focus {
    @apply bg-blue-600 text-blue-100;
}
</style>
