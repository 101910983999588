import { defineStore, storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { getSubscriptions } from "@/api/billing";
import { User } from "@/types/user";
import { analytics } from "@/services/segment.ts";
import { getHours } from "date-fns";
import { RemovableRef, useLocalStorage } from "@vueuse/core";

const getLoginStatus = async () => {
    const response = await fetch(`/api/auth-status`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: "include",
    });
    return await response.json();
};

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const useUserStore = defineStore("user", () => {
    const user = ref<{ user: User } | null>(null);
    const loaded = ref(false);
    const subscriptions = ref<any[]>([]);
    const lastIdentifiedAt: RemovableRef<null | number> = useLocalStorage("lastIdentifiedAt", null);
    const userObj = computed<User | null | undefined>(() => {
        return user.value?.user;
    });

    let getUserPromise: Promise<any> | null = null;

    const init = () => {
        getUserPromise = getLoginStatus().then((response) => {
            user.value = response;
            loaded.value = true;

            if (!lastIdentifiedAt.value || getHours(Date.now()) - getHours(lastIdentifiedAt.value) > 1) {
                lastIdentifiedAt.value = Date.now();
                analytics.identify(response.user.uuid);
            }

            return response;
        });
        return getUserPromise;
    };

    const getUsersSubscriptions = async () => {
        if (getUserPromise) {
            await getUserPromise;
        }

        subscriptions.value = await getSubscriptions(user.value?.user?.uuid);
    };

    const isInTrial = computed(() => {
        return user.value?.user?.is_in_trial === true;
    });

    init();

    return {
        user,
        userObj,
        init,
        getUsersSubscriptions,
        subscriptions,
        isInTrial,
        loaded,
    };
});

export function useUser() {
    const userStore = useUserStore();
    const { userObj: user, loaded } = storeToRefs(userStore);
    console.log(`user: `, user);

    return { user, loaded: loaded };
}
