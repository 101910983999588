<script lang="ts">
export default {
    methods: {
        createUrl(path) {
            // use vue router v3 to get current query params
            const currentQuery = this.$route.query;
            // create a new query object
            return { path: path, query: currentQuery };
        },
    },
};
</script>
