<template>
    <div class="h-full">
        <template v-if="subject != 'admin'">
            <section class="section-main relative p-4 sm:p-8">
                <div class="container">
                    <div class="reporting-overview">
                        <section
                            class="section-title flex flex-wrap justify-between"
                        >
                            <div
                                class="flex items-center flex-wrap justify-start"
                            >
                                <PulseExploreReportSubject />
                                <PulseExploreReportType />
                            </div>
                            <PulseExploreReportPeriod />
                        </section>
                        <section class="section-main">
                            <template
                                v-if="
                                    ['history', 'overview', 'prep'].indexOf(
                                        viewContext
                                    ) == -1
                                "
                            >
                                <div
                                    class="bg-white rounded-md shadow p-6 mb-8"
                                >
                                    <DataChart />
                                </div>
                            </template>
                            <PulseOverview v-if="viewContext == 'overview'" />
                            <PulseExploreDetails
                                v-if="viewContext == 'lessons'"
                                api="lesson-views"
                            />
                            <PulseLesson v-if="viewContext == 'lesson'" />
                            <PulseHistory v-if="viewContext == 'history'" />
                            <!-- <PulseAssessments v-if="viewContext == 'assessments'"/> -->
                            <PulseExploreDetails
                                v-if="viewContext == 'assessments'"
                                api="assessment"
                            />
                            <PulseAssessment
                                v-if="viewContext == 'assessment'"
                            />
                            <PulseAssessmentInstance
                                v-if="viewContext == 'assessmentInstance'"
                            />
                            <PulsePrep v-if="viewContext == 'prep'" />
                        </section>
                    </div>
                </div>
            </section>
        </template>
        <template v-if="subject == 'admin'">
            <Placeholder label="Select an  organization" />
        </template>
    </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import $events from "../../../event-bus";

export default {
    props: {
        crumbs: {
            type: Array,
        },
        settings: {
            type: Object,
        },
        instanceId: {
            type: String,
            default: null,
        },
        period: {
            type: String,
        },
        subject: {
            type: String,
        },
        subjectId: {
            type: String,
        },
        type: {
            type: String,
            default: null,
        },
        typeId: {
            type: String,
            default: null,
        },
    },
    computed: {
        ...mapGetters(["viewContext"]),
    },
    created() {
        // @ts-ignore
        this.$store.commit("registerState", {
            crumbs: this.crumbs,
            instanceId: this.instanceId,
            period: this.period,
            subject: this.subject,
            subjectId: this.subjectId,
            settings: this.settings,
            type: this.type,
            typeId: this.typeId,
        });
    },
    mounted() {
        if (this.subject == "admin") {
            $events.$emit("focusSearch", {
                type: "Organization",
            });
        }
    },
};
</script>
