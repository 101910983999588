<template>
    <div>
        <slot v-bind="{ openProductEnrollmentsListModal }"></slot>
        <BaseModal
            :heading="`${product} Enrollments`"
            :opened="isOpen"
            @close="close"
        >
            <template v-slot:body>
                <div class="-m-6">
                    <div
                        v-for="course in courses"
                        class="flex items-center justify-between even:bg-gray-50 text-sm"
                    >
                        <div class="p-6">
                            {{ course.title }}
                        </div>
                        <div class="font-mono p-6">
                            {{ course.expires_at }}
                        </div>
                    </div>
                </div>
            </template>
        </BaseModal>
    </div>
</template>

<script lang="ts">
import BaseModal from "./BaseModal.vue";

export default {
    props: {
        courses: {
            type: Array,
            default: [],
        },
        product: String,
    },
    data(): any {
        return {
            isOpen: false,
        };
    },
    methods: {
        open() {
            this.isOpen = true;
        },
        close() {
            this.isOpen = false;
        },
        openProductEnrollmentsListModal() {
            this.open();
        },
    },

    components: {
        BaseModal,
    },
};
</script>
