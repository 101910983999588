<template>
    <div
        class="flex items-center justify-center bg-white rounded-md shadow h-64"
    >
        <div
            class="flex flex-col justify-center items-center space-y-4 py-8 px-16 text-center"
        >
            <div class="py-4">
                <SvgIcon
                    class="text-gray-200"
                    icon="resource-interactive_images"
                    size="xl3"
                    weight="light"
                />
            </div>
            <button
                type="button"
                class="main button"
                @click="openArticulateModal"
            >
                <slot />
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        bundle: String,
    },
    methods: {
        openArticulateModal() {
            // @ts-ignore
            this.$events.$emit("openModal", {
                interactiveImage: `https://s3.amazonaws.com/files.cloverlearning.com/articulate/${this.bundle}`,
            });
        },
    },
});
</script>
