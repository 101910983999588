<template>
    <div class="w-full">
        <div class="sm:hidden mb-4 flex items-cener gap-2">
            <h3
                class="text-xl leading-12 font-medium text-gray-900 sm:mr-10"
                v-if="label"
                v-text="label"
            />
            <label for="tabs" class="sr-only">Select a tab</label>
            <select
                name="tabs"
                class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus-visible:ring-blue-400 focus:border-blue-400 sm:text-sm rounded-md"
                @change="(e) => onTabChange(e)"
            >
                <option
                    v-for="tab in tabs"
                    :key="tab.name"
                    :selected="active === tab.name"
                    :value="tab.name"
                >
                    {{ getTabLabel(tab) }}
                </option>
            </select>
        </div>
        <div class="hidden sm:block">
            <div class="border-b border-gray-200 mb-8 px-4">
                <div class="flex items-center justify-between">
                    <div class="sm:flex sm:items-baseline">
                        <h3
                            class="text-xl leading-6 font-medium text-gray-900 sm:mr-10"
                            v-if="label"
                            v-text="label"
                        />
                        <div class="mt-4 sm:mt-0">
                            <nav class="-mb-px flex space-x-8">
                                <button
                                    v-for="tab in tabs"
                                    type="button"
                                    class="whitespace-nowrap py-4 px-1 border-b-2 font-medium leading-4 capitalize text-sm"
                                    :class="{
                                        'border-blue-500 text-blue-600':
                                            active === tab.name,
                                        'border-transparent text-gray-600 hover:text-gray-700 hover:border-gray-300':
                                            active !== tab.name,
                                    }"
                                    @click="switchTab(tab.name)"
                                >
                                    <div class="flex items-center">
                                        <div>
                                            {{ getTabLabel(tab) }}
                                        </div>
                                        <div
                                            v-if="tab.count"
                                            class="hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
                                            :class="{
                                                'bg-blue-100 text-blue-600':
                                                    active === tab.name,
                                                'bg-gray-200 text-gray-800':
                                                    active !== tab.name,
                                            }"
                                            v-text="tab.count"
                                        />
                                    </div>
                                </button>
                            </nav>
                        </div>
                    </div>
                    <div v-if="typeof action != 'undefined'">
                        <a
                            class="flex items-center space-x-2 small main button"
                            :href="action.link"
                        >
                            <SvgIcon
                                :icon="action.icon"
                                size="xxs"
                                weight="extra-bold"
                            />
                            <div>
                                {{ action.label }}
                            </div>
                        </a>
                    </div>
                    <div v-for="tab in tabs" v-show="active === tab.name">
                        <template v-if="typeof tab['action'] != 'undefined'">
                            <a
                                class="flex items-center space-x-2 small main button"
                                :href="tab.action.link"
                            >
                                <SvgIcon
                                    v-if="typeof tab.action.icon != 'undefined'"
                                    :icon="tab.action.icon"
                                    size="xxs"
                                    weight="extra-bold"
                                />
                                <div>
                                    {{ tab.action.label }}
                                </div>
                            </a>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div v-for="tab in tabs" v-show="active === tab.name">
            <slot :name="tab.name" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        action: Object,
        initial: String,
        label: String,
        tabs: Array as PropType<any[]>,
    },
    data(): any {
        return {
            active: this.tabs?.[0].name,
        };
    },
    methods: {
        onTabChange(event: any) {
            this.active = event.target?.value;
        },
        getTabLabel(tab) {
            return typeof tab.label != "undefined" ? tab.label : tab.name;
        },
        pushState() {
            let url = new URL(window.location.href);
            url.searchParams.delete("sort");
            url.searchParams.delete("direction");
            url.searchParams.set("tab", this.active);
            window.history.pushState("", "", url);
        },
        switchTab(tab) {
            this.active = tab;
            this.$events.$emit("tabChanged", tab);
            this.pushState();
        },
    },
    mounted() {
        let self = this;
        window.onpopstate = function (event) {
            let tab = new URLSearchParams(window.location.search).get("tab");
            if (tab) {
                self.active = tab;
            }
        };

        if (this.initial) {
            this.active = this.initial;
        }
    },
});
</script>
