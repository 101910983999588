import ListItem from "@tiptap/extension-list-item";

// 2. Overwrite the keyboard shortcuts
export const CustomListItem = ListItem.extend({
    renderHTML(props) {
        return [
            "li",
            {
                class: "pl-2",
                ...props.HTMLAttributes,
            },
            0,
        ];
    },
});
