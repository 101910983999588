<template>
    <div class="h-full">
        <section class="mb-8 section-title">
            <PulseSubjectHeader />
        </section>

        <section class="flex flex-wrap justify-end mb-6 section-title">
            <!--            <PulseContextToggle />-->
            <PulseReportPeriod />
        </section>

        <section class="relative section-main">
            <slot />
        </section>
        <slot name="placeholder" />
    </div>
</template>

<script lang="ts">
import $events from "../../../event-bus";

export default {
    props: {
        course: {
            type: String,
            default: null,
        },
        instanceId: {
            type: String,
            default: null,
        },
        period: String,
        settings: Object,
        partner: String,
        group: String,
        learners: Array,
        subjectTree: Array,
        tab: String,
        type: {
            type: String,
            default: null,
        },
        typeId: {
            type: String,
            default: null,
        },
        view: String,
    },
    created() {
        // @ts-ignore
        this.$store.commit("registerState", {
            period: this.period,
            partner: this.partner,
            group: this.group,
            course: this.course,
            subjectTree: this.subjectTree,
            learners: this.learners,
            settings: this.settings,
            type: this.type,
            typeId: this.typeId,
            tab: this.tab,
            view: this.view,
        });
    },
    mounted() {
        $events.$on("triggerPulseRefresh", () => {
            $events.$emit("pulseTableFiltered");
        });
    },
};
</script>
