<template>
    <button
        type="button"
        class="flex items-center justify-center bg-white rounded-lg shadow w-9 h-9"
        v-tippy
        content="Provide feedback"
        @click="open"
    >
        <SvgIcon class="text-gray-600" icon="contact" size="sm" />
        <span class="sr-only">Provide feedback</span>
    </button>
    <LessonFeedbackModal
        :init-timestamp="timestamp"
        :active="showModal"
        :lesson-name="subject"
        :lesson-id="lessonId"
        :module-id="moduleId"
        :course-id="courseId"
        @close="showModal = false"
    />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import LessonFeedbackModal from "./_elements/modals/LessonFeedbackModal.vue";
import { useVideoStore } from "@/stores/videoStore";
import { storeToRefs } from "pinia";
import { string } from "zod";

export default defineComponent({
    props: {
        subject: String,
        lessonId: String,
        moduleId: String,
        courseId: String,
    },
    setup: (props) => {
        const showModal = ref(false);
        const store = useVideoStore();
        const { timestamp } = storeToRefs(store);

        return {
            showModal,
            timestamp,
            open() {
                showModal.value = true;
            },
        };
    },
    components: { LessonFeedbackModal },
});
</script>
