<template>
    <dropdown
        :modelValue="selected"
        variant="white"
        context-string="Type"
        width-class="w-60"
    >
        <dropdown-item variant="white" @click.native="handleClick('all')">
            <span
                class="text-sm capitalize"
                :class="{ 'font-bold': isActive('all') }"
                >All</span
            >
        </dropdown-item>

        <dropdown-item
            v-for="(value, label) in values"
            :key="value"
            variant="white"
            @click.native="handleClick(value)"
        >
            <span
                class="text-sm capitalize"
                :class="{ 'font-bold': isActive(value) }"
            >
                {{ label }}
            </span>
        </dropdown-item>
    </dropdown>
</template>

<script lang="ts">
export default {
    data(): any {
        return {
            values: {
                assessment: "Assessment",
                lesson: "Lesson",
                quiz: "Quiz",
            },
            selected: "all",
        };
    },
    methods: {
        isActive(value) {
            return value === this.selected;
        },
        handleClick(value) {
            if (value === "all") {
                this.selected = "all";
            } else {
                this.selected = value;
            }
            let url = new URL(window.location.href);
            url.searchParams.set("event_type", value);
            window.history.replaceState({}, "", url);

            this.$events.$emit("pulseTableFiltered");
        },
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const eventType = urlParams.get("event_type");
        this.seleted = eventType ?? "all";
    },
};
</script>
