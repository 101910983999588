<template>
    <SearchableSelectDropdown
        selectable
        selectableLabel="Learners"
        icon="learner-square"
        :searchable="learnerNames"
        selected-label-prop="full_name"
        @save="saveSelection"
        @reset="resetSelection"
        @searchable-filtered="updateLearners"
    >
        <template v-slot:default="{ selectable, onSelected, hasSelections }">
            <SearchableSelectDropdownItem
                v-for="learner in learners"
                v-show="filteredLearners.includes(learner.full_name)"
                :image="learner.asset"
                :key="learner.id"
                :dropdown-has-selections="hasSelections"
                :checked-on-init="isSelected(learner)"
                :selectable="selectable"
                @change="onSelected($event, learner)"
                :aria-label="learner.full_name"
            >
                {{ learner.full_name }}
            </SearchableSelectDropdownItem>
        </template>
    </SearchableSelectDropdown>
</template>

<script lang="ts">
import { mapGetters, mapState } from "vuex";
import { getGroupLearners } from "../../../../api";

export default {
    data(): any {
        return {
            filteredLearners: [],
        };
    },
    computed: {
        ...mapState(["subject", "learners", "selectedLearners"]),
        ...mapGetters(["groupUuid"]),
        learnerNames() {
            return this.learners?.map((learner) => learner.full_name) || [];
        },
        selectedLabel() {
            return "TESTING";
        },
    },
    watch: {
        groupUuid: {
            handler(val, old) {
                console.log(val, old);
                if (val) {
                    getGroupLearners(val).then((learners) => {
                        this.$store.commit("registerState", {
                            learners: learners,
                        });
                    });
                }
            },
            immediate: true,
        },
    },
    methods: {
        updateLearners(data) {
            this.filteredLearners = data;
        },
        isSelected(learner) {
            return this.selectedLearners.includes(learner.id);
        },
        saveSelection(learners) {
            // pluck id from selected learners
            // filter where checked is false
            let selectedLearners = learners.filter(
                (learner) => learner.checked
            );
            // pluck id from selected learners
            let selectedLearnersIds = selectedLearners.map(
                (learner) => learner.value.id
            );

            this.$store.commit("registerState", {
                selectedLearners: selectedLearnersIds,
            });
        },
        resetSelection() {
            this.$store.commit("registerState", {
                selectedLearners: [],
            });
        },
    },
    mounted() {},
};
</script>
