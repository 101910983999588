<template>
    <div class="flex items-center space-x-2 text-sm">
        <div v-if="modules" class="bg-yellow-50 px-2 leading-6">
            {{ modules }} {{ pluralizeLabel("module", modules) }}
        </div>
        <div v-if="lessons" class="bg-cyan-50 px-2 leading-6">
            {{ lessons }} {{ pluralizeLabel("lesson", lessons) }}
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { filters } from "../../../../../vue-filters";

export default defineComponent({
    props: {
        modules: {
            type: Number,
            required: false,
        },
        lessons: {
            type: Number,
            required: false,
        },
    },
    methods: {
        pluralizeLabel(label: string, count: number) {
            return filters.pluralizeLabel(label, count);
        },
    },
});
</script>
