<template>
    <div>
        <div ref="editor"></div>
    </div>
</template>
<script lang="ts">
import Quill from "quill";
import "quill/dist/quill.snow.css";

export default {
    props: {
        value: {
            type: Object,
            default: "",
        },
    },
    data(): any {
        return {
            editor: null,
        };
    },
    methods: {
        storeContent() {
            this.$store.commit("registerState", {
                admin: { lessonContent: this.editor.getContents() },
            });
        },
    },
    watch: {
        value: function (val) {
            this.editor.setContents(val.ops);
        },
    },
    mounted() {
        this.editor = new Quill(this.$refs.editor, {
            modules: {
                toolbar: [
                    ["bold", "italic", "underline", "strike"], // toggled buttons
                    ["blockquote", "code-block"],
                    [{ header: 1 }, { header: 2 }], // custom button values
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ script: "sub" }, { script: "super" }], // superscript/subscript
                    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                    [{ direction: "rtl" }], // right to left
                    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                    [{ font: [] }],
                    [{ size: [] }], // custom dropdown
                    [{ header: [] }],
                    [{ align: [] }],
                    ["clean"],
                ],
            },
            theme: "snow",
        });
        this.editor.on("text-change", this.storeContent);
    },
};
</script>
