<template>
    <tr class="group bg-white border-b border-gray-200 last:border-none">
        <td class="px-4 py-4 whitespace-nowrap group-last:first:rounded-bl-md">
            <div class="text-xs leading-6">
                <div v-text="$filters.dateTime(examTime, user.timezone)"/>
            </div>
        </td>
        <td class="px-4 py-4">
            <h2 class="leading-6">
                <a
                    v-if="!examInactive"
                    class="cta"
                    :href="'/prep/exams/' + exam.uuid">
                    {{ exam.name || 'Unnamed' }}
                </a>
                <div v-else>
                    {{ exam.name || 'Unnamed' }}
                </div>
            </h2>
        </td>
        <td class="p-4 py-4">
            <PrepCategoryCircles
                class="justify-start"
                :categories="exam.categoryList"
                options="stacked"
            />
        </td>
        <td class="border-gray-100 text-sm p-4 py-4 text-center">
            {{ exam.questions_total }}
        </td>
        <template v-if="examInactive">
            <td class="text-sm font-medium leading-6 px-4 py-4 text-center">
                <div v-html="$filters.grade(examScore, user.settings.pulse_grade)"/>
            </td>
            <td class="text-sm px-4 py-4 text-center group-last:last:rounded-br-md">
                <div v-html="$filters.elapsed(examDuration)"/>
            </td>
        </template>
        <template v-else>
            <td class="px-4 py-4 text-center">
                <div class="flex-grow text-sm font-medium uppercase">
                    <ProgressBar
                        :percent="exam.progress"
                        size="md"
                    />
                </div>
            </td>
            <td class="p-4 py-4 text-center group-last:last:rounded-br-md">
                <ActionMenu>
                    <ActionMenuAction
                        v-if="exam.status !== 'archived'"
                        type="button"
                        label="Archive"
                        icon="archive"
                        iconColor="text-blue-200"
                        :action="archive"
                    />
                    <ActionMenuAction
                        v-if="exam.status === 'archived'"
                        type="button"
                        label="Restore"
                        icon="restore"
                        iconColor="text-blue-200"
                        :action="restore"
                    />
                </ActionMenu>
            </td>
        </template>
    </tr>
</template>

<script lang="ts">
import axios from "axios";
import { differenceInSeconds } from "date-fns";
import { mapState } from "vuex";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        exam: {
            type: Object,
            default: () => {},
        },
    },
    data(): any {
        return {
            labels: {
                learner: "bg-blue-50 text-blue-700",
                educator: "bg-red-50 text-red-700",
                clover: "bg-pear-100 text-pear-700",
            },
        };
    },
    computed: {
        ...mapState(["user"]),
        examInactive() {
            return ["completed", "expired"].includes(this.exam.status);
        },
        examDuration() {
            return differenceInSeconds(
                new Date(this.exam.time_completed),
                new Date(this.exam.time_started)
            );
        },
        examScore() {
            return (
                (this.exam.questions_correct / this.exam.questions_total) * 100
            );
        },
        examTime() {
            return this.exam.time_completed
                ? this.exam.time_completed
                : this.exam.time_started;
        },
        examOwner() {
            return this.exam.blueprint ? this.exam.blueprint.owner : "learner";
        },
        viewable() {
            return this.exam.blueprint ? !this.exam.blueprint.assignment : true;
        },
    },
    methods: {
        archive() {
            axios
                .post("/api/prep/exams/" + this.exam.uuid + "/archive")
                .then(() => window.location.reload());
        },
        restore() {
            axios
                .post("/api/prep/exams/" + this.exam.uuid + "/restore")
                .then(() => window.location.reload());
        },
    },
});
</script>
