<template>
    <svg
        viewBox="0 0 1440 274"
        fill="none"
        class="w-full"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g style="mix-blend-mode: multiply">
            <path
                d="M709.731 98.1806C1211.79 -27.7471 1403.49 156.301 1702.44 177.289C1710.81 200.968 1774.66 75.2554 1787.44 138.542C1803.41 217.651 1673.33 274.157 1586.61 379.096C1499.89 484.036 1438.28 403.313 1168.99 450.133C899.702 496.952 215.075 543.771 -49.6481 556.687C-314.371 569.602 -723.423 172.446 -486.085 207.964C-248.748 243.482 339.512 191.039 709.731 98.1806Z"
                fill="#FEF5DE"
            />
        </g>
        <g style="mix-blend-mode: multiply">
            <path
                d="M251.03 52.9757C-41.0777 -9.01952 -314.168 19.072 -390.238 44.9034C-464.786 57.2809 -611.043 145.646 -656.685 206.349C-713.737 282.229 -681.788 409.771 -631.582 588.976C-581.376 768.181 -348.603 660.012 -232.216 669.699C-115.829 679.385 676.057 647.096 876.881 650.325C1077.7 653.554 1584.33 571.217 1598.02 569.602C1611.71 567.988 1917.51 262.855 1901.54 85.265C1885.57 -92.3254 1577 -82.0659 1300.79 72.3493C832.964 333.892 616.165 130.47 251.03 52.9757Z"
                fill="#D5E4F9"
            />
        </g>
        <g style="mix-blend-mode: multiply">
            <path
                d="M1106.81 172.446C835.246 61.048 829 -128 -223.645 148.229C-223.645 209.578 -239.062 234.764 -239.062 277.385C-239.062 330.662 -77.0333 408.156 0.557751 438.831C78.1488 469.506 347.436 543.771 472.951 550.229C598.466 556.687 963.6 564.759 1317.32 576.06C1671.05 587.361 1698.43 547 1970 475.964C2241.57 404.928 2049.88 188.59 1842.21 224.108C1634.54 259.626 1302.85 252.859 1106.81 172.446Z"
                fill="#FEDFDF"
            />
        </g>
    </svg>
</template>
