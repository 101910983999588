<template>
    <input
        v-on:input="$emit('row-selected')"
        :checked="value"
        class="mr-2 text-indigo-500"
        type="checkbox"
    />
</template>

<script lang="ts">
export default {
    props: {
        value: {
            type: Boolean,
            required: true,
            default: false,
        },
    },
};
</script>
