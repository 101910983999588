<template>
    <div class="w-56 h-4 px-1 mx-2 my-auto bg-gray-200 rounded-md">
        <div
            :class="[percent < 100 ? 'bg-navy-700' : 'bg-emerald-500']"
            class="h-2 my-1 rounded-md"
            :style="`width: ${percent}%`"
        ></div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        percent: {
            type: Number,
            required: true,
        },
    },
};
</script>
