import events from "@/event-bus";

export type Toast = {
    status: "success" | "error" | "warning" | "info";
    heading: string;
    message?: string;
};

export function toast(toast: Toast) {
    events.$emit("toastNotification", toast);
}
