<template>
  <div class="flex flex-col gap-6" v-if="module">
    <div class="flex flex-col gap-2">
      <!-- Breadcrumbs -->
      <div class="text-sm text-gray-500 mb-4">
        <router-link to="/content/learn/modules">Courses</router-link>
        > {{ module.title }}
      </div>
      <div
          class="flex flex-col md:flex-row md:items-center md:justify-between gap-3 md:gap-0"
      >
        <div class="flex flex-col flex-wrap">
          <h2
              class="text-2xl font-semibold leading-none tracking-tight"
          >
            {{ module.title }}
          </h2>
          <div class="flex flex-wrap items-center gap-2 mt-1">
            <div class="flex items-center">
              <p class="inline-flex text-xs text-gray-600 italic">
                {{ module.slug }}
              </p>
              <CopyAction
                  :value="module.slug"
                  :label="`${module.slug}`"
              />
            </div>
            <div class="text-xs flex flex-row items-center">
              <span class="font-semibold">ID</span>
              <CopyAction
                  :value="module.id"
                  :label="`${module.id}`"
              />
            </div>
            <div class="text-xs flex flex-row items-center">
              <span class="font-semibold">UUID</span>
              <CopyAction
                  :value="module.uuid"
                  :label="`${module.uuid}`"
              />
            </div>
          </div>
        </div>

        <div class="flex items-center gap-4 flex-start flex-wrap">
          <Button size="sm" @click="saveModule">Save</Button>
        </div>
      </div>
    </div>

    <div class="w-full px-2 my-2 sm:px-0">
      <div class="bg-white shadow rounded relative z-0">
        <div class="flex flex-col p-6 space-y-6">

          <div class="flex flex-col md:flex-row gap-4">
            <div class="flex-1">
              <div class="flex flex-col gap-1">
                <label
                    for="title"
                    class="block text-sm font-medium text-gray-700"
                >
                  Title
                </label>
                <input
                    type="text"
                    v-model="module.title"
                    class="w-full border  rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    :class="[errors?.title ? '!border-red-500' : 'border-gray-300']"
                />
                <span
                    v-if="errors?.title"
                    class="text-sm text-red-500">{{ errors.title }}</span>
              </div>
            </div>
            <div class="flex-1">
              <div class="flex flex-col gap-1">
                <label
                    for="slug"
                    class="block text-sm font-medium text-gray-700"
                >
                  Slug
                </label>
                <input
                    type="text"
                    v-model="module.slug"
                    class="w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    :class="[errors?.slug ? '!border-red-500' : 'border-gray-300']"
                />
                <span
                    v-if="errors?.slug"
                    class="text-sm text-red-500">{{ errors.slug }}</span>
              </div>
            </div>
          </div>
          <div class="flex-1">
            <div class="flex flex-col gap-1">
              <label
                  for="ce_credit_hours"
                  class="block text-sm font-medium text-gray-700"
              >
                Post-Module Exam
              </label>
              <Combobox
                  v-if="exams"
                  :options="exams.map((e) => ({
                                                value: e.id,
                                                label: e.name
                                            }))"
                  class=""
                  input-classes="border-gray-300"
                  v-model="selectedExam"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import { Course } from "@/types";
import Button from "@/components/ui/ButtonLinks/Button.vue";
import Combobox from "@/components/ui/forms/Combobox.vue";
import {useRoute} from "vue-router";
import axios from "axios";
import $events from "@/event-bus.ts";

const module = ref<Partial<Course> | null>(null);
const route = useRoute();
const exams = ref([]);
const errors = ref([]);
const selectedExam = ref();

async function getExams() {
  axios.get(`/api/admin/content/learn/exams`).then((response) => {
    if (response.status === 200) {
      const resExams = response.data.flat();
      exams.value = resExams;
      // If course doesn't have an exam, pop() will not return anything
      selectedExam.value = resExams.filter((exam) => {
        return exam.module_id === module.value.id;
      }).pop()?.id;
    }
  }).catch(e => console.error(e));
}

async function saveExam(exam) {
  await axios.post(
      `/api/admin/content/learn/modules/${module.value.id}/exams/${exam.uuid}`,
      { module_id: module.value.id }
  )
      .then((response) => {
        if (response.status === 200) {
          $events.$emit("toastNotification", {
            action: "saved",
            heading: "Post module exam saved successfully",
            status: "saved",
          });
        }
      })
      .catch((e) => {

        $events.$emit("toastNotification", {
          action: "error",
          heading: `Error saving post module exam: ${e}`,
          status: "error",
        });
      });
}

async function updateModule(module) {
  axios
      .put(`/api/admin/content/modules/${module.id}`, { ...module })
      .then((response) => {
        if (response.status < 400) {
          $events.$emit("toastNotification", {
            action: "saved",
            heading: "Module saved",
            status: "success",
          });
        }
      })
      .catch((e) => {
        const errs = Object.entries(e.response.data.errors)
        errors.value = Object.fromEntries(errs.map((e) => e.flat()));
        $events.$emit("toastNotification", {
          action: "saved",
          heading: "Error Saving Module",
          status: "error",
          message: e.response.data.message
        });
      });
}

onMounted(async () => {
  await axios.get(`/api/admin/content/learn/modules/${route.params.uuid}`)
      .then((res) => {
        module.value = res.data;
      })
      .catch(e => console.error(e));
  await getExams();
});

const saveModule = async () => {
  const fullExam = exams.value.filter(exam => exam.id == selectedExam.value).pop();
  if (selectedExam.value) {
    await saveExam(fullExam);
  }
  await updateModule(module.value);
};
</script>
