<script setup lang="ts">
import { useSearchStore } from "../../stores/searchStore";
import { onMounted, onUnmounted } from "vue";
import SearchAnnouncement from "./SearchAnnouncement.vue";

const searchStore = useSearchStore();

const keyListener = (event) => {
    // '/' key
    if (event.keyCode === 191) {
        searchStore.open();
        event.preventDefault();
    }
};

onMounted(() => {
    document.addEventListener("keydown", keyListener);
});

onUnmounted(() => {
    document.removeEventListener("keydown", keyListener);
});
</script>
<template>
    <div class="relative">
        <button
            @click="searchStore.open()"
            class="flex justify-center md:justify-between text-gray-700 md:text-gray-400 border h-9 w-9 md:w-64 px-4 py-2 bg-gray-50 border-gray-400 md:border-gray-200 rounded-full focus-visible:ring-blue-300 focus-visible:ring-opacity-50 items-center focus-visible:ring"
            aria-haspopup="dialog"
        >
            <div class="flex items-center space-x-1">
                <SvgIcon icon="search" size="sm" />
                <div class="hidden md:flex">Search</div>
            </div>
            <div
                class="hidden md:block w-6 text-center text-gray-400 border border-gray-300 rounded pointer-events-none"
                style="
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                "
            >
                /
            </div>
        </button>
    </div>
</template>
