<template>
    <div>
        <div>
            <h3 class="text-sm leading-6 font-medium text-gray-900 uppercase">
                {{ title }}
            </h3>
            <div class="flex-grow border-t border-gray-200 my-3"></div>
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped></style>
