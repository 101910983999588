<template>
    <div>
        <PulseExploreLayout :legacy="legacy" v-bind="$attrs">
            <PulseExploreChart :legacy="legacy" />
            <PulseExploreDetailsListingMeta
                :legacy="legacy"
                :count="rowLength"
                :typeData="typeData"
            />
            <PulseExploreDetailsListingInstances
                :legacy="legacy"
                label="Lesson Views"
                :rows="rows"
            />
        </PulseExploreLayout>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        legacy: Boolean,
        typeData: Object,
        rows: Array as PropType<any[]>,
    },
    computed: {
        rowLength() {
            // @ts-ignore
            return this.rows?.length ?? 0;
        },
    },
});
</script>
