<template>
    <PulseLayout v-bind="$attrs">
        <PulseLearnCourses v-if="showCoursesContent" />
        <PulseLearnCourse v-if="showCourseContent" />
        <PulseLearnModule v-if="showModuleContent" />
    </PulseLayout>
</template>

<script lang="ts">
export default {
    computed: {
        showCoursesContent() {
            return this.$attrs.type === "courses";
        },
        showCourseContent() {
            return this.$attrs.type === "course";
        },
        showModuleContent() {
            return this.$attrs.type === "module";
        },
    },
};
</script>
