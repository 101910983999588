<template>
    <div>
        <PulseLearnOverview
            title="Modules"
            :loading="loading"
            :subtitle="viewSubtitle"
        >
            <PulseModuleEngagementCard
                v-for="mod in sortedModules"
                :key="mod.module_uuid"
                :mod="mod"
            />

            <template #sort>
                <PulseLearnOverviewSort
                    :options="sortBy"
                    @sort="handleSort"
                    :selected="sort"
                />
            </template>
        </PulseLearnOverview>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import { getCoursesEngagement } from "../../../../api/pulse-requests";

export default {
    data(): any {
        return {
            pagination: {},
            modules: [],
            loading: true,
            sortBy: [
                "alphabetically",
                "lesson views",
                "quiz attempts",
                "assessment attempts",
            ],
            sort:
                localStorage.getItem("pulse.cards.selectedSort") ||
                "alphabetically",
            currentPage: "",
        };
    },
    computed: {
        ...mapGetters(["context"]),
        ...mapState(["group", "partner", "period", "selectedLearners"]),
        sortedModules() {
            switch (this.sort) {
                case "alphabetically":
                    return this.modules.sort((a, b) =>
                        a.title.localeCompare(b.title)
                    );
                case "lesson views":
                    return this.modules.sort(
                        (a, b) => b.lesson_views - a.lesson_views
                    );
                case "quiz attempts":
                    return this.modules.sort(
                        (a, b) =>
                            b.lesson_quiz_attempts - a.lesson_quiz_attempts
                    );
                case "assessment attempts":
                    return this.modules.sort(
                        (a, b) =>
                            b.module_quiz_attempts - a.module_quiz_attempts
                    );
                default:
                    return this.modules;
            }
        },
        viewSubtitle() {
            return (this.modules?.length ?? 0) + " Modules";
        },
        apiContext() {
            return `group=${this.group}&partner=${this.partner}&period=${this.period}&type=${this.type}`;
        },
    },

    watch: {
        apiContext(contextNew, contextOld) {
            if (contextNew !== contextOld) {
                this.getData();
            }
        },
    },
    methods: {
        getData() {
            let courseId = this.$route.params.courseId;
            getCoursesEngagement(
                "course",
                this.partner,
                this.group,
                this.period,
                courseId,
                this.selectedLearners
            ).then((response) => {
                this.modules = response.data.data;
                this.pagination = response.data.meta;
                this.loading = false;
            });
        },
        handleSort(sortBy) {
            localStorage.setItem("pulse.cards.selectedSort", sortBy);
            this.sort = sortBy;
            this.currentPage = 1;
        },
        handlePageUpdated(page) {
            this.currentPage = page;
            this.getData();
        },
    },
    mounted() {
        if (this.partner || this.group) {
            this.getData();
        }
        this.getData();
    },
};
</script>
