<template>
    <div
        class="relative group first:rounded-t-md last:rounded-b-md"
        :class="[variantClasses.bgColorClass, variantClasses.bgHoverColorClass]"
    >
        <a
            class="flex items-center justify-between w-full p-4 leading-tight cursor-pointer hover:no-underline"
            :class="[
                variantClasses.textColorClass,
                `hover:${variantClasses.textColorClass}`,
                variantClasses.fontWeightClass,
            ]"
            :href="path"
        >
            <div class="flex items-center">
                <SvgIcon
                    v-if="iconLeft"
                    class="mr-2"
                    :icon="iconLeft"
                    :color="variantClasses.textColorClass"
                    weight="medium"
                />

                <slot />
            </div>
            <SvgIcon
                v-if="iconRight"
                class="mx-2"
                :icon="iconRight"
                :color="variantClasses.textColorClass"
                weight="medium"
            />
        </a>
        <div class="mx-2 border-b group-last:border-b-0"></div>
    </div>
</template>

<script lang="ts">
import { DropdownVariants } from "./Dropdown.vue";

export default {
    props: {
        iconLeft: String,
        iconRight: String,
        path: String,
        variant: String,
    },
    data(): any {
        return {
            variantClasses:
                DropdownVariants[this.variant ?? "white"] ??
                DropdownVariants["white"],
        };
    },
};
</script>
