<template>
    <dropdown
        v-if="type !== 'courses'"
        :contextStringIsBold="true"
        :modelValue="dropdowns[active]"
        contextString="Showing"
        width-class="max-w-xs"
    >
        <dropdown-item
            v-for="(option, index) in dropdowns[active].options"
            :key="index"
            :path="
                option.path === dropdowns.module.options.course.path
                    ? option.path + context + `&course=${getCourseUuid()}`
                    : option.path + context
            "
        >
            <span class="text-sm">{{ option.label }}</span>
        </dropdown-item>
    </dropdown>
</template>

<script lang="ts">
import { mapState, mapGetters } from "vuex";

export default {
    data(): any {
        return {
            dropdowns: {
                course: {
                    label: "Course (All Modules)",
                    options: {
                        all: {
                            label: "All Courses",
                            path: "/pulse/learn/courses",
                        },
                    },
                },
                module: {
                    label: "Module (All Lessons)",
                    options: {
                        all: {
                            label: "All Courses",
                            path: "/pulse/learn/courses",
                        },
                        course: {
                            label: "Course (All Modules)",
                            path: "/pulse/learn/course",
                        },
                    },
                },
            },
        };
    },
    computed: {
        ...mapState(["type"]),
        ...mapGetters(["context"]),
        active: function () {
            return this.type;
        },
    },
    methods: {
        getCourseUuid() {
            return new URLSearchParams(window.location.search).get("course");
        },
    },
};
</script>
