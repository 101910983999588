<template>
	<div v-if="course" class="flex flex-col gap-6">
		<div class="flex flex-col gap-2">
			<!-- Breadcrumbs -->
			<div class="text-sm text-gray-500 mb-4">
				<router-link to="/content/learn/courses">Courses</router-link>
				> {{ course.title }}
			</div>
			<div
				class="flex flex-col md:flex-row md:items-center md:justify-between gap-3 md:gap-0">
				<div class="flex flex-col flex-wrap">
					<h2
						class="text-2xl font-semibold leading-none tracking-tight">
						{{ course.title }}
					</h2>
					<div class="flex flex-wrap items-center gap-2 mt-1">
						<div class="flex items-center">
							<p class="inline-flex text-xs text-gray-600 italic">
								{{ course.slug }}
							</p>
							<CopyAction
								:value="course.slug"
								:label="`${course.slug}`" />
						</div>
						<div class="text-xs flex flex-row items-center">
							<span class="font-semibold">ID</span>
							<CopyAction
								:value="course.id"
								:label="`${course.id}`" />
						</div>
						<div class="text-xs flex flex-row items-center">
							<span class="font-semibold">UUID</span>
							<CopyAction
								:value="course.uuid"
								:label="`${course.uuid}`" />
						</div>
					</div>
				</div>

				<div class="flex items-center gap-4 flex-start flex-wrap">
					<Button size="sm" @click="saveCourse">Save</Button>
				</div>
			</div>
		</div>

		<div class="w-full px-2 my-2 sm:px-0">
			<TabGroup>
				<TabList class="flex space-x-1 rounded bg-gray-200 p-2">
					<Tab
						v-for="tab in tabs"
						as="template"
						:key="tab"
						v-slot="{ selected }">
						<button
							:class="[
								'w-full rounded p-2 text-sm font-medium leading-5',
								'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
								selected
									? 'bg-slate-900 shadow-sm text-white'
									: 'text-gray-600 hover:bg-white/40 hover:text-gray-900',
							]">
							{{ tab }}
						</button>
					</Tab>
				</TabList>

				<TabPanels class="mt-2">
					<TabPanel key="details" :unmount="false">
						<div class="bg-white shadow rounded relative z-0">
							<div class="flex flex-col p-6 space-y-6">
								<!-- Slug -->
								<div class="flex flex-col md:flex-row gap-4">
									<div class="flex-1">
										<div class="flex flex-col gap-1">
											<label
												for="slug"
												class="block text-sm font-medium text-gray-700">
												Title
											</label>
											<input
												type="text"
												v-model="course.title"
												class="w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-1"
												:class="[
													errors?.title
														? '!border-red-500'
														: 'border-gray-300',
												]" />
											<span
												v-if="errors?.title"
												class="text-sm text-red-500"
												>{{ errors.title }}</span
											>
										</div>
									</div>
									<div class="flex-1">
										<div class="flex flex-col gap-1">
											<label
												for="slug"
												class="block text-sm font-medium text-gray-700">
												Slug
											</label>
											<input
												type="text"
												v-model="course.slug"
												class="w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm mt-1"
												:class="[
													errors?.slug
														? '!border-red-500'
														: 'border-gray-300',
												]" />
											<span
												v-if="errors?.slug"
												class="text-sm text-red-500"
												>{{ errors.slug }}</span
											>
										</div>
									</div>
								</div>
								<div class="flex-1">
									<div class="flex flex-col gap-1">
										<label
											for="ce_credit_hours"
											class="block text-sm font-medium text-gray-700">
											Post-Course Exam
										</label>
										<Combobox
											:options="
												exams.map((e) => ({
													value: e.id,
													label: e.name,
												}))
											"
											class=""
											input-classes="border-gray-300"
											v-model="selectedExam" />
									</div>
								</div>
								<!-- Description -->
								<div class="flex flex-col gap-1">
									<label
										for="description"
										class="block text-sm font-medium text-gray-700">
										Description
									</label>
									<CourseContentEditor
										v-model="course.description" />
								</div>
								<!-- Meta Description -->
								<div class="flex flex-col gap-1">
									<label
										for="meta_description"
										class="block text-sm font-medium text-gray-700">
										Meta Description
									</label>
									<textarea
										v-model="course.meta_description" />
								</div>
								<!-- Objectives -->
								<div class="flex flex-col gap-1">
									<label
										for="objectives"
										class="block text-sm font-medium text-gray-700">
										Objectives
									</label>
									<CourseContentEditor
										v-model="course.objectives" />
								</div>
								<div class="flex flex-col gap-1">
									<label
										for="target_audience"
										class="block text-sm font-medium text-gray-700">
										Target Audience
									</label>
									<CourseContentEditor
										v-model="course.target_audience" />
								</div>
							</div>
						</div>
					</TabPanel>
					<TabPanel key="CE" :unmount="false">
						<div class="bg-white shadow rounded relative z-0">
							<div class="flex flex-col p-6 space-y-6">
								<!-- Credit Hours -->
								<CEApprovalForm
									heading="+ Add CE Approval"
									buttonText="+ Add CE Approval"
									:onSubmit="createCEApproval"
									:ce="newCE"
									:shouldClearForm="true"
									ref="createCEFormRef" />
								<div class="flex flex-col">
									<div v-if="activeCE">
										<h2
											class="text-sm mb-2 text-gray-700 font-medium">
											Active CE Approval
										</h2>
										<Disclosure v-slot="{ open }">
											<DisclosureButton
												:class="{
													'rounded-t-md border-slate-800':
														open,
													'rounded-md': !open,
												}"
												class="flex w-full items-start justify-between bg-blue-100 p-6 text-left mt-1 text-sm">
												<div>
													<strong>{{
														activeCE?.reference_number
													}}</strong>
													&nbsp;
													{{
														`${formatDateSimple(
															activeCE?.valid_from
														)} - ${formatDateSimple(
															activeCE?.valid_to
														)}`
													}}
												</div>
												<div>
													<ChevronDownIcon
														class="transition h-5 w-5 text-gray-900"
														:class="{
															'rotate-180': open,
														}" />
												</div>
											</DisclosureButton>
											<DisclosurePanel
												class="rounded-b-md bg-blue-100">
												<CEApprovalForm
													button-text="Update CE Approval"
													class="border-none"
													bgColor="bg-blue-100"
													:ce="activeCE || {}"
													:onSubmit="
														updateCEApproval
													" />
											</DisclosurePanel>
										</Disclosure>
									</div>

									<div
										v-if="
											futureCes && futureCes?.length > 0
										"
										class="mt-6">
										<h2
											class="text-sm mb-2 text-gray-700 font-medium">
											Future CE Approvals
										</h2>
										<Disclosure
											v-slot="{ open }"
											v-for="ce in futureCes">
											<DisclosureButton
												:class="{
													'rounded-t-md border-slate-800':
														open,
													'rounded-md': !open,
												}"
												class="flex w-full items-start justify-between bg-blue-50 p-6 text-left mt-1 text-sm">
												<div>
													<strong>{{
														ce.reference_number
													}}</strong>
													&nbsp;
													{{
														`${formatDateSimple(
															ce.valid_from
														)} - ${formatDateSimple(
															ce.valid_to
														)}`
													}}
												</div>
												<div>
													<ChevronDownIcon
														class="transition h-5 w-5 text-gray-900"
														:class="{
															'rotate-180': open,
														}" />
												</div>
											</DisclosureButton>
											<DisclosurePanel
												class="rounded-b-md bg-blue-50">
												<CEApprovalForm
													class="border-none bg-blue-50"
													bgColor="bg-blue-50"
													:ce="ce"
													buttonText="Update CE Approval"
													:onSubmit="
														updateCEApproval
													" />
											</DisclosurePanel>
										</Disclosure>
									</div>
								</div>
								<div class="flex flex-col gap-1">
									<label
										for="target_audience"
										class="block text-sm font-medium text-gray-700">
										CE Info
									</label>
									<CourseContentEditor
										v-model="course.ce_info" />
								</div>
								<div class="flex flex-col gap-1">
									<label
										for="target_audience"
										class="block text-sm font-medium text-gray-700">
										Structured Ed Info
									</label>
									<CourseContentEditor
										v-model="course.structured_ed_info" />
								</div>
								<div class="flex flex-col gap-1">
									<label
										for="target_audience"
										class="block text-sm font-medium text-gray-700">
										CQR Info
									</label>
									<CourseContentEditor
										v-model="course.cqr_info" />
								</div>
							</div>
						</div>
					</TabPanel>
					<TabPanel key="taxonomy">
						<div class="space-y-8 p-4 bg-white rounded">
							<div>
								<h3
									class="text-xl font-bold mb-2 text-gray-700">
									Categories
								</h3>
								<TaxonomySelector
									v-model="course.categories"
									:taxonomy-list="categories" />
							</div>
							<div>
								<h3
									class="text-xl font-bold mb-2 text-gray-700">
									CE Disciplines
								</h3>
								<TaxonomySelector
									v-model="course.ce_disciplines"
									:taxonomy-list="disciplines" />
							</div>
							<div>
								<h3
									class="text-xl font-bold mb-2 text-gray-700">
									Structured Ed Disciplines
								</h3>
								<TaxonomySelector
									v-model="course.structured_ed_disciplines"
									:taxonomy-list="disciplines" />
							</div>
							<div>
								<h3
									class="text-xl font-bold mb-2 text-gray-700">
									CQR Disciplines
								</h3>
								<TaxonomySelector
									v-model="course.cqr_disciplines"
									:taxonomy-list="disciplines" />
							</div>
						</div>
					</TabPanel>
				</TabPanels>
			</TabGroup>
		</div>
	</div>
</template>
<script setup lang="ts">
import { PropType, onMounted, ref, computed } from "vue";
import { CEApproval, Course } from "@/types";
import Button from "@/components/ui/ButtonLinks/Button.vue";
import CourseContentEditor from "@/components/_elements/CourseContentEditor.vue";
import {
	TabGroup,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
	Disclosure,
	DisclosureButton,
	DisclosurePanel,
} from "@headlessui/vue";
import TaxonomySelector from "@/components/Admin/Content/taxonomy/TaxonomySelector.vue";
import Combobox from "@/components/ui/forms/Combobox.vue";
import { useCategories, useDisciplines } from "@/hooks/data";
import { getCourseCeApprovals } from "@/api/admin.ts";
import { format, isAfter, isWithinInterval } from "date-fns";
import axios from "axios";
import Input from "@/components/ui/forms/Input.vue";
import $events from "@/event-bus.ts";
import CEApprovalForm from "@/components/Admin/Content/courses/CEApprovalForm.vue";
import { ChevronDownIcon } from "@heroicons/vue/24/outline";

const course = ref<Partial<Course> | null>(null);
const disciplines = useDisciplines();
const categories = useCategories();
const createCEFormRef = ref<InstanceType<typeof CEApprovalForm>>();

const props = defineProps({
	course: {
		type: Object as PropType<Partial<Course> | null>,
		required: true,
	},
	errors: {
		type: Object,
		required: false,
	},
});

const exams = ref([]);
const courseCes = ref<CEApproval[]>([]);
const activeCE = computed(() => {
	return courseCes.value.find((ce) =>
		isWithinInterval(Date.now(), {
			start: new Date(ce.valid_from),
			end: new Date(ce.valid_to),
		})
	);
});
const futureCes = computed(() => {
	return (
		activeCE.value &&
		courseCes.value.filter((ce) =>
			isAfter(
				new Date(ce.valid_to),
				new Date(activeCE.value?.valid_to ?? Date.now())
			)
		)
	);
});

const formatDateSimple = (date: string | undefined) =>
	date && format(new Date(date), "MM/dd/yyyy");
const formatDateISO = (dateString: string): string =>
	new Date(dateString).toISOString();
const formatCERequest = (ce) => {
	try {
		ce.valid_from = formatDateISO(ce.valid_from);
		ce.valid_to = formatDateISO(ce.valid_to);
	} catch (e) {
		console.error(e);
	}

	return ce;
};
const newCE = ref<Partial<CEApproval>>({
	course_name: props.course?.title,
	course_id: props.course?.uuid,
});
const selectedExam = ref();

async function getExams() {
	axios
		.get(`/api/admin/content/learn/exams`)
		.then((response) => {
			if (response.status === 200) {
                const noPostCourseAssessment = { id: 0, uuid: '00000000-0000-0000-0000-000000000000', name: '(none)' };
				const resExams = response.data.flat();
				exams.value = resExams;
                exams.value.unshift(noPostCourseAssessment)

                const postCourseAssessment = resExams
                    .filter((exam) => {
                        return exam.course_id === course.value.id;
                    })
                    .pop()?.id;

                selectedExam.value = postCourseAssessment ?? noPostCourseAssessment.id;
			}
		})
		.catch((e) => console.error(e));
}

const getCes = async () => {
	if (!props.course || !props.course.uuid) {
		throw new Error("Course not found");
	}
	const { data } = await getCourseCeApprovals(props.course?.uuid);
	courseCes.value = data;
};

const createCEApproval = async (ce: CEApproval) => {
	if (Object.keys(ce).length > 2) {
		try {
			await axios.post(
				`/api/admin/content/learn/courses/${props.course?.uuid}/ceApprovals/`,
				formatCERequest({ ...ce })
			);
			$events.$emit("toastNotification", {
				action: "saved",
				heading: "CE Approval created Successfully.",
				status: "saved",
			});

			createCEFormRef.value?.clearForm();
			await getCes();
		} catch (e: any) {
			$events.$emit("toastNotification", {
				action: "error",
				heading: `Error saving CE approval.`,
				message: `${Object.values(e.response.data.errors).join(" ")}`,
				status: "error",
			});
		}
	}
};

const updateCEApproval = async (ce: CEApproval) => {
	try {
		await axios.put(
			`/api/admin/content/learn/courses/${props.course?.uuid}/ceApprovals/${ce.id}`,
			formatCERequest({ ...ce })
		);
		$events.$emit("toastNotification", {
			action: "saved",
			heading: "CE Approval updated successfully.",
			status: "saved",
		});
		await getCes();
	} catch (e: any) {
		$events.$emit("toastNotification", {
			action: "error",
			heading: `Error updating CE approval.`,
			message: `${Object.values(e.response.data.errors).join(" ")}`,
			status: "error",
		});
	}
};

onMounted(async () => {
	course.value = props.course;
	await getExams();
	await getCes();
});

const tabs = ref(["Details", "CE", "Taxonomy"]);

const emit = defineEmits(["save"]);

const saveCourse = async () => {
	const fullExam = exams.value
		.filter((exam) => exam.id == selectedExam.value)
		.pop();
	emit("save", course.value, fullExam);
};
</script>
