import { Course, Lesson, Module } from "../types";

export interface NextSteps {
    next: {
        lesson?: Lesson | null;
        module?: Module | null;
    };
    current: {
        lesson: Lesson;
        module: Module;
        hasQuiz: boolean;
        hasModuleAssessment: boolean;
    };
    course: Course;
}

export function getNextSteps(currentVideoSlug: string): Promise<NextSteps> {
    return fetch(`/learn/courses/${currentVideoSlug}/next`)
        .then((response) => response.json())
        .then((data) => data as NextSteps);
}
