<template>
    <div class="flex items-center">
        <ActionLink
            v-if="exportLink"
            :url="url"
            icon="download"
            @click="exportData"
        >
            Export
        </ActionLink>
        <ActionButton
            v-if="!exportLink"
            :class="{
                'rounded-r-none': shareable,
            }"
            dusk="export-button"
            icon="download"
            @click="exportData"
        >
            Export
        </ActionButton>
        <CopyAction
            v-if="shareable"
            icon="share"
            class="border border-blue-500 rounded-l-none border-l-0 text-blue-600"
            label="Copy shareable link"
            :value="url"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "DataExport",
    props: {
        exportLink: Boolean,
        filename: String,
        shareable: Boolean,
        url: {
            type: String,
            required: true,
        },
    },
    methods: {
        exportData() {
            // @ts-ignore
            this.$events.$emit("toastNotification", {
                heading: "Exporting data. Please wait...",
            });

            if (!this.exportLink) {
                fetch(this.url)
                    .then((response) => response.blob())
                    .then((blob) => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        a.href = url;
                        a.download = this.filename + ".csv";
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                        // @ts-ignore
                        this.$events.$emit("toastNotification", {
                            action: "saved",
                            heading: "Data export succeeded!",
                            status: "saved",
                        });
                    })
                    .catch((error) => {
                        // @ts-ignore
                        this.$events.$emit("toastNotification", {
                            action: "error",
                            heading: "Data export failed!",
                            status: "error",
                        });
                    });
            }
        },
    },
});
</script>
