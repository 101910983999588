<script setup lang="ts">
import { useSearchStore } from "../../stores/searchStore";
import { storeToRefs } from "pinia";

const searchStore = useSearchStore();
const { searchQuery } = storeToRefs(searchStore);

// When the search box is focused we want to register up and down arrow key events
// to allow the user to navigate the search results list.
const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
        searchStore.selectNextListItem();
        e.preventDefault();
    }
    if (e.key === "ArrowUp") {
        searchStore.selectPreviousListItem();
        e.preventDefault();
    }
    if (e.key === "Enter") {
        searchStore.goToSelectedResult();
        e.preventDefault();
    }
};
</script>
<template>
    <header class="flex items-center justify-between px-4 py-2">
        <SvgIcon icon="search" />
        <input
            type="text"
            v-model="searchQuery"
            class="global-search-input w-full px-4 py-2 text-gray-700"
            @keydown="handleKeyDown"
            placeholder="Search"
        />
        <button
            class="bg-slate-600 text-white hover:bg-slate-800 text-sm rounded p-1 focus-visible:ring-2 focus-visible:ring-offset-2"
            @click="searchStore.close()"
        >
            Esc<span class="sr-only"> key to close or click this button</span>
        </button>
    </header>
</template>
<style scoped>
.global-search-input {
    @apply w-full px-4 py-2 text-gray-700 border-none focus:outline-none focus-visible:ring-0 focus:border-none;
}
</style>
