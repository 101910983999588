<template>
    <PulseActivityTableRow :row-grid-template="rowGridTemplate">
        <PulseActivityTableCell centered="y">
            <slot name="checkbox" />

            <PulseActivityTableLearner
                :image="row.user.profile_image"
                :forename="row.user.first_name"
                :surname="row.user.last_name"
            />
        </PulseActivityTableCell>

        <PulseActivityTableCell v-slot="{ asDate }" centered="y">
            <span class="text-sm">{{ asDate(row.date_submitted) }}</span>
        </PulseActivityTableCell>

        <PulseActivityTableCell centered="y">
            <PulsePrepCategoryCircles :row="tempRowData" />
        </PulseActivityTableCell>

        <PulseActivityTableCell centered>
            {{ row.questions_correct }}/{{ row.questions_total }}
        </PulseActivityTableCell>

        <PulseActivityTableCell centered>
            <PulseActivityTableType :type="row.status" />
        </PulseActivityTableCell>

        <PulseActivityTableCell centered>
            {{ convertSecondsToMinutes(row.time) }}
        </PulseActivityTableCell>

        <PulseActivityTableCell centered>
            <PulsePrepScoreData :score="row.score" />
            <a href="#" class="ml-2 text-blue-600" target="_blank">
                <SvgIcon icon="external-link" size="sm" weight="bold" />
            </a>
        </PulseActivityTableCell>
    </PulseActivityTableRow>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import PulsePrepCategoryCircles from "../../_shared/PulsePrepCategoryCircles.vue";
export default {
    components: { PulsePrepCategoryCircles },
    props: {
        row: Object,
        rowGridTemplate: String,
        loading: Boolean,
    },
    data(): any {
        return {
            tempRowData: {
                categories: {
                    1: [
                        {
                            id: 2,
                            uuid: "cbbb4a8e-b7b7-4ca3-9bab-9914d7d47f13",
                            parent_id: 1,
                            category: "Patient Interactions and Management",
                            app: "Prep",
                            bank: "Radiography",
                            level: 1,
                            order: 1,
                            root_category: "Patient Care",
                            primary_category:
                                "Patient Interactions and Management",
                            descendent_ids: [
                                2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
                                16, 17, 18, 2139, 19, 20, 21, 22, 23, 24, 25,
                                26, 27, 28, 29, 30, 31, 32, 33, 34, 36, 37, 38,
                                39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51,
                                52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
                                64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75,
                                76, 77, 78, 80, 81, 82, 83, 84, 85, 79, 86, 87,
                                88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
                                100, 101, 102, 103, 104, 105, 106, 2140, 2141,
                                111, 112, 113, 114, 115, 116, 117, 118, 119,
                                120, 121, 122, 123,
                            ],
                            deleted_at: null,
                            bank_slug: "radiography",
                        },
                    ],
                    124: [
                        {
                            id: 125,
                            uuid: "bc91199a-1e5b-49b4-92af-afa291627a3e",
                            parent_id: 124,
                            category: "Radiation Physics and Radiobiology",
                            app: "Prep",
                            bank: "Radiography",
                            level: 1,
                            order: 1,
                            root_category: "Safety",
                            primary_category:
                                "Radiation Physics and Radiobiology",
                            descendent_ids: [
                                125, 126, 127, 128, 129, 130, 131, 132, 133,
                                134, 135, 136, 137, 138, 139, 140, 141, 142,
                                143, 145, 144, 146, 147, 148, 149, 150, 151,
                                152, 153, 154, 155, 156, 157, 158, 159, 160,
                                161, 162, 2388, 166, 171, 165, 164, 2389, 163,
                                167, 168, 169, 170,
                            ],
                            deleted_at: null,
                            bank_slug: "radiography",
                        },
                        {
                            id: 175,
                            uuid: "fde480e4-dd2d-44fc-bc73-06c9b5d5e788",
                            parent_id: 124,
                            category: "Radiation Protection",
                            app: "Prep",
                            bank: "Radiography",
                            level: 1,
                            order: 2,
                            root_category: "Safety",
                            primary_category: "Radiation Protection",
                            descendent_ids: [
                                175, 176, 177, 178, 179, 180, 185, 186, 187,
                                192, 193, 194, 195, 196, 188, 189, 190, 191,
                                197, 198, 199, 200, 201, 202, 203, 204, 205,
                                206, 207, 208, 209, 210, 211, 212, 213, 214,
                                215, 216, 217, 218, 219, 220, 221, 222, 223,
                                224, 225, 226, 227, 228, 229, 230, 231, 232,
                                233, 234, 235, 236, 237, 238, 239, 240, 241,
                                242, 243, 244, 245, 246, 247, 248, 249,
                            ],
                            deleted_at: null,
                            bank_slug: "radiography",
                        },
                    ],
                    250: [
                        {
                            id: 251,
                            uuid: "36f0d99c-f0af-415b-9a4a-2b324f36fea0",
                            parent_id: 250,
                            category:
                                "Image Acquisition and Technical Evaluation",
                            app: "Prep",
                            bank: "Radiography",
                            level: 1,
                            order: 1,
                            root_category: "Image Production",
                            primary_category:
                                "Image Acquisition and Technical Evaluation",
                            descendent_ids: [
                                251, 252, 1673, 1675, 1676, 253, 254, 256, 257,
                                258, 259, 260, 261, 262, 2390, 2391, 263, 264,
                                265, 266, 267, 268, 269, 270, 271, 273, 272,
                                276, 274, 275, 277, 2392, 278, 279, 280, 281,
                                283, 284, 285, 356, 357, 358, 359, 360, 361,
                                362, 363, 364, 365,
                            ],
                            deleted_at: null,
                            bank_slug: "radiography",
                        },
                        {
                            id: 286,
                            uuid: "3f11566b-5bbb-45c3-a141-098f21935dbd",
                            parent_id: 250,
                            category:
                                "Equipment Operation and Quality Assurance",
                            app: "Prep",
                            bank: "Radiography",
                            level: 1,
                            order: 2,
                            root_category: "Image Production",
                            primary_category:
                                "Equipment Operation and Quality Assurance",
                            descendent_ids: [
                                286, 287, 301, 302, 303, 304, 288, 289, 290,
                                291, 292, 293, 2403, 294, 295, 296, 297, 298,
                                299, 314, 315, 316, 317, 318, 2405, 2406, 319,
                                320, 321, 300, 305, 306, 307, 308, 309, 310,
                                311, 312, 313, 322, 323, 324, 325, 326, 327,
                                328, 329, 330, 331, 332, 333, 334, 335, 336,
                                337, 338, 339, 340, 341, 342, 343, 344, 345,
                                346, 347, 348, 349, 350, 2407, 353, 354, 355,
                                2408, 352, 351, 2409, 366, 367, 368, 369, 370,
                                371, 372, 373, 374, 375,
                            ],
                            deleted_at: null,
                            bank_slug: "radiography",
                        },
                    ],
                    376: [
                        {
                            id: 377,
                            uuid: "330e60a6-6c02-4369-b167-73f7d1651edd",
                            parent_id: 376,
                            category: "Head, Spine and Pelvis Procedures",
                            app: "Prep",
                            bank: "Radiography",
                            level: 1,
                            order: 1,
                            root_category: "Procedures",
                            primary_category:
                                "Head, Spine and Pelvis Procedures",
                            descendent_ids: [
                                377, 378, 379, 380, 381, 383, 384, 385, 386,
                                387, 388, 389, 390, 391, 392, 393, 394, 395,
                            ],
                            deleted_at: null,
                            bank_slug: "radiography",
                        },
                        {
                            id: 397,
                            uuid: "038e25c1-e18e-4a48-86dd-4e9b783fd475",
                            parent_id: 376,
                            category: "Thorax and Abdomen Procedures",
                            app: "Prep",
                            bank: "Radiography",
                            level: 1,
                            order: 2,
                            root_category: "Procedures",
                            primary_category: "Thorax and Abdomen Procedures",
                            descendent_ids: [
                                397, 398, 399, 400, 401, 402, 2410, 403, 404,
                                405, 406, 407, 408, 409, 410, 411, 412, 413,
                                414, 415, 416, 396,
                            ],
                            deleted_at: null,
                            bank_slug: "radiography",
                        },
                        {
                            id: 417,
                            uuid: "2746655d-f3c6-4533-a6ef-9432363b4dcc",
                            parent_id: 376,
                            category: "Extremity Procedures",
                            app: "Prep",
                            bank: "Radiography",
                            level: 1,
                            order: 3,
                            root_category: "Procedures",
                            primary_category: "Extremity Procedures",
                            descendent_ids: [
                                417, 418, 419, 420, 421, 422, 423, 424, 425,
                                426, 427, 428, 429, 430, 431, 432, 433, 434,
                                435, 436, 437, 438, 439, 440, 441,
                            ],
                            deleted_at: null,
                            bank_slug: "radiography",
                        },
                    ],
                },
                categories_complete: {
                    patient_care: "80%",
                    safety: "82%",
                    image_production: "91%",
                    procedures: "77%",
                },
            },
        };
    },
    computed: {
        ...mapGetters(["context"]),
    },
    methods: {
        convertSecondsToMinutes(seconds) {
            return new Date(seconds * 1000).toISOString().slice(14, 19);
        },
    },
};
</script>
