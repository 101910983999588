<template>
    <div class="profilePhoto flex items-center space-x-4">
        <div class="relative" :class="size">
            <div
                v-if="!hasPhoto && !loadingFile"
                class="absolute inset-0 flex items-center justify-center w-full h-full z-10"
            >
                <SvgIcon
                    class="text-gray-300"
                    icon="user"
                    size="xl3"
                    weight="light"
                />
            </div>
            <FilePond
                ref="pond"
                labelIdle=""
                credits=""
                maxFiles="1"
                imagePreviewHeight="80"
                imagePreviewWidth="80"
                imageCropAspectRatio="1:1"
                imageResizeTargetWidth="320"
                imageResizeTargetHeight="320"
                imageTransformOutputMimeType="image/jpeg"
                :stylePanelLayout="layout"
                stylePanelAspectRatio="1:1"
                styleButtonRemoveItemPosition="center"
                styleLoadIndicatorPosition="bottom center"
                :className="classes"
                @addfile="filepondAddFile"
                @addfilestart="filepondAddFileStart"
                @removefile="filepondRemoveFile"
            />
        </div>
        <button
            v-if="editable === 'true'"
            type="button"
            class="small basic primary button"
            v-text="buttonLabel"
            @click="toggleFile"
        />
    </div>
</template>

<script lang="ts">
// @ts-nocheck
import vueFilePond from "vue-filepond";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

export default {
    components: {
        FilePond: vueFilePond(
            FilePondPluginFileEncode,
            FilePondPluginImageCrop,
            FilePondPluginImagePreview,
            FilePondPluginImageResize,
            FilePondPluginImageTransform
        ),
    },
    props: {
        file: String,
        classes: {
            type: String,
            default: "rounded",
        },
        editable: {
            type: String,
            default: "true",
        },
        size: {
            type: String,
            default: "w-20 h-20",
        },
        layout: {
            type: String,
            default: "compact circle",
        },
    },
    data(): any {
        return {
            hasChanged: false,
            hasPhoto: false,
            loadingFile: false,
            loadedFile: null,
            isDirty: false,
        };
    },
    computed: {
        buttonLabel() {
            return this.hasPhoto ? "Remove" : "Upload";
        },
    },
    methods: {
        filepondAddFileStart() {
            this.loadingFile = true;
        },
        filepondAddFile() {
            this.hasPhoto = true;
            this.loadingFile = false;
            this.setLoadedFile();
            this.setDirty();
        },
        filepondRemoveFile() {
            this.hasPhoto = false;
            this.hasChanged = true;
            this.setLoadedFile();
            this.setDirty();
        },
        setDirty() {
            //
        },
        setLoadedFile() {
            if (this.$refs.pond.getFiles().length > 0) {
                this.loadedFile = this.$refs.pond.getFiles()[0].filename;
            } else {
                this.loadedFile = null;
            }
        },
        toggleFile() {
            if (this.hasPhoto) {
                this.$refs.pond.removeFile();
            } else {
                this.$refs.pond.browse();
            }
        },
    },
    mounted() {
        if (this.file) {
            this.loadingFile = true;
            this.$refs.pond.addFile(this.file);
        }
    },
};
</script>

<style>
.profilePhoto {
    .filepond--drop-label {
        color: #4c4e53;
    }

    .filepond--label-action {
        text-decoration-color: #babdc0;
    }

    .filepond--image-preview-overlay-idle {
        display: none;
    }

    .filepond--file-action-button {
        bottom: 1.75rem !important;
    }

    .filepond--load-indicator {
        bottom: 15% !important;
    }

    .filepond--action-remove-item {
        @apply hidden;
    }

    .filepond--panel-root {
        @apply bg-gray-100 border-0 shadow-inner !important;

        border-radius: inherit !important;
    }

    .filepond--root {
        margin: 0 auto;
    }

    .rounded {
        border-radius: 4rem !important;
    }
}
</style>
