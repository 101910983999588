<template>
    <div
        class="hover:bg-gray-50 border-b border-gray-100 last:border-none first:rounded-t last:rounded-b"
    >
        <button
            v-if="label == 'Contact'"
            class="w-full flex items-center whitespace-nowrap p-4 pr-12 focus-visible:ring"
            type="button"
            @click="openSupportWidget"
        >
            <SvgIcon class="mr-3" :icon="icon" size="base" />
            <span>{{ label }}</span>
        </button>
        <form v-if="label == 'Logout'" action="/logout" method="post">
            <input type="hidden" name="_token" :value="token" />
            <button
                class="w-full flex items-center whitespace-nowrap p-4 pr-12 focus-visible:ring"
                type="submit"
            >
                <SvgIcon class="mr-3" :icon="icon" size="base" />
                <span>{{ label }}</span>
            </button>
        </form>
        <a
            v-if="label != 'Logout' && label != 'Contact'"
            class="flex items-center hover:no-underline whitespace-nowrap p-4 pr-12 focus-visible:ring"
            :href="path"
            :target="target"
        >
            <SvgIcon class="mr-3" :icon="icon" size="base" />
            <span>{{ label }}</span>
        </a>
    </div>
</template>

<script lang="ts">
import { mapState } from "vuex";

export default {
    props: {
        icon: String,
        label: String,
        path: String,
    },
    computed: {
        ...mapState(["token"]),
        target() {
            return this.path?.includes("http") ? "_blank" : "_self";
        },
    },
    methods: {
        openSupportWidget() {
            // @ts-ignore
            zE("messenger", "open");
        },
    },
};
</script>
