<template>
    <div>
        <template v-if="!loading">
            <h3
                class="flex items-center justify-between bg-white text-gray-800 border-l-4 border-pear-600 p-4 rounded shadow mb-12"
            >
                <div class="flex items-center w-128">
                    <div>
                        <strong class="">{{ lesson.topic }}</strong>
                        <div class="text-sm font-semibold mt-2">
                            {{ lesson.section }} &rarr; {{ lesson.lesson }}
                        </div>
                    </div>
                </div>
                <div class="flex items-center">
                    <div class="text-center text-lg px-8">
                        <strong class="text-pear-600 font-bold">{{
                            lesson.views
                        }}</strong>
                        <h4 class="text-sm uppercase mt-2">Lesson Views</h4>
                    </div>
                </div>
            </h3>
            <pulse-lesson-learners v-if="scope != 'learner'" :rows="rows" />
            <pulse-lesson-learner v-if="scope == 'learner'" :rows="rows" />
        </template>
        <div
            class="flex items-center justify-center"
            v-if="loading"
            style="height: 400px"
        >
            <div class="dark loader"></div>
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState, mapGetters } from "vuex";

export default {
    data(): any {
        return {
            lesson: {},
            loading: true,
            rows: [],
        };
    },
    computed: {
        ...mapState(["id", "period", "scope", "type", "typeId"]),
        ...mapGetters(["context"]),
    },
    methods: {
        getData() {
            axios
                .get(
                    "/api/pulse/lesson-views/" +
                        this.context +
                        "/" +
                        this.type +
                        "/" +
                        this.typeId
                )
                .then((response) => {
                    this.lesson = response.data.lesson;
                    this.rows = response.data.rows;
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getData();
    },
};
</script>
