import Vuex from "vuex";

const state = {
    lessonContent: null,
};

const getters = {};

const mutations = {
    registerState(state, payload) {
        for (const [key, value] of Object.entries(payload)) {
            state[key] = value;
        }
    },
};

const actions = {};

export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
});
