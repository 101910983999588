<script setup lang="ts">
import StyledModal from "@/components/_elements/modals/StyledModal.vue";
import { ExclamationTriangleIcon } from "@heroicons/vue/20/solid";

// Create prop for course slug
const props = defineProps<{
	deprecatedCourseSlug: string;
}>();

const localKey = "clover.deprecated-course-modal";
// show if not set
const show =
	window.location.href.includes(props.deprecatedCourseSlug) &&
	localStorage.getItem(localKey) !== "false";

const onClose = () => {
	localStorage.setItem(localKey, "false");
};
</script>

<template>
	<StyledModal :start-open="show" @on-close="onClose">
		<template v-slot:panel="slotProps">
			<div class="text-left">
				<h2 class="mt-0 flex gap-2 items-center text-gray-900">
					<ExclamationTriangleIcon
						class="w-10 h-10 text-orange-400" />
					Attention
				</h2>
				<p class="text-lg text-gray-900">
					After <strong>May 30, 2024</strong>, this course,
					<span class="italic"
						>Fundamentals of Fluoroscopic Imaging (2021)</span
					>, will no longer be available on our platform.
				</p>
				<p class="text-base text-gray-700">
					This course is being replaced by
					<a
						href="/learn/courses/fundamentals-of-fluoroscopy"
						class="italic text-blue-700"
						>Fluoroscopy Fundamentals</a
					>
					as of January 2, 2024. The new course covers all the
					previous content of the old course and each lesson in the
					new course now has a post-video quiz.
				</p>
				<p class="text-base text-gray-700">
					The Fluoroscopy Fundamentals  offers additional information
					on fluoroscopy and the content has been reorganized.
				</p>
				<p class="text-sm text-gray-700 italic">
					NOTE: Only the
					<a
						href="/learn/courses/fundamentals-of-fluoroscopy"
						class="italic text-blue-700"
						>Fluoroscopy Fundamentals (2023)</a
					>
					course has been approved for continuing education credits.
				</p>
				<div class="flex mt-8 gap-2 items-center justify-end">
					<button
						class="button rounded-md bg-gray-100 shadow-none border-none hover:bg-gray-200"
						@click="slotProps.events?.closeModal()">
						Ok, got it.
					</button>
				</div>
			</div>
		</template>
	</StyledModal>
</template>
