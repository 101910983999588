import { Feature } from "../types";
import { FrequencyKeys, PlanKeys } from "../types";

export const commonFeatures: Feature[] = [
	{
		type: "basic",
		description: "10-day Money Back Guarantee",
	},
];

export interface Price {
	name: string;
	key: FrequencyKeys;
	price: number;
	description: string;
	frequency: string;
	badge?: string;
}

export type FrequencyMapping<T> = {
	[F in FrequencyKeys]?: T;
};

export type PlanMapping<T> = {
	[P in PlanKeys]?: T;
};

export interface PlanDetail {
	name: string;
	key: PlanKeys;
	upgradeKeys?: PlanKeys[];
	downgradeKeys?: PlanKeys[];
	prices: Price[];
	price: FrequencyMapping<string>;
	totalText: FrequencyMapping<string>;
	description: string;
	features: Feature[];
	badge?: string;
}

export const studentPlanDetails: PlanDetail = {
	name: "Training & Exam Prep",
	key: "student",
	upgradeKeys: ["professional", "ce"],
	prices: [
		{
			name: "Monthly",
			key: "1m",
			price: 55,
			description: "$55/every month",
			frequency: "every month",
		},
		{
			name: "Quarterly",
			key: "3m",
			price: 124,
			description: "$124/every 3 months",
			badge: "25% off",
			frequency: "every 3 months",
		},
		{
			name: "Yearly",
			key: "1y",
			price: 264,
			description: "$264/every 12 months",
			badge: "60% off",
			frequency: "every 12 months",
		},
	],
	price: {
		"1m": "55",
		"3m": "129",
		"1y": "297",
	},
	totalText: {
		"1m": "Automatically billed every month. Cancel anytime.",
		"3m": "Automatically billed every 3 months. Cancel anytime.",
		"1y": "Automatically billed every year. Cancel anytime.",
	},
	description:
		"Ace your classes and certification exams through unlimited course and exam prep resources for pre-licensure students.",
	features: [
		...commonFeatures,
		{
			type: "basic",
			description: "Full access to all platform courses",
		},
		{
			type: "basic",
			description: "250+ video lessons and quizzes",
		},
		{
			type: "basic",
			description: "2,000+ ARRT® registry exam-style questions",
		},
		{
			type: "basic",
			description: "Unlimited Mock Exam Simulator attempts",
		},
		{
			type: "basic",
			description: "Real-time platform engagement reporting",
		},
		{
			type: "basic",
			description: "Dedicated email support",
		},
		{
			type: "basic",
			description: "24/7 online access",
		},
		{
			type: "missing",
			description: "Post-course assessments",
		},
		{
			type: "missing",
			description: "Continuing education credits",
		},
	],
};

export const professionalPlanDetails: PlanDetail = {
	name: "CT Cross-Trainer",
	key: "professional",
	downgradeKeys: ["student", "ce"],
	prices: [
		{
			name: "Monthly",
			key: "1m",
			price: 99,
			description: "$99/every month",
			frequency: "every months",
		},
		{
			name: "Semiyearly",
			key: "6m",
			price: 297,
			description: "$297/every 6 months",
			badge: "50% off",
			frequency: "every 6 months",
		},
		{
			name: "Yearly",
			key: "1y",
			price: 356,
			description: "$356/every 12 months",
			badge: "70% off",
			frequency: "every 12 months",
		},
	],
	price: {
		"1m": "99",
		"6m": "297",
		"1y": "356",
	},
	totalText: {
		"1m": "Automatically billed every month. Cancel anytime.",
		"6m": "Automatically billed every 6 months. Cancel anytime.",
		"1y": "Automatically billed every year. Cancel anytime.",
	},
	description:
		"Prepare for your ARRT® advanced certification with unlimited course and exam prep access and 16 hours of ASRT-approved structured education.",
	badge: "CE included",
	features: [
		...commonFeatures,
		{
			type: "basic",
			description: "Full access to all CT courses",
		},
		{
			type: "basic",
			description: "130+ video lessons and quizzes",
		},
		{
			type: "basic",
			description: "650+ ARRT® CT Registry Exam-style questions",
		},
		{
			type: "basic",
			description: "Unlimited CT Mock Exam Simulator attempts",
		},
		{
			type: "basic",
			description: "16 hours of ASRT-Approved SE credits",
		},
		{
			type: "basic",
			description: "Digital ebook with answer key",
		},
		{
			type: "basic",
			description: "Real-time platform engagement reporting",
		},
		{
			type: "basic",
			description: "Dedicated email support",
		},
		{
			type: "basic",
			description: "24/7 online access",
		},
	],
};

export const cePlanDetails: PlanDetail = {
	name: "Continuing Education",
	key: "ce",
	upgradeKeys: ["professional"],
	prices: [
		{
			name: "Quarterly",
			key: "3m",
			price: 89,
			description: "$89/every 3 months",
			frequency: "every 3 months",
		},
		{
			name: "Yearly",
			key: "1y",
			price: 210,
			description: "$210/every 12 months",
			badge: "41% off",
			frequency: "every 12 months",
		},
		{
			name: "Bi-Yearly",
			key: "2y",
			price: 294,
			description: "$294/every 24 months",
			badge: "59% off",
			frequency: "every 24 months",
		},
	],
	price: {
		"3m": "89",
		"1y": "210",
		"2y": "294",
	},
	totalText: {
		"3m": "Automatically billed every 3 months. Cancel anytime.",
		"1y": "Automatically billed every year. Cancel anytime.",
		"2y": "Automatically billed every 2 years. Cancel anytime.",
	},
	description:
		"Fulfill your continuing education requirements with unlimited access to ASRT-approved CE courses in all modalities for licensed ARRT® technologists.",
	badge: "CE included",
	features: [
		...commonFeatures,
		{
			type: "basic",
			description: "Access to all available CE courses",
		},
		{
			type: "basic",
			description: "Access to all available CQR courses",
		},
		{
			type: "basic",
			description: "Access to all available SE courses",
		},
		{
			type: "basic",
			description: "Unlimited CE certificate downloads",
		},
		{
			type: "basic",
			description: "Real-time engagement reporting",
		},
		{
			type: "basic",
			description: "24/7 online access",
		},
		{
			type: "basic",
			description: "Dedicated email support",
		},
		{
			type: "missing",
			description: "Exam prep Qbank access",
		},
	],
};

export const planDetailsMapping: PlanMapping<PlanDetail> = {
	student: studentPlanDetails,
	professional: professionalPlanDetails,
	ce: cePlanDetails,
};
