<script setup lang="ts">
// a compone that extends html button or vue RouterLink or anchor tag
import { cva, VariantProps } from "class-variance-authority";
import { buttonStyles } from "@/components/ui/ButtonLinks/buttonStyles";
import { ButtonHTMLAttributes, computed } from "vue";
// build a tailwind button
type ButtonProps = VariantProps<typeof buttonStyles>;

const props = defineProps<{
    intent?: ButtonProps["intent"];
    size?: ButtonProps["size"];
    full?: ButtonProps["full"];
    round?: ButtonProps["round"];
    onClick?: ButtonHTMLAttributes["onClick"];
    isDisabled?: ButtonProps["isDisabled"];
}>();
const classes = computed(() => {
    return buttonStyles({
        intent: props.intent,
        size: props.size,
        full: props.full,
        round: props.round,
        isDisabled: props.isDisabled,
    });
});
const { intent, size, full, round, ...restProps } = props;
</script>
<template>
    <button :class="classes" v-bind="restProps" :disabled="props.isDisabled || false">
        <slot></slot>
    </button>
</template>
