<template>
    <a
        v-tippy="{
            delay: [1000, 0],
            placement: 'left',
        }"
        ref="tip"
        :content="label"
        class="flex items-center justify-center rounded-full h-9 w-9"
        :class="[
            theme,
            {
                'bg-cyan-50': !theme,
                'leading-4 text-sm': initials,
            },
        ]"
        :href="path"
    >
        <SvgIcon v-if="icon" :icon="icon" size="base" weight="medium" />
        <span v-if="initials">
            {{ initials }}
        </span>
    </a>
</template>

<script lang="ts">
export default {
    props: {
        icon: {
            type: String,
            default: null,
        },
        initials: {
            type: String,
            default: null,
        },
        label: String,
        path: String,
        theme: {
            type: String,
            default: null,
        },
    },
};
</script>
