<template>
    <div class="flex bg-white rounded-md shadow p-6 font-light text-sm">
        <QuillRenderer :delta="content" />
    </div>
</template>

<script lang="ts">
export default {
    props: {
        content: String,
    },
    methods: {},
};
</script>
