<template>
    <div ref="lessons">
        <div class="flex bg-gray-200 shadow-inner rounded-md p-4 mb-4 sm:mb-8">
            <div class="flex items-center justify-between text-sm w-full">
                <div class="flex items-center flex-wrap">
                    <div class="flex flex-col mr-4">
                        <label
                            class="font-semibold text-xs uppercase leading-6 ml-2"
                        >Course</label
                        >
                        <SelectDropdown v-model="filterCourse">
                            <option value="">Show All</option>
                            <option
                                v-for="course in courses"
                                :value="course.uuid"
                                v-text="course.title"
                            />
                        </SelectDropdown>
                    </div>
                    <div class="flex flex-col mt-4 sm:mt-0">
                        <label
                            class="font-semibold text-xs uppercase leading-6 ml-2"
                        >Filter By Module Title</label
                        >
                        <div class="w-72">
                            <input
                                class="leading-5 rounded-lg shadow p-3 w-full"
                                type="text"
                                v-model="filterTerm"
                                placeholder="e.g. anode heel effect"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            class="hidden sm:flex items-center justify-end text-center"
            v-if="rows.length > 0"
        >
            <div class="text-sm uppercase font-medium text-gray-600 w-22">
                #
            </div>
            <template v-if="isAssessment">
                <div class="text-sm uppercase font-medium text-gray-600 w-32">
                    Avg Score
                </div>
                <div class="text-sm uppercase font-medium text-gray-600 w-24">
                    Avg Time
                </div>
            </template>
        </div>
        <div class="rounded-md shadow mt-4" v-if="rows.length > 0">
            <PulseExploreDetailsCourseOverviewRow
                v-for="row in rows"
                :key="row.name"
                :legacy="legacy"
                :row="row"
                v-if="!loading"
            />
        </div>
        <div
            class="flex items-center justify-center text-lg text-gray-500 px-4 h-18"
            v-if="!loading && rows.length == 0"
        >
            For detailed reporting, choose a course from the dropdown above.
        </div>
        <Loader :show="loading"/>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import debounce from "debounce";
import Mark from "mark.js";
import {mapGetters, mapState} from "vuex";

export default {
    props: {
        api: String,
        course: String,
        courses: Array,
        legacy: Boolean,
    },
    data(): any {
        return {
            loading: false,
            rows: [],
            filterCourse: "",
            filterTerm: "",
            search: "",
        };
    },
    computed: {
        ...mapState(["scope", "type"]),
        ...mapGetters(["context"]),
        filterString() {
            return (
                "&course=" +
                this.filterCourse +
                "&type=" +
                this.type +
                "&filter=" +
                this.filterTerm +
                "&legacy=" +
                this.legacy
            );
        },
        isAssessment() {
            return ["assessments", "quizzes"].indexOf(this.type) > -1;
        },
    },
    methods: {
        filterData() {
            this.getData();
        },
        getData() {
            this.loading = true;
            this.$events.$emit("updateChart", this.filterString);

            axios
                .get(
                    "/api/pulse/" + this.api + this.context + this.filterString
                )
                .then((response) => {
                    this.rows = response.data;
                    this.loading = false;
                    this.$nextTick(() => this.highlight());
                });
        },
        highlight() {
            if (this.filterTerm) {
                this.$events.$emit("highlight", this.filterTerm);

                let instance = new Mark(this.$refs.lessons);
                instance.mark(this.filterTerm);
            }
        },
    },
    mounted() {
        this.filterData = debounce(this.filterData, 500);

        this.filterCourse = this.course || this.filterCourse;
    },
    watch: {
        filterCourse() {
            this.getData();
        },
        filterTerm() {
            this.filterData();
        },
    },
};
</script>

<style>
mark {
    @apply bg-yellow-100;

    color: inherit;
}
</style>
