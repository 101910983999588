<template>
    <div
        class="overflow-hidden bg-white rounded-md shadow ring-1 ring-black ring-opacity-5"
    >
        <!-- Table Header -->
        <div
            class="flex flex-col justify-between px-4 md:flex-row md:py-2 md:items-center"
        >
            <div
                class="flex flex-col my-3 space-y-3 md:items-center md:flex-row md:space-y-0 md:space-x-5 lg:space-x-10"
            >
                <h2 class="w-full text-xl font-medium align-middle md:w-fit">
                    <slot name="breadcrumb" />

                    {{ heading }}
                </h2>
            </div>

            <div class="my-3">
                <slot name="views" />
            </div>
        </div>

        <!-- Table Content -->
        <div>
            <template v-if="loading"><Loader :show="loading" /></template>

            <div
                v-else
                :aria-label="heading"
                :aria-rowcount="rows.length"
                class="min-h-[500px] flex flex-col"
                role="table"
            >
                <!-- Table Filters -->
                <div
                    v-show="showTableFilters"
                    class="flex flex-col justify-start w-full p-4 space-y-4 shadow-inner bg-gray-50 md:flex-row md:space-x-4 md:space-y-0 md:justify-end lg:space-x-8 lg:px-8"
                >
                    <slot name="filters" />
                    <template v-if="exportable">
                        <DataExport
                            :filename="heading.replace(' ', '')"
                            :url="`${baseLink}/export`"
                            :has-border="false"
                        />
                    </template>
                </div>

                <!-- Table Body -->
                <div
                    class="relative flex flex-col justify-between w-full px-4 pt-5 grow"
                >
                    <div
                        v-if="showTableToggle"
                        class="w-32 mb-3 ml-auto lg:mb-0 whitespace-nowrap lg:top-5 lg:py-5 lg:h-[60px] lg:absolute lg:right-4"
                    >
                        <PulseToggle
                            :initial="false"
                            :label="toggleLabel"
                            @toggle="(e) => (expandAll = e)"
                        />
                    </div>

                    <div class="overflow-x-auto">
                        <div class="w-max mxl:w-full">
                            <div
                                :style="{
                                    gridTemplateColumns: templateColumns,
                                }"
                                class="grid w-auto bg-white"
                                role="row"
                            >
                                <PulseActivityTableColumnHeader
                                    v-for="(column, index) in columns"
                                    role="columnheader"
                                    :key="column.header"
                                    :label="column.header"
                                    :sortable="column.sort || false"
                                    :primary="column.primary || false"
                                    :initial-sort-dir="
                                        getInitialSortDirection(column.header)
                                    "
                                >
                                    <template
                                        #checkbox
                                        v-if="selectableOn && index === 0"
                                    >
                                        <PulseActivityTableCheckbox
                                            :value="allSelected"
                                            @row-selected="checkAll"
                                        />
                                    </template>
                                </PulseActivityTableColumnHeader>
                            </div>
                            <!-- Table Data -->
                            <component
                                v-for="(row, index) in rows"
                                :is="rowAs"
                                :key="index"
                                :expanded="expandAll"
                                :row-grid-template="templateColumns"
                                :row="row"
                                :aria-rowindex="index"
                                role="row"
                            >
                                <template #checkbox v-if="selectableOn">
                                    <PulseActivityTableCheckbox
                                        :value="
                                            isRowSelected(row[selectableOn])
                                        "
                                        @row-selected="
                                            updateSelectedRows(
                                                row[selectableOn]
                                            )
                                        "
                                    />
                                </template>
                            </component>
                        </div>
                    </div>

                    <PulsePagination
                        :current-page="pagination.current_page"
                        :last-page="pagination.last_page"
                        :per-page="pagination.per_page"
                        :total="pagination.total"
                        :from="pagination.from"
                        :to="pagination.to"
                        @page-updated="(e) => $emit('page-updated', e)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import _ from "lodash";
import { defineComponent, PropType } from "vue";
import { mapGetters, mapState } from "vuex";

export default defineComponent({
    props: {
        columns: Array as PropType<Array<any>>,
        exportable: {
            type: Boolean,
            default: false,
        },
        filters: Array,
        loading: Boolean,
        rows: {
            type: Array as PropType<Array<any>>,
            required: true,
        },
        rowAs: [String, Object],
        selectableOn: {
            type: String,
            required: false,
            default: "",
        },
        sort: {
            type: Object,
            required: false,
            default: () => {},
        },
        hasViews: {
            type: Boolean,
            default: false,
        },
        tableName: {
            type: String,
            required: false,
            default: "",
        },
        pagination: {
            type: Object,
            required: true,
        },
    },
    data(): any {
        return {
            expandAll: false,
            sortValue: "",
            selectedRows: [] as any[],
        };
    },
    computed: {
        ...mapState(["view"]),
        ...mapGetters(["baseLink", "viewContext"]),
        heading() {
            return this.hasViews
                ? _.capitalize(this.view) + " View"
                : this.tableName;
        },
        showTableFilters() {
            return this.filters || this.exportable;
        },
        showTableToggle() {
            if (this.hasViews) {
                return this.view === "structured" || this.view === "timeline";
            }
        },
        templateColumns() {
            return this.columns
                ?.map((column) =>
                    column.width
                        ? `minmax(140px, ${column.width}%)`
                        : "minmax(140px, 1fr)"
                )
                .join(" ");
        },
        toggleLabel() {
            return this.view === "timeline" ? "Collapse All" : "Expand All";
        },
        allSelected() {
            return this.selectedRows.length === this.rows.length;
        },
    },
    methods: {
        isRowSelected(current) {
            return this.selectedRows.includes(current);
        },
        updateSelectedRows(current) {
            this.isRowSelected(current)
                ? (this.selectedRows = this.selectedRows.filter(
                      (row) => row !== current
                  ))
                : this.selectedRows.push(current);
        },
        checkAll() {
            this.selectedRows = !this.allSelected
                ? this.rows.map((row: any) => row[this.selectableOn ?? ""])
                : [];
        },
        getInitialSortDirection(columnName) {
            let col = columnName.toLowerCase();

            return this.sort?.hasOwnProperty(col) ? this.sort[col] : "";
        },
    },
    watch: {
        selectedRows(value) {
            return this.$emit("selected-rows", value);
        },
    },
});
</script>
