<template>
    <div class="mb-3 sm:mb-1 last:mb-0">
        <label
            class="flex sm:inline-flex items-center justify-between sm:justify-start"
        >
            <div class="flex items-center">
                <input
                    type="checkbox"
                    class="form-checkbox text-pear-600"
                    v-model="proxy"
                />
                <span class="leading-6 mx-2">{{ category.name }}</span>
            </div>
            <div
                class="inline-block bg-gray-700 text-gray-50 rounded leading-5 px-2 text-xs"
            >
                {{ count }}
            </div>
        </label>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        count: Number,
        name: String,
        initialValue: Boolean,
        category: Object,
    },
    data(): any {
        return {
            proxy: false,
        };
    },
    mounted() {
        this.proxy = this.initialValue;
    },
    watch: {
        proxy: {
            handler() {
                this.$emit("update", this.proxy);
            },
        },
        modelValue(value) {
            this.proxy = this.value;
        },
    },
};
</script>
