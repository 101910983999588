<script lang="ts">
import { PropType } from "vue";
import { decimalToPercentLabel } from "../../../utils";

export default {
    props: {
        expanded: Boolean,
        learners: Array as PropType<any[]>,
    },
    computed: {
        learnersLessonComplete() {
            return (
                this.learners?.filter(
                    (learner) => learner.lesson_views_complete
                ).length ?? 0
            );
        },
    },
    methods: {
        decimalToPercentLabel,
    },
};
</script>
<template>
    <PulseActivityTableRow row-grid-template="minmax(140px, 15%) auto" nested>
        <PulseActivityTableCell>
            <Pill
                :background-color="'butter-300'"
                :text-color="'color'"
                class="inline-flex"
            >
                Assessment
            </Pill>
        </PulseActivityTableCell>

        <PulseActivityTableCell :padded="false">
            <PulseLearnCompletionsRow
                :expanded="expanded"
                :completed-count="learnersLessonComplete"
                :total-count="learners?.length"
            >
                <PulseLearnCompletionsRowTable
                    :columns="[
                        {
                            key: 'status',
                            label: 'Status',
                        },
                        {
                            key: 'attempts',
                            label: 'Attempts',
                        },
                        {
                            key: 'avg_score',
                            label: 'Avg Score',
                        },
                    ]"
                    :data="learners"
                >
                    <template v-slot:status="{ row }">
                        <CompletionStatus
                            :completed="row.assessment_complete"
                        />
                    </template>
                    <template v-slot:attempts="{ row }">
                        <span class="font-semibold">{{
                            row.assessment_attempts
                        }}</span>
                    </template>
                    <template v-slot:avg_score="{ row }">
                        <span class="font-semibold">{{
                            decimalToPercentLabel(row.assessment_avg_score)
                        }}</span>
                    </template>
                </PulseLearnCompletionsRowTable>
            </PulseLearnCompletionsRow>
        </PulseActivityTableCell>
    </PulseActivityTableRow>
</template>
