import defaultStore from "./default-store";
import pulseStore from "./pulse-store";
import prepStore from "./prep-store";
import reportingStore from "./reporting-store";

const store = function () {
    switch (window.location.pathname.split("/")[1]) {
        case "pulse":
            return pulseStore;
            break;
        case "reporting":
            return reportingStore;
            break;
        case "prep":
            return prepStore;
            break;
        default:
            return defaultStore;
    }
};

export default store;
