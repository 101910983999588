type StatusMatchFunction<TData, TError> = (
    response: Response
) => Promise<{ data: TData; error: TError }>;

interface ResponseStatusMatch<TData, TError> {
    [key: number]: StatusMatchFunction<TData, TError>;
    "200s"?: StatusMatchFunction<TData, TError>;
    "300s"?: StatusMatchFunction<TData, TError>;
    "400s"?: StatusMatchFunction<TData, TError>;
    "500s"?: StatusMatchFunction<TData, TError>;
    default: StatusMatchFunction<TData, TError>;
}
export function responseMatch<TData, TError = unknown>(
    response: Response,
    match: ResponseStatusMatch<TData | null, TError | null>
) {
    const responseCode = response.status;
    // first check if there is an exact match

    if (match[responseCode]) {
        return match[responseCode](response);
    }

    // now check ranges

    if (responseCode >= 200 && responseCode < 300 && match["200s"]) {
        return match["200s"](response);
    }

    if (responseCode >= 300 && responseCode < 400 && match["300s"]) {
        return match["300s"](response);
    }

    if (responseCode >= 400 && responseCode < 500 && match["400s"]) {
        return match["400s"](response);
    }

    if (responseCode >= 500 && responseCode < 600 && match["500s"]) {
        return match["500s"](response);
    }

    // if no match, return default
    return match.default(response);
}
