<template>
    <TitleBar
        :action="''"
        :actionPath="''"
        :app="'Content'"
        :icon="'chalkboard'"
        :moduleTitle="'moduleTitle'"
        :resourceTitle="'resourceTitle'"
        :searchable="['Lesson']"
        :search-addition="''"
        :section="''"
        :sectionPath="''"
        :showBilling="false"
        :impersonating="false"
    />
</template>

<script lang="ts">
import { mapState } from "vuex";
import TitleBar from "../../../_layout/TitleBar.vue";

export default {
    components: {
        TitleBar,
    },
    props: {},
    computed: {
        ...mapState(["activePath", "user"]),
    },
    methods: {},
};
</script>
