<template>
    <div class="relative bg-white rounded-md shadow overflow-hidden p-8">
        <!--
        <div class="absolute flex justify-center inset-x-0 left-1/2 transform -translate-x-1/2 w-full overflow-hidden bottom-0">
            <svg
                class="flex-shrink-0"
                :class="shift"
                width="1440"
                height="320"
                viewBox="0 0 1440 320">
                <path
                    :fill="colors[0]"
                    :fill-opacity="opacities[0]"
                    d="M0,64L48,74.7C96,85,192,107,288,138.7C384,171,480,213,576,218.7C672,224,768,192,864,192C960,192,1056,224,1152,202.7C1248,181,1344,107,1392,69.3L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"/>
                <path
                    :fill="colors[1]"
                    :fill-opacity="opacities[1]"
                    d="M0,256L48,245.3C96,235,192,213,288,213.3C384,213,480,235,576,213.3C672,192,768,128,864,117.3C960,107,1056,149,1152,192C1248,235,1344,277,1392,298.7L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"/>
            </svg>
        </div>
        -->
        <div class="relative">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        colors: Array,
        opacities: Array,
        shift: String,
    },
};
</script>
