<template>
    <div>
        <div class="flex justify-between align-content-center">
            <h1 class="text-2xl font-semibold tracking-tight align-content-center m-0">
                Question Banks
            </h1>
            <Menu as="div" class="relative" v-slot="{ open }">
                <MenuButton
                    class="button main small group inline-flex items-center rounded-md text-base font-medium"
                    :class="{
                    }"
                >+ Add Question Bank</MenuButton>
                <Transition
                    enter-active-class="transition duration-100 ease-out"
                    enter-from-class="transform opacity-0 -translate-y-4"
                    enter-to-class="transform opacity-100 translate-y-0"
                    leave-active-class="transition duration-75 ease-out"
                    leave-from-class="transform opacity-100 translate-y-0"
                    leave-to-class="transform opacity-0 -translate-y-4"
                >
                    <MenuItems
                        class="absolute divide-y left-1/2 z-10 mt-3 flex flex-col w-screen max-w-[200px] -translate-x-1/2 transform overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
                    >
                    <MenuItem
                            as="template"
                            v-slot="{ active }"
                        >
                            <button
                                class="hover:bg-blue-50 p-3.5 hover:text-blue-700 hover:no-underline text-sm font-medium text-gray-600 flex items-center gap-2"
                                @click="handleFileUploadInputClick"
                            >
                                <input @change="handleFileUploadInputChange" ref="file" id="file" type="file" style="display:none" accept=".xlsx" />
                                <ArrowDownTrayIcon class="w-6 h-6"/> Upload Launch Doc
                            </button>
                        </MenuItem>
                    </MenuItems>
                </Transition>
            </Menu>
        </div>
        <table
            class="table-auto border-collapse border border-gray-200 w-full mt-4 shadow overflow-hidden rounded-md"
        >
            <tbody>
                <tr
                    v-for="bank in banks"
                    :key="bank.id"
                    class="border border-gray-200 text-gray-700 bg-white"
                >
                    <td class="py-5 px-6">
                        <RouterLink
                            :to="`/prep/banks/${bank.id}`"
                            class="text-blue-600 underline hover:text-blue-800 hover:no-underline"
                            >{{ bank.name }}</RouterLink
                        >
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup lang="ts">
import { Bank } from "@/types";
import axios from "axios";
import { ref } from "vue";
import { RouterLink } from "vue-router";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { PlusIcon, ArrowDownTrayIcon } from "@heroicons/vue/20/solid";
import Button from "@/components/ui/ButtonLinks/Button.vue";
import $events from "@/event-bus.ts";

const file = ref<HTMLInputElement | null>(null);
const banks = ref<Bank[]>([]);

const handleFileUploadInputClick = () => {
    file.value?.click();
}

const handleFileUploadInputChange = async (event: Event) => {
    const formData = new FormData();
    const file = (event?.target as HTMLInputElement)?.files?.[0];

    if (file) {
        formData.append("launch-sheet", file);
    }

    try {
        const res = await axios.post('/api/prep/question-banks/upload-launch-sheet', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        if (res.status === 202) {
            $events.$emit("toastNotification", {
                action: "saved",
                heading: "Question Bank Launch Sheet Submitted Successfully",
                message: "You'll receive an email when the upload process is complete.",
                status: "saved",
            });
        }
    } catch (e: any) {
        $events.$emit("toastNotification", {
            action: "error",
            heading: `Error Uploading Question Bank Launch Sheet`,
            message: e?.response
                ? `${Object.values(e?.response?.data?.errors).join(" ")}`
                : 'Something unexpected occurred.',
            status: "error",
        });
    }
};

const fetchBanks = async () => {
    try {
        const response = await axios.get("/api/prep/question-banks");
        banks.value = response.data;
    } catch (error) {
        console.error("An error occurred while fetching the banks:", error);
    }
};

fetchBanks();
</script>
