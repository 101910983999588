<template>
    <button class="group/bookmark flex items-center gap-1">
        <svg
            class="group-hover/bookmark:hidden h-full w-auto"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.195 2.49181C14.0204 2.5876 14.625 3.29925 14.625 4.13024V15.75L9 12.9375L3.375 15.75V4.13024C3.375 3.29925 3.97956 2.5876 4.80501 2.49181C6.18117 2.3321 7.581 2.25 9 2.25C10.419 2.25 11.8188 2.3321 13.195 2.49181Z"
                stroke="#0F172A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
        <svg
            class="group-hover/bookmark:inline-flex hidden h-full w-auto text-blue-700"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.74017 1.93306C6.1378 1.77086 7.55928 1.6875 9 1.6875C10.4407 1.6875 11.8622 1.77086 13.2598 1.93306C14.3829 2.06339 15.1875 3.02792 15.1875 4.13024V15.75C15.1875 15.945 15.0866 16.126 14.9207 16.2285C14.7549 16.331 14.5478 16.3403 14.3734 16.2531L9 13.5664L3.62656 16.2531C3.45219 16.3403 3.24511 16.331 3.07928 16.2285C2.91344 16.126 2.8125 15.945 2.8125 15.75V4.13024C2.8125 3.02792 3.61712 2.06339 4.74017 1.93306Z"
                fill="#004EC0"
            />
        </svg>
        <span class="text-sm font-medium group-hover/bookmark:text-blue-700"
            >Bookmark</span
        >
    </button>
</template>
