<template>
    <div>
        <PulseExploreLayout :legacy="legacy" v-bind="$attrs">
            <PulseExploreChart :legacy="legacy" />
            <PulseExploreDetailsListingMeta
                :legacy="legacy"
                :count="count"
                :typeData="typeData"
            />
            <Placeholder
                v-if="!typeData"
                class="mt-20"
                label="Lesson not found"
            >
                Sorry! We could not find a lesson in our platform to match your
                search.
            </Placeholder>
            <PulseExploreDetailsListingLearners
                v-if="(rows?.length ?? 0) > 0"
                :legacy="legacy"
                :rows="rows"
            />
        </PulseExploreLayout>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        legacy: Boolean,
        typeData: Object,
        rows: Array as PropType<any[]>,
    },
    computed: {
        count() {
            return this.rows?.reduce((carry, row) => row.count + carry, 0);
        },
    },
});
</script>
