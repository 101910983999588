<template>
    <div class="flex-1">
        <div
            class="bg-white border-b border-gray-100 rounded-md space-y-16 p-4 sm:p-6 pt-6"
            v-if="customer"
        >
            <Alert
                :type="messageType"
                :message="message"
                :title="messageTitle"
                v-if="message"
            />
            <BillingActiveSubscriptions
                :customer="customer"
            ></BillingActiveSubscriptions>
            <BillingPaymentMethods :customer="customer"></BillingPaymentMethods>
            <BillingBillingInfo :customer="customer"></BillingBillingInfo>
            <BillingInvoiceHistory :customer="customer"></BillingInvoiceHistory>
        </div>
    </div>
</template>
<script lang="ts">
import axios from "axios";
import { mapState } from "vuex";
import BillingActiveSubscriptions from "./BillingActiveSubscriptions.vue";
import BillingInvoiceHistory from "./BillingInvoiceHistory.vue";
import BillingBillingInfo from "./BillingBillingInfo.vue";
import BillingPaymentMethods from "./BillingPaymentMethods.vue";

declare const Stripe: any;

export default {
    components: {
        BillingActiveSubscriptions,
        BillingPaymentMethods,
        BillingBillingInfo,
        BillingInvoiceHistory,
    },
    data(): any {
        return {
            customer: null,
            stripe: new Stripe("pk_test_jewtLBEWv3s1FrGh31BJ8TGm"),
            message: "",
            messageTitle: "",
            messageType: "",
        };
    },
    computed: {
        ...mapState(["user"]),
    },
    methods: {
        getBillingInfo() {
            axios
                .get(`/api/users/${this.user.uuid}/billing-info`)
                .then((response) => {
                    this.customer = response.data;
                });
        },
        checkStripeIntent() {
            // Retrieve the "setup_intent_client_secret" query parameter appended to
            // your return_url by Stripe.js
            const clientSecret = new URLSearchParams(
                window.location.search
            ).get("setup_intent_client_secret");
            if (!clientSecret) return;

            // Retrieve the SetupIntent
            this.stripe
                .retrieveSetupIntent(clientSecret)
                .then(({ setupIntent }) => {
                    switch (setupIntent.status) {
                        case "succeeded": {
                            this.messageType = "success";
                            this.message =
                                "Success! Your payment method has been saved.";
                            break;
                        }

                        case "processing": {
                            this.messageType = "info";
                            this.message =
                                "Processing payment details. We'll update you when processing is complete.";
                            break;
                        }

                        case "requires_payment_method": {
                            this.messageType = "error";
                            this.message =
                                "Failed to process payment details. Please try another payment method.";
                            break;
                        }
                    }
                });
        },
    },
    mounted() {
        this.getBillingInfo();
        this.checkStripeIntent();
    },
};
</script>
