<template>
    <div class="table-heading flex items-center space-x-2">
        <div>
            <slot></slot>
        </div>
        <SvgIcon
            v-if="sortable && sortBy !== 'true'"
            icon="chevron-y"
            class="text-gray-300"
            size="xs"
            weight="extra-bold"
        />
        <SvgIcon
            v-if="sortable && sortable === 'asc' && sortBy === 'true'"
            icon="chevron-up"
            size="xs"
            weight="extra-bold"
        />
        <SvgIcon
            v-if="sortable && sortable === 'desc' && sortBy === 'true'"
            icon="chevron-down"
            size="xs"
            weight="extra-bold"
        />
    </div>
</template>

<script lang="ts">
export default {
    props: {
        sortable: String,
        sortBy: String,
    },
};
</script>
