<template>
    <button
        type="button"
        class="text-gray-500 ml-2"
        :class="{ 'text-gray-900': active }"
        @click="toggleSort"
    >
        <SvgIcon v-if="order == 'desc'" icon="sortDesc" size="sm" />
        <SvgIcon v-if="order == 'asc'" icon="sortAsc" size="sm" />
    </button>
</template>

<script lang="ts">
export default {
    props: {
        emit: String,
        initial: String,
        variable: String,
    },
    data(): any {
        return {
            active: false,
            order: "desc",
        };
    },
    methods: {
        toggleSort() {
            this.order = this.order == "desc" ? "asc" : "desc";
            this.$events.$emit(this.emit, {
                sortBy: this.variable,
                sortOrder: this.order,
            });
        },
    },
    mounted() {
        this.$events.$on(this.emit, (response) => {
            this.order =
                response.sortBy == this.variable ? this.order : this.initial;
            this.active = response.sortBy == this.variable;
        });
    },
};
</script>

<style scoped>
.icon {
    display: block !important;
}
</style>
