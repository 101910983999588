<template>
    <div class="card">
        <div class="bg-gray-50 border-b border-gray-100 rounded-t-md p-8">
            <h2 class="font-bold">History</h2>
        </div>
        <table class="w-full">
            <tbody>
                <ContentQuestionHistoryListItem
                    v-for="item in history"
                    :questionType="questionType"
                    :historyItem="item"
                    :key="item.id"
                    :inModal="inModal"
                />
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import { PropType } from "vue";
export default {
    props: {
        history: {
            type: Array as PropType<any[]>,
            default: () => [],
        },
        inModal: {
            type: Boolean,
            default: false,
        },
        questionType: {
            type: String,
            default: "prep",
        },
    },
};
</script>
