<script setup lang="ts">
import axios from "axios";
import { useStore } from "vuex";
import FullPagination from "../../../_elements/FullPagination.vue";
import { onMounted, ref } from "vue";
import AddLessonToModule from "./AddLessonToModule.vue";
import Link from "@/components/ui/ButtonLinks/Link.vue";
import { ArrowsUpDownIcon, PencilIcon } from "@heroicons/vue/24/outline";
import $events from "@/event-bus.ts";
import SelectDropdown from "@/components/_elements/SelectDropdown.vue";
import ActionMenuAction from "@/components/_elements/ActionMenuAction.vue";
import ActionMenu from "@/components/_elements/ActionMenu.vue";

const store = useStore();

const props = defineProps<{
    uuid?: string;
}>();

const data = ref<any>({
    module: null,
    lessons: [],
    path: window.location.pathname + "/",
    pagination: {
        currentPage: 1,
        lastPage: 10,
        perPage: 1,
        total: 0,
        from: 1,
        to: 10,
    },
});

const getLessons = (pageNumber) => {
    axios
        .post(`/api/admin/content/learn/lessons`, { page: pageNumber })
        .then((response) => {
            data.value.lessons = response.data.data;
            data.value.pagination.perPage = response.data.per_page;
            data.value.pagination.total = response.data.total;
            data.value.pagination.currentPage = response.data.current_page;
            data.value.pagination.from = response.data.from;
            data.value.pagination.to = response.data.to;
            data.value.pagination.lastPage = response.data.last_page;
            data.value.publishedStatuses = {};

            data.value.lessons.forEach((l) => {
                data.value.publishedStatuses[l.uuid] = { uuid: l.uuid, status: l.status }
            });
        });
};
const getModuleLessons = (pageNumber) => {
    axios
        .post(`/api/admin/content/learn/modules/${props.uuid}/lessons`, {
            page: pageNumber,
        })
        .then((response) => {
            data.value.module = response.data.data[0];
            data.value.lessons = data.value.module.lessons;
            data.value.pagination.perPage = response.data.per_page;
            data.value.pagination.total = response.data.total;
            data.value.pagination.currentPage = response.data.current_page;
            data.value.pagination.from = response.data.from;
            data.value.pagination.to = response.data.to;
            data.value.pagination.lastPage = response.data.last_page;
            data.value.publishedStatuses = {};

            data.value.module.lessons.forEach((l) => {
              data.value.publishedStatuses[l.uuid] = { uuid: l.uuid, status: l.status }
            });
        });
};

const handleToggleClick = (status, lesson) => {
  if (lesson.status === status) {
    return;
  }

  axios
      .post(
          `/api/admin/content/learn/lessons/${lesson.uuid}`,
          { ...lesson, status }
      )
      .then((response) => {
          $events.$emit("toastNotification", {
            action: "saved",
            heading: "Lesson saved",
            status: "success",
          });
      })
      .catch((e) => {
        $events.$emit("toastNotification", {
          action: "saved",
          heading: "Error Saving Lesson",
          status: "error",
          message: e.response.data.message
        });
      });
}

const handleRemoveLessonFromModule = ({ lessonUuid, moduleUuid }) => {
  axios.delete(`/api/admin/content/learn/modules/${moduleUuid}/lessons/${lessonUuid}`)
      .then((response) => {
        // DELETE endpoints return 204 on success
        if (response.status === 204) {
          getData();
          $events.$emit("toastNotification", {
            action: "saved",
            heading: "Lesson successfully detached from module",
            status: "saved",
          });
        }
      })
      .catch((e) => {
        console.error(e)
        $events.$emit("toastNotification", {
          action: "error",
          heading: `Error detaching lesson from module: ${e}`,
          status: "error",
        });
      });
}

const getData = () => {
  if (!!props.uuid) {
    getModuleLessons(1);
  } else {
    getLessons(1);
  }
}

onMounted(() => {
    store.dispatch("hideBladePage");
    getData();
});
</script>
<template>
    <div>
        <div v-if="data.module" class="w-full flex flex-col gap-2">
            <!-- Breadcrumbs -->
            <div class="text-sm text-gray-500 mb-4">
                <router-link to="/content/learn/courses">Courses</router-link>
                > Course Name > {{ data.module?.title }}
            </div>

            <div
                class="flex flex-col md:flex-row md:items-center md:justify-between gap-3 md:gap-0"
            >
                <div class="flex flex-col flex-wrap">
                    <h2 class="text-2xl font-semibold tracking-tight">
                        {{ data.module?.title ?? "" }}
                    </h2>
                    <div>
                        <div
                            class="flex flex-row flex-wrap items-center space-x-2"
                        >
                            <div class="flex items-center">
                                <p
                                    class="inline-flex text-xs text-gray-600 italic"
                                >
                                    {{ data.module.slug }}
                                </p>
                                <CopyAction
                                    :value="data.module.slug"
                                    :label="`${data.module.slug}`"
                                />
                            </div>
                            <div class="text-xs flex flex-row items-center">
                                <span class="font-semibold">ID</span>
                                <CopyAction
                                    :value="data.module.id"
                                    :label="`${data.module.id}`"
                                />
                            </div>
                            <div class="text-xs flex flex-row items-center">
                                <span class="font-semibold">UUID</span>
                                <CopyAction
                                    :value="data.module.uuid"
                                    :label="`${data.module.uuid}`"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex md:justify-end gap-2 items-center flex-wrap">
                    <div v-if="data.module" class="flex flex-row gap-2">
                        <Link
                                intent="flat"
                                size="sm"
                                :to="{
                                name: 'module.edit',
                                params: { uuid: data.module.uuid },
                            }"
                                class="gap-2 !w-auto"
                        >
                            <PencilIcon class="w-4 h-4" /> Edit Module
                        </Link>
                        <Link
                            intent="flat"
                            size="sm"
                            :to="{
                                name: 'module.edit.lesson-order',
                                params: { uuid: data.module.uuid },
                            }"
                            class="gap-2 !w-auto"
                        >
                            <ArrowsUpDownIcon class="w-4 h-4" /> Reorder
                        </Link>
                        <AddLessonToModule :module="data.module" @close="getData"/>
                    </div>
                    <Link
                        size="sm"
                        :to="{ name: 'module_lessons.add', params: { uuid: data.module.uuid } }"
                        class="!w-auto"
                    >
                        <div>&plus; New</div>
                    </Link>
                </div>
            </div>
        </div>

        <div class="rounded-md shadow divide-y mt-6">
            <div
                class="flex items-center bg-gray-200 border-b-2 border-gray-200 text-xs text-gray-600 uppercase font-semibold leading-none rounded-t-md pt-6 pb-5"
            >
                <div class="flex items-center justify-between px-6 w-1/5"

                >
                    Lesson Name
                </div>
                <div class="flex items-center justify-between px-6 w-1/5"

                >
                    Slug
                </div>
                <div class="flex items-center justify-between px-6 w-1/5"

                >
                    Courses
                </div>
              <div class="flex items-center justify-between px-6 w-1/5"

              >
                Status
              </div>
              <div class="flex items-center justify-between px-6 w-1/5"></div>
            </div>
            <div>
                <div
                    v-for="l in data.lessons"
                    :key="l.uuid"
                    class="flex items-center bg-white text-sm even:bg-gray-50 last:rounded-b-md"
                >
                    <div class="p-6 w-1/5"

                    >
                        <router-link
                            class="cta"
                            :to="{ name: 'module_lessons.edit', params: { lessonUuid: l.uuid, moduleUuid: data.module.uuid } }"
                        >
                            {{ l.title }}
                        </router-link>
                    </div>
                    <div class="w-1/5 p-6"

                    >
                        {{ l.slug }}
                    </div>
                    <div class="p-6 w-1/5"

                    >
                        <ul
                            v-for="c in l.courses"
                            class="list-disc list-outside"
                        >
                            <li>{{ c.title }}</li>
                        </ul>
                    </div>
                  <div v-if="data.publishedStatuses" class="w-1/5 p-6">
                    <SelectDropdown
                        v-model="data.publishedStatuses[l.uuid].status"
                        variant="status"
                        @input="(e) => handleToggleClick(e.target.value, l)"
                    >
                      <option value="published">Published</option>
                      <option value="draft">Draft</option>
                    </SelectDropdown>
                  </div>
                  <div class="w-1/5 p-6 flex justify-end">
                    <ActionMenu>
                      <ActionMenuAction
                          type="submit"
                          label="Remove Lesson From Module"
                          icon="close-circle"
                          icon-color="text-red-500"
                          :use-modal="'true'"
                          :message="`Are you sure you want to remove ${l.title} module from ${data?.module?.title} module? This will not delete the lesson permanently, just detach it from the module.`"
                          :action="handleRemoveLessonFromModule"
                          :argument="{ 'lessonUuid': l.uuid, 'moduleUuid': data?.module?.uuid }"
                      ></ActionMenuAction>
                    </ActionMenu>
                  </div>
                </div>
            </div>
            <FullPagination
                class="p-6"
                :current-page="data.pagination.currentPage"
                :last-page="data.pagination.lastPage"
                :per-page="data.pagination.perPage"
                :total="data.pagination.total"
                :from="data.pagination.from"
                :to="data.pagination.to"
                @page-updated="getLessons"
            />
        </div>
    </div>
</template>
