<template>
    <tr class="history-item">
        <td>
            <div class="status" :class="historyItem.status"></div>
        </td>
        <td>
            <div class="flex items-center text-sm w-48">
                <SvgIcon class="text-blue-600 mr-2" icon="clock" size="sm" />
                <div
                    v-text="
                        $filters.dateTime(historyItem.created_at, user.timezone)
                    "
                />
            </div>
        </td>
        <td>
            <div class="text-sm w-48">
                {{ historyUser.name }}
            </div>
        </td>
        <td class="note">
            <div class="flex items-center text-sm">
                <SvgIcon
                    v-if="historyItem.flagged"
                    class="text-red-700 mr-2"
                    icon="flag"
                    size="sm"
                />
                {{ historyItem.note }}
            </div>
        </td>
        <td v-if="!inModal">
            <button
                type="button"
                class="flex items-center justify-center transition duration-300 bg-red-50 text-red-600 hover:text-white hover:bg-red-600 rounded-lg w-9 h-9"
                @click="remove(historyItem)"
            >
                <SvgIcon icon="close" size="xs" weight="extra-bold" />
            </button>
        </td>
    </tr>
</template>

<script lang="ts">
import { mapState } from "vuex";
import axios from "axios";

export default {
    props: {
        historyItem: {
            type: Object,
            default: () => {},
        },
        inModal: Boolean,
        questionType: {
            type: String,
            default: "prep",
        },
    },
    data(): any {
        return {
            historyUser: {
                name: "Anonymous user",
            },
        };
    },
    computed: {
        ...mapState(["user"]),
        path() {
            return "/api/" + this.questionType + "/questions/history/";
        },
    },
    methods: {
        remove(historyItem) {
            axios.delete(this.path + historyItem.id).then((response) => {
                this.$events.$emit(
                    "prepQuestionHistoryItemRemoved",
                    historyItem
                );
            });
        },
    },
    mounted() {
        this.$nextTick(
            () => (this.historyUser = this.historyItem.user || this.historyUser)
        );
    },
};
</script>

<style scoped>
.history-item {
    @apply border-b w-full;
}

.history-item td {
    @apply p-4;
}

.history-item td.note {
    @apply w-full;
}

.history-item:last-child {
    @apply border-b-0;
}
</style>
