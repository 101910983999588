<template>
    <div
        v-if="show"
        class="flex items-center justify-center"
        :class="[
            {
                'h-full min-h-24': !height,
                'min-h-52': padding,
            },
            height,
        ]"
    >
        <SvgIcon
            class="text-gray-500 animate-ping"
            icon="circles"
            size="xl"
            weight="light"
        />
    </div>
</template>

<script lang="ts">
export default {
    props: {
        height: String,
        show: {
            type: Boolean,
            default: true,
        },
        padding: {
            type: Boolean,
            default: true,
        },
    },
};
</script>
