<template>
    <PulseActivityTableRow :row-grid-template="rowGridTemplate">
        <PulseActivityTableCell
            v-slot="{ asLink }"
            :row-span="row.modules.length"
        >
            <router-link
                :to="courseLink(row.uuid)"
                class="font-medium text-blue-600 hover:text-blue-700"
            >
                {{ row.title }}
            </router-link>
        </PulseActivityTableCell>

        <PulseActivityTableRow
            v-for="(mod, index) in row.modules"
            :key="row.uuid + '-module-' + index"
            row-grid-template="minmax(325px, 30%) auto"
            nested
        >
            <PulseActivityTableCell v-slot="{ asLink }">
                <router-link
                    :to="moduleLink(mod.uuid)"
                    class="font-medium text-blue-600 hover:text-blue-700"
                >
                    {{ mod.title }}
                </router-link>
            </PulseActivityTableCell>

            <PulseActivityTableCell :padded="false">
                <PulseLearnCompletionsRow
                    :expanded="expanded"
                    :completed-count="getCompletionsForModule(mod)"
                    :total-count="getTotalLearnersForModule(mod)"
                >
                    <PulseLearnCompletionsRowTable
                        :columns="[
                            {
                                key: 'status',
                                label: 'Status',
                            },
                        ]"
                        :data="mod.learners"
                    >
                        <template v-slot:status="{ row }">
                            <CompletionStatus :completed="row.completed" />
                        </template>
                    </PulseLearnCompletionsRowTable>
                </PulseLearnCompletionsRow>
            </PulseActivityTableCell>
        </PulseActivityTableRow>
    </PulseActivityTableRow>
</template>

<script lang="ts">
import { PropType } from "vue";

export default {
    props: {
        expanded: Boolean,
        row: Object as PropType<any>,
        rowGridTemplate: String,
    },
    methods: {
        getCompletionsForModule(mod) {
            return mod.learners?.filter((learner) => learner.completed).length;
        },
        getTotalLearnersForModule(mod) {
            return mod.learners?.length;
        },
        moduleLink(moduleUuid) {
            const query = this.$route.query;
            // remove course and module from query
            delete query.course;
            delete query.module;
            return {
                path: "/module/" + moduleUuid + "/structured",
                query: { ...query, page: 1 },
            };
        },
        courseLink(courseUuid) {
            const query = this.$route.query;
            // remove course and module from query
            delete query.course;
            delete query.module;
            return {
                path: "/course/" + courseUuid + "/activity/structured",
                query: { ...query, page: 1 },
            };
        },
    },
};
</script>
