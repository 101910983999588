<template>
    <div>
        <template v-if="!loading">
            <div v-if="rows.length > 0">
                <h3
                    class="flex items-center justify-between bg-white text-gray-800 border-l-4 border-pear-600 p-4 rounded shadow mb-12"
                >
                    <div class="flex items-center w-128">
                        <svg-icon class="text-pear-600 mr-2" :icon="typeIcon" />
                        <div>
                            {{ typeTitle }}:
                            <strong class="font-semibold ml-1">{{
                                assessment.assessment_title
                            }}</strong>
                        </div>
                    </div>
                    <div class="flex items-center">
                        <div class="text-center text-lg px-8">
                            <strong class="text-pear-600 font-bold">{{
                                assessmentData.taken
                            }}</strong>
                            <h4 class="text-sm uppercase mt-2">Taken</h4>
                        </div>
                        <div class="text-center text-lg px-8">
                            <strong class="text-pear-600 font-bold">{{
                                $filters.grade(assessmentData.avgScore)
                            }}</strong>
                            <h4 class="text-sm uppercase mt-2">Avg Score</h4>
                        </div>
                        <div class="text-center text-lg px-8">
                            <strong class="text-pear-600 font-bold">{{
                                $filters.elapsed(assessmentData.avgDuration)
                            }}</strong>
                            <h4 class="text-sm uppercase mt-2">Avg Duration</h4>
                        </div>
                    </div>
                </h3>
                <PulseAssessmentLearners
                    v-if="scope != 'learner'"
                    :rows="rows"
                />
                <PulseAssessmentAttempts
                    v-if="scope == 'learner'"
                    :rows="rows"
                />
                <PulseAssessmentQuestions
                    :assessment="assessment"
                    :total="assessmentData.taken"
                />
            </div>
            <div class="card p-4" v-if="rows.length == 0">No Assessments</div>
        </template>
        <LoadingSpinner :show="loading" />
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState, mapGetters } from "vuex";

export default {
    data(): any {
        return {
            assessment: {},
            loading: true,
            rows: [],
        };
    },
    computed: {
        ...mapState(["id", "period", "scope", "type", "typeId"]),
        ...mapGetters(["context"]),
        typeIcon() {
            switch (this.type) {
                case "quizzes":
                    return "checklist";
                    break;
                case "assessments":
                    return "exam";
                    break;
            }
        },
        typeTitle() {
            switch (this.type) {
                case "quizzes":
                    return "Quiz";
                    break;
                case "assessments":
                    return "Assessment";
                    break;
            }
        },
    },
    methods: {
        getData() {
            axios
                .get(
                    "/api/pulse/assessment/" +
                        this.context +
                        "/" +
                        this.type +
                        "/" +
                        this.typeId +
                        "/overview"
                )
                .then((response) => {
                    this.assessment = response.data.assessment;
                    this.rows = response.data.rows;
                    this.assessmentData = {
                        taken: response.data.taken,
                        avgScore: response.data.avgScore,
                        avgDuration: response.data.avgDuration,
                    };
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getData();
    },
};
</script>
