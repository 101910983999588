<template>
    <div>
        <slot v-bind="{ openGroupAssignModal }"></slot>
        <base-modal
            :heading="`Assign ${learnerName} to Group`"
            :opened="isOpen"
            @close="close"
        >
            <template v-slot:body>
                <SelectDropdown class="mt-4 w-full" v-model="groupId">
                    <option value="">Choose a group</option>
                    <option
                        v-for="group in groups"
                        :value="group.id.toString()"
                    >
                        {{ group.name }}
                    </option>
                </SelectDropdown>
                <div v-if="errors.group_id" class="text-xs text-red-600 mt-1">
                    {{ errors.group_id[0] }}
                </div>
            </template>
            <template v-slot:actions>
                <button
                    type="button"
                    class="flex items-center justify-center primary button px-4 py-2 text-base sm:text-sm leading-6 w-32"
                    @click="submit"
                >
                    <SvgIcon
                        v-if="isSaving"
                        class="animate-spin"
                        icon="loader"
                    />
                    <span v-else>Submit</span>
                </button>
            </template>
        </base-modal>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import BaseModal from "./BaseModal.vue";

export default {
    props: {
        groups: {
            type: Array,
            default: [],
        },
    },
    data(): any {
        return {
            isOpen: false,
            isSaving: false,
            groupId: "",
            enrollmentId: "",
            learnerName: "",
            errors: {},
        };
    },
    methods: {
        open() {
            this.isOpen = true;
        },
        close() {
            this.isOpen = false;
            this.reset();
        },
        reset() {
            this.groupId = "";
            this.enrollmentId = "";
            this.learnerName = "";
        },
        openGroupAssignModal(groupId, enrollmentId, learnerName) {
            this.groupId = groupId;
            this.enrollmentId = enrollmentId;
            this.learnerName = learnerName;
            this.open();
        },
        submit() {
            this.isSaving = true;
            axios
                .put(`/learn/enrollments/${this.enrollmentId}/access`, {
                    group_id: this.groupId,
                })
                .then(() => {
                    this.isSaving = false;
                    this.close();
                    location.reload();
                })
                .catch(({ response }) => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    }
                    this.isSaving = false;
                });
        },
    },

    components: {
        BaseModal,
    },
};
</script>
