import { ref, watch, computed, defineEmits } from "vue";

const getQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const buyNow = urlParams.get("buynow");
    const plan = urlParams.get("plan");
    const frequency = urlParams.get("frequency");
    return {
        buyNow: buyNow !== null,
        plan,
        frequency,
    };
};

export function useBuyNow() {
    const buyNow = computed(() => getQueryParams().buyNow);
    return buyNow;
}

export function useBuyNowLink() {
    const plan = computed(() => getQueryParams().plan);
    const frequency = computed(() => getQueryParams().frequency);
    return ref(
        `/account/checkout/payment-details?plan=${plan.value}&frequency=${frequency.value}`
    );
}
