<template>
    <section class="section-head">
        <div
            class="fixed top-0 left-0 flex flex-wrap items-center justify-center w-full bg-white shadow sm:justify-between sm:pl-32 sm:pr-8 sm:h-20 z-60"
        >
            <MobileNav />
            <div
                class="fixed flex flex-col items-center justify-between w-full px-6 bg-white sm:relative sm:flex-row sm:px-0 gap-4"
            >
                <SearchDialog />
                <PageSearch
                    v-if="searchable?.length && pageSearch"
                    class="hidden sm:flex"
                    v-bind="{
                        app,
                    }"
                />
                <PageTitle
                    v-else
                    class="hidden sm:flex"
                    v-bind="{
                        action,
                        actionPath,
                        moduleTitle,
                        resourceTitle,
                        app,
                        icon,
                        searchable,
                        section,
                        sectionPath,
                    }"
                />
                <div
                    class="fixed top-0 right-0 flex items-center p-4 mt-1 space-x-2 md:space-x-6 sm:relative sm:mt-0 sm:p-0"
                >
                    <SearchButton v-if="showGlobalSearch" />
                    <PrimarySearch
                        v-else-if="showPrimarySearch"
                        :app="app"
                        :section="section"
                        :searchAddition="searchAddition"
                        :searchable="searchable"
                        :searchClear="searchClear"
                    />
                    <nav
                        class="flex items-center justify-end space-x-2 md:space-x-6"
                    >
                        <MenuSupport />
                        <MenuAccount
                            :initials="user.initials"
                            :avatar="user.asset_url"
                            :impersonating="impersonating"
                            :showBilling="showBilling"
                        />
                    </nav>
                </div>
            </div>
        </div>
    </section>
    <FreeTrialHeaderCta />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import { useSearchStore } from "../../stores/searchStore";
import SearchDialog from "../Search/SearchDialog.vue";
import MenuSupport from "../_elements/MenuSupport.vue";
import MobileNav from "../_elements/MobileNav.vue";
import PageSearch from "../_elements/PageSearch.vue";
import PageTitle from "../_elements/PageTitle.vue";
import SearchButton from "../Search/SearchButton.vue";
import PrimarySearch from "../_elements/PrimarySearch.vue";
import FreeTrialHeaderCta from "@/components/Account/FreeTrialHeaderCta.vue";

export default defineComponent({
    components: {
        FreeTrialHeaderCta,
        PrimarySearch,
        SearchButton,
        PageTitle,
        PageSearch,
        MobileNav,
        SearchDialog,
        MenuSupport,
    },
    props: {
        action: String,
        actionPath: String,
        app: String,
        icon: String,
        moduleTitle: String,
        showGlobalSearch: {
            type: Boolean,
            default: false,
        },
        resourceTitle: String,
        searchable: Array,
        searchAddition: String,
        searchClear: Boolean,
        section: String,
        sectionPath: String,
        showBilling: Boolean,
        impersonating: Boolean,
    },
    computed: {
        ...mapState(["activePath", "pageSearch", "user"]),
        showPrimarySearch() {
            if (this.app === "Reporting") {
                return this.user.role === "admin";
            }
            if (this.app === "Pulse" && this.section !== "Explore") {
                return this.searchable?.length && this.user.role === "admin";
            }

            return (this.searchable?.length ?? 0) > 0;
        },
    },
    methods: {
        analytics: function () {
            let token =
                // @ts-ignore
                document
                    .querySelector('meta[name="token"]')
                    .getAttribute("content") ?? "";
            fetch("/analytics", {
                headers: {
                    "Content-type": "application/json",
                    "X-CSRF-TOKEN": token,
                },
                method: "POST",
                body: JSON.stringify({ event: "Help Articles Visited" }),
            });
        },
    },
});
</script>
