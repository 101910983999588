<script lang="ts">
import StructuredLessonRows from "../../../../Reporting/course/StructuredLessonRows.vue";
import { defineComponent } from "vue";

export default defineComponent({
    components: { StructuredLessonRows },
    props: {
        expanded: Boolean,
        row: Object as any,
        rowGridTemplate: String,
    },
    methods: {
        moduleLink(moduleUuid) {
            return {
                path: "/module/" + moduleUuid + "/structured",
                query: { ...this.$route.query, page: 1 },
            };
        },
    },
});
</script>
<template>
    <PulseActivityTableRow :row-grid-template="rowGridTemplate">
        <PulseActivityTableCell v-slot="{ asLink }" :row-span="20">
            <router-link
                :to="moduleLink(row.module.uuid)"
                class="font-medium text-blue-600 hover:text-blue-700"
            >
                {{ row.module.title }}
            </router-link>
        </PulseActivityTableCell>

        <StructuredLessonRows :learners="row.learners" :expanded="expanded" />
        <StructuredQuizRows :learners="row.learners" :expanded="expanded" />
        <StructuredAssessmentRows
            :learners="row.learners"
            :expanded="expanded"
            v-if="row.has_exam"
        />
    </PulseActivityTableRow>
</template>
