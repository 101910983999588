<template>
    <div>
        <div class="list-item justify-between">
            <div class="flex items-center text-sm text-center mr-8">
                <SvgIcon class="mr-4 text-indigo-600" icon="clock" size="sm" />
                <div v-text="dateTime(row.timestamp, user.timezone)" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { PropType } from "vue";
import { mapState } from "vuex";
import { filters } from "../../../vue-filters";

export default {
    props: {
        row: {
            type: Object as PropType<any>,
        },
    },
    methods: {
        dateTime: filters.dateTime,
    },
    computed: {
        ...mapState(["user"]),
    },
};
</script>
