<script setup lang="ts">
import DashboardWidget from "../DashboardWidget.vue";
import ActivityListItem from "../ActivityListItem.vue";
import ActivityList from "../ActivityList.vue";
import LessonActivityStats from "./LessonActivityStats.vue";
import { defineProps, onMounted, ref } from "vue";
import { getRecentActivity } from "../../../../api/user-requests";
import LoadingSpinner from "../../../_elements/LoadingSpinner.vue";

defineProps({
    title: {
        type: String,
        required: true,
    },
});
const data = ref<any[]>([]);
const loading = ref(true);

onMounted(async () => {
    const recentActivity = await getRecentActivity();
    // map recent activity to data;
    data.value = recentActivity.map((activity) => {
        return {
            lessonUrl: `/learn/courses/${activity.course.slug}/${activity.module.slug}/${activity.lesson.slug}`,
            lessonTitle: activity.lesson.title,
            thumbUrl: activity.course.asset,
            courseTitle: activity.course.title,
            videoStatus: activity.video_status,
            videoTime: activity.video_length,
            quizStatus: activity.exam_status,
            quizGrade: activity.highest_score,
            activityTime: activity.activity_datetime,
            hasQuiz: activity.has_exam,
        };
    });
    loading.value = false;
});
</script>
<template>
    <DashboardWidget title="Recent Activity" :empty="data.length === 0">
        <ActivityList>
            <ActivityListItem
                v-for="activity in data"
                :key="activity.lessonTitle"
                :line1="activity.lessonTitle"
                :line2="activity.courseTitle"
                :thumbnail="activity.thumbUrl"
                :time="activity.activityTime"
                :url="activity.lessonUrl"
            >
                <template v-slot:tail>
                    <LessonActivityStats
                        :video-status="activity.videoStatus"
                        :video-time="activity.videoTime"
                        :has-quiz="activity.hasQuiz"
                        :quiz-grade="activity?.quizGrade"
                        :quiz-status="activity?.quizStatus"
                    />
                </template>
            </ActivityListItem>
        </ActivityList>

        <template v-slot:empty>
            <div
                class="text-center w-full space-y-5 py-10 min-h-50"
                v-if="loading"
            >
                <LoadingSpinner :show="true" />
            </div>
            <div class="text-center w-full space-y-5 py-10" v-if="!loading">
                <svg-icon
                    size="xl5"
                    class="text-gray-400"
                    icon="empty-folder"
                ></svg-icon>
                <div class="space-y-1">
                    <h3 class="text-xl font-bold text-gray-900">
                        No activity yet!
                    </h3>
                    <p>Head to your Courses page and get started!</p>
                </div>
                <a class="button primary medium" href="/learn/courses"
                    >View All Courses</a
                >
            </div>
        </template>
    </DashboardWidget>
</template>
