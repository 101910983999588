<template>
    <div>
        <div>Partner</div>
        <div class="w-98">
            <SearchInput
                app="Accounts"
                search-addition=""
                searchable="Partner"
                @selectedInput="blur"
            />
        </div>
        <div class="mt-6" v-if="!hideGroup">Group</div>
        <div v-if="!hideGroup">
            <select
                class="w-98 mr-4 border-gray-200 border rounded-lg p-3"
                name="short_code"
                label=""
            >
                <option value="" v-if="canBeNull">No Group</option>
                <option v-for="(result, key) in results" :value="result.code">
                    {{ result.name }}
                </option>
            </select>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SearchInput from "../../_elements/SearchInput.vue";
import axios from "axios";

export default defineComponent({
    name: "PartnerGroupsList",
    components: { SearchInput },
    props: {
        canBeNull: {
            type: Boolean,
            default: false,
        },
        hideGroup: {
            type: Boolean,
            default: false,
        },
    },
    data(): any {
        return {
            results: [],
        };
    },
    methods: {
        blur(text) {
            axios
                .get(
                    "/api/search" +
                        "?app=Accounts" +
                        "&type=Groups" +
                        "&all=true" +
                        "&q=" +
                        text.term
                )
                .then((response) => {
                    this.results = response.data;
                });
        },
    },
});
</script>

<style scoped></style>
