<template>
    <div v-html="content" class="quill-content"></div>
</template>
<script lang="ts">
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";

export default {
    props: {
        delta: {
            type: String,
            default: "",
        },
    },
    data(): any {
        return {
            content: "",
        };
    },
    methods: {
        parseContent() {
            let deltaOps = JSON.parse(this.delta).ops;
            let cfg = { inlineStyles: true };
            var converter = new QuillDeltaToHtmlConverter(deltaOps, cfg);
            this.content = converter.convert();
        },
    },
    mounted() {
        this.parseContent();
    },
};
</script>
