import { Bank, BankCategory, PaginationInfo, PrepQuestion } from "@/types";
import { responseMatch } from "../response-match";
import { backend } from "../utils";

interface SearchQuestionsParams {
    category?: number[];
    deep?: boolean;
    status?: string;
    access?: string;
    flagged?: "all" | "flagged" | "not_flagged";
    pageSize?: number;
    keyword?: string;
    page?: number;
    bankId?: number;
}

export const questionBankApi = {
    async getBank(id: number) {
        const response = await backend.get(`/api/prep/question-banks/${id}`);
        const { data: bank, error } = await responseMatch<Bank, any>(response, {
            200: async (response) => {
                return { data: await response.json(), error: null };
            },
            default: async (response) => {
                return { data: null, error: await response.json() };
            },
        });

        return bank;
    },

    async addCategoryToBank(id: number, category: Partial<BankCategory>) {
        const response = await backend.post(
            `/api/prep/question-banks/${id}/categories`,
            category
        );
        return responseMatch<BankCategory, any>(response, {
            201: async (response) => ({
                data: await response.json(),
                error: null,
            }),
            default: async (response) => ({
                data: null,
                error: await response.json(),
            }),
        });
    },

    async getBankCategories(id: number) {
        const response = await backend.get(
            `/api/prep/question-banks/${id}/categories`
        );
        return responseMatch<BankCategory[], any>(response, {
            200: async (response) => ({
                data: await response.json(),
                error: null,
            }),
            default: async (response) => ({
                data: null,
                error: await response.json(),
            }),
        });
    },

    async getBankCategory(bankId: number, categoryId: number) {
        const response = await backend.get(
            `/api/prep/question-banks/${bankId}/categories/${categoryId}`
        );
        return responseMatch<BankCategory, any>(response, {
            200: async (response) => ({
                data: await response.json(),
                error: null,
            }),
            default: async (response) => ({
                data: null,
                error: await response.json(),
            }),
        });
    },

    async getCategorySubcategories(categoryId: number) {
        const response = await backend.get(
            `/api/prep/categories/${categoryId}/categories`
        );
        return responseMatch<BankCategory[], any>(response, {
            200: async (response) => ({
                data: await response.json(),
                error: null,
            }),
            default: async (response) => ({
                data: null,
                error: await response.json(),
            }),
        });
    },

    async addCategoryToCategory(
        categoryId: number,
        subcategory: Partial<BankCategory>
    ) {
        const response = await backend.post(
            `/api/prep/categories/${categoryId}/categories`,
            subcategory
        );
        return responseMatch<BankCategory, any>(response, {
            201: async (response) => ({
                data: await response.json(),
                error: null,
            }),
            default: async (response) => ({
                data: null,
                error: await response.json(),
            }),
        });
    },

    async searchQuestions(params: SearchQuestionsParams = {}) {
        const {
            category = [],
            deep = false,
            status = null,
            access = null,
            flagged = "all",
            pageSize = null,
            page = 1,
            keyword = null,
        } = params;
        const requestParams: any = {
            category: JSON.stringify(category),
            deep: deep.toString(),
            status,
            access,
            pageSize,
            keyword,
            page,
        };
        if (flagged !== "all") {
            requestParams.flagged = flagged === "flagged" ? "1" : "0";
        }
        if (params.bankId) requestParams.bankId = params.bankId;

        const response = await backend.get(
            `/api/prep/questions`,
            requestParams
        );

        return responseMatch<PaginationInfo<PrepQuestion[]>, any>(response, {
            200: async (response) => ({
                data: await response.json(),
                error: null,
            }),
            default: async (response) => ({
                data: null,
                error: await response.json(),
            }),
        });
    },

    async categoryStats(bankId: number, categoryId: number) {
        const response = categoryId
            ? await backend.get(
                  `/api/prep/question-banks/${bankId}/categories/${categoryId}/stats`
              )
            : await backend.get(`/api/prep/question-banks/${bankId}/stats`);

        return responseMatch<{
            published?: number;
            closed?: number;
            draft?: number;
        }>(response, {
            200: async (response) => ({
                data: await response.json(),
                error: null,
            }),
            default: async (response) => ({
                data: null,
                error: await response.json(),
            }),
        });
    },
    async getBreadcrumbs(bankId: number, categoryId: number) {
        const response = categoryId
            ? await backend.get(
                  `/api/prep/question-banks/${bankId}/categories/${categoryId}/breadcrumbs`
              )
            : await backend.get(
                  `/api/prep/question-banks/${bankId}/breadcrumbs`
              );

        return responseMatch<{
            ancestors: {
                label: string;
                url: string;
                siblings: {
                    label: string;
                    url: string;
                }[];
            }[];
            children: {
                label: string;
                url: string;
            }[];
        }>(response, {
            200: async (response) => ({
                data: await response.json(),
                error: null,
            }),
            default: async (response) => ({
                data: null,
                error: await response.json(),
            }),
        });
    },
} as const;
