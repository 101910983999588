<template>
    <div class="leading-tight">
        <span class="inline-flex items-center">
            <span class="mr-2" :class="{ 'font-medium': bold }">{{
                name ?? ""
            }}</span>
            <span
                v-if="showLessonOrder"
                class="hidden px-4 py-1 text-xs lg:inline bg-blue-50 rounded-md"
            >
                {{ order }}
            </span>
        </span>

        <!-- Render subtext in slot if content passed through  -->
        <div v-if="!!$slots.default" class="mt-2 text-xs leading-3">
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        bold: {
            type: Boolean,
            required: false,
            default: true,
        },
        name: {
            type: String,
            required: false,
            default: "",
        },
        order: {
            type: Number,
            required: false,
            default: "",
        },
        type: {
            type: Number,
            required: true,
        },
    },
    computed: {
        showLessonOrder() {
            return false; // TODO show lesson order
        },
    },
};
</script>
