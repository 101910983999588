<template>
    <div ref="link">
        <slot />
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    methods: {
        changeTab(tab) {
            // @ts-ignore
            let a = this.$refs?.link?.getElementsByTagName("a")[0];
            let url = new URL(a.href);
            url.searchParams.set("tab", tab);
            a.href = url;
        },
    },
    mounted() {
        // @ts-ignore
        this.$events.$on("tabChanged", (tab) => this.changeTab(tab));
    },
});
</script>
