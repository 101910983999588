<template>
    <Tabs :initial="activeTab" :tabs="tabs">
        <template #overview>
            <slot name="overview" />
        </template>

        <template #activity>
            <slot name="activity" />
        </template>
    </Tabs>
</template>

<script lang="ts">
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
import $events from "../../../../event-bus";
export default {
    computed: {
        ...mapGetters(["appPath", "context"]),
        ...mapState(["activePath", "type", "tab"]),
        activeTab() {
            const isLearnTab = Boolean(
                this.tabs.find((tab) => tab.name === this.tab)
            );

            if (!isLearnTab) {
                return "overview";
            }

            // @ts-ignore
            return this.tab;
        },
        tabs() {
            return [
                {
                    name: "overview",
                    // @ts-ignore
                    label:
                        this.type === "courses"
                            ? _.capitalize(this.type)
                            : "Modules",
                },
                {
                    name: "activity",
                    label: "Activity",
                },
            ];
        },
    },
    created() {
        $events.$on("tabChanged", (tab) => {
            // @ts-ignore
            this.$store.commit("registerState", {
                tab: tab,
            });
        });
    },
};
</script>
