<template>
    <div>
        <div
            class="sm:hidden bg-gray-900 opacity-75 fixed top-0 transition duration-300 right-0 bottom-0 left-0 z-70"
            v-if="open"
            @click="open = false"
        />
        <div
            class="sm:hidden fixed top-0 left-0 transform transition duration-200 z-75"
            :class="{
                'translate-x-24': open,
                '-translate-x-24': !open,
            }"
        >
            <button
                class="text-white rounded-full ml-1 mt-1 p-2"
                @click="open = false"
            >
                <SvgIcon icon="close" size="base" weight="medium" />
            </button>
        </div>
        <section
            ref="sidebar"
            class="sidebar flex flex-col justify-between shadow fixed left-0 h-full z-80 transform transition-all ease-out duration-200 sm:translate-x-0 sm:w-24"
            :class="[
                'theme-' + theme,
                {
                    '-translate-x-24': !open,
                    'translate-x-0 w-24 sm:w-24': open,
                },
            ]"
        >
            <div>
                <div class="logo flex items-center justify-center m-5">
                    <a :href="websitePath">
                        <div class="w-10 h-10">
                            <svg
                                viewBox="0 0 100 100"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M76.964,42.607c7.207,-1.743 12.486,-8.352 12.297,-16.092c-0.216,-8.908 -7.594,-15.964 -16.463,-15.746c-8.87,0.217 -15.896,7.627 -15.679,16.535c0.033,1.352 0.161,6.62 0.262,10.775c0.035,1.424 -0.495,2.803 -1.473,3.834c-0.978,1.031 -2.324,1.63 -3.742,1.665c-0.008,-0 -0.017,-0 -0.025,0c-2.951,0.072 -5.402,-2.272 -5.474,-5.236c-0.084,-3.455 -0.19,-7.834 -0.262,-10.775c-0.361,-14.847 11.348,-27.196 26.131,-27.559c14.783,-0.363 27.078,11.397 27.44,26.244c0.209,8.626 -3.655,16.409 -9.83,21.488c-3.768,-2.802 -8.287,-4.633 -13.182,-5.133Z"
                                    style="fill: #2b76e2"
                                />
                                <path
                                    d="M23.036,57.393c-7.207,1.743 -12.486,8.352 -12.297,16.092c0.216,8.908 7.594,15.964 16.463,15.746c8.87,-0.217 15.896,-7.627 15.679,-16.535c-0.033,-1.352 -0.161,-6.62 -0.262,-10.775c-0.035,-1.424 0.495,-2.803 1.473,-3.834c0.978,-1.031 2.324,-1.63 3.742,-1.665c0.008,0 0.017,0 0.025,-0c2.951,-0.072 5.402,2.272 5.474,5.236c0.084,3.455 0.19,7.834 0.262,10.775c0.361,14.847 -11.348,27.196 -26.131,27.559c-14.783,0.363 -27.078,-11.397 -27.44,-26.244c-0.209,-8.626 3.655,-16.409 9.83,-21.488c3.768,2.802 8.287,4.633 13.182,5.133Z"
                                    style="fill: #f9cb58"
                                />
                                <path
                                    d="M57.577,76.869c1.735,7.237 8.315,12.54 16.022,12.35c8.87,-0.217 15.896,-7.626 15.679,-16.535c-0.217,-8.908 -7.594,-15.964 -16.464,-15.746c-1.346,0.033 -6.591,0.162 -10.728,0.263c-1.418,0.035 -2.792,-0.497 -3.818,-1.48c-1.027,-0.982 -1.623,-2.334 -1.658,-3.758c0,-0.008 0,-0.017 -0,-0.025c-0.071,-2.964 2.263,-5.425 5.214,-5.497c3.44,-0.085 7.799,-0.192 10.728,-0.264c14.783,-0.363 27.079,11.397 27.44,26.244c0.361,14.847 -11.348,27.196 -26.131,27.559c-8.589,0.211 -16.338,-3.67 -21.395,-9.872c2.79,-3.784 4.613,-8.323 5.111,-13.239Z"
                                    style="fill: #fb5f5f"
                                />
                                <path
                                    d="M42.423,23.131c-1.735,-7.237 -8.315,-12.54 -16.022,-12.35c-8.87,0.217 -15.896,7.626 -15.679,16.535c0.217,8.908 7.594,15.964 16.464,15.746c1.346,-0.033 6.591,-0.162 10.728,-0.263c1.418,-0.035 2.792,0.497 3.818,1.48c1.027,0.982 1.623,2.334 1.658,3.758c-0,0.008 -0,0.017 0,0.025c0.071,2.964 -2.263,5.425 -5.214,5.497c-3.44,0.085 -7.799,0.192 -10.728,0.264c-14.783,0.363 -27.079,-11.397 -27.44,-26.244c-0.361,-14.847 11.348,-27.196 26.131,-27.559c8.589,-0.211 16.338,3.67 21.395,9.872c-2.79,3.784 -4.613,8.323 -5.111,13.239Z"
                                    style="fill: #33c18e"
                                />
                            </svg>
                        </div>
                    </a>
                </div>
                <nav>
                    <slot name="primary" />
                </nav>
            </div>
            <div>
                <nav>
                    <slot name="secondary" />
                </nav>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { mapState } from "vuex";

export default {
    props: {
        theme: String,
    },
    data(): any {
        return {
            open: false,
        };
    },
    computed: {
        ...mapState(["websitePath"]),
    },
    mounted() {
        this.$events.$on("toggleNav", () => (this.open = !this.open));
    },
};
</script>
