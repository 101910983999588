<template>
    <div
        :content="content"
        v-tippy="{ placement: 'bottom' }"
        class="relative flex items-center justify-center w-10 h-10 m-1.5 font-medium uppercase rounded-full cursor-default pulse-prep-circle"
        :class="`bg-${label.color}-${label.color === 'cyan' ? 50 : 100} text-${
            label.color
        }-700 `"
        :style="cssVars"
    >
        {{ label.ab }}
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        categories: Array as PropType<any[]>,
        parent: String,
        complete: Object as PropType<any>,
        options: Object as PropType<any>,
    },
    computed: {
        content() {
            let cats =
                this.categories?.map((o) => {
                    return "<br>• " + o.category;
                }) ?? [];
            return (
                `<div class="text-left"><strong>${this.label.name} - ${this.completePercent}</strong>` +
                cats.join("") +
                "</div>"
            );
        },
        label(): any {
            switch (this.parent) {
                case "1":
                case "1324":
                    return {
                        color: "violet",
                        name: "Patient Care",
                        ab: "PC",
                    };
                    break;
                case "124":
                case "1421":
                    return {
                        color: "cyan",
                        name: "Safety",
                        ab: "Sa",
                    };
                    break;
                case "250":
                case "1522":
                    return {
                        color: "pink",
                        name: "Image Production",
                        ab: "IP",
                    };
                    break;
                case "376":
                case "1622":
                    return {
                        color: "orange",
                        name: "Procedures",
                        ab: "Pr",
                    };
                    break;
            }
        },
        optionClasses() {
            switch (this.options) {
                case "stacked":
                    return "border-2 border-white text-sm -ml-2 first:ml-0";
                    break;
                default:
                    return "";
            }
        },
        completePercent() {
            return this.complete[
                this.label.name.replace(" ", "_").toLowerCase()
            ];
        },
        cssVars() {
            return {
                "--complete-percent": this.completePercent,
                "--border-color": "#a81616", //(tailwind as any).theme.colors[this.label.color][500],// TODO: fix this
            };
        },
    },
});
</script>

<style scoped>
.pulse-prep-circle::before {
    content: "";
    background: conic-gradient(
        var(--border-color) var(--complete-percent),
        transparent 0
    );
    -webkit-mask: radial-gradient(
        farthest-side,
        #0000 calc(99% - 3px),
        #000 calc(100% - 3px)
    );
    mask: radial-gradient(
        farthest-side,
        #0000 calc(99% - 3px),
        #000 calc(100% - 3px)
    );
    top: -3px;
    left: -3px;
    height: 115%;
    width: 115%;
    @apply absolute rounded-full;
}
</style>
