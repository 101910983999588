<template>
    <div class="bg-white rounded-md shadow py-4 px-8">
        <ul class="divide-y">
            <li
                v-for="reference in internalReferences"
                :key="reference.id"
                class="py-4"
            >
                <div
                    class="flex items-center text-lg leading-8 cursor-pointer space-x-2"
                    :class="{ 'font-bold': reference.is_opened }"
                    @click="reference.is_opened = !reference.is_opened"
                >
                    <SvgIcon
                        v-if="!reference.is_opened"
                        icon="chevron-right"
                        size="xs"
                        weight="extra-bold"
                    />
                    <SvgIcon
                        v-if="reference.is_opened"
                        icon="chevron-down"
                        size="xs"
                        weight="extra-bold"
                    />
                    <span>{{ reference.title }}</span>
                </div>
                <transition
                    enter-active-class="transition duration-100 ease-out"
                    enter-class="transform opacity-0"
                    enter-to-class="transform opacity-100"
                    leave-active-class="transition duration-100 ease-in"
                    leave-class="transform opacity-100"
                    leave-to-class="transform opacity-0"
                >
                    <div v-show="reference.is_opened" class="flex mt-8">
                        <div class="w-2/5">
                            <img
                                class="bg-white border rounded-lg shadow w-full p-3"
                                :src="
                                    'https://s3.amazonaws.com/files.cloverlearning.com/images/resources/' +
                                    reference.image
                                "
                                alt=""
                            />
                        </div>
                        <div class="w-3/5 pl-8">
                            <div class="prose" v-html="reference.body" />
                        </div>
                    </div>
                </transition>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        references: {
            type: Array,
            required: true,
        },
    },
    data(): any {
        return {
            internalReferences: this.references.map((reference: any) => {
                reference.is_opened = false;
                return reference;
            }),
        };
    },
};
</script>

<style scoped>
.rotated-90 {
    transform: rotate(90deg);
}
</style>
