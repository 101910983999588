<template>
    <div class="container__ribbon" v-if="text !== ''">
        {{ text }}
    </div>
</template>
<style scoped>
.container__ribbon {
    /* Position */
    left: -74px;
    position: fixed;
    top: 16px;
    z-index: 1000;

    /* Size */
    height: 24px;
    width: 206px;

    /* Displayed diagonally */
    transform: rotate(-45deg);

    /* Background color */
    background-color: rgb(251 94 96);
    color: white;

    /* Centerize the text content */
    text-align: center;
}
</style>
<script lang="ts">
export default {
    computed: {
        text() {
            let hostname = location.hostname;
            if (hostname.endsWith(".test")) {
                return "DEV";
            }
            if (hostname.startsWith("qa-app.")) {
                return "QA";
            }
            if (hostname.startsWith("staging-app.")) {
                return "STAGING";
            }
            if (hostname.startsWith("demo-app.")) {
                return "DEMO";
            }
            return "";
        },
    },
};
</script>
