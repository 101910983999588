<template>
    <div class="space-y-4" v-if="!isLoading">
        <CategoryBreadcrumbs></CategoryBreadcrumbs>
        <h1
            class="pt-4 text-2xl font-semibold tracking-tight flex items-center gap-2"
        >
            {{ category?.category }}
            <div class="flex items-center gap-1">
                <Pill backgroundColor="green-50 border border-green-200"
                    >{{ catStats?.published ?? "0" }} Published</Pill
                >
                <Pill backgroundColor="yellow-50 border border-yellow-200"
                    >{{ catStats?.draft ?? "0" }} Draft</Pill
                >
                <Pill backgroundColor="red-50 border border-red-200"
                    >{{ catStats?.closed ?? "0" }} Closed</Pill
                >
            </div>
        </h1>
        <div class="space-y-4">
            <ContentQuestionFilter v-model="filters"></ContentQuestionFilter>
            <div class="flex flex-row justify-between items-center">
                <Link
                    :href="`/admin/content/prep/questions/create?category=${categoryId}&bank=${bankId}&referrer=/admin-portal${$route.fullPath}`"
                    intent="primary"
                    size="xs"
                    :round="true"
                    >&plus; Add New Question</Link
                >
                <PaginationManager
                    :pagination-data="questionsPage"
                    @next="
                        () => {
                            page++;
                        }
                    "
                    @prev="
                        () => {
                            page--;
                        }
                    "
                    v-if="questionsPage?.data"
                ></PaginationManager>
            </div>
            <ContentQuestionsList
                :questions="questionsPage?.data"
                questionType="prep"
                v-if="questionsPage?.data"
            />
            <div class="flex flex-row justify-between items-center">
                <Link
                    :href="`/admin/content/prep/questions/create?category=${categoryId}&bank=${bankId}&referrer=/admin-portal${$route.fullPath}`"
                    intent="primary"
                    size="xs"
                    :round="true"
                    >&plus; Add New Question</Link
                >
                <PaginationManager
                    :pagination-data="questionsPage"
                    @next="
                        () => {
                            page++;
                        }
                    "
                    @prev="
                        () => {
                            page--;
                        }
                    "
                    v-if="questionsPage?.data"
                ></PaginationManager>
            </div>
        </div>
    </div>
    <div v-else>
        <h1>Loading...</h1>
    </div>
</template>

<script setup lang="tsx">
/**
 * Component to display a question bank category page
 */

import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useQuery } from "@tanstack/vue-query";
import { adminApi } from "@/api/admin";
import ContentQuestionsList from "@/components/Admin/Content/questions/ContentQuestionsList.vue";
import PaginationManager from "@/components/PaginationManager.vue";
import ContentQuestionFilter, {
    FilterOptions,
} from "../Content/ContentQuestionFilter.vue";
import Link from "@/components/ui/ButtonLinks/Link.vue";
import { CategoryBreadcrumbs } from "./CategoryBreadcrumbs.tsx";

const filters = ref<FilterOptions>({
    access: "open",
    flagged: "all",
    keyword: "",
    status: "published",
});

const route = useRoute();
const bankId = computed(() => parseInt(route.params.id as string));
const categoryId = computed(() => parseInt(route.params.categoryId as string));
const { data: category, isLoading } = useQuery({
    queryKey: ["bank", bankId, "category", categoryId],
    queryFn: async () => {
        const response = await adminApi.questionBank.getBankCategory(
            bankId.value,
            categoryId.value
        );
        return response.data;
    },
});

const page = ref(1);
const { data: questionsPage } = useQuery({
    queryKey: [
        "bank",
        bankId,
        "category",
        categoryId,
        "questions",
        page,
        filters,
    ],
    queryFn: async () => {
        const response = await adminApi.questionBank.searchQuestions({
            deep: true,
            page: page.value,
            ...filters.value,
            category: [categoryId.value],
        });
        return response.data;
    },
});

const { data: catStats } = useQuery({
    queryKey: ["bank", bankId, "category", categoryId, "stats"],
    queryFn: async () => {
        const response = await adminApi.questionBank.categoryStats(
            bankId.value,
            categoryId.value
        );
        return response.data;
    },
});

watch(categoryId, () => {
    page.value = 1;
});
</script>
