<template>
    <div class="rounded-md shadow">
        <div class="flex bg-white border-b border-gray-100 rounded-t-md p-4">
            <strong>Folders</strong>
        </div>
        <draggable
            v-model="internalFolders"
            handle=".drag-handle"
            item-key="id"
            group="folderA"
            tag="ul"
            draggable=".draggable-item"
            class="bg-white divide-y"
            @end="handleMove"
        >
            <template #item="{ element: folder }">
                <li
                    class="flex justify-between py-4 pr-4"
                    :class="{
                        'draggable-item': folder.order !== 1,
                    }"
                    :data-id="folder.id"
                >
                    <div
                        class="flex items-center"
                        :class="{
                            'drag-handle': folder.order !== 1,
                        }"
                    >
                        <div class="flex items-center justify-center w-9 h-9">
                            <SvgIcon
                                v-if="folder.order !== 1"
                                class="text-gray-300"
                                icon="dots"
                                size="sm"
                            />
                        </div>
                        <a class="cta text-sm" :href="folder.url">
                            {{ folder.name }}
                        </a>
                    </div>
                    <div
                        class="flex items-center justify-center bg-cyan-50 text-sm rounded-full w-9 h-9"
                    >
                        {{ folder.saved_items_count }}
                    </div>
                </li>
            </template>
        </draggable>
        <div class="bg-gray-50 border-t border-gray-100 rounded-b-md p-4">
            <SavedItemsFolderModal
                :enrollment-id="enrollmentId"
                @addedFolder="appendFolder"
            >
                <template #default="{ openModal }">
                    <button class="small primary button" @click="openModal">
                        Add Folder
                    </button>
                </template>
            </SavedItemsFolderModal>
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import draggable from "vuedraggable";
import SavedItemsFolderModal from "./../_elements/modals/SavedItemsFolderModal.vue";

export default {
    name: "SavedFoldersList",

    props: {
        baseUrl: String,
        enrollmentId: {
            type: Number,
            required: true,
        },
        folders: {
            required: true,
            type: Array,
        },
    },

    data(): any {
        return {
            internalFolders: this.folders,
        };
    },

    methods: {
        appendFolder(folder) {
            this.internalFolders.push({
                id: folder.id,
                name: folder.name,
                saved_items_count: 0,
                slug: folder.slug,
                url: folder.url,
            });
        },

        handleMove(event) {
            axios
                .put(
                    `/api/learn/enrollments/${this.enrollmentId}/saved-items-folders-order/${event.item.dataset.id}`,
                    {
                        order: event.newIndex + 1,
                    }
                )
                .catch(({ response }) => {});
        },

        updateFolder(data) {
            let folder = this.internalFolders.find((folder) => {
                return folder.id === data.id;
            });
            folder.name = data.name;
            folder.slug = data.slug;
            folder.url = this.baseUrl + "/" + data.slug;
        },
    },

    mounted() {
        this.$events.$on("updatedFolder", (data) => {
            this.updateFolder(data);
        });
    },

    components: {
        draggable,
        SavedItemsFolderModal,
    },
};
</script>
