<template>
    <svg
        class="h-16 w-16 mx-auto w-auto text-gray-700"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M76.964 42.607c7.207-1.743 12.486-8.352 12.297-16.092-.216-8.908-7.594-15.964-16.463-15.746-8.87.217-15.896 7.627-15.679 16.535l.262 10.775a5.379 5.379 0 01-1.473 3.834 5.34 5.34 0 01-3.742 1.665h-.025c-2.951.072-5.402-2.272-5.474-5.236l-.262-10.775C46.044 12.72 57.753.371 72.536.008c14.783-.363 27.078 11.397 27.44 26.244.209 8.626-3.655 16.409-9.83 21.488a26.594 26.594 0 00-13.182-5.133z"
            fill="#2B76E2"
        ></path>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M23.036 57.393c-7.207 1.743-12.486 8.352-12.297 16.092.216 8.908 7.594 15.964 16.463 15.746 8.87-.217 15.896-7.627 15.679-16.535l-.262-10.775a5.38 5.38 0 011.473-3.834 5.34 5.34 0 013.742-1.665h.025c2.951-.072 5.402 2.272 5.474 5.236l.262 10.775c.361 14.847-11.348 27.196-26.131 27.559C12.681 100.355.386 88.595.024 73.748c-.209-8.626 3.655-16.409 9.83-21.488a26.593 26.593 0 0013.182 5.133z"
            fill="#F9CB58"
        ></path>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M57.577 76.869c1.735 7.237 8.315 12.54 16.022 12.35 8.87-.217 15.896-7.626 15.679-16.535-.217-8.908-7.594-15.964-16.464-15.746l-10.728.263a5.328 5.328 0 01-3.818-1.48 5.382 5.382 0 01-1.658-3.758v-.025c-.071-2.964 2.263-5.425 5.214-5.497l10.728-.264c14.783-.363 27.079 11.397 27.44 26.244.361 14.847-11.348 27.196-26.131 27.559-8.589.211-16.338-3.67-21.395-9.872a26.842 26.842 0 005.111-13.239z"
            fill="#FB5F5F"
        ></path>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M42.423 23.131c-1.735-7.237-8.315-12.54-16.022-12.35-8.87.217-15.896 7.626-15.679 16.535.217 8.908 7.594 15.964 16.464 15.746l10.728-.263a5.328 5.328 0 013.818 1.48 5.382 5.382 0 011.658 3.758v.025c.071 2.964-2.263 5.425-5.214 5.497l-10.728.264C12.665 54.186.369 42.426.008 27.579-.353 12.732 11.356.383 26.139.02c8.589-.211 16.338 3.67 21.395 9.872a26.842 26.842 0 00-5.111 13.239z"
            fill="#33C18E"
        ></path>
    </svg>
</template>
