import {
    Category,
    Discipline,
    NewCategory,
    NewDiscipline,
    TaxonomyItem,
} from "@/types/content";

const saveTaxonomy = async (type: "category" | "discipline", data: any) => {
    const url = `/api/admin/content/learn/taxonomy?type=${type}`;
    const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
    });
    return (await response.json()) as Category[];
};

const getTaxonomy = async (type: "category" | "discipline") => {
    const url = `/api/admin/content/learn/taxonomy?type=${type}`;
    const response = await fetch(url);
    return (await response.json()) as TaxonomyItem[];
};

export const getDisciplines = async () => {
    return getTaxonomy("discipline");
};

export const saveDisciplines = async (
    disciplines: (Discipline | NewDiscipline)[]
) => {
    return saveTaxonomy("discipline", disciplines);
};

export const getCategories = async () => {
    return getTaxonomy("category");
};

export const saveCategories = async (
    categories: (Category | NewCategory)[]
) => {
    return saveTaxonomy("category", categories);
};
