import { createStore } from "vuex";

const state = {
    location: {
        app:
            window.location.pathname.split("/").length > 1
                ? "/" + window.location.pathname.split("/")[1]
                : "",
        page:
            window.location.pathname.split("/").length > 2
                ? "/" + window.location.pathname.split("/")[2]
                : "",
    },
    token: null,
    user: null,
    crumbs: null,
    instanceId: null,
    period: null,
    settings: {},
    subject: null,
    subjectGroup: null,
    subjects: [
        "organization",
        "account",
        "cohort",
        "learner",
        "partner",
        "group",
    ],
    type: null,
    typeId: null,
    bladePage: true,
};

const getters = {
    appPath: (state) => {
        return state.location.app + state.location.page;
    },
    baseLink: (state, getters) => {
        return "/pulse/explore/" + getters.context;
    },
    context: (state) => {
        return "?subject=" + state.subject + "&period=" + state.period;
    },
    nextSubject: (state) => {
        if (state.subjectGroup == "learner") {
            return "learner";
        }
        return state.subjects[
            state.subjects.findIndex((subject) => {
                return subject == state.subjectGroup;
            }) + 1
        ];
    },
    viewContext: (state) => {
        switch (state.type) {
            case "overview":
                return "overview";
                break;
            case "lessons":
                if (state.typeId) {
                    return "lesson";
                }
                return "lessons";
                break;
            case "history":
                return "history";
                break;
            case "prep":
                return "prep";
                break;
            case "quizzes":
            case "assessments":
                if (state.instanceId) {
                    return "assessmentInstance";
                }
                if (state.typeId) {
                    return "assessment";
                }
                return "assessments";
                break;
            default:
                return null;
        }
    },
    viewZoom: (state) => {
        if (state.instanceId) {
            return "single";
        }
        return ["admin", "organization", "account", "cohort"].indexOf(
            state.subject
        ) > -1
            ? "overview"
            : "detail";
    },
};

const mutations = {
    registerState(state, payload) {
        for (const [key, value] of Object.entries(payload)) {
            state[key] = value;
        }
    },
};

const actions = {};

export default createStore({
    state,
    getters,
    mutations,
    actions,
});
