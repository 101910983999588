<template>
    <div v-if="rows.length > 0" class="mt-8">
        <div class="flex items-center justify-between">
            <div class="flex flex-grow justify-start">
                <h3 class="text-sm text-gray-600 font-medium uppercase ml-4">
                    {{ label }}
                </h3>
            </div>
            <template v-if="type != 'lessons'">
                <div class="hidden sm:flex justify-center w-32">
                    <div class="text-sm text-gray-700 uppercase font-medium">
                        Score
                    </div>
                </div>
                <div class="hidden sm:flex justify-center w-24">
                    <div class="text-sm text-gray-700 uppercase font-medium">
                        Time
                    </div>
                </div>
            </template>
        </div>
        <div class="card">
            <PulseExploreDetailsListingInstancesRow
                v-for="row in rows"
                :key="row.uuid"
                :legacy="legacy"
                v-bind="row"
                :type="type"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { mapState } from "vuex";

export default defineComponent({
    props: {
        legacy: Boolean,
        label: String,
        rows: {
            type: Array as PropType<Array<any>>,
            required: true,
        },
    },
    computed: {
        ...mapState(["type"]),
    },
});
</script>
