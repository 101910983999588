<template>
    <div class="space-y-8">
        <div
            class="lg:flex lg:items-center lg:justify-between lg:space-y-0 space-y-2"
        >
            <h2
                class="flex items-center space-x-3 text-xl leading-8 text-gray-900"
            >
                <strong class="font-bold">{{ title }}</strong>
                <Skeleton :loading="loading">
                    <span v-if="subtitle" class="text-sm text-gray-600">{{
                        subtitle
                    }}</span>
                </Skeleton>
            </h2>

            <slot name="sort" />
        </div>

        <Loader v-if="loading" />

        <template v-else>
            <div class="space-y-5">
                <slot />
            </div>

            <slot name="pagination" />
        </template>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        loading: {
            type: Boolean,
            default: false,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            default: "",
            required: false,
        },
    },
};
</script>
