import { responseMatch } from "./response-match";
import { backend } from "./utils";
import {
    AnswerRequestBody,
    Exam,
    QuestionResponse,
    SubmitAnswerResponse,
} from "@/types/prep";

export const prepApi = {
    async getExamQuestion(
        examUuid: string,
        questionNumber: number,
        signal?: AbortSignal
    ) {
        const response = await backend.get(
            `/prep/exams/api/${examUuid}/${questionNumber}`,
            {},
            signal
        );
        const { data, error } = await responseMatch<QuestionResponse, any>(
            response,
            {
                200: async (response) => {
                    return { data: await response.json(), error: null };
                },
                default: async (response) => {
                    return { data: null, error: await response.json() };
                },
            }
        );

        return data!;
    },

    async submitExamAnswer(
        answer: AnswerRequestBody
    ): Promise<SubmitAnswerResponse> {
        const response = await backend.post(
            `/api/prep/exams/respond?auto=auto`,
            answer
        );
        const { data, error } = await responseMatch<SubmitAnswerResponse, any>(
            response,
            {
                200: async (response) => {
                    return { data: await response.json(), error: null };
                },
                default: async (response) => {
                    return { data: null, error: await response.json() };
                },
            }
        );
        return data!;
    },

    flagQuestion(questionId: number) {
        return backend.post(`/api/prep/questions/flag`, {
            question: questionId,
        });
    },

    async submitExam(examUuid: string) {
        const response = await backend.post(
            `/prep/exams/api/${examUuid}/submit`,
            {}
        );
        const { data, error } = await responseMatch<Exam, any>(response, {
            200: async (response) => {
                return { data: await response.json(), error: null };
            },
            default: async (response) => {
                return { data: null, error: await response.json() };
            },
        });
        return data!;
    },

    async pauseExam(examUuid: string) {
        const response = await backend.put(
            `/api/prep/exams/${examUuid}/pause`,
            {}
        );
        const { data, error } = await responseMatch<
            { pause: boolean; timeRemaining: number; exam: Exam },
            any
        >(response, {
            200: async (response) => {
                return { data: await response.json(), error: null };
            },
            default: async (response) => {
                return { data: null, error: await response.json() };
            },
        });
        return data!;
    },

    async resumeExam(examUuid: string) {
        const response = await backend.put(
            `/api/prep/exams/${examUuid}/resume`,
            {}
        );
        const { data, error } = await responseMatch<
            {
                pause: boolean;
                remaining: number;
                exam: Exam;
            },
            any
        >(response, {
            200: async (response) => {
                return { data: await response.json(), error: null };
            },
            default: async (response) => {
                return { data: null, error: await response.json() };
            },
        });
        return data!;
    },
} as const;
