<script setup lang="ts">
import { ref, defineProps } from "vue";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/24/outline";
import { Course } from "@/types";
import TiptapRender from "@/components/ui/content/TiptapRenderer.vue";

const props = defineProps<{ course: Course }>();

const showExtra = ref(false);
</script>

<template>
    <div
        class="mt-6 overflow-hidden relative transition"
        :class="showExtra ? 'h-auto' : 'h-[100px]'"
    >
        <div class="prose">
            <TiptapRender :content="course.description" />

            <h3 v-if="course.objectives">What you'll learn</h3>
            <TiptapRender :content="course.objectives" />
        </div>
        <div
            v-if="!showExtra"
            class="block absolute bottom-0 left-0 w-full h-full bg-gradient-to-t from-white to-transparent"
        ></div>
    </div>
    <button
        @click="showExtra = !showExtra"
        class="flex items-center gap-1 text-sm text-blue-700 font-semibold mt-6"
    >
        {{ showExtra ? "Show Less" : "Show More" }}
        <ChevronDownIcon v-if="!showExtra" class="h-4 w-4"></ChevronDownIcon>
        <ChevronUpIcon v-if="showExtra" class="h-4 w-4"></ChevronUpIcon>
    </button>
</template>
