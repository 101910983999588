<template>
    <div class="relative">
        <div class="flex justify-end text-blue-600">
            <div
                class="bg-gray-50 border border-gray-100 rounded w-36 sm:w-64 h-8 sm:h-12 flex items-center justify-end"
            >
                <input
                    class="text-gray-800 leading-5 p-2"
                    ref="searchBox"
                    v-model="search"
                />
                <SvgIcon class="mr-2 sm:mr-4" icon="search" size="base" />
            </div>
        </div>
        <div
            class="bg-white shadow rounded absolute overflow-hidden right-0 mt-2 -mr-2 z-60 w-80 sm:w-120"
            style="max-height: 258px; top: 100%"
        >
            <div class="overflow-x-auto" style="max-height: 258px">
                <div v-for="result in results">
                    <a
                        class="flex items-center justify-between text-sm px-3 py-2 border-b border-gray-200 last-child:border-none hover:no-underline hover:bg-gray-200"
                        :href="
                            '/pulse/explore/' +
                            result.type +
                            '/' +
                            period +
                            '/' +
                            result.uuid +
                            '/overview'
                        "
                    >
                        <span class="font-semibold">{{ result.name }}</span>
                        <span
                            class="bg-pear-200 text-pear 700 rounded py-1 px-2 text-xs capitalize"
                            >{{ result.type }}</span
                        >
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { mapState } from "vuex";
import axios from "axios";

export default {
    data(): any {
        return {
            search: "",
            terms: [],
        };
    },
    computed: {
        ...mapState(["period"]),
        results() {
            let self = this;
            if (this.search) {
                return this.terms.filter((term) => {
                    return (
                        term.name
                            .toLowerCase()
                            .search(self.search.toLowerCase()) > -1
                    );
                });
            }
            return [];
        },
    },
    methods: {
        getResults() {
            axios.get("/api/overview-search").then((response) => {
                this.terms = response.data;
            });
        },
    },
    mounted() {
        this.getResults();

        let self = this;
        document.onkeypress = (event) => {
            if (event.keyCode === 47) {
                // @ts-ignore
                if (document.activeElement.tagName != "INPUT") {
                    self.$refs.searchBox.focus();
                    return false;
                }
            }
        };
    },
};
</script>

<style scoped>
input {
    @apply absolute w-full h-full bg-transparent shadow-none rounded-none;
}
</style>
