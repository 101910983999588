<template>
    <div>
        <button
            type="button"
            class="flex items-center justify-center text-red-600 bg-red-50 hover:bg-red-100 rounded-lg w-9 h-9"
            v-tippy
            content="Delete"
            @click="showDelete = true"
        >
            <SvgIcon icon="close" size="xs" weight="extra-bold" />
        </button>
        <ConfirmDeleteModal
            :active="showDelete"
            :message="message"
            @close="showDelete = false"
            @confirm="confirmDelete"
        />
    </div>
</template>

<script lang="ts">
import axios from "axios";

export default {
    name: "DeleteTrashWithModal",
    props: {
        url: String,
        message: String,
        position: String,
        parentElement: String,
        customSuccessMessage: {
            type: String,
            default: "Removed successfully!",
        },
        customErrorMessage: {
            type: String,
            default: "An error occurred. Failed to remove.",
        },
    },
    data(): any {
        return {
            showDelete: false,
        };
    },
    methods: {
        confirmDelete() {
            axios
                .delete(this.url)
                .then((response) => {
                    this.showDelete = false;

                    let item = document.getElementById(
                        this.position
                    ) as HTMLElement;
                    item.remove();

                    this.$events.$emit("toastNotification", {
                        action: "saved",
                        heading: this.customSuccessMessage,
                        status: "success",
                    });
                })
                .catch((error) => {
                    this.showDelete = false;
                    this.$events.$emit("toastNotification", {
                        action: "Error!",
                        heading: this.customErrorMessage,
                        status: "error",
                    });
                });
        },
    },
};
</script>
