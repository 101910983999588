import axios from "axios";

export function getSubscriptions(userId) {
    return axios.get(`/api/users/${userId}/subscriptions`).then((response) => {
        return response.data;
    });
}

export function getSubscription(userId, subscriptionId) {
    return axios
        .get(`/api/users/${userId}/subscriptions/${subscriptionId}`)
        .then((response) => {
            return response.data;
        });
}

export function renewSubscription(userId, subscriptionId) {
    return axios
        .post(`/api/users/${userId}/subscriptions/${subscriptionId}/renew`)
        .then((response) => {
            return response.data;
        });
}

export function cancelSubscription(userId, subscriptionId) {
    return axios
        .post(`/api/users/${userId}/subscriptions/${subscriptionId}/cancel`)
        .then((response) => {
            return response.data;
        });
}

export function getPaymentMethods(userId) {
    return axios
        .get(`/api/users/${userId}/payment-methods`)
        .then((response) => {
            return response.data?.data ?? [];
        });
}

export function makeSubscriptionPayment(userId, invoiceId, paymentMethod) {
    return axios
        .post(`/api/users/${userId}/invoices/${invoiceId}/pay`, {
            paymentMethod,
            makeDefault: true,
        })
        .then((response) => {
            return response.data;
        });
}
