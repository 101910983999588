<template>
    <div class="bg-white shadow sm:rounded-md">
        <div
            class="flex flex-col justify-between px-4 py-5 border-b border-gray-200 lg:flex-row sm:px-6 bg-gray-50"
        >
            <div>
                <h2 class="text-lg font-semibold">Category Breakdown</h2>
                <p class="text-sm text-gray-500">
                    This report gives insight into Prep exam performance broken
                    down by category and subcategory.
                </p>
            </div>
            <div class="w-40 mt-6 lg:w-auto lg:mt-auto">
                <PulseToggle
                    :initial="showAnalytics"
                    label="Show Analytics"
                    variant="green"
                    @toggle="
                        $store.commit('registerState', {
                            showAnalytics: !showAnalytics,
                        })
                    "
                />
            </div>
        </div>

        <div v-if="showAnalytics">
            <div
                class="relative grid w-full h-full grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 lg:px-10"
            >
                <div
                    v-for="(category, index) in categories"
                    :key="index"
                    class="flex flex-col justify-center p-10 pb-5 text-sm text-center cursor-pointer"
                    @click="openModal(category)"
                    aria-roledescription="Click to open detailed modal"
                >
                    <div class="w-5/6 mx-auto">
                        <PrepCategoryReportDonut
                            :name="category.name"
                            :correct="category.questions_correct"
                            :total="category.questions_total"
                        />
                    </div>
                    <h3 class="pt-6">{{ category.name }}</h3>
                    <p class="pt-4 text-blue-600 underline">
                        {{ category.questions_correct }}/{{
                            category.questions_total
                        }}
                        Correct
                        <span hidden>{{
                            percentage(
                                category.questions_correct,
                                category.questions_total
                            )
                        }}</span>
                    </p>
                </div>
            </div>
            <div class="px-4 py-5 sm:px-6 bg-gray-50">
                <label class="ml-2 text-sm font-semibold leading-6"
                    >Question Bank</label
                >
                <Dropdown value="Question 1" widthClass="w-52">
                    <DropdownItem>Question 1</DropdownItem>
                    <DropdownItem>Question 2</DropdownItem>
                    <DropdownItem>Question 3</DropdownItem>
                    <DropdownItem>Question 4</DropdownItem>
                </Dropdown>
            </div>
        </div>
        <PrepCategoryReportModal
            :category="selectedCategory"
            :active="modalIsActive"
            @close="modalIsActive = false"
        />
    </div>
</template>

<script lang="ts">
import { mapState } from "vuex";

export default {
    data(): any {
        return {
            showBody: true,
            selectedCategory: {},
            modalIsActive: false,
            categories: {
                patient_care: {
                    name: "Patient Care",
                    questions_correct: 4,
                    questions_total: 12,
                    rows: {
                        patient_interactions_and_management: {
                            name: "Patient Interactions and Management",
                            questions_correct: 3,
                            questions_total: 5,
                            children: {
                                ethical_and_legal_aspects: {
                                    name: "Ethical and Legal Aspects",
                                    questions_correct: 3,
                                    questions_total: 5,
                                },
                            },
                        },
                    },
                },
                safety: {
                    name: "Safety",
                    questions_correct: 10,
                    questions_total: 21,
                    rows: {
                        patient_interactions_and_management: {
                            name: "Patient Interactions and Management",
                            questions_correct: 3,
                            questions_total: 5,
                            children: {
                                ethical_and_legal_aspects: {
                                    name: "Ethical and Legal Aspects",
                                    questions_correct: 3,
                                    questions_total: 5,
                                },
                            },
                        },
                    },
                },
                image_production: {
                    name: "Image Production",
                    questions_correct: 9,
                    questions_total: 10,
                    rows: {
                        patient_interactions_and_management: {
                            name: "Patient Interactions and Management",
                            questions_correct: 3,
                            questions_total: 5,
                            children: {
                                ethical_and_legal_aspects: {
                                    name: "Ethical and Legal Aspects",
                                    questions_correct: 3,
                                    questions_total: 5,
                                },
                            },
                        },
                    },
                },
                procedures: {
                    name: "Procedures",
                    questions_correct: 8,
                    questions_total: 12,
                    rows: {
                        patient_interactions_and_management: {
                            name: "Patient Interactions and Management",
                            questions_correct: 3,
                            questions_total: 5,
                            children: {
                                ethical_and_legal_aspects: {
                                    name: "Ethical and Legal Aspects",
                                    questions_correct: 3,
                                    questions_total: 5,
                                },
                            },
                        },
                    },
                },
            },
        };
    },
    computed: {
        ...mapState(["showAnalytics"]),
    },
    methods: {
        openModal(category) {
            this.selectedCategory = category;
            this.modalIsActive = true;
        },
        percentage(correct, total) {
            return Math.round((correct / total) * 100) + "%";
        },
    },
};
</script>
