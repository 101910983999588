<template>
    <PulseContentEngagementCard>
        <div class="md:flex md:items-start md:space-x-4">
            <img
                v-if="image"
                :class="imageClasses"
                :src="image"
                :alt="imageAlt"
            />

            <div class="flex flex-col w-full mt-5 space-y-5 md:mt-0">
                <div class="flex flex-row justify-between">
                    <div class="sm:flex flex-row">
                        <div
                            class="items-center space-x-2"
                            v-for="(crumb, index) in breadcrumbs"
                        >
                            <div v-if="crumb.to">
                                <router-link
                                    :to="crumb.to"
                                    class="text-lg font-medium text-blue-600 whitespace-nowrap lg:text-xl"
                                >
                                    {{ crumb.title }}
                                </router-link>
                                <SvgIcon
                                    class="flex-none"
                                    icon="chevron-right"
                                    size="sm"
                                    weight="extra-bold"
                                    v-if="
                                        index + 1 < (breadcrumbs?.length ?? 0)
                                    "
                                />
                            </div>
                            <div v-else>
                                <p
                                    class="text-lg font-medium text-gray-500 whitespace-nowrap lg:text-xl"
                                >
                                    {{ crumb.title }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        class="text-sm italic font-medium text-gray-500 md:text-base justify-self-end self-end"
                    >
                        {{ activeLearners }}
                        {{ pluralizeLabel("active learner", activeLearners) }}
                    </div>
                </div>

                <PulseContentCountBadges
                    :modules="course?.module_count ?? 0"
                    :lessons="course?.lesson_count ?? 0"
                />

                <slot name="stats"></slot>
            </div>
        </div>
    </PulseContentEngagementCard>
</template>

<script lang="ts">
import { mapGetters } from "vuex";
import { defineComponent, PropType } from "vue";
import { filters } from "../../../../vue-filters";

export default defineComponent({
    props: {
        course: {
            type: Object,
            required: false,
        },
        image: {
            type: String,
            required: false,
        },
        imageAlt: {
            type: String,
            required: false,
        },
        breadcrumbs: {
            type: Array as PropType<{ title: string; to?: string }[]>,
            required: false,
        },
        module: {
            type: Object,
            required: false,
            default: null,
        },
        activeLearners: {
            type: Number,
            required: false,
            default: null,
        },
        viewingAs: {
            type: String,
            required: false,
            default: "overview", // overview, course, or module
        },
    },
    methods: {
        ...filters,
    },
    computed: {
        ...mapGetters(["context"]),
        showBreadcrumbs() {
            return (this.breadcrumbs?.length ?? 0) > 0;
        },
        imageClasses() {
            return this.showBreadcrumbs
                ? "w-32 h-26 rounded"
                : "w-38 h-26 rounded";
        },
        notViewingAsOverview() {
            return this.viewingAs === "course" || this.viewingAs === "module";
        },
        showImage() {
            return Boolean(this.course?.asset);
        },
        coursesUrl() {
            const queryParams = this.$route.query;
            return {
                path: `/courses`,
                query: queryParams,
            };
        },
        courseUrl() {
            const queryParams = this.$route.query;
            return {
                path: `/course/${this.course?.uuid}`,
                query: queryParams,
            };
        },
    },
});
</script>
