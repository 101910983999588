<template>
	<form
		@submit.prevent="() => onSubmit(newCE as CEApproval)"
		class="p-6 rounded flex flex-col border border-gray-300 border-dashed"
		:class="{ bgColor: bgColor, 'bg-gray-100': !bgColor }">
		<h2 v-if="heading" class="text-xl font-medium mb-2">
			{{ heading }}
		</h2>
		<div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 mt-2">
			<div>
				<label
					class="block text-sm font-medium text-gray-700"
					for="course_name">
					Course name
				</label>
				<input
					:value="newCE.course_name"
					class="text-black w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
					disabled
					name="course_name"
					type="text" />
			</div>
			<div>
				<label
					class="block text-sm font-medium text-gray-700"
					for="category">
					Category
				</label>
				<input
					v-model="newCE.category"
					class="text-black w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
					name="category"
					type="text" />
			</div>
			<div>
				<label
					class="block text-sm font-medium text-gray-700"
					for="ce_reference_number">
					Reference Number
				</label>
				<input
					v-model="newCE.reference_number"
					class="text-black w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
					name="ce_reference_number"
					type="text" />
			</div>
			<div>
				<label
					class="block text-sm font-medium text-gray-700"
					for="ce_credit_hours">
					Credit Hours
				</label>
				<input
					v-model="newCE.credits"
					class="text-black w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
					name="ce_credit_hours"
					type="number"
                    step="0.01"/>
			</div>
			<div>
				<label
					class="block text-sm font-medium text-gray-700"
					for="ce_valid_from">
					Valid from
				</label>
				<input
					v-model="newCE.valid_from"
					class="text-black w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
					name="ce_valid_from"
					type="date" />
			</div>
			<div>
				<label
					class="block text-sm font-medium text-gray-700"
					for="ce_valid_to">
					Valid to
				</label>
				<input
					v-model="newCE.valid_to"
					class="text-black w-full border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
					name="ce_valid_to"
					type="date" />
			</div>
		</div>
		<Button type="submit" size="xs" class="ml-auto mt-4">
			{{ buttonText }}
		</Button>
	</form>
</template>
<script setup lang="ts">
import Button from "@/components/ui/ButtonLinks/Button.vue";
import { CEApproval } from "@/types";
import { onMounted, ref } from "vue";
import { format } from "date-fns";

interface Props {
	onSubmit: (ce: CEApproval) => void;
	bgColor?: string;
	heading?: string;
	buttonText: string;
	ce: Partial<CEApproval> | CEApproval;
}

const props = defineProps<Props>();
const formatDate = (date: string | undefined) =>
	date && format(new Date(date), "yyyy-MM-dd");
const newCE = ref<Partial<CEApproval>>({ ...props.ce });

onMounted(() => {
	newCE.value.valid_to = formatDate(newCE.value.valid_to);
	newCE.value.valid_from = formatDate(newCE.value.valid_from);
});

const clearForm = () => {
	newCE.value = {
		course_name: newCE.value.course_name,
		course_id: newCE.value.course_id,
	};
};

defineExpose({
	clearForm,
});
</script>
