import z from "zod";
import {
    backend,
    responseMatch,
    succeed,
    fail,
    failWithError,
    succeedText,
} from "./utils";

export const registerSchema = z.object({
    create_first_name: z
        .string()
        .nonempty({ message: "First name is required" }),
    create_last_name: z.string().nonempty({ message: "Last name is required" }),
    create_email: z.string(),
    create_email_confirmation: z.string(),
    create_password: z.string().min(8, { message: "Password is too short" }),
    create_notifications: z.boolean(),
    create_trial: z.boolean(),
});

export type RegisterData = z.infer<typeof registerSchema>;

export async function register(data: RegisterData) {
    // validate
    const results = await registerSchema.parseAsync(data);
    const response = await backend.post("/register", results, {});
    let temp = await responseMatch(response, {
        302: succeedText,
        "200s": succeedText,
        "300s": failWithError("User with email already exists"),
        "500s": fail,
        default: fail,
    });
    return temp;
}

const onboardingSurveySchema = z.object({
    self_identified_as: z.string().nonempty(),
    institution: z.string().nonempty(),
    using_for: z.string().nonempty(),
    interested_in_topic: z.array(z.string().nonempty()),
    how_did_you_hear: z.string().nonempty(),
    completed: z.boolean(),
});

export type OnboardingSurveyData = Partial<
    z.infer<typeof onboardingSurveySchema>
>;
export type OnboardingSurveyDataKeys = keyof OnboardingSurveyData;
export type OnboardingSurveyDataValues =
    OnboardingSurveyData[OnboardingSurveyDataKeys];

export async function createOnboardingSurvey(data: OnboardingSurveyData) {
    const response = await backend.post("/api/onboarding-survey", data);
}

export async function updateOnboardingSurvey(
    field: OnboardingSurveyDataKeys,
    value: OnboardingSurveyDataValues
) {
    return backend.patch("/api/onboarding-survey", { field, value });
}

export const loginSchema = z.object({
    email: z.string(),
    password: z.string(),
});

export type LoginData = z.infer<typeof loginSchema>;

export async function login(data: LoginData) {
    // validate
    const results = await loginSchema.parseAsync(data);
    const response = await backend.post("/login", results, {});
    let temp = await responseMatch(response, {
        302: succeedText,
        "200s": succeedText,
        "300s": failWithError("User with email already exists"),
        "500s": fail,
        default: fail,
    });
    return temp;
}

export async function startFreeTrial() {
    const response = await backend.post("/api/start-free-trial", {}, {});
    let temp = await responseMatch(response, {
        302: succeedText,
        "200s": succeedText,
        "300s": succeedText,
        "500s": fail,
        default: fail,
    });
    return temp;
}
