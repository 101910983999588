<template>
    <div
        class="bg-white first:rounded-t-md last:rounded-b-md w-full mb-px last:mb-0 h-20"
    >
        <div class="flex items-center">
            <div
                class="flex items-center flex-shrink-0 border-gray-100 px-4 w-full sm:w-48 h-20"
            >
                <div>
                    <div
                        :class="labels[examOwner]"
                        class="inline-block text-xs font-medium rounded-md capitalize leading-6 mt-2 px-2"
                    >
                        {{ examOwner }}
                    </div>
                    <div class="flex items-center text-xs leading-6">
                        <div
                            v-text="$filters.dateTime(examTime, user.timezone)"
                        />
                    </div>
                </div>
            </div>
            <div class="flex items-center flex-grow border-gray-100 px-4 h-20">
                <h2 class="truncate leading-6">
                    {{ exam.name || "Unnamed" }}
                </h2>
            </div>
            <div class="flex items-center border-gray-100 p-4 w-48 h-20">
                <PrepCategoryCircles
                    class="justify-start"
                    :categories="exam.categories"
                    options="stacked"
                />
            </div>
            <div
                class="flex items-center border-gray-100 text-sm p-4 w-24 h-20"
            >
                {{ exam.questions_total }}
            </div>
            <div
                v-if="exam.status == 'completed'"
                class="flex items-center border-gray-100 text-sm px-4 w-24 h-20"
            >
                <div v-html="$filters.elapsed(examDuration)" />
            </div>
            <div
                v-if="exam.status != 'completed'"
                class="flex items-center border-gray-100 px-4 w-48 h-20"
            >
                <div class="flex-grow text-sm font-medium uppercase">
                    <ProgressBar :percent="exam.progress" size="md" />
                </div>
            </div>
            <div
                v-if="exam.status == 'completed'"
                class="flex items-center border-gray-100 text-sm font-medium leading-6 px-4 w-24 h-20"
            >
                <div v-html="$filters.grade(examScore, settings.pulse_grade)" />
            </div>
            <div class="flex items-center justify-center p-4 w-20 h-20">
                <a
                    v-if="viewable"
                    class="flex items-center justify-center bg-gray-100 rounded-full w-8 h-8"
                    v-tippy
                    content="View exam"
                    :href="'/prep/exams/' + exam.uuid"
                >
                    <SvgIcon class="text-blue-600" icon="view" />
                </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { differenceInSeconds } from "date-fns";
import { mapState } from "vuex";

export default {
    props: {
        exam: {
            type: Object,
            default: () => {},
        },
    },
    data(): any {
        return {
            labels: {
                learner: "bg-blue-50 text-blue-700",
                educator: "bg-red-50 text-red-700",
                clover: "bg-pear-100 text-pear-700",
            },
        };
    },
    computed: {
        ...mapState(["settings", "user"]),
        examDuration() {
            return differenceInSeconds(
                new Date(this.exam.time_completed),
                new Date(this.exam.time_started)
            );
        },
        examScore() {
            return (
                (this.exam.questions_correct / this.exam.questions_total) * 100
            );
        },
        examTime() {
            return this.exam.time_completed
                ? this.exam.time_completed
                : this.exam.time_started;
        },
        examOwner() {
            return this.exam.blueprint ? this.exam.blueprint.owner : "learner";
        },
        viewable() {
            return this.exam.blueprint ? !this.exam.blueprint.assignment : true;
        },
    },
};
</script>
