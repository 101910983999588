<template>
    <div>
        <div
            v-if="loading"
            class="bg-gray-300 animate-pulse rounded-lg w-full h-5"
        />
        <slot v-if="!loading" />
    </div>
</template>

<script lang="ts">
export default {
    props: {
        loading: Boolean,
    },
};
</script>
