import axios from "axios";
import { questionBankApi } from "./admin/question-bank";

export function adminSearchLessons(query: string) {
    return axios.get(`/admin/content/learn/lessons-search?q=${query}`);
}

export function adminSearchModules(query: string) {
    return axios.get(`/admin/content/learn/modules-search?q=${query}`);
}

export function adminAddLessonToModule(lessonUuid: string, moduleUuid: string) {
    return axios.post(`/admin/content/learn/add-lesson-to-module`, {
        lessonUuid,
        moduleUuid,
    });
}

export function adminAddModuleToCourse(courseUuid: string, moduleUuid: string) {
    return axios.post(`/api/admin/content/courses/${courseUuid}/modules/${moduleUuid}`, {
        courseUuid,
        moduleUuid,
    });
}

export function getModuleLessons(moduleUuid: string) {
    return axios.get(`/api/admin/content/modules/${moduleUuid}/lessons`);
}

export function getCourseModules(courseUuid: string) {
    return axios.get(`/api/admin/content/courses/${courseUuid}/modules`);
}

export function getCourseCeApprovals(courseUuid: string) {
    return axios.get(`/api/admin/content/learn/courses/${courseUuid}/ceApprovals`);
}

export function updateModuleLessonOrder(
    moduleUuid: string,
    lessonIds: number[]
) {
    return axios.put(`/api/admin/content/modules/${moduleUuid}/lessons/order`, {
        lessonIds,
    });
}

export function updateCourseModuleOrder(
    courseUuid: string,
    moduleUuids: number[]
) {
    return axios.put(`/api/admin/content/courses/${courseUuid}/modules/order`, {
        moduleUuids,
    });
}

export const adminApi = {
    questionBank: questionBankApi,
} as const;
