<script setup lang="ts">
import { PaginationInfo, PrepQuestion } from "@/types";
import { filters } from "@/vue-filters";

const props = defineProps<{
    paginationData: PaginationInfo<PrepQuestion[]>;
}>();

const emits = defineEmits<{
    (e: "next"): void;
    (e: "prev"): void;
}>();

const next = () => {
    if (props.paginationData.current_page < props.paginationData.last_page) {
        emits("next");
    }
};

const prev = () => {
    if (props.paginationData.current_page > 1) {
        emits("prev");
    }
};
</script>
<template>
    <div
        class="flex items-center justify-end"
        v-if="paginationData.data.length > 0"
    >
        <div class="flex items-center space-x-6">
            <div class="flex items-center">
                <span
                    >Showing {{ filters.quantity(paginationData.from) }} –
                    {{ filters.quantity(paginationData.to) }} of
                    {{ filters.quantity(paginationData.total) }}</span
                >
                <button
                    class="flex items-center justify-center bg-blue-500 disabled:bg-blue-200 disabled:cursor-not-allowed hover:bg-blue-400 hover:disabled:bg-blue-200 text-white rounded-full ml-4 mr-1 w-8 h-8"
                    :disabled="paginationData.current_page === 1"
                    @click="prev"
                >
                    <SvgIcon icon="arrow-left" size="xs" weight="extra-bold" />
                </button>
                <button
                    class="flex items-center justify-center bg-blue-600 disabled:bg-blue-200 disabled:cursor-not-allowed hover:bg-blue-700 hover:disabled:bg-blue-200 text-white rounded-full w-8 h-8"
                    :disabled="
                        paginationData.last_page === paginationData.current_page
                    "
                    @click="next"
                >
                    <SvgIcon icon="arrow-right" size="xs" weight="extra-bold" />
                </button>
            </div>
        </div>
    </div>
</template>
