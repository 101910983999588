<template>
    <div class="flex items-center">
        <PrepExamLibraryCategoryCircle
            v-for="root in (categories?.length ? categories : loadingState)"
            :options="options"
            :parent="root"
            :categories="root.kids"
            :key="root"
        />
        <div
            class="hidden bg-indigo-100 text-indigo-600 bg-cyan-100 text-cyan-600 bg-pink-100 text-pink-600 bg-orange-100 text-orange-600"
        ></div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import {MappedRootCategory} from "@/types";
export default defineComponent({
    props: {
        categories: Array<MappedRootCategory>,
        options: String,
    },
    data() {
        return {
            // This creates four empty gray circles to serve as a loading state.
            loadingState: [...Array(4)].map(i =>
                ({
                    name: '',
                    ab: '',
                    color: 'gray',
                    kids:[],
                })
            )
        }
    },
});
</script>
