<template>
    <div>
        <slot v-bind="{ openModal }"></slot>
        <base-modal
            :heading="`Delete ${folder.name}?`"
            :opened="isOpen"
            @close="close"
        >
            <template v-slot:body>
                <div class="flex flex-col space-y-4">
                    <div>Are you sure you want to delete this folder?</div>
                    <div
                        class="flex items-center bg-gray-50 border border-gray-100 rounded-md space-x-2 p-4"
                    >
                        <SvgIcon icon="error" size="base" />
                        <div class="text-sm">
                            All items in this folder will be deleted
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:actions>
                <button
                    type="button"
                    class="danger button"
                    @click="deleteFolder"
                >
                    Delete
                </button>
            </template>
        </base-modal>
    </div>
</template>

<script lang="ts">
import BaseModal from "./BaseModal.vue";
import axios from "axios";

export default {
    name: "ConfirmDeleteSavedItemsFolderModal",

    props: {
        folder: {
            type: Object,
            required: true,
        },
        itemCount: {
            type: Number,
            required: false,
            default: 0,
        },
        redirectUrl: {
            type: String,
            required: true,
        },
    },

    data(): any {
        return {
            isOpen: false,
            name: "",
            errors: {},
        };
    },

    methods: {
        close() {
            this.isOpen = false;
            this.reset();
        },
        deleteFolder() {
            axios
                .delete(
                    `/api/learn/enrollments/${this.folder.enrollment_id}/saved-items-folders/${this.folder.id}`
                )
                .then(() => {
                    window.location = this.redirectUrl;
                })
                .catch(({ response }) => {
                    // todo: proper handling
                });
        },
        open() {
            this.isOpen = true;
        },
        openModal() {
            this.open();
        },
        reset() {
            this.name = "";
            this.errors = {};
        },
    },

    components: {
        BaseModal,
    },
};
</script>
