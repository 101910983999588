<template>
    <BillingSection title="Billing Information">
        <div class="flex flex-col sm:gap-4 sm:flex-row" v-if="email">
            <div class="label-col">Email</div>
            <div class="flex-1 font-semibold">
                {{ email }}
            </div>
        </div>
        <div class="flex flex-col sm:flex-row sm:gap-4 mt-4" v-if="hasAddress">
            <div class="label-col">Billing Address</div>
            <div class="flex-1">
                <div class="font-semibold" v-if="addressLine1">
                    {{ addressLine1 }}
                </div>
                <div class="font-semibold" v-if="addressLine2">
                    {{ addressLine2 }}
                </div>
                <div class="font-semibold">
                    {{ addressCity }}, {{ addressState }}
                    {{ addressPostalCode }} {{ addressCountry }}
                </div>
            </div>
        </div>
        <div>
            <div class="flex flex-row justify-start mt-4">
                <a
                    href="/account/billing/edit-billing-info"
                    class="button small flex flex-row"
                    ><SvgIcon icon="edit" size="sm" class="mr-1" />Update
                    Information</a
                >
            </div>
        </div>
    </BillingSection>
</template>
<script lang="ts">
import { mapState } from "vuex";
import BillingSection from "./BillingSection.vue";

export default {
    components: { BillingSection },
    props: {
        customer: {
            type: Object,
        },
    },
    data(): any {
        return {
            billingInfo: {},
        };
    },
    computed: {
        ...mapState(["user"]),
        addressLine1() {
            return this.customer?.address?.line1;
        },
        addressLine2() {
            return this.customer?.address?.line2;
        },
        addressCity() {
            return this.customer?.address?.city;
        },
        addressState() {
            return this.customer?.address?.state;
        },
        addressPostalCode() {
            return this.customer?.address?.postal_code;
        },
        addressCountry() {
            return this.customer?.address?.country;
        },
        email() {
            return this.customer?.email;
        },
        hasAddress() {
            return (
                this.addressLine1 ||
                this.addressLine2 ||
                this.addressCity ||
                this.addressState ||
                this.addressPostalCode ||
                this.addressCountry
            );
        },
    },
};
</script>
<style scoped>
.label-col {
    /* fle x-basis: 160px; */
}
</style>
