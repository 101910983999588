<template>
    <PrepExamOverview
        :active="active"
        :exam="exam"
        :percentComplete="percentCompleteData"
        :responses="responsesData"
        :status="status"
        :altPath="altPath"
    />
</template>

<script lang="ts">
export default {
    props: {
        active: Number,
        altPath: String,
        exam: String,
        percentComplete: Number,
        responses: Array,
        status: String,
    },
    data(): any {
        return {
            responsesData: null,
            percentCompleteData: null,
        };
    },
    mounted() {
        this.responsesData = this.responses;
        this.percentCompleteData = this.percentComplete;

        this.$events.$on("questionResponse", (response) => {
            this.responsesData = response.responses;
            this.percentCompleteData = response.percent;
        });
    },
};
</script>
