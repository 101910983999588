<template>
    <div>
        <ActionMenu>
            <ActionMenuAction
                type="link"
                label="Create sub group"
                icon="sort"
                icon-color="text-yellow-200"
                action="/test"
            ></ActionMenuAction>
            <ActionMenuAction
                type="button"
                label="Delete group"
                icon="remove"
                icon-color="text-red-300"
                :action="remove"
            ></ActionMenuAction>
        </ActionMenu>
    </div>
</template>

<script lang="ts">
import axios from "axios";

export default {
    props: {
        group: Object,
    },
    methods: {
        remove() {},
    },
};
</script>
