<script lang="ts">
export default {
    props: {
        to: {
            type: [String, Object],
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        active: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
<template>
    <RouterLink
        :to="to"
        as="'button'"
        class="whitespace-nowrap py-4 px-1 border-b-2 font-medium leading-4 capitalize text-sm"
        :class="{
            'report-tab-link': true,
            'border-blue-500 text-blue-600 hover:no-underline': active,
            'border-transparent text-gray-600 hover:text-gray-700 hover:no-underline':
                !active,
        }"
    >
        <div class="flex items-center">
            <div>
                {{ label }}
            </div>
        </div>
    </RouterLink>
</template>
<style>
.report-tab-link {
    text-decoration: none;
}
</style>
