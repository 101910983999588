<script setup lang="ts">
import { defineProps, ref } from "vue";
import {
	Dialog,
	DialogPanel,
	TransitionChild,
	TransitionRoot,
} from "@headlessui/vue";

const props = defineProps<{
	startOpen?: boolean;
	isOpen?: boolean;
	isWide?: boolean;
}>();

const emits = defineEmits<{
	(event: "onClose"): void;
}>();

const isOpen = ref(props.startOpen || false);

const closeModal = () => {
	isOpen.value = false;
	emits("onClose");
};

const openModal = () => {
	isOpen.value = true;
};

const events = {
	closeModal,
	openModal,
};

defineExpose({
	openModal,
});
</script>

<template>
	<TransitionRoot appear :show="isOpen" as="template">
		<Dialog as="div" @close="closeModal" class="relative z-100">
			<TransitionChild
				as="template"
				enter="duration-300 ease-out"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="duration-200 ease-in"
				leave-from="opacity-100"
				leave-to="opacity-0">
				<div class="fixed inset-0 bg-black bg-opacity-50" />
			</TransitionChild>
			<div class="fixed inset-0 overflow-y-auto">
				<div
					class="flex min-h-full items-center justify-center p-4 text-center">
					<TransitionChild
						as="template"
						enter="duration-300 ease-out"
						enter-from="opacity-0 scale-95"
						enter-to="opacity-100 scale-100"
						leave="duration-200 ease-in"
						leave-from="opacity-100 scale-100"
						leave-to="opacity-0 scale-95">
						<DialogPanel
							:class="{
								'max-w-xl': !props.isWide,
								'max-w-3xl': props.isWide,
							}"
							class="text-center prose w-full transform overflow-hidden rounded-md bg-white p-6 md:p-12align-middle shadow-xl transition-all">
							<slot name="panel" :events="events"></slot>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>
