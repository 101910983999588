<script lang="ts">
import { Line } from "vue-chartjs";
import "chartjs-adapter-date-fns";
import { enUS } from "date-fns/locale";

export default {
    components: {
        Line,
    },
    props: {
        chartData: Object,
    },
    // chart options in data
    data(): any {
        // hide dataset label
        return {
            // chartjs options set y access to be increment of 1
            // do not keep aspect ratio
            // hide label
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                elements: {
                    point: {
                        // radius: 0,
                        hitRadius: 5,
                    },
                },
                scales: {
                    y: {
                        ticks: {
                            beginAtZero: true,
                            fontColor: "hsla(218, 24%, 20%, 1)",
                            minRotation: 0,
                            maxRotation: 0,
                            precision: 0,
                            sampleSize: 8,
                        },
                        adapters: {
                            date: {
                                locale: enUS,
                            },
                        },
                    },
                    x: {
                        type: "time",
                        time: {
                            unit: "day",
                            displayFormats: {
                                day: "MMM d",
                            },
                            tooltipFormat: "MMM d",
                        },
                        ticks: {
                            autoSkipPadding: 24,
                            fontColor: "hsla(218, 24%, 20%, 1)",
                            minRotation: 0,
                            maxRotation: 0,
                            sampleSize: 8,
                        },
                        title: {
                            display: false,
                        },
                        adapters: {
                            date: {
                                locale: enUS,
                            },
                        },
                    },
                },
            },
        };
    },
};
</script>
<template>
    <Line :data="chartData" :options="options" />
</template>
