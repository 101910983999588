<template>
    <div class="bg-white rounded-md shadow">
        <div
            class="flex flex-col justify-center items-center py-16 px-16 text-center"
        >
            <div class="py-4">
                <SvgIcon
                    class="text-gray-200"
                    :icon="icon"
                    size="xl2"
                    weight="light"
                />
            </div>
            <h3
                class="mt-2 text-sm font-medium text-gray-900"
                v-text="heading"
            />
            <p class="mt-1 text-sm text-gray-500" v-text="content" />
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        icon: String,
        heading: String,
        content: String,
    },
};
</script>
