<template>
    <div>
        <PulseLearnOverview
            title="Courses"
            :loading="loading"
            :subtitle="viewSubtitle"
        >
            <PulseCourseEngagementCard
                v-for="course in sortedCourses"
                :key="course.course_uuid"
                :image="course?.asset ?? null"
                :image-alt="course?.title ?? ''"
                :breadcrumbs="[
                    {
                        title: course.title,
                        to: {
                            path: `/course/${course.uuid}`,
                            query: $route.query,
                        },
                    },
                ]"
                :course="course"
                :active-learners="course.active_learners"
            >
                <template v-slot:stats>
                    <PulseContentEngagementStats
                        v-if="course"
                        :lesson-views="course.lesson_views"
                        :assessment-attempts="course.module_quiz_attempts"
                        :assessment-average-score="
                            course.module_quiz_average_score
                        "
                        :quiz-attempts="course.lesson_quiz_attempts"
                        :quiz-average-score="course.lesson_quiz_average_score"
                    />
                </template>
            </PulseCourseEngagementCard>
            <template #sort>
                <PulseLearnOverviewSort
                    :options="sortBy"
                    @sort="handleSort"
                    :selected="sort"
                />
            </template>
            <!--            <template #pagination>-->
            <!--                <PulseLearnOverviewPagination :pagination="pagination" @page-updated="handlePageUpdated" />-->
            <!--            </template>-->
        </PulseLearnOverview>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import { getCoursesEngagement } from "../../../../api/pulse-requests";

export default {
    data(): any {
        return {
            courses: [],
            loading: true,
            sortBy: [
                "alphabetically",
                "lesson views",
                "quiz attempts",
                "assessment attempts",
            ],
            sort:
                localStorage.getItem("pulse.cards.selectedSort") ||
                "alphabetically",
            pagination: {},
            currentPage: "",
        };
    },
    computed: {
        ...mapGetters(["watchContext"]),
        sortedCourses() {
            switch (this.sort) {
                case "alphabetically":
                    return this.courses.sort((a, b) =>
                        a.title.localeCompare(b.title)
                    );
                case "lesson views":
                    return this.courses.sort(
                        (a, b) => b.lesson_views - a.lesson_views
                    );
                case "quiz attempts":
                    return this.courses.sort(
                        (a, b) =>
                            b.lesson_quiz_attempts - a.lesson_quiz_attempts
                    );
                case "assessment attempts":
                    return this.courses.sort(
                        (a, b) =>
                            b.module_quiz_attempts - a.module_quiz_attempts
                    );
                default:
                    return this.courses;
            }
        },
        ...mapState(["group", "partner", "period", "type", "selectedLearners"]),
        viewSubtitle() {
            let count = this.courses?.length ?? 0;

            return count + " " + this.$filters.pluralizeLabel("course", count);
        },
        apiContext() {
            return `group=${this.group}&partner=${this.partner}&period=${this.period}&type=${this.type}`;
        },
    },
    methods: {
        getData() {
            if (this.partner || this.group) {
                getCoursesEngagement(
                    "courses",
                    this.partner,
                    this.group,
                    this.period,
                    null,
                    this.selectedLearners
                ).then((response) => {
                    this.courses = response.data.data;
                    this.pagination = response.data.meta;
                    this.loading = false;
                });
            }
        },
        handleSort(sortBy) {
            localStorage.setItem("pulse.cards.selectedSort", sortBy);
            this.sort = sortBy;
            this.currentPage = 1;
        },
        handlePageUpdated(page) {
            this.currentPage = page;
            // this.getData()
        },
    },
    watch: {
        watchContext: {
            handler() {
                this.getData();
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>
