<template>
    <div>
        <template v-if="!loading">
            <h3
                class="flex items-center justify-between bg-white text-gray-800 border-l-4 border-pear-600 p-4 rounded shadow mb-12"
            >
                <div class="flex items-center w-128">
                    <svg-icon class="text-pear-600 mr-2" :icon="typeIcon" />
                    <div class="flex items-center">
                        {{ typeTitle }}:
                        <strong class="font-semibold ml-1">{{
                            assessment.assessment_title
                        }}</strong>
                        <div class="flex items-center ml-16">
                            <SvgIcon
                                class="text-pear-600 mr-2"
                                icon="clock"
                                size="sm"
                            />
                            <div
                                v-text="
                                    $filters.dateTime(
                                        rows[0].created_at,
                                        user.timezone
                                    )
                                "
                            />
                        </div>
                    </div>
                </div>
                <div class="flex items-center">
                    <div class="text-center text-lg px-8">
                        <strong class="text-pear-600 font-bold">
                            <span
                                v-html="
                                    $filters.grade(
                                        assessmentData.avgScore,
                                        settings.pulse_grade
                                    )
                                "
                            />
                        </strong>
                        <h4 class="text-sm uppercase mt-2">Score</h4>
                    </div>
                    <div class="text-center text-lg px-8">
                        <strong class="text-pear-600 font-bold">{{
                            $filters.elapsed(assessmentData.avgDuration)
                        }}</strong>
                        <h4 class="text-sm uppercase mt-2">Duration</h4>
                    </div>
                </div>
            </h3>
            <PulseAssessmentQuestions
                :assessment="assessment"
                :total="assessmentData.taken"
            />
        </template>
        <LoadingSpinner :show="loading" />
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState, mapGetters } from "vuex";

export default {
    data(): any {
        return {
            assessment: {},
            loading: true,
            rows: [],
        };
    },
    computed: {
        ...mapState([
            "id",
            "period",
            "scope",
            "settings",
            "type",
            "typeId",
            "user",
        ]),
        ...mapGetters(["context"]),
        typeIcon() {
            switch (this.type) {
                case "quizzes":
                    return "checklist";
                    break;
                case "assessments":
                    return "exam";
                    break;
            }
        },
        typeTitle() {
            switch (this.type) {
                case "quizzes":
                    return "Quiz";
                    break;
                case "assessments":
                    return "Assessment";
                    break;
            }
        },
    },
    methods: {
        getData() {
            axios
                .get(
                    "/api/pulse/assessment/" +
                        this.context +
                        "/" +
                        this.type +
                        "/" +
                        this.typeId +
                        "/overview"
                )
                .then((response) => {
                    this.assessment = response.data.assessment;
                    this.rows = response.data.rows;
                    this.assessmentData = {
                        taken: response.data.taken,
                        avgScore: response.data.avgScore,
                        avgDuration: response.data.avgDuration,
                    };
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getData();
    },
};
</script>
