<template>
    <div>
        <div
            class="flex justify-between p-2 my-1 flex-column md:flex-row lg:px-4"
        >
            <div class="flex flex-row w-full md:max-w-5/6">
                <div
                    class="hidden my-auto text-xs font-semibold tracking-tight uppercase whitespace-nowrap md:mr-4 md:block"
                >
                    Completion Rate
                </div>

                <div class="flex flex-row w-full md:w-80">
                    <PulseCompletionBar :percent="percent" />

                    <div
                        class="flex flex-row justify-between w-20 my-auto text-xs font-semibold"
                        :class="[
                            percent < 100
                                ? 'text-navy-700'
                                : 'text-emerald-600',
                        ]"
                    >
                        <span class="w-10">{{ percent.toFixed() }}%</span>
                        <span
                            class="w-10 my-auto text-xs font-semibold text-gray-500"
                            >({{ completedCount }}/{{ totalCount }})</span
                        >
                    </div>
                </div>
            </div>

            <div
                class="px-3 my-auto cursor-pointer"
                :class="[rowIsExpanded ? '-rotate-90' : '']"
                @click="rowIsExpanded = !rowIsExpanded"
            >
                <SvgIcon icon="chevron-down" size="xs" weight="bold" />
            </div>
        </div>

        <div
            v-if="rowIsExpanded"
            class="inline-block min-w-full overflow-hidden align-middle"
        >
            <!-- Nested Table -->
            <slot />
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        completedCount: Number,
        expanded: Boolean,
        totalCount: Number,
    },
    data(): any {
        return {
            rowIsExpanded: this.expanded,
        };
    },
    computed: {
        percent() {
            return (this.completedCount / this.totalCount) * 100;
        },
    },
    watch: {
        expanded() {
            this.rowIsExpanded = this.expanded;
        },
    },
};
</script>
