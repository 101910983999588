<template>
    <transition
        enter-active-class="transition duration-300"
        leave-active-class="transition duration-300"
    >
        <div
            class="fixed inset-0 overflow-hidden z-80 transition duration-300"
            v-show="show"
        >
            <div class="absolute inset-0 overflow-hidden">
                <transition
                    enter-active-class="transition ease-in-out duration-300"
                    enter-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition ease-in-out duration-300"
                    leave-class="opacity-100"
                    leave-to-class="opacity-0"
                >
                    <div
                        class="absolute inset-0 bg-gray-900 bg-opacity-75 transition-opacity"
                        v-show="show"
                        @click="close"
                    ></div>
                </transition>
                <transition
                    enter-active-class="transform transition ease-in-out duration-300 sm:duration-300"
                    enter-class="translate-x-full"
                    enter-to-class="translate-x-0"
                    leave-active-class="transform transition ease-in-out duration-300 sm:duration-300"
                    leave-class="translate-x-0"
                    leave-to-class="translate-x-full"
                >
                    <section
                        class="absolute inset-y-0 right-0 pl-10 max-w-full flex"
                        v-show="show"
                    >
                        <div class="relative w-screen" style="max-width: 800px">
                            <transition
                                enter-active-class="transition ease-in-out duration-300"
                                enter-class="opacity-0"
                                enter-to-class="opacity-100"
                                leave-active-class="transition ease-in-out duration-300"
                                leave-class="opacity-100"
                                leave-to-class="opacity-0"
                            >
                                <div
                                    class="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4"
                                    v-show="show"
                                >
                                    <button
                                        aria-label="Close panel"
                                        class="text-gray-300 hover:text-white transition ease-in-out duration-150"
                                        @click="close"
                                    >
                                        <svg
                                            class="h-6 w-6"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="M6 18L18 6M6 6l12 12"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </transition>
                            <div
                                class="h-full flex flex-col space-y-4 py-4 bg-gray-100 shadow-xl overflow-y-scroll"
                            >
                                <div class="relative flex-1">
                                    <slot />
                                </div>
                            </div>
                        </div>
                    </section>
                </transition>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
export default {
    props: {
        trigger: String,
    },
    data(): any {
        return {
            show: false,
        };
    },
    methods: {
        close() {
            this.show = false;
            this.$events.$emit("slideoverDismissed");
        },
        registerListeners() {
            this.$events.$on(this.trigger, () => {
                this.show = true;
            });
            this.$events.$on("closeSlideoverPanel", () => {
                this.close();
            });
        },
    },
    mounted() {
        this.registerListeners();
    },
};
</script>
