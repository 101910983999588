<template>
    <div
        class="flex-1 rounded py-4 px-8"
        v-bind:class="{
            'bg-green-100': type === 'success',
            'bg-red-100': type === 'error',
            'bg-yellow-100': type === 'warning',
            'bg-gray-100': type === 'info',
        }"
    >
        <p>
            <strong v-if="title" class="mr-2">{{ title }}</strong
            >{{ message }}
        </p>
    </div>
</template>
<script lang="ts">
export default {
    props: {
        message: {
            type: String,
            required: true,
        },
        title: {
            type: String,
        },
        type: {
            type: String,
            required: true,
        },
    },
};
</script>
<style scoped></style>
