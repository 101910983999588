<template>
    <div>
        <div class="flex items-end justify-between px-4">
            <div class="w-72 mr-8">
                <div class="flex items-center">
                    <h3 class="font-bold">Learner</h3>
                    <sort-button
                        emit="sortAssessment"
                        initial="asc"
                        variable="name"
                    />
                </div>
            </div>
            <div class="w-32 text-center mr-8">
                <div class="flex items-center justify-center">
                    <h3 class="font-bold">Taken</h3>
                    <sort-button
                        emit="sortAssessment"
                        initial="asc"
                        variable="taken"
                    />
                </div>
            </div>
            <div class="w-96 text-center mr-8">
                <h3 class="font-bold mb-3">Latest</h3>
                <div class="flex">
                    <div class="flex items-center justify-center w-1/3">
                        <h3>Date</h3>
                        <sort-button
                            emit="sortAssessment"
                            initial="asc"
                            variable="date"
                        />
                    </div>
                    <div class="flex items-center justify-center w-1/3">
                        <h3>Score</h3>
                        <sort-button
                            emit="sortAssessment"
                            initial="asc"
                            variable="latestScore"
                        />
                    </div>
                    <div class="flex items-center justify-center w-1/3">
                        <h3>Duration</h3>
                        <sort-button
                            emit="sortAssessment"
                            initial="asc"
                            variable="latestDuration"
                        />
                    </div>
                </div>
            </div>
            <div class="w-64 text-center mr-8">
                <h3 class="font-bold mb-3">Average</h3>
                <div class="flex">
                    <div class="flex items-center justify-center w-1/2">
                        <h3>Score</h3>
                        <sort-button
                            emit="sortAssessment"
                            initial="asc"
                            variable="averageScore"
                        />
                    </div>
                    <div class="flex items-center justify-center w-1/2">
                        <h3>Duration</h3>
                        <sort-button
                            emit="sortAssessment"
                            initial="asc"
                            variable="averageDuration"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <pulse-assessment-learners-row
                v-for="row in sorted"
                :key="row.uuid"
                :row="row"
            />
        </div>
    </div>
</template>

<script lang="ts">
import _ from "lodash";
export default {
    props: {
        rows: {
            type: Array,
        },
    },
    data(): any {
        return {
            sortBy: "name",
            sortOrder: "asc",
        };
    },
    computed: {
        sorted() {
            return _.orderBy(
                this.rows,
                [
                    (row: any) => {
                        return row[this.sortBy] || "";
                    },
                ],
                this.sortOrder
            );
        },
    },
    mounted() {
        this.$events.$on("sortAssessment", (response) => {
            this.sortBy = response.sortBy;
            this.sortOrder = response.sortOrder;
        });

        this.$events.$on("hydrateRow", (response) => {
            let rowIndex = this.rows.findIndex((o) => o.uuid == response.uuid);
            this.$set(this.rows[rowIndex], "taken", response.taken);
            this.$set(this.rows[rowIndex], "date", response.date);
            this.$set(this.rows[rowIndex], "latestScore", response.latestScore);
            this.$set(
                this.rows[rowIndex],
                "latestDuration",
                response.latestDuration
            );
            this.$set(
                this.rows[rowIndex],
                "averageScore",
                response.averageScore
            );
            this.$set(
                this.rows[rowIndex],
                "averageDuration",
                response.averageDuration
            );
        });
    },
};
</script>
