<template>
    <div v-show="show_form">
        <div class="bg-white rounded-md shadow p-8">
            <div class="flex flex-col space-y-8">
                <h3 class="text-lg font-bold">Login</h3>
                <div class="space-y-6">
                    <slot />

                    <button
                        class="main button"
                        type="submit"
                        @click="submitEmail"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";

export default {
    data(): any {
        return {
            show_form: true,
        };
    },
    methods: {
        submitEmail() {
            var emailAddress = (
                document.getElementById("emailCheck") as HTMLInputElement
            ).value;

            axios
                .post("/auth/email/", {
                    email: emailAddress,
                })
                .then((response) => {
                    if (response.data == "inUnmigratedGroup") {
                        window.location.href =
                            "https://www.cloverleaflearning.com/learn/sign_in";
                    } else if (response.data == "isMigrated") {
                        window.location.href = "migrate";
                    } else if (response.data == "emailError") {
                        window.location.reload();
                    } else {
                        this.$events.$emit("displayLoginForm", {
                            email_address: emailAddress,
                        });
                        this.show_form = false;
                    }
                });
        },
    },
};
</script>
