<template>
    <div>
        <template v-if="!loading" v-for="row in rows">
            <div
                class="bg-white text-gray-800 border-l-4 border-gray-500 rounded shadow mb-4"
                :class="{ 'border-pear-600': row.show }"
            >
                <h2 class="flex items-center justify-between p-4">
                    <div class="flex items-center">
                        <svg-icon
                            class="text-gray-600 mr-2"
                            :class="{ 'text-pear-600': row.show }"
                            icon="course"
                        />
                        <button class="cta" @click="toggle(row)">
                            {{ row.courseTitle }}
                        </button>
                    </div>
                    <div
                        class="flex items-center"
                        :class="{ invisible: !row.show }"
                    >
                        <div class="mr-2">Hide empty rows</div>
                        <toggle-switch v-model="row.hideEmpty" size="sm" />
                    </div>
                </h2>
                <div
                    class="overflow-hidden"
                    :ref="'row_' + row.uuid"
                    v-if="row.show"
                >
                    <div class="bg-gray-200 flex items-end justify-between p-4">
                        <div class="w-72 mr-8">
                            <h3 class="font-bold">{{ typeTitle }}</h3>
                        </div>
                        <div class="w-32 text-center mr-8">
                            <h3 class="font-bold">Taken</h3>
                        </div>
                        <div class="w-32 text-center mr-8">
                            <h3 class="font-bold">Avg Score</h3>
                        </div>
                        <div class="w-32 text-center mr-8">
                            <h3 class="font-bold">Avg Duration</h3>
                        </div>
                    </div>
                    <div class="card mt-0">
                        <pulse-assessments-row
                            v-for="assessment in row.assessments"
                            :hideEmpty="row.hideEmpty"
                            :key="assessment.uuid[0]"
                            :row="assessment"
                        />
                    </div>
                </div>
            </div>
        </template>
        <div
            class="flex items-center justify-center"
            v-if="loading"
            style="height: 100px"
        >
            <div class="dark loader"></div>
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState, mapGetters } from "vuex";
import { TweenMax } from "gsap";

export default {
    data(): any {
        return {
            loading: true,
            rows: [],
        };
    },
    computed: {
        ...mapState(["type"]),
        ...mapGetters(["context"]),
        typeTitle() {
            switch (this.type) {
                case "quizzes":
                    return "Quiz";
                    break;
                case "assessments":
                    return "Assessment";
                    break;
            }
        },
    },
    methods: {
        open(ref) {
            TweenMax.set(ref, { height: "auto" });
            TweenMax.from(ref, 0.3, {
                ease: Power4.easeIn,
                force3D: true,
                height: "0",
            });
        },
        close(ref) {
            TweenMax.to(ref, 0.3, {
                ease: Power4.easeOut,
                force3D: true,
                height: "0",
            });
        },
        toggle(row) {
            row.show = !row.show;
            // if (!row.show) {
            //     row.show = true
            //     setTimeout(() => {
            //         this.open(this.$refs['row_' + row.uuid])
            //     }, 10)
            // } else {
            //     this.close(this.$refs['row_' + row.uuid])
            //     setTimeout(() => {
            //         row.show = false
            //     }, 300)
            // }
        },
        getData() {
            axios
                .get("/api/pulse/assessment/" + this.context + "/" + this.type)
                .then((response) => {
                    this.rows = response.data;
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getData();
    },
};
</script>
