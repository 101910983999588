<template>
    <dropdown
        :modelValue="period ? periodLabel : ''"
        context-string="Period"
        fade-classes="absolute top-0 left-0 sm:left-auto sm:right-0 -ml-4 mb-4 sm:ml-0 sm:mb-0 mt-14 z-80 sm:z-10 w-screen sm:w-auto"
        ref="dropdown"
        variant="period"
        @show="openDatePicker"
        width-class="w-72"
    >
        <div class="flex flex-wrap items-start sm:flex-nowrap">
            <div
                class="grid w-full grid-cols-2 px-6 pt-8 text-sm sm:inline-flex sm:flex-col whitespace-nowrap sm:pt-12"
            >
                <button
                    class="flex items-center justify-start p-2 mb-1 rounded-lg sm:justify-end sm:mt-2"
                    @click="update('1w')"
                    :class="{
                        'bg-blue-100 text-blue-600 font-medium':
                            period === '1w',
                    }"
                >
                    <SvgIcon
                        class="mr-2"
                        icon="calendar-1w"
                        size="sm"
                        weight="medium"
                    />
                    Past week
                </button>
                <button
                    class="flex items-center justify-start p-2 mb-1 rounded-lg sm:justify-end"
                    @click="update('1m')"
                    :class="{
                        'bg-blue-100 text-blue-600 font-medium':
                            period === '1m',
                    }"
                >
                    <SvgIcon
                        class="mr-2"
                        icon="calendar-1m"
                        size="sm"
                        weight="medium"
                    />
                    Past month
                </button>
                <button
                    class="flex items-center justify-start p-2 mb-1 rounded-lg sm:justify-end"
                    @click="update('3m')"
                    :class="{
                        'bg-blue-100 text-blue-600 font-medium':
                            period === '3m',
                    }"
                >
                    <SvgIcon
                        class="mr-2"
                        icon="calendar-3m"
                        size="sm"
                        weight="medium"
                    />
                    Past 3 months
                </button>
                <button
                    class="flex items-center justify-start p-2 mb-1 rounded-lg sm:justify-end"
                    @click="update('1y')"
                    :class="{
                        'bg-blue-100 text-blue-600 font-medium':
                            period === '1y',
                    }"
                >
                    <SvgIcon
                        class="mr-2"
                        icon="calendar-1y"
                        size="sm"
                        weight="medium"
                    />
                    Past year
                </button>
                <div
                    class="flex items-center justify-end p-2 font-medium text-right text-blue-600 rounded-lg cursor-pointer bg-blue-50"
                    v-show="periodIsCustom"
                >
                    <SvgIcon
                        class="mr-2"
                        icon="calendar-custom"
                        size="sm"
                        weight="medium"
                    />
                    Custom
                </div>
            </div>
            <div
                class="flex flex-wrap items-center justify-center sm:flex-nowrap sm:pr-2"
            >
                <div
                    class="flex flex-col px-2 pt-8 border-b border-gray-100 sm:border-b-0 sm:border-l"
                >
                    <div class="flex flex-col mx-4 mb-2">
                        <div class="mb-1 text-sm font-medium leading-5">
                            Start Date
                        </div>
                        <input
                            class="p-2 border border-gray-200 rounded focus-visible:ring focus-visible:ring-blue-300 focus-visible:ring-opacity-50"
                            v-model="startDateString"
                            @change="updateStart"
                            aria-label="Update Start Date"
                        />
                    </div>
                    <v-date-picker ref="start" v-model="startDate" />
                </div>
                <div
                    class="flex flex-col pt-8 pl-2 border-gray-100 sm:border-l"
                >
                    <div class="flex flex-col mx-4 mb-2">
                        <div class="mb-1 text-sm font-medium leading-5">
                            End Date
                        </div>
                        <input
                            class="p-2 border border-gray-200 rounded focus-visible:ring focus-visible:ring-blue-300 focus-visible:ring-opacity-50"
                            v-model="endDateString"
                            @change="updateEnd"
                            aria-label="Update End Date"
                        />
                    </div>
                    <v-date-picker ref="end" v-model="endDate" />
                </div>
            </div>
        </div>
        <div
            class="flex justify-end p-6 border-t border-gray-100 bg-gray-50 rounded-b-md"
        >
            <button
                class="small primary button"
                type="button"
                @click="update(dateRoute)"
            >
                Update
            </button>
        </div>
    </dropdown>
</template>

<script lang="ts">
import { format, parse, sub } from "date-fns";
import { mapState } from "vuex";

export default {
    props: {
        legacy: Boolean,
    },
    data(): any {
        return {
            startDate: null,
            startDateString: null,
            endDate: null,
            endDateString: null,
            newPeriod: null,
        };
    },
    computed: {
        ...mapState(["period"]),
        periodIsCustom() {
            switch (this.period) {
                case "1w":
                case "1m":
                case "3m":
                case "1y":
                case "mtd":
                case "ytd":
                    return false;
                    break;
                default:
                    return true;
            }
        },
        periodLabel() {
            switch (this.period) {
                case "1w":
                    return "Past week";
                    break;
                case "1m":
                    return "Past month";
                    break;
                case "3m":
                    return "Past 3 months";
                    break;
                case "1y":
                    return "Past year";
                    break;
                case "mtd":
                    return "Month-to-date";
                    break;
                case "ytd":
                    return "Year-to-date";
                    break;
                default:
                    return (
                        this.periodStart.month +
                        "/" +
                        this.periodStart.day +
                        "/" +
                        this.periodStart.year +
                        " – " +
                        this.periodEnd.month +
                        "/" +
                        this.periodEnd.day +
                        "/" +
                        this.periodEnd.year
                    );
            }
        },
        periodStart() {
            let date;
            if (this.periodIsCustom) {
                date = parse(
                    this.period.split("-")[0].toString(),
                    "yyyyMMdd",
                    new Date()
                );
            } else {
                switch (this.period) {
                    case "1w":
                        date = sub(Date.now(), { days: 7 });
                        break;
                    case "1m":
                        date = sub(Date.now(), { months: 1 });
                        break;
                    case "3m":
                        date = sub(Date.now(), { months: 3 });
                        break;
                    case "1y":
                        date = sub(Date.now(), { years: 1 });
                        break;
                }
            }

            return {
                year: format(date, "yyyy"),
                month: format(date, "M"),
                day: format(date, "d"),
            };
        },
        periodEnd() {
            let date;

            if (this.periodIsCustom) {
                date = parse(
                    this.period.split("-")[1].toString(),
                    "yyyyMMdd",
                    new Date()
                );
            } else {
                date = Date.now();
            }

            return {
                year: format(date, "yyyy"),
                month: format(date, "M"),
                day: format(date, "d"),
            };
        },
        dateRoute() {
            if (!this.startDate || !this.endDate) {
                return null;
            }
            return (
                format(this.startDate, "yyyyMMdd") +
                "-" +
                format(this.endDate, "yyyyMMdd")
            );
        },
    },
    mounted() {},
    methods: {
        hideDropdown() {
            // this.$refs.dropdown.hide();
        },
        openDatePicker() {
            this.newPeriod = this.period;
            this.calculateStartEndStrings();
        },
        calculateStartEndStrings() {
            if (!this.periodIsCustom) {
                switch (this.period) {
                    case "1w":
                        this.startDateString = format(
                            sub(Date.now(), { days: 7 }),
                            "MM/dd/yyyy"
                        );
                        break;
                    case "1m":
                        this.startDateString = format(
                            sub(Date.now(), { months: 1 }),
                            "MM/dd/yyyy"
                        );
                        break;
                    case "3m":
                        this.startDateString = format(
                            sub(Date.now(), { months: 3 }),
                            "MM/dd/yyyy"
                        );
                        break;
                    case "1y":
                        this.startDateString = format(
                            sub(Date.now(), { years: 1 }),
                            "MM/dd/yyyy"
                        );
                        break;
                }
                this.endDateString = format(Date.now(), "MM/dd/yyyy");
                this.startDate = parse(
                    this.startDateString,
                    "MM/dd/yyyy",
                    new Date()
                );
                this.endDate = parse(
                    this.endDateString,
                    "MM/dd/yyyy",
                    new Date()
                );
                this.$refs.start.move(this.startDate);
                this.$refs.end.move(this.endDate);
            }
        },
        update(period) {
            if (period === this.period) {
                this.hideDropdown();
                return;
            }
            // add period to $router query string
            this.$router.replace({
                query: {
                    ...this.$route.query,
                    period: period,
                },
            });
            this.$store.commit("registerState", {
                period: period,
            });
            this.hideDropdown();
        },
        updateEnd() {
            let end = new Date(this.endDateString);
            if (end instanceof Date) {
                this.$refs.end.$refs.calendar.focusDate(end);
                this.endDate = end;
            }
        },
        updateStart() {
            let start = new Date(this.startDateString);
            if (start instanceof Date) {
                this.$refs.start.$refs.calendar.focusDate(start);
                this.startDate = start;
            }
        },
    },
    watch: {
        endDate(value) {
            this.endDateString = format(value, "MM/dd/yyyy");
        },
        startDate(value) {
            this.startDateString = format(value, "MM/dd/yyyy");
        },
    },
};
</script>

<style>
.vc-container {
    border: none !important;
}

.vc-highlight {
    background-color: theme("colors.blue.500") !important;
}
</style>
