<script setup lang="ts">
import { defineProps, ref } from "vue";
import axios from "axios";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const uuid = ref(route.params.uuid);

const props = defineProps<{}>();

const module = ref<{
    title: string;
    slug: string;
    exam_id: number | null;
}>({
    title: "",
    slug: "",
    exam_id: null,
});

const submitForm = async (e) => {
    e?.preventDefault();

    // validate data name and slug required > 3 characters
    if (module.value.title.length < 3 || module.value.slug.length < 3) {
        return;
    }

    await axios.post(`/api/admin/content/courses/${uuid.value}/modules`, {
        title: module.value.title,
        slug: module.value.slug,
        exam_id: module.value.exam_id,
    });

    // go back to course page
    await router.replace(`/content/learn/courses/${uuid.value}`);
};
</script>
<template>
    <div class="flex gap-6 flex-col">
        <router-link :to="`/content/learn/courses/${uuid}`" class="text-sm text-gray-500">&larr; Back to Course</router-link>
        <h2 class="font-semibold text-2xl tracking-tight">Add Module</h2>
        <form @submit="submitForm" id="newModuleForm" class="shadow rounded-x">
            <div class="bg-white rounded-t-md space-y-6 py-9 px-6">
                <div class="grid grid-cols-2 gap-6">
                    <div>
                        <label
                            for="title"
                            class="flex items-center text-sm leading-7 font-medium ml-2 space-x-4"
                        >
                            Title
                        </label>
                        <input
                            type="text"
                            name="title"
                            id="title"
                            v-model="module.title"
                            class="h-12"
                            required
                        />
                    </div>
                    <div>
                        <label
                            for="slug"
                            class="flex items-center text-sm leading-7 font-medium ml-2 space-x-4"
                        >
                            Slug
                        </label>
                        <input
                            type="text"
                            name="slug"
                            id="slug"
                            v-model="module.slug"
                            class="h-12"
                            required
                        />
                    </div>
                </div>
            </div>
            <div
                class="bg-gray-50 border-t border-gray-200 rounded-b-md p-6 flex justify-end"
            >
                <button class="main button rounded-md" type="submit">
                    Submit
                </button>
            </div>
        </form>
    </div>
</template>
