<template>
    <div>
        <div class="flex items-center justify-end mb-4">
            <a
                class="flex items-center small main button space-x-2"
                :href="createUrl"
            >
                <SvgIcon icon="add" size="xs" weight="extra-bold" />
                <div>Add new</div>
            </a>
        </div>
        <section class="section-main">
            <div>
                <div
                    class="flex items-start bg-gray-200 rounded-md shadow-inner justify-start p-6 mb-8"
                >
                    <div
                        v-if="questionType == 'prep'"
                        class="flex flex-col mr-4 w-96"
                    >
                        <label
                            class="font-semibold uppercase leading-5 text-xs mb-1"
                            >Categories</label
                        >
                        <PrepCategoryFilter
                            v-model="category"
                            @update:modelValue="resetPage"
                        />
                    </div>
                    <div v-else class="flex flex-col mr-4 w-96">
                        <label
                            class="font-semibold uppercase leading-5 text-xs mb-1"
                            >Exam</label
                        >
                        <SelectDropdown v-model="exam">
                            <option value="">Select an option</option>
                            <option
                                v-for="exam in exams"
                                v-text="exam.name"
                                :value="exam.id + ''"
                            />
                        </SelectDropdown>
                    </div>
                    <div class="flex flex-col mr-4">
                        <label
                            class="font-semibold uppercase leading-5 text-xs mb-1"
                            >Status</label
                        >
                        <SelectDropdown
                            v-model="status"
                            variant="status"
                            @input="resetPage"
                        >
                            <option value="">All</option>
                            <option value="published">Published</option>
                            <option value="draft">Draft</option>
                            <option value="closed">Closed</option>
                        </SelectDropdown>
                    </div>
                    <div
                        v-if="questionType == 'prep'"
                        class="flex flex-col mr-4"
                    >
                        <label
                            class="font-semibold uppercase leading-5 text-xs mb-1"
                            >Access</label
                        >
                        <SelectDropdown
                            v-model="access"
                            variant="access"
                            @input="resetPage"
                        >
                            <option value="open">Open</option>
                            <option value="reserved">Reserved</option>
                        </SelectDropdown>
                    </div>
                    <div class="flex flex-col mr-4">
                        <label
                            class="font-semibold uppercase leading-5 text-xs mb-1"
                            >Flagged</label
                        >
                        <SelectDropdown v-model="flagged" @input="resetPage">
                            <option value="">All</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </SelectDropdown>
                    </div>
                    <div class="flex flex-col w-64">
                        <label
                            class="font-semibold uppercase leading-5 text-xs mb-1"
                            >Keyword</label
                        >
                        <input
                            class="leading-5 rounded p-3 shadow"
                            type="text"
                            v-model="keyword"
                            @input="resetPage"
                        />
                    </div>
                </div>
                <template v-if="!loading">
                    <div
                        class="flex items-center justify-end mb-4"
                        v-if="questions.length > 0"
                    >
                        <div class="flex items-center space-x-6">
                            <div class="flex items-center">
                                <span
                                    >Showing
                                    {{ $filters.quantity(resultStart) }} –
                                    {{ $filters.quantity(resultEnd) }} of
                                    {{ $filters.quantity(resultTotal) }}</span
                                >
                                <button
                                    class="flex items-center justify-center bg-blue-500 disabled:bg-blue-200 disabled:cursor-not-allowed hover:bg-blue-400 hover:disabled:bg-blue-200 text-white rounded-full ml-4 mr-1 w-8 h-8"
                                    :disabled="page == 1"
                                    @click="prevPage"
                                >
                                    <SvgIcon
                                        icon="arrow-left"
                                        size="xs"
                                        weight="extra-bold"
                                    />
                                </button>
                                <button
                                    class="flex items-center justify-center bg-blue-500 disabled:bg-blue-200 disabled:cursor-not-allowed hover:bg-blue-400 hover:disabled:bg-blue-200 text-white rounded-full w-8 h-8"
                                    :disabled="page == pages"
                                    @click="nextPage"
                                >
                                    <SvgIcon
                                        icon="arrow-right"
                                        size="xs"
                                        weight="extra-bold"
                                    />
                                </button>
                            </div>
                            <SelectDropdown v-model="pageSize">
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </SelectDropdown>
                        </div>
                    </div>
                    <ContentQuestionsList
                        :questions="questions"
                        :questionType="questionType"
                    />
                    <div
                        class="flex items-center justify-end mb-4"
                        v-if="questions.length > 0"
                    >
                        <div class="flex items-center">
                            <div class="flex items-center">
                                <span
                                    >Showing
                                    {{ $filters.quantity(resultStart) }} –
                                    {{ $filters.quantity(resultEnd) }} of
                                    {{ $filters.quantity(resultTotal) }}</span
                                >
                                <button
                                    class="flex items-center justify-center bg-blue-500 disabled:bg-blue-200 disabled:cursor-not-allowed hover:bg-blue-400 hover:disabled:bg-blue-200 text-white rounded-full ml-4 mr-1 w-8 h-8"
                                    :disabled="page == 1"
                                    @click="prevPage"
                                >
                                    <SvgIcon
                                        icon="arrow-left"
                                        size="xs"
                                        weight="extra-bold"
                                    />
                                </button>
                                <button
                                    class="flex items-center justify-center bg-blue-500 disabled:bg-blue-200 disabled:cursor-not-allowed hover:bg-blue-400 hover:disabled:bg-blue-200 text-white rounded-full w-8 h-8"
                                    :disabled="page == pages"
                                    @click="nextPage"
                                >
                                    <SvgIcon
                                        icon="arrow-right"
                                        size="xs"
                                        weight="extra-bold"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
                <Loader :show="loading" />
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import debounce from "debounce";

export default {
    props: {
        createUrl: String,
        getCategory: Array,
        getStatus: String,
        getAccess: String,
        getFlagged: String,
        getPageSize: String,
        getPage: String,
        getExams: Array,
        getExam: String,
        getKeyword: String,
        getDeep: String,
        questionType: {
            type: String,
            default: "prep",
        },
    },
    data(): any {
        return {
            access: "open",
            category: [],
            deep: "true",
            exams: [],
            exam: "",
            filterStateSet: false,
            flagged: "",
            keyword: "",
            loading: true,
            page: 1,
            pages: null,
            pageSize: "10",
            questions: [],
            resultEnd: null,
            resultStart: null,
            resultTotal: null,
            status: "",
        };
    },
    computed: {
        categoryIds() {
            return JSON.stringify(this.category.map((category) => category.id));
        },
        cleanFilters() {
            return (
                !this.getCategory &&
                !this.getAccess &&
                !this.getExams &&
                !this.getExam &&
                !this.getStatus &&
                !this.getFlagged &&
                !this.getPageSize &&
                !this.getPage &&
                !this.getKeyword &&
                !this.getDeep
            );
        },
        filters() {
            if (this.questionType == "prep") {
                return (
                    "?category=" +
                    this.categoryIds +
                    "&status=" +
                    this.status +
                    "&access=" +
                    this.access +
                    "&flagged=" +
                    this.flagged +
                    "&pageSize=" +
                    this.pageSize +
                    "&page=" +
                    this.page +
                    "&keyword=" +
                    this.keyword +
                    "&deep=" +
                    this.deep
                );
            } else {
                return (
                    "?exam=" +
                    this.exam +
                    "&status=" +
                    this.status +
                    "&flagged=" +
                    this.flagged +
                    "&pageSize=" +
                    this.pageSize +
                    "&page=" +
                    this.page +
                    "&keyword=" +
                    this.keyword
                );
            }
        },
        path() {
            return this.questionType == "prep"
                ? "/api/prep/questions"
                : "/api/learn/questions";
        },
    },
    methods: {
        fetch() {
            this.loading = true;
            axios.get(this.path + this.filters).then((response) => {
                this.questions = response.data.data;
                this.resultStart = response.data.from;
                this.resultEnd = response.data.to;
                this.resultTotal = response.data.total;
                this.pages = response.data.last_page;
                this.loading = false;
            });
        },
        prevPage() {
            if (this.page > 1) {
                this.page--;
            }
        },
        nextPage() {
            if (this.page != this.pages) {
                this.page++;
            }
        },
        resetPage() {
            if (this.filterStateSet) {
                this.page = 1;
            }
        },
        setState() {
            this.category = this.getCategory || this.category;
            this.deep = this.getDeep || this.deep;
            this.exams = this.getExams || this.exams;
            this.exam = this.getExam || this.exam;
            this.status = this.getStatus || this.status;
            this.access = this.getAccess || this.access;
            this.flagged = this.getFlagged || this.flagged;
            this.pageSize = this.getPageSize || this.pageSize;
            this.page = this.getPage || this.page;
            this.keyword = this.getKeyword || this.keyword;
            this.$nextTick(() => (this.filterStateSet = true));
        },
        registerListeners() {
            this.$events.$on("questionEdited", (question) => {
                this.updateQuestion(question);

                this.$events.$emit("closeSlideoverPanel");

                this.$events.$emit("toastNotification", {
                    action: "saved",
                    heading: "Question updated",
                    status: "success",
                });
            });

            this.$events.$on("questionApproved", (question) => {
                this.updateQuestion(question);

                this.$events.$emit("toastNotification", {
                    action: "saved",
                    heading: "Question approved",
                    status: "success",
                });
            });
        },
        updateQuestion(question) {
            let Q = this.questions.findIndex((q) => q.uuid === question.uuid);
            this.$set(this.questions, Q, question);
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.setState();
        });
        this.fetch = debounce(this.fetch, 500);
        this.fetch();

        this.registerListeners();
    },
    watch: {
        filters(value, oldValue) {
            this.fetch();
            // @ts-ignore
            window.history.pushState({}, false, value);
        },
        pageSize() {
            this.page = 1;
        },
    },
};
</script>
