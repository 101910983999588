<template>
    <div class="flex flex-col space-y-8">
        <PulsePrepExamCategories />

        <PulseActivityTable
            :columns="viewColumns"
            :filters="viewFilters"
            :loading="loading"
            :pagination="pagination"
            :rows="data"
            rowAs="pulse-exams-table-row"
            selectableOn="uuid"
            table-name="Exam Activity"
        />
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState } from "vuex";

export default {
    data(): any {
        return {
            data: [],
            loading: true,
            pagination: {},
            viewColumns: [
                {
                    header: "Date Submitted",
                    width: 15,
                },
                {
                    header: "Name",
                    width: 20,
                },
                {
                    header: "Exam Name",
                    width: 15,
                },
                {
                    header: "Type",
                    width: 10,
                },
                {
                    header: "Categories",
                    width: 20,
                },
                {
                    header: "Time",
                    width: 10,
                },
                {
                    header: "Score",
                    width: 10,
                },
            ],
            viewFilters: [
                {
                    name: "status",
                },
                {
                    name: "type",
                },
            ],
        };
    },
    methods: {
        getData() {
            axios
                .get(
                    "https://my.api.mockaroo.com/pulse_prep_exams_data.json?key=189cc610"
                )
                .then((response) => {
                    this.data = response.data;
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getData();
    },
};
</script>
