<template>
    <div class="w-full overflow-x-auto shadow" v-if="(exams?.length ?? 0) > 0">
        <table class="table w-full">
            <thead>
                <tr class="bg-gray-200">
                    <th class="py-4 rounded-tl-md px-4">
                        <div
                            class="text-left text-sm uppercase font-medium text-gray-600"
                        >
                            Date
                        </div>
                    </th>
                    <th class="py-4 px-4">
                        <div
                            class="text-left text-sm uppercase font-medium text-gray-600"
                        >
                            Exam
                        </div>
                    </th>
                    <th class="w-42 py-4">
                        <div
                            class="text-sm uppercase font-medium text-gray-600"
                        >
                            Categories
                        </div>
                    </th>
                    <th class="py-4">
                        <div
                            class="text-sm uppercase font-medium text-gray-600 text-center"
                        >
                            <SvgIcon
                                icon="question"
                                size="sm"
                                weight="medium"
                            />
                        </div>
                    </th>
                    <template v-if="complete">
                        <th class="py-4 text-center">
                            <div
                                class="text-sm uppercase font-medium text-gray-600"
                            >
                                Score
                            </div>
                        </th>
                        <th class="py-4 text-center rounded-tr-md">
                            <div
                                class="text-sm uppercase font-medium text-gray-600"
                            >
                                Time
                            </div>
                        </th>
                    </template>
                    <template v-if="!complete">
                        <th class="py-4 text-center">
                            <div
                                class="text-sm uppercase font-medium text-gray-600"
                            >
                                Progress
                            </div>
                        </th>
                        <th class="w-16 py-4 text-center rounded-tr-md">
                            &nbsp;
                        </th>
                    </template>
                </tr>
            </thead>

            <tbody>
                <PrepExamCollectionResource
                    v-for="exam in exams"
                    :exam="exam"
                    :key="exam.uuid"
                />
            </tbody>
        </table>
    </div>

    <placeholder v-else>
        <div class="text-gray-500 text-3xl font-light mt-8">No exams found</div>
    </placeholder>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        complete: Boolean,
        exams: Array as PropType<any[]>,
    },
});
</script>
