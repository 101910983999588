<template>
    <div class="bg-white rounded-md shadow p-6 mb-8">
        <section class="data-chart h-64">
            <div class="flex items-center mb-6">
                <h3
                    class="flex items-center text-gray-800 text-xl capitalize leading-6"
                >
                    {{ title }}
                </h3>
                <div
                    class="bg-gray-100 text-gray-600 text-sm font-medium rounded-lg ml-4 leading-none py-1 px-2"
                    v-if="chartHasData"
                >
                    {{ $filters.quantity(count) }} total
                </div>
            </div>
            <div class="relative h-52">
                <div
                    :class="{
                        blur: !chartHasData,
                    }"
                >
                    <ChartLine
                        v-if="chartData"
                        :chartData="chartData"
                        :height="208"
                    />
                </div>
                <Loader :show="!chartHasData && status == 'loading'" />
                <div
                    v-if="!chartHasData && status == 'ready'"
                    class="absolute top-0 left-0 flex items-center justify-center w-full h-full"
                >
                    <div
                        class="bg-gray-50 border border-gray-200 uppercase tracking-wide rounded-lg -mt-12 py-2 px-4"
                    >
                        No data
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState } from "vuex";

export default {
    props: {
        legacy: Boolean,
    },
    data(): any {
        return {
            chartData: null,
            status: "loading",
            titles: {
                lessons: "Lesson Views",
                quizzes: "Quizzes Taken",
                assessments: "Assessments Taken",
            },
        };
    },
    computed: {
        ...mapState([
            "subject",
            "subjectGroup",
            "period",
            "type",
            "typeId",
            "instanceId",
        ]),
        activityEndpoint() {
            return (
                "/api/pulse/chart" +
                "?subject=" +
                this.subject +
                "&period=" +
                this.period +
                "&type=" +
                this.type +
                "&typeId=" +
                (this.typeId || "") +
                "&instance=" +
                (this.instanceId || "") +
                "&legacy=" +
                this.legacy
            );
        },
        chartHasData() {
            return this.count > 0;
        },
        count() {
            if (!this.chartData) {
                return null;
            }
            return this.chartData.datasets.reduce((carry, item) => {
                return (
                    carry +
                    item.data.reduce((carry, item) => {
                        return carry + item;
                    }, 0)
                );
            }, 0);
        },
        title() {
            return this.titles[this.type];
        },
    },
    methods: {
        buildChart(variables) {
            this.status = "loading";
            this.chartData = null;
            let path =
                typeof variables != "undefined"
                    ? this.activityEndpoint + variables
                    : this.activityEndpoint;

            axios.get(path).then((response) => {
                this.chartData = {
                    labels: response.data.chartData.labels,
                    datasets: response.data.chartData.datasets,
                };
                this.options = response.data.options;
                this.status = "ready";
            });
        },
    },
    mounted() {
        this.buildChart();

        this.$events.$on("updateChart", (data) => {
            this.buildChart(data);
        });
    },
    watch: {
        activityEndpoint(newEndpoint) {
            this.buildChart();
        },
    },
};
</script>

<style scoped>
.blur {
    filter: blur(3px);
}
</style>
