<template>
    <div class="flex flex-col space-y-8">
        <PulsePrepExamCategories />

        <PulseActivityTable
            :columns="viewColumns"
            :filters="viewFilters"
            :loading="loading"
            :pagination="pagination"
            :rows="data"
            :table-name="group"
            rowAs="pulse-assignment-table-row"
            selectableOn="uuid"
        >
            <template #breadcrumb>
                <span
                    class="inline font-medium text-blue-600 cursor-pointer"
                    @click="$events.$emit('updatedAssignmentUuid', null)"
                >
                    Assigned Exams
                </span>
                <SvgIcon
                    icon="chevron-right"
                    class="w-5 h-5"
                    aria-hidden="true"
                    size="xs"
                    weight="black"
                />
            </template>
        </PulseActivityTable>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapGetters } from "vuex";

export default {
    props: {
        assignmentUuid: {
            type: String,
            required: true,
        },
    },
    data(): any {
        return {
            data: [],
            loading: true,
            group: "",
            pagination: {},
            viewColumns: [
                {
                    header: "Learner",
                    width: 20,
                },
                {
                    header: "Date Submitted",
                    width: 20,
                },
                {
                    header: "Categories",
                    width: 20,
                },
                {
                    header: "Correct",
                    width: 10,
                },
                {
                    header: "Status",
                    width: 10,
                },
                {
                    header: "Time",
                    width: 10,
                },
                {
                    header: "Score",
                    width: 10,
                },
            ],
            viewFilters: [
                {
                    name: "status",
                },
            ],
        };
    },
    computed: {
        ...mapGetters(["context"]),
    },
    mounted() {
        axios.get("/_temp/assignment.json").then((response) => {
            this.data = response.data;
            this.group = "RT 101 - Wk1";
            this.loading = false;
        });
    },
};
</script>
