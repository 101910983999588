<template>
    <ul class="divide-y">
        <li
            v-for="item in internalItems"
            class="flex items-center justify-between border-gray-100 p-6"
        >
            <div class="flex flex-col space-y-1">
                <div class="flex items-center space-x-2">
                    <a class="cta" :href="item.url" v-text="item.title" />
                    <SvgIcon
                        v-if="item.has_note"
                        icon="documentation"
                        size="xs"
                    />
                </div>
                <div class="text-xs">{{ item.subtitle }}</div>
            </div>
            <div class="flex items-center space-x-4">
                <div
                    class="text-sm text-gray-600"
                    v-text="item.created_at_for_humans"
                />
                <button
                    type="button"
                    class="flex items-center justify-center hover:bg-cyan-50 rounded-lg w-9 h-9"
                    @click.once="deleteItem(item)"
                >
                    <SvgIcon class="text-red-700" icon="remove" size="sm" />
                </button>
            </div>
        </li>
    </ul>
</template>

<script lang="ts">
import axios from "axios";

export default {
    props: {
        items: {
            required: true,
        },
    },

    data(): any {
        return {
            internalItems: this.items,
        };
    },

    methods: {
        deleteItem(item) {
            axios.delete(item.delete_route).then(() => {
                this.internalItems.splice(this.internalItems.indexOf(item), 1);
            });
        },
    },
};
</script>
