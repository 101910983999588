<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        user: Object as PropType<any>,
        permissions: Object as PropType<any>,
        learnerMode: Boolean,
        settings: Object as PropType<any>,
        websitePath: String,
    },
    created() {
        this.user.permissions = this.permissions;
        this.user.settings = this.settings;
        this.user.learnerMode = this.learnerMode;
        // @ts-ignore
        this.$store.commit("registerState", {
            // @ts-ignore
            token: document.querySelector('meta[name="token"]').content,
            user: this.user,
            websitePath: this.websitePath,
        });
    },
});
</script>
<template>
    <div>
        <slot></slot>
    </div>
</template>
