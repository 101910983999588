import { add, format, formatDistance, intervalToDuration } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export const filters = {
    blank: (value) => {
        if (value === "") {
            return "Question left blank";
        }
        return value;
    },
    dateTime: (value, timezone?: any) => {
        let dateObject;
        if (typeof value == "string") {
            dateObject =
                value.charAt(value.length - 1) != "Z"
                    ? new Date(value + "Z")
                    : new Date(value);
        } else {
            dateObject = new Date(value * 1000);
        }
        return format(
            utcToZonedTime(dateObject, timezone),
            "MMM d, yyyy h:mm a"
        );
    },

    shortDateTime: (value, timezone) => {
        let addChar = value.charAt(value.length - 1) == "Z" ? "" : "Z";
        return format(
            utcToZonedTime(new Date(value + addChar), timezone),
            "MM/dd/yy hh:mm a"
        );
    },
    currency: (value) => {
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        }).format(value);
    },
    elapsed: (value) => {
        if (typeof value === "number" && value > 0) {
            let start = Date.now();
            let end = add(start, { seconds: value });
            let duration = intervalToDuration({
                start: start,
                end: end,
            });
            let durationString = "";

            if (duration.days) {
                durationString += duration.days + "d ";
            }
            if (duration.hours) {
                durationString += duration.hours + ":";
            }
            durationString +=
                duration?.minutes?.toString().padStart(2, "0") + ":";
            durationString += duration?.seconds?.toString().padStart(2, "0");
            return durationString;
        }
        return "&nbsp;";
    },
    grade: (value, threshold) => {
        if (typeof value === "number") {
            if (value < threshold) {
                return (
                    '<div class="alert-text">' + value.toFixed(2) + "%</div>"
                );
            } else {
                return value.toFixed(2) + "%";
            }
        }
        return "&nbsp;";
    },

    pluralizeLabel: (value, count) => (count !== 1 ? value + "s" : value),

    integer: (value) => {
        return Math.round(value);
    },

    quantity: (value) => {
        return value > 0 ? value.toLocaleString() : 0;
    },

    relative: (value, timezone) => {
        if (value) {
            return formatDistance(
                utcToZonedTime(new Date(value), timezone),
                Date.now(),
                {
                    addSuffix: true,
                }
            );
        }
        return "";
    },
};
