<template>
    <div>
        <div v-if="!loading">
            <div class="mt-8" v-if="questions.length > 0">
                <div class="flex items-center justify-between">
                    <div class="flex flex-grow justify-start">
                        <h3
                            class="text-sm text-gray-600 font-medium uppercase ml-4"
                        >
                            Questions
                        </h3>
                    </div>
                </div>
                <PulseExploreDetailsQuestionsRow
                    v-for="(question, i) in questions"
                    :key="'question_' + i"
                    :legacy="legacy"
                    :row="question"
                    :count="count"
                />
            </div>
        </div>
        <Loader :show="loading" />
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState, mapGetters } from "vuex";

export default {
    props: {
        count: Number,
        legacy: Boolean,
    },
    data(): any {
        return {
            loading: true,
            questions: [],
        };
    },
    computed: {
        ...mapState(["instanceId", "typeId"]),
        ...mapGetters(["context"]),
    },
    methods: {
        getData() {
            let instance = this.instanceId || "";
            axios
                .get(
                    "/api/pulse/answers/" +
                        this.context +
                        "&id=" +
                        this.typeId +
                        "&instance=" +
                        instance +
                        "&legacy=" +
                        this.legacy
                )
                .then((response) => {
                    this.questions = response.data;
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getData();
    },
};
</script>
