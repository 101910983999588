<template>
    <div>
        <div>User</div>
        <div class="w-98">
            <SearchInput
                app="Users"
                search-addition=""
                searchable="User"
                @selectedInput="blur"
            />
        </div>
    </div>
</template>

<script lang="ts">
import SearchInput from "../../_elements/SearchInput.vue";
export default {
    name: "LearnerDetailsList",
    components: { SearchInput },
    data(): any {
        return {
            results: [],
        };
    },
    methods: {
        blur(text) {
            (
                document.querySelector("#add-user-form") as HTMLFormElement
            ).reset();
            document
                .querySelectorAll("#add-user-form input")
                .forEach((element) => this.assignment(text, element));
        },
        assignment(data, element) {
            //this works by assigning the input field with id='x' to data with key or value = 'x'
            Object.keys(data).forEach((datum) => {
                if (Array.isArray(data[datum])) {
                    this.assignment(Object.assign({}, data[datum]), element);
                } else {
                    if (element.tagName.toLowerCase() === "input") {
                        if (
                            element.getAttribute("type") === "text" &&
                            element.id === datum
                        ) {
                            element.value = data[datum];
                        } else if (
                            element.getAttribute("type") === "checkbox" &&
                            element.id == data[datum]
                        ) {
                            element.checked = true;
                        }
                    }
                }
            });
        },
    },
};
</script>

<style scoped></style>
