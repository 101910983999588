<template>
    <div
        class="grid w-full border-gray-200"
        :class="[nested ? 'border-l' : 'border-t-4']"
        :style="{ gridTemplateColumns: rowGridTemplate }"
        role="row"
    >
        <slot />
    </div>
</template>

<script lang="ts">
export default {
    props: {
        nested: {
            type: Boolean,
            required: false,
            default: false,
        },
        rowGridTemplate: {
            type: String,
            required: true,
        },
    },
};
</script>
