<template>
    <Menu as="div" class="relative">
        <MenuButton
            v-tippy="{
                delay: [1000, 0],
                placement: 'left',
            }"
            ref="tip"
            :content="label"
            class="text-gray-500 flex items-center justify-center rounded-full h-9 w-9 focus-visible:ring bg-yellow-50"
            type="button"
        >
            <SvgIcon
                icon="question"
                size="2xl"
                class="h-9 w-9 text-gray-400"
                weight="light"
            />
        </MenuButton>
        <MenuItems
            class="absolute top-0 right-0 mt-10 z-10 min-w-full flex flex-col bg-white rounded shadow"
        >
            <MenuItem
                class="border-b border-gray-100 last:border-none first:rounded-t last:rounded-b"
                v-slot="{ active }"
            >
                <a
                    href="https://help.cloverlearning.com"
                    :class="{
                        'flex gap-3 items-center whitespace-nowrap p-4 pr-12 bg-blue-50 focus-visible:ring hover:no-underline':
                            active,
                        'hover:no-underline flex gap-3 items-center whitespace-nowrap p-4 pr-12':
                            !active,
                    }"
                >
                    <SvgIcon icon="documentation" size="base" weight="medium" />
                    Help Articles
                </a>
            </MenuItem>
            <MenuItem
                class="border-b border-gray-100 last:border-none first:rounded-t last:rounded-b"
                v-slot="{ active }"
            >
                <button
                    @click="openSupportWidget"
                    :class="{
                        'flex gap-3 items-center whitespace-nowrap p-4 pr-12 bg-blue-50 focus-visible:ring hover:no-underline':
                            active,
                        'hover:no-underline flex gap-3 items-center whitespace-nowrap p-4 pr-12':
                            !active,
                    }"
                >
                    <SvgIcon icon="contact" size="base" weight="medium" />
                    Contact
                </button>
            </MenuItem>
        </MenuItems>
    </Menu>
</template>

<script lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

export default {
    props: {
        avatar: String,
        icon: String,
        initials: String,
        label: String,
        theme: String,
    },
    methods: {
        openSupportWidget() {
            // @ts-ignore
            zE("messenger", "open");
        },
    },
    components: {
        Menu,
        MenuButton,
        MenuItems,
        MenuItem,
    },
};
</script>
