<script setup lang="ts">
import { ref } from "vue";
import { defineProps } from "vue";
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from "@headlessui/vue";
import { InformationCircleIcon } from "@heroicons/vue/24/outline";
import { formatCeCredits } from "@/utils";
import { Course } from "@/types";
import TiptapRender from "@/components/ui/content/TiptapRenderer.vue";

const props = defineProps<{
    course: Course;
}>();

const isOpen = ref(false);

function closeModal() {
    isOpen.value = false;
}
function openModal() {
    isOpen.value = true;
}
</script>

<template>
    <button
        class="flex items-center gap-1 relative z-20 bg-blue-700 hover:bg-blue-800 text-white text-xs rounded-full py-1 px-2"
        @click="openModal"
    >
        {{ formatCeCredits(course?.ce_credit_hours ?? 0) }} credits
        <InformationCircleIcon class="w-4 h-4" />
    </button>
    <TransitionRoot appear :show="isOpen" as="template">
        <Dialog as="div" @close="closeModal" class="relative z-90">
            <TransitionChild
                as="template"
                enter="duration-200 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black bg-opacity-50" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div
                    class="flex min-h-full items-center justify-end text-center"
                >
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 right-[-100%]"
                        enter-to="opacity-100 right-0"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 right-0"
                        leave-to="opacity-0 right-[-100%]"
                    >
                        <DialogPanel
                            class="align-end relative h-screen w-full max-w-[90vw] transform overflow-auto bg-white p-4 pb-20 text-left shadow-xl transition-all md:max-w-2xl md:p-10"
                        >
                            <div class="flex justify-between gap-4">
                                <DialogTitle
                                    as="h3"
                                    class="font-heading text-2xl font-bold tracking-tight text-gray-900 md:text-3xl"
                                >
                                    <span
                                        class="mb-2 block font-sans text-base font-normal tracking-normal text-blue-700"
                                        >{{ course.title }}</span
                                    >
                                    Continuing Education Information
                                </DialogTitle>
                                <button
                                    type="button"
                                    class="inline-flex justify-center self-start rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-bold text-blue-800 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 md:hidden"
                                    @click="closeModal"
                                >
                                    Close
                                </button>
                            </div>

                            <div class="space-y-4 divide-gray-200">
                                <div class="prose mt-6">
                                    <TiptapRender :content="course.ce_info" />
                                </div>

                                <div>
                                    <Disclosure
                                        v-slot="{ open }"
                                        :as="'div'"
                                        v-if="course.structured_ed_info"
                                    >
                                        <DisclosureButton
                                            class="flex w-full items-start justify-between border-t border-gray-200 px-5 py-4 text-left text-gray-900"
                                            :class="open && 'bg-blue-50'"
                                        >
                                            <span
                                                class="text-xl font-bold font-medium"
                                            >
                                                Structured Education
                                            </span>
                                            <span
                                                class="ml-6 flex h-7 items-center"
                                            >
                                                <svg
                                                    class="h-6 w-6 group-hover:text-yellow-500"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        v-show="!open"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                                    ></path>
                                                    <path
                                                        v-show="open"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M18 12H6"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </DisclosureButton>
                                        <DisclosurePanel
                                            class="p-4"
                                            :class="
                                                open &&
                                                'rounded-b-md bg-blue-50'
                                            "
                                        >
                                            <div class="overflow-auto">
                                                <TiptapRender
                                                    :content="
                                                        course.structured_ed_info
                                                    "
                                                />
                                            </div>
                                        </DisclosurePanel>
                                    </Disclosure>

                                    <Disclosure
                                        v-slot="{ open }"
                                        :as="'div'"
                                        class="border-b border-gray-200"
                                        v-if="course.cqr_info"
                                    >
                                        <DisclosureButton
                                            class="flex w-full items-start justify-between border-t border-gray-200 px-5 py-4 text-left text-gray-900"
                                            :class="open && 'bg-blue-50'"
                                        >
                                            <span
                                                class="text-xl font-bold font-medium"
                                            >
                                                CQR
                                            </span>
                                            <span
                                                class="ml-6 flex h-7 items-center"
                                            >
                                                <svg
                                                    class="h-6 w-6 group-hover:text-yellow-500"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                >
                                                    <path
                                                        v-show="!open"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                                    ></path>
                                                    <path
                                                        v-show="open"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                        stroke-width="2"
                                                        d="M18 12H6"
                                                    ></path>
                                                </svg>
                                            </span>
                                        </DisclosureButton>
                                        <DisclosurePanel
                                            class="p-4"
                                            :class="
                                                open &&
                                                'rounded-b-md bg-blue-50'
                                            "
                                        >
                                            <div class="overflow-auto">
                                                <TiptapRender
                                                    :content="course.cqr_info"
                                                />
                                            </div>
                                        </DisclosurePanel>
                                    </Disclosure>
                                </div>

                                <div
                                    class="flex flex-col md:flex-row items-center gap-4 mt-6"
                                >
                                    <a
                                        class="button main rounded-md mt-4"
                                        :href="`/learn/courses/${course.slug}`"
                                        cta
                                        >View All Course Details</a
                                    >
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
