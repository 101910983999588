<template>
    <a
        :href="url"
        class="flex items-center justify-center border border-blue-500 text-blue-500 rounded-lg h-9 px-4 space-x-2 hover:bg-gray-50 hover:no-underline hover:text-blue-500"
        @click="$emit('click')"
    >
        <div class="text-sm font-medium">
            <slot />
        </div>
        <SvgIcon :icon="icon" size="sm" weight="bold" />
    </a>
</template>

<script lang="ts">
export default {
    props: {
        icon: String,
        url: String,
    },
};
</script>
