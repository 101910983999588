<template>
    <div
        class="bg-white flex items-center p-4 mb-px border-l-4 h-20"
        :class="{
            'border-blue-400': row.owner == 'learner',
            'border-red-400': row.owner == 'instructor',
            'border-pear-400': row.owner == 'clover',
        }"
    >
        <div class="text-sm w-32 mr-8">
            <div
                class="flex flex-col items-start leading-none text-xs"
                :title="row.updated_at"
            >
                <div
                    :class="{
                        'bg-blue-50 text-blue-700': row.owner == 'learner',
                        'bg-red-50 text-red-700': row.owner == 'instructor',
                        'bg-pear-100 text-pear-700': row.owner == 'clover',
                    }"
                    class="inline-block text-xs font-medium py-1 px-2 capitalize rounded-md leading-5 mb-2"
                >
                    {{ row.owner }}
                </div>
                <div class="flex items-center">
                    <SvgIcon
                        class="text-gray-600 mr-2"
                        icon="clock"
                        size="sm"
                    />
                    <div
                        v-tippy
                        :content="
                            $filters.dateTime(row.updated_at, user.timezone)
                        "
                        v-text="
                            $filters.relative(row.updated_at, user.timezone)
                        "
                    />
                </div>
            </div>
        </div>
        <div class="text-sm w-64 mr-8">
            <div class="capitalize">
                <a
                    class="cta"
                    :href="
                        '/pulse/explore/learner/' +
                        period +
                        '/' +
                        row.user_id +
                        '/prep'
                    "
                >
                    {{ row.full_name }}
                </a>
            </div>
            <div
                class="text-xs font-medium mt-1"
                v-if="scope == 'organization' || scope == 'account'"
            >
                <a
                    class="cta"
                    :href="
                        '/pulse/explore/cohort/' +
                        period +
                        '/' +
                        row.cohort_id +
                        '/prep'
                    "
                >
                    {{ row.cohort_name }}
                </a>
            </div>
        </div>
        <div class="w-64 mr-8">
            <div class="text-sm">
                {{ row.name }}
            </div>
        </div>
        <div class="w-40 mr-8">
            <PrepCategoryCircles
                :categories="row.categories"
                options="stacked"
            />
        </div>
        <div class="w-24 mr-8">
            {{ row.questions_total }}
        </div>
        <div class="flex flex-grow items-center justify-between">
            <div class="flex items-center">
                <div class="w-32 flex items-center mr-4">
                    <svg-icon
                        class="text-gray-700 mr-3"
                        icon="timer"
                        size="base"
                    />
                    <div class="text-base">
                        {{ $filters.elapsed(duration) }}
                    </div>
                </div>
                <div class="w-32 flex items-center mr-4">
                    <svg-icon
                        class="text-gray-700 mr-3"
                        icon="data"
                        size="base"
                    />
                    <div class="text-base">
                        <span
                            v-html="$filters.grade(grade, settings.pulse_grade)"
                        />
                    </div>
                </div>
                <div class="w-32 flex text-sm items-center mr-4">
                    <a
                        class="cta"
                        :href="'/prep/exams/' + row.uuid"
                        target="_blank"
                    >
                        Go to exam
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { mapState, mapGetters } from "vuex";

export default {
    props: {
        row: Object,
    },
    data(): any {
        return {
            types: {
                prep: "Prep exam",
            },
        };
    },
    computed: {
        ...mapState(["period", "scope", "settings", "user"]),
        ...mapGetters(["context"]),
        duration() {
            return (
                (Date.parse(this.row.time_completed) -
                    Date.parse(this.row.time_started)) /
                1000
            );
        },
        grade() {
            return (
                (this.row.questions_correct / this.row.questions_total) * 100
            );
        },
    },
};
</script>
