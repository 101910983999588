<template>
    <div>
        <slot v-bind="{ openRedemptionsModal }"></slot>
        <base-modal
            heading="Generate Redemption Codes"
            :opened="isOpen"
            @close="close"
        >
            <template v-slot:body>
                <div class="space-y-4">
                    <div>
                        <SelectDropdown class="mt-4 w-full" v-model="courseId">
                            <option value="">Choose a course</option>
                            <option
                                v-for="course in courses"
                                :value="course.id.toString()"
                            >
                                {{ course.title }}
                            </option>
                        </SelectDropdown>
                        <div
                            v-if="errors.course_id"
                            class="text-xs text-red-600 mt-1"
                        >
                            {{ errors.course_id[0] }}
                        </div>
                    </div>

                    <div>
                        <input
                            class="bg-cool-gray-100 text-base leading-5 rounded p-3 w-full"
                            placeholder="Number of Seats"
                            v-model="seats"
                        />
                        <div
                            v-if="errors.seats"
                            class="text-xs text-red-600 mt-1"
                        >
                            {{ errors.seats[0] }}
                        </div>
                    </div>

                    <div>
                        <input
                            class="bg-cool-gray-100 text-base leading-5 rounded p-3 w-full"
                            placeholder="Number of months access"
                            v-model="months_access"
                        />
                        <div
                            v-if="errors.months_access"
                            class="text-xs text-red-600 mt-1"
                        >
                            {{ errors.months_access[0] }}
                        </div>
                    </div>

                    <div v-if="billingLevel > 0">
                        <SelectDropdown class="w-full" v-model="groupId">
                            <option value="">Choose a group</option>
                            <option
                                v-for="group in groups"
                                :value="group.id.toString()"
                            >
                                {{ group.name }}
                            </option>
                        </SelectDropdown>
                        <div
                            v-if="errors.group_id"
                            class="text-xs text-red-600 mt-1"
                        >
                            {{ errors.group_id[0] }}
                        </div>
                    </div>

                    <div>
                        <textarea
                            class="bg-cool-gray-100 text-base leading-5 rounded p-3 w-full"
                            placeholder="Note"
                            v-model="note"
                        ></textarea>
                        <div
                            v-if="errors.note"
                            class="text-xs text-red-600 mt-1"
                        >
                            {{ errors.note[0] }}
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:actions>
                <button
                    type="button"
                    class="flex items-center justify-center primary button px-4 py-2 text-base sm:text-sm leading-6 w-32"
                    @click="submit"
                >
                    <SvgIcon
                        v-if="isSaving"
                        class="animate-spin"
                        icon="loader"
                    />
                    <span v-else>Submit</span>
                </button>
            </template>
        </base-modal>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import BaseModal from "./BaseModal.vue";

export default {
    name: "PartnerAccessCodesModal",

    props: {
        partnerId: {
            type: Number,
            required: true,
        },
        billingLevel: {
            type: Number,
            required: true,
        },
        courses: {
            type: Array,
            default: [],
        },
        groups: {
            type: Array,
            default: [],
        },
    },
    data(): any {
        return {
            isOpen: false,
            isSaving: false,
            courseId: "",
            seats: null,
            months_access: null,
            groupId: "",
            note: "",
            errors: {},
        };
    },
    methods: {
        open() {
            this.isOpen = true;
        },
        close() {
            this.isOpen = false;
            this.reset();
        },
        reset() {
            this.courseId = "";
            this.seats = null;
            this.months_access = null;
            this.groupId = "";
            this.note = "";
            this.errors = {};
        },
        openRedemptionsModal() {
            this.open();
        },
        submit() {
            this.isSaving = true;
            axios
                .post("/admin/partner-redemption-code-batches", {
                    course_id: this.courseId,
                    seats: this.seats,
                    months_access: this.months_access,
                    note: this.note,
                    billable_type: this.groupId ? "group" : "partner",
                    billable_id: this.groupId ? this.groupId : this.partnerId,
                })
                .then(() => {
                    this.isSaving = false;
                    this.close();
                    location.reload();
                })
                .catch(({ response }) => {
                    if (response.data.errors) {
                        this.errors = response.data.errors;
                    }
                    this.isSaving = false;
                });
        },
    },

    components: {
        BaseModal,
    },
};
</script>
