<template>
    <div
        class="flex items-center flex-wrap bg-white sm:bg-transparent sm:border-b border-gray-100 last:border-b-0 rounded-md sm:rounded-none shadow sm:shadow-none mb-4 sm:mb-0 sm:h-20"
    >
        <div
            class="flex items-center flex-shrink-0 sm:border-r border-gray-100 px-4 w-full sm:w-48 h-20"
        >
            <div>
                <div class="flex items-center text-xs leading-6">
                    <div v-text="dateTime(row.date, user.timezone)" />
                </div>
            </div>
        </div>
        <div
            class="flex items-center flex-shrink-0 text-sm truncate border-r border-gray-100 px-4 w-52 h-20"
        >
            <div>
                <div class="capitalize">
                    <a class="cta font-semibold" :href="link(row.user_id)">
                        {{ row.first_name }} {{ row.last_name }}
                    </a>
                </div>
                <div class="flex items-center text-xs mt-1" v-if="showCohort">
                    <SvgIcon
                        class="text-gray-600 mr-2"
                        icon="indent"
                        size="xs"
                        weight="medium"
                    />
                    <a class="cta" :href="link(row.cohort_id)">
                        {{ row.cohort }}
                    </a>
                </div>
            </div>
        </div>
        <div class="flex flex-grow items-center justify-between">
            <div class="text-sm mr-8 px-4 lg:w-60 xl:w-80">
                <div class="flex items-center">
                    <div class="flex-shrink-0 w-8 mr-2">
                        <div
                            v-if="row.assignment"
                            class="flex items-center justify-center bg-gradient-to-tr from-indigo-100 to-pink-100 rounded-full w-8 h-8"
                            v-tippy
                            content="Assignment"
                        >
                            <SvgIcon icon="assignment" size="sm" />
                        </div>
                    </div>
                    <div v-html="row.topic" />
                </div>
            </div>
            <div class="flex items-center">
                <div
                    class="flex items-center justify-center border-l border-r border-gray-100 text-sm w-24 h-20"
                >
                    <div v-html="elapsed(row.time)" />
                </div>
                <div
                    class="flex items-center justify-center border-r border-gray-100 text-sm w-28 h-20"
                >
                    <div
                        class="font-medium"
                        v-html="grade(row.grade, settings.pulse_grade)"
                    />
                </div>
                <div class="w-20 flex text-sm items-center justify-center">
                    <a
                        class="flex items-center justify-center bg-gray-100 rounded-full w-8 h-8"
                        :href="instanceLink"
                        v-tippy
                        content="View item"
                        target="_blank"
                    >
                        <SvgIcon class="text-blue-600" icon="view" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { mapState, mapGetters } from "vuex";
import { defineComponent, PropType } from "vue";
import { filters } from "../../../../vue-filters";

export default defineComponent({
    props: {
        legacy: Boolean,
        row: {
            type: Object as PropType<any>,
        },
    },
    computed: {
        ...mapState(["period", "settings", "subjectGroup", "user"]),
        ...mapGetters(["context"]),
        showCohort() {
            return ["organization", "account"].includes(this.subjectGroup);
        },
        instanceLink() {
            return "/prep/exams/" + this.row.uuid;
        },
        path() {
            return this.legacy ? "/pulse/explore-legacy/" : "/pulse/explore/";
        },
    },
    methods: {
        ...filters,
        link(subject) {
            return (
                this.path +
                "prep" +
                "?subject=" +
                subject +
                "&period=" +
                this.period
            );
        },
    },
});
</script>
