<script setup lang="tsx">
// a compone that extends html button or vue RouterLink or anchor tag
import { cva, type VariantProps } from "class-variance-authority";
import { RouterLink, RouterLinkProps } from "vue-router";
import { computed } from "vue";
import { buttonStyles } from "@/components/ui/ButtonLinks/buttonStyles";
// build a tailwind button

type ButtonProps = VariantProps<typeof buttonStyles>;

const props = defineProps<{
    to?: string;
    intent?: ButtonProps["intent"];
    size?: ButtonProps["size"];
    full?: ButtonProps["full"];
    round?: ButtonProps["round"];
}>();

const classes = computed(() => {
    return buttonStyles({
        intent: props.intent,
        size: props.size,
        full: props.full,
        round: props.round,
    });
});
</script>
<template>
    <template v-if="props.to">
        <RouterLink :to="props.to" :class="classes" v-bind="props">
            <slot></slot>
        </RouterLink>
    </template>
    <template v-else>
        <a :class="classes" v-bind="props">
            <slot></slot>
        </a>
    </template>
</template>
