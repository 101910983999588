<template>
    <PulseActivityTableRow :row-grid-template="rowGridTemplate">
        <PulseActivityTableCell :row-span="titleRows">
            <PulseActivityTableLearner
                :image="row.image_url"
                :forename="row.first_name"
                :surname="row.last_name"
            />
        </PulseActivityTableCell>
        <template v-if="visibleActivityRows.length">
            <template
                v-for="(lesson, index) in visibleActivityRows"
                :key="row.uuid + '-type-' + index"
            >
                <PulseActivityTableCell centered="y">
                    <PulseActivityTableType :type="lesson.activity_type_id" />
                </PulseActivityTableCell>

                <PulseActivityTableCell centered="y">
                    <PulseActivityTableLesson
                        :name="lesson.lesson_title"
                        :type="lesson.activity_type_id"
                        :order="4"
                    />
                </PulseActivityTableCell>

                <PulseActivityTableCell v-slot="{ asLink }" centered="y">
                    <router-link
                        :to="moduleLink(lesson.module_uuid)"
                        class="font-medium text-blue-600 hover:text-blue-700"
                    >
                        {{ lesson.module_title }}
                    </router-link>
                </PulseActivityTableCell>

                <PulseActivityTableCell centered>
                    <ScoreData
                        :score="lesson.score"
                        :empty="lesson.activity_type_id === 8"
                    />
                </PulseActivityTableCell>

                <PulseActivityTableCell v-slot="{ asDate }" centered="y">
                    <span class="text-sm">{{ asDate(lesson.date) }}</span>
                </PulseActivityTableCell>
            </template>
            <PulseActivityTableCell
                v-if="shouldShowExpander"
                @click.native="expanded = !expanded"
                centered
                class="col-span-5 space-x-4 font-bold text-blue-600 cursor-pointer bg-gray-50"
            >
                <div v-if="!expanded">
                    <SvgIcon icon="chevron-down" size="xs" weight="black" />
                    <span>Show {{ hiddenRowCount }} more rows</span>
                    <SvgIcon icon="chevron-down" size="xs" weight="black" />
                </div>
                <div v-if="expanded">
                    <SvgIcon icon="chevron-up" size="xs" weight="black" />
                    <span>Hide rows</span>
                    <SvgIcon icon="chevron-up" size="xs" weight="black" />
                </div>
            </PulseActivityTableCell>
        </template>
        <template v-else>
            <PulseActivityTableCell
                centered="y"
                class="col-span-5 italic text-gray-500"
            >
                Sorry, no activity exists for this learner.
            </PulseActivityTableCell>
        </template>
    </PulseActivityTableRow>
</template>

<script lang="ts">
export default {
    props: {
        row: Object,
        rowGridTemplate: String,
    },
    data(): any {
        return {
            rowsToShow: 5,
            expanded: false,
        };
    },
    computed: {
        shouldShowExpander() {
            return (
                this.row.activity && this.rowsToShow < this.row.activity.length
            );
        },
        hiddenRowCount() {
            return this.row.activity.length - this.rowsToShow;
        },
        visibleActivityRows() {
            if (this.expanded) {
                return this.row.activity;
            }
            return this.row.activity.slice(0, this.rowsToShow);
        },
        titleRows() {
            if (this.shouldShowExpander && !this.expanded) {
                return this.rowsToShow + 1;
            }

            if (this.shouldShowExpander && this.expanded) {
                return this.row.activity.length + 1;
            }

            return this.row.activity.length;
        },
    },
    methods: {
        generateLinkContext(courseUuid, moduleUuid) {
            return `course=${courseUuid}&module=${moduleUuid}`;
        },
        moduleLink(moduleUuid) {
            return {
                path: "/module/" + moduleUuid + "/learner",
                query: { ...this.$route.query, page: 1 },
            };
        },
    },
};
</script>
