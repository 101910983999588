<script setup lang="ts">
import { ref } from "vue";

const storageKey = "clover.searchAnnouncementDismissed";

const dismissed = ref(localStorage.getItem(storageKey) === "true");

const dismiss = () => {
    localStorage.setItem(storageKey, "true");
    dismissed.value = true;
};
</script>
<template>
    <div
        class="absolute hidden md:block text-left p-4 text-xs w-80 rounded bg-slate-900 text-white shadow top-full right-0 md:top-0 md:right-[102%]"
        v-if="!dismissed"
    >
        <p class="font-bold text-lg leading-tight mb-1.5">
            Checkout the new advanced search.
        </p>
        <p class="mb-3">Search for courses, modules and lessons.</p>
        <button class="button x-small" @click="dismiss">
            <span>Dismiss</span>
        </button>
        <div
            class="absolute left-[calc(100%_-_5px)] top-3 w-2.5 h-2.5 rotate-45 bg-slate-900"
        ></div>
    </div>
</template>
