<script setup lang="ts">
import { Course } from "@/types";
import { computed, defineProps, ref, defineExpose } from "vue";
import { InformationCircleIcon, BookmarkIcon } from "@heroicons/vue/24/outline";
import { useUserStore } from "@/stores/userStore";
import {
	BookmarkIcon as BookmarkSolid,
	ExclamationTriangleIcon,
} from "@heroicons/vue/20/solid";
import CePill from "./CePill.vue";
import { useCoursesStore } from "@/stores/coursesStore";
const userStore = useUserStore();
const { user } = storeToRefs(userStore);
import { storeToRefs } from "pinia";

const courseStore = useCoursesStore();
const { categories } = storeToRefs(courseStore);

const props = defineProps<{
	course: Course;
	status: string;
	deprecated?: boolean;
}>();

const cta = computed(() => {
	switch (props.status) {
		case "new":
			return "Start course";
		case "completed":
			return "Review course";
		case "started":
			return "Continue course";
		default:
			return "Launch course";
	}
});

const statusBadge = computed(() => {
	switch (props.status) {
		case "new":
			return "bg-blue-100 text-blue-800";
		case "completed":
			return "bg-green-100 text-green-800";
		case "started":
			return "bg-yellow-100 text-yellow-800";
		default:
			return "bg-gray-100 text-gray-800";
	}
});

const cardRef = ref<HTMLDivElement | null>(null);

const getOffsetLeft = () => {
	return cardRef.value?.offsetLeft;
};

defineExpose({
	getOffsetLeft,
	cardRef,
});
</script>
<template>
	<div
		class="relative flex flex-col rounded-md shadow group hover:shadow-lg transition snap-start w-100 h-full"
		ref="cardRef">
		<div
			class="relative bg-blue-500 rounded-t-md aspect-w-16 aspect-h-9 w-full overflow-hidden">
			<img
				v-if="course.asset"
				class="object-cover rounded-t-md h-full w-full z-10 transition duration-300 group-hover:scale-105"
				:src="course.asset"
				:alt="course.title" />
			<img
				v-else
				class="object-cover rounded-t-md h-full w-full z-10 transition duration-300 group-hover:scale-105"
				src="https://s3.amazonaws.com/files.cloverlearning.com/images/default/clover-course-default-thumbnail.jpg"
				:alt="course.title" />
			<div
				:class="statusBadge"
				class="absolute z-20 top-0 right-0 inline-flex text-2xs leading-6 uppercase font-bold tracking-wider rounded-tr-md rounded-bl-lg pr-3 pl-2 bottom-auto left-auto w-auto h-auto">
				{{ status }}
			</div>
			<button
				v-if="false"
				v-tippy="{ content: 'Bookmark', placement: 'right' }"
				class="group/bookmark absolute top-1 left-1 p-2 bg-white rounded-full z-20 w-8 h-8 inline-flex items-center justify-center">
				<BookmarkIcon
					class="group-hover/bookmark:hidden h-full w-auto"></BookmarkIcon>
				<BookmarkSolid
					class="group-hover/bookmark:inline-flex hidden h-full w-auto text-blue-700"></BookmarkSolid>
			</button>
		</div>
		<div
			class="flex flex-col justify-between bg-white flex-grow rounded-b-md p-6">
			<div>
				<!-- <div
					class="mb-2 text-xs flex gap-1 bg-yellow-100 text-gray-700 border border-yellow-200 p-1 rounded-sm items-center">
					<ExclamationTriangleIcon
						class="h-5 w-5 text-yellow-600 inline-block" />
					<p>This course will be removed on 3/30/24</p>
				</div> -->
				<h3
					class="mt-1 text-lg font-semibold leading-tight group-hover:text-blue-700">
					{{ course.title }}
				</h3>
				<div class="flex items-center text-sm space-x-2 mt-2 mb-6">
					<!-- Add if statement if user is academic -->
					<CePill
						:course="course"
						v-if="
							course.ce_credit_hours &&
							user?.user.type != 'academic'
						"></CePill>
					<div
						class="bg-yellow-50 text-xs rounded-full leading-6 px-2">
						{{ course.active_lesson_count }} videos
					</div>
				</div>
			</div>
			<div>
				<a
					class="text-sm text-blue-700 before:z-10 hover:text-blue-700 hover🙅‍♂️ before:block before:absolute before:-inset-1 transition hover:no-underline"
					:href="`/learn/courses/${course.slug}`">
					{{ cta }} &rarr;
				</a>
			</div>
		</div>
	</div>
</template>
