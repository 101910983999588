<template>
    <div>
        <PulseExploreLayout :legacy="legacy" v-bind="$attrs">
            <PulseExploreChart :legacy="legacy" />
            <PulseExploreDetailsListingMeta
                :legacy="legacy"
                :rows="rows"
                :count="count"
                :typeData="typeData"
            />
            <PulseExploreDetailsListingLearners :legacy="legacy" :rows="rows" />
            <PulseExploreDetailsQuestions :legacy="legacy" :count="count" />
        </PulseExploreLayout>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
export default defineComponent({
    props: {
        legacy: Boolean,
        typeData: Object,
        rows: Array as PropType<any[]>,
    },
    computed: {
        count() {
            return this.rows?.reduce((carry, row) => row.count + carry, 0);
        },
    },
});
</script>
