<template>
    <a class="skip-main button" href="#main">Skip to main content</a>
</template>
<script lang="ts">
export default {};
</script>

<style>
a.skip-main {
    left: -999px;
    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
}
a.skip-main:focus,
a.skip-main:active {
    left: 0.5em;
    top: 0.5em;
    width: auto;
    height: auto;
    overflow: auto;
    z-index: 999;
}
</style>
