<template>
    <div>
        <div class="flex bg-gray-200 shadow-inner rounded-md p-4 mb-4">
            <div class="flex items-center justify-between text-sm w-full">
                <div
                    class="flex items-center flex-wrap space-y-4 sm:space-y-0 sm:space-x-4"
                >
                    <div class="flex flex-col">
                        <label
                            class="font-semibold text-xs uppercase leading-6 ml-2"
                            >Course</label
                        >
                        <SelectDropdown v-model="filterCourse">
                            <option value="">Show All</option>
                            <option
                                v-for="course in courses"
                                :value="course.uuid"
                                v-text="course.title"
                            />
                        </SelectDropdown>
                    </div>
                    <div class="flex flex-col">
                        <label
                            class="font-semibold text-xs uppercase leading-6 ml-2"
                            >Type</label
                        >
                        <SelectDropdown v-model="filterType">
                            <option value="">Show All</option>
                            <option value="lesson">Lesson view</option>
                            <option value="quiz">Quiz</option>
                            <option value="assessment">Assessment</option>
                            <option value="builder">Quiz Builder</option>
                        </SelectDropdown>
                    </div>
                    <div class="flex flex-col">
                        <label
                            class="font-semibold text-xs uppercase leading-6 ml-2"
                            >Filter</label
                        >
                        <div class="w-72">
                            <input
                                class="border-none leading-5 rounded-lg shadow p-3 w-full h-12 focus-visible:ring focus-visible:ring-blue-300 focus-visible:ring-opacity-50"
                                type="text"
                                v-model="filterSearchable"
                            />
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <label
                            class="font-semibold text-xs uppercase leading-6 ml-2"
                            >Page Size</label
                        >
                        <SelectDropdown class="w-28" v-model="perPage">
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </SelectDropdown>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="!loading">
            <template v-if="rows.length > 0">
                <div class="flex items-center justify-end mt-8">
                    <div class="flex items-center space-x-4 px-4">
                        <Pagination
                            v-if="pages > 1"
                            :total="total"
                            :perPage="perPage"
                            v-model="page"
                        />
                    </div>
                </div>
                <div class="sm:bg-white sm:rounded-md sm:shadow mt-4">
                    <PulseExploreTimelineRow
                        :legacy="legacy"
                        v-for="row in rows"
                        :key="row.uuid"
                        :row="row"
                    />
                </div>
            </template>
            <div v-if="rows.length === 0" class="h-40">
                <Placeholder label="No data" />
            </div>
        </template>
        <Loader :show="loading" />
    </div>
</template>

<script lang="ts">
import axios from "axios";
import debounce from "debounce";
import { mapGetters } from "vuex";

export default {
    props: {
        courses: Array,
        legacy: Boolean,
    },
    data(): any {
        return {
            loading: true,
            rows: [],
            filterCourse: "",
            filterType: "",
            filterSearchable: "",
            sortBy: "timestamp",
            sortOrder: "desc",
            page: 1,
            perPage: "25",
            total: null,
        };
    },
    computed: {
        ...mapGetters(["context"]),
        filter() {
            return (
                this.filterCourse +
                this.filterType +
                this.filterSearchable +
                this.page +
                this.perPage
            );
        },
        pages() {
            return Math.ceil(this.total / this.perPage);
        },
        path() {
            return (
                "/api/pulse/timeline" +
                this.context +
                "&type=" +
                this.filterType +
                "&course=" +
                this.filterCourse +
                "&search=" +
                encodeURIComponent(this.filterSearchable) +
                "&page=" +
                this.page +
                "&perPage=" +
                this.perPage +
                "&legacy=" +
                this.legacy
            );
        },
    },
    methods: {
        getData() {
            this.loading = true;
            axios.get(this.path).then((response) => {
                this.rows = response.data.data;
                this.total = response.data.total;
                this.loading = false;
            });
        },
    },
    mounted() {
        this.getData = debounce(this.getData, 200);
        this.getData();
    },
    watch: {
        filter(newValue) {
            this.getData();
        },
        perPage() {
            this.page = 1;
        },
    },
};
</script>
