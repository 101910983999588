<script setup lang="ts">
import { defineProps, computed } from "vue";
import { format, formatDistance, parse } from "date-fns";

const props = defineProps({
    line1: {
        type: String,
        required: true,
    },
    line2: {
        type: String,
        required: false,
    },
    thumbnail: {
        type: String,
        required: false,
    },
    time: {
        type: String,
        required: false,
    },
    url: {
        type: String,
        required: true,
    },
});

// time is a string in format like "2023-01-18T20:14:17.000000Z"
// use date-fns to formatDistance from current time.
const formattedTime = computed(() => {
    if (props.time) {
        return formatDistance(
            parse(props.time, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSxxx", new Date()),
            new Date()
        );
    }
});
</script>
<template>
    <li class="border-gray-100">
        <a
            :href="url"
            class="flex hover:bg-cyan-50 hover:no-underline border-b last:border-b-0 border-gray-100 space-x-4 py-4 sm:p-4 cursor-pointer"
        >
            <div
                class="hidden sm:flex items-start justify-center flex-shrink-0 flex-grow-0 w-21"
                v-if="thumbnail"
            >
                <div class="aspect-w-3 aspect-h-2 w-full">
                    <img
                        :src="thumbnail"
                        :alt="line2"
                        class="object-cover ring-1 ring-gray-300"
                    />
                </div>
            </div>
            <div class="text-sm flex-1">
                <div class="font-semibold">{{ line1 }}</div>
                <div class="leading-9/2" v-if="line2">{{ line2 }}</div>
                <div class="text-gray-500 text-xs mt-2" v-if="formattedTime">
                    {{ formattedTime }} ago
                </div>
            </div>
            <div class="flex flex-col items-center space-y-2">
                <slot name="tail"> </slot>
            </div>
        </a>
    </li>
</template>
