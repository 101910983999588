<template>
    <div
        class="border-b last:border-b-0 pl-4 last:pb-px"
        :class="{
            'bg-white border-gray-100 first:rounded-t-md last:rounded-b-md':
                child == 0,
            'border-gray-200': child == 1,
            'border-gray-300': child == 2,
        }"
    >
        <div
            class="flex flex-col sm:flex-row sm:items-center justify-between py-4 pr-4 sm:py-0 sm:pr-0"
        >
            <div
                class="flex items-center justify-start flex-grow"
                v-if="row.rows"
            >
                <SvgIcon
                    class="mr-2 transition transform duration-100 rotate-0"
                    :class="{
                        'rotate-90': showRows,
                    }"
                    icon="chevron-right"
                    size="xs"
                    weight="bold"
                />
                <button
                    class="text-left hover:font-medium"
                    :class="{
                        'font-medium text-pear-600': showRows,
                    }"
                    type="button"
                    @click="showRows = !showRows"
                >
                    {{ row.name }}
                </button>
            </div>
            <a
                class="text-blue-600 underline"
                v-if="!row.rows"
                :href="link + row.uuid"
                v-text="row.name"
            />
            <div
                class="flex items-start sm:items-center justify-between sm:justify-start border sm:border-none border-dashed border-gray-400 text-sm rounded-lg sm:rounded-none mt-4 sm:mt-0 p-4 sm:p-0"
            >
                <div
                    class="flex flex-col sm:flex-row items-center sm:justify-center sm:border-l sm:border-r leading-6 sm:w-22 sm:h-18"
                    :class="{
                        'border-gray-100': child == 0,
                        'border-gray-200': child == 1,
                        'border-gray-300': child == 2,
                    }"
                >
                    <span class="sm:hidden text-sm font-bold underline"
                        >Count</span
                    >
                    <span>{{ row.count }}</span>
                </div>
                <template v-if="isAssessment">
                    <div
                        class="flex flex-col sm:flex-row items-center sm:justify-center leading-6 sm:w-32 sm:h-18"
                    >
                        <span class="sm:hidden text-sm font-bold underline"
                            >Avg Score</span
                        >
                        <span
                            v-html="
                                $filters.grade(
                                    row.averageScore,
                                    settings.pulse_grade
                                )
                            "
                        />
                    </div>
                    <div
                        class="flex flex-col sm:flex-row items-center sm:justify-center sm:border-l border-gray-100 leading-6 sm:w-24 sm:h-18"
                    >
                        <span class="sm:hidden text-sm font-bold underline"
                            >Avg Time</span
                        >
                        <span v-html="$filters.elapsed(row.averageDuration)" />
                    </div>
                </template>
            </div>
        </div>
        <div
            class="border border-l-4 border-r-0 rounded-l-lg mb-4"
            :class="{
                'bg-gray-50 border-gray-200': child == 0,
                'bg-gray-100 border-gray-300': child == 1,
            }"
            v-show="showRows"
        >
            <PulseExploreDetailsCourseOverviewRow
                v-for="r in row.rows"
                :child="child + 1"
                :legacy="legacy"
                :key="r.name"
                :row="r"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { mapState, mapGetters } from "vuex";

export default {
    props: {
        child: {
            type: Number,
            default: 0,
        },
        legacy: Boolean,
        row: Object,
    },
    data(): any {
        return {
            showRows: false,
        };
    },
    computed: {
        ...mapState(["settings", "type"]),
        ...mapGetters(["context"]),
        isAssessment() {
            return ["assessments", "quizzes"].indexOf(this.type) > -1;
        },
        path() {
            return this.legacy ? "/pulse/explore-legacy/" : "/pulse/explore/";
        },
        link() {
            return this.path + this.type + this.context + "&id=";
        },
    },
    mounted() {
        this.$events.$on("showRows", () => {
            this.showRows = true;
            this.$parent.$emit("showRows");
        });

        this.$events.$on("highlight", (term) => {
            if (
                this.row.name &&
                this.row.name.toLowerCase().indexOf(term.toLowerCase()) > -1
            ) {
                this.$parent.$emit("showRows");
            }
        });
    },
};
</script>
