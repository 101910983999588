<template>
    <div class="flex flex-col items-start mr-4 sm:mr-8 mb-4 max-w-full">
        <strong
            class="text-navy-600 text-xs uppercase font-semibold leading-6 ml-2"
            >Report Subject</strong
        >
        <div class="relative max-w-full" v-click-away="hide">
            <button
                class="relative bg-white flex items-center justify-between text-left rounded-lg shadow py-3 px-4 min-w-48 max-w-full h-12"
                :class="{
                    'ring ring-blue-300 ring-opacity-50': show,
                }"
                @click="toggleShow"
            >
                <div class="text-sm truncate">
                    {{ subjectTree[current].name }}
                </div>
                <div class="flex ml-4 w-4" v-if="subjectTree.length > 1">
                    <svg-icon
                        v-if="!show"
                        icon="dropdown"
                        size="xs"
                        weight="extra-bold"
                    />
                    <svg-icon
                        v-if="show"
                        icon="close"
                        size="xs"
                        weight="extra-bold"
                    />
                </div>
            </button>
            <fade
                classes="absolute top-0 left-0 mt-14 z-10 min-w-full"
                :show="show"
            >
                <div class="flex flex-col">
                    <a
                        v-for="(branch, key) in subjectTree"
                        class="bg-white hover:bg-gray-50 flex items-center p-4 border-b last:border-none no-underline hover:no-underline first:rounded-t-md last:rounded-b-md shadow"
                        :class="indents[branch.indent]"
                        :href="link(branch)"
                    >
                        <SvgIcon
                            v-if="branch.indent > 1"
                            class="text-navy-500 mr-4"
                            icon="indent"
                            size="sm"
                            weight="medium"
                        />
                        <div class="flex flex-col">
                            <span class="sm:whitespace-nowrap">{{
                                branch.name
                            }}</span>
                        </div>
                    </a>
                </div>
            </fade>
        </div>
    </div>
</template>

<script lang="ts">
import { mapState } from "vuex";

export default {
    props: {
        legacy: Boolean,
    },
    data(): any {
        return {
            icons: {
                Learner: "learner",
                Cohort: "cohort",
                Account: "school",
                Organization: "overview",
                Group: "cohort",
                Partner: "overview",
                Admin: "admin",
            },
            indents: {
                "1": null,
                "2": null,
                "3": "pl-6",
                "4": "pl-8",
            },
            show: false,
        };
    },
    computed: {
        ...mapState(["subjectTree", "period", "type"]),
        current() {
            return this.subjectTree.length - 1;
        },
        path() {
            return this.legacy ? "/pulse/explore-legacy/" : "/pulse/explore/";
        },
    },
    methods: {
        hide() {
            this.show = false;
        },
        link(branch) {
            return (
                this.path +
                this.type +
                "?subject=" +
                branch.uuid +
                "&period=" +
                this.period
            );
        },
        toggleShow() {
            if (this.subjectTree.length > 1) {
                this.show = !this.show;
            }
        },
    },
};
</script>
