<template>
    <div>
        <section class="section-head flex items-center justify-between">
            <page-title app="Admin" section="Logger" icon="logging-truck" />
        </section>
        <section class="section-main">
            <div>
                <div class="well justify-start mb-8">
                    <div class="flex flex-col mr-4">
                        <label
                            class="font-semibold uppercase leading-5 text-xs mb-1"
                            >Log</label
                        >
                        <select-dropdown v-model="log">
                            <option value="">Choose a log</option>
                            <option value="export">Export</option>
                            <option value="prep">Prep</option>
                        </select-dropdown>
                    </div>
                    <div class="flex flex-col mr-4">
                        <label
                            class="font-semibold uppercase leading-5 text-xs mb-1"
                            >Type</label
                        >
                        <select-dropdown v-model="activity">
                            <option value="">Choose an activity</option>
                            <option
                                value="OrganizationExport"
                                v-if="log == 'export'"
                            >
                                OrganizationExport
                            </option>
                        </select-dropdown>
                    </div>
                </div>
                <template v-if="!loading">
                    <div class="flex items-center justify-end mb-4">
                        <div class="flex items-center">
                            <div
                                class="flex items-center"
                                v-if="items.length > 0"
                            >
                                <span
                                    >Showing {{ resultStart }} –
                                    {{ resultEnd }} of {{ resultTotal }}</span
                                >
                                <button
                                    class="paginate-button ml-3 mr-1"
                                    :disabled="page == 1"
                                    @click="prevPage"
                                >
                                    &larr;
                                </button>
                                <button
                                    class="paginate-button"
                                    :disabled="page == pages"
                                    @click="nextPage"
                                >
                                    &rarr;
                                </button>
                            </div>
                            <select-dropdown
                                class="shadow ml-4"
                                size="sm"
                                v-model="pageSize"
                            >
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select-dropdown>
                        </div>
                    </div>
                    <div class="bg-white rounded shadow">
                        <div
                            class="flex items-center border-b"
                            v-for="item in items"
                        >
                            <div
                                class="text-sm p-4 w-56"
                                v-text="
                                    $filters.dateTime(
                                        item.created_at,
                                        user.timezone
                                    )
                                "
                            />
                            <div class="font-mono p-4">
                                <a
                                    class="block text-indigo-600 text-sm bg-gray-100 rounded leading-8 px-2"
                                    :href="'/admin/logger/' + item.id"
                                    >{{ item.description }}</a
                                >
                            </div>
                            <div class="p-4 w-64">
                                {{ item.causer.name }}
                            </div>
                            <div class="p-4">
                                <div
                                    v-html="returnExamPath(item.subject)"
                                ></div>
                            </div>
                        </div>
                    </div>
                </template>
                <loading-spinner :show="loading" />
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState } from "vuex";

export default {
    data(): any {
        return {
            loading: true,
            items: [],
            log: "",
            activity: "",
            pageSize: "50",
            page: 1,
            pages: null,
            resultStart: null,
            resultEnd: null,
            resultTotal: null,
        };
    },
    computed: {
        ...mapState(["user"]),
        filters() {
            return (
                "?log=" +
                this.log +
                "&activity=" +
                this.activity +
                "&pageSize=" +
                this.pageSize +
                "&page=" +
                this.page
            );
        },
    },
    methods: {
        fetch() {
            this.loading = true;
            axios.get("/api/admin/logger" + this.filters).then((response) => {
                this.items = response.data.data;
                this.resultStart = response.data.from;
                this.resultEnd = response.data.to;
                this.resultTotal = response.data.total;
                this.pages = response.data.last_page;
                this.loading = false;
            });
        },
        nextPage() {
            if (this.page != this.pages) {
                this.page++;
            }
        },
        prevPage() {
            if (this.page > 2) {
                this.page--;
            }
        },
        returnExamPath(subject, order) {
            if (subject && typeof subject.exam !== undefined) {
                return (
                    '<a href="/prep/exams/' +
                    subject.exam.uuid +
                    "/" +
                    subject.order +
                    '" target="_blank">View in Exam</a>'
                );
            }
        },
    },
    mounted() {
        this.fetch();
    },
    watch: {
        filters() {
            this.fetch();
        },
    },
};
</script>
