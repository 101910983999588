<template>
    <PrepExamQuestion
        :type="type"
        :exam-instance-uuid="examInstanceUuid"
        :active="active"
        :category="category"
        :course-tree="courseTree"
        :editable="true"
        :exam="exam"
        :has-multiple-answers="hasMultipleAnswers"
        :last-question="lastQuestion"
        :paused="paused"
        :position="position"
        :question="question"
        :question-flagged="questionFlagged"
        :question-id="questionId"
        :question-uuid="questionUuid"
        :question-image="questionImage"
        :get-response="getResponse"
        :show-feedback="showFeedback"
        :status="status"
        :total-questions="totalQuestions"
        :exam-path="examPath"
        :results-path="resultsPath"
        :review-path="reviewPath"
    ></PrepExamQuestion>
</template>

<script setup lang="ts">
import { defineProps, onMounted } from "vue";
import { usePrepExamStore } from "../../../stores/prepStore";
import { storeToRefs } from "pinia";

const prepStore = usePrepExamStore();

const {
    exam,
    paused,
    answers,
    correct,
    feedback,
    percentComplete,
    response,
    responses,
    score,
    set,
    status,
} = storeToRefs(prepStore);

const props = defineProps({
    type: {
        type: String,
        required: true,
    },
    examInstanceUuid: {
        type: String,
        required: true,
    },
    active: {
        type: Number,
        required: true,
    },
    answers: {
        type: Array,
        required: true,
    },
    category: {
        type: String,
        required: false,
    },
    correct: {
        type: Boolean,
        required: false,
        default: false,
    },
    courseTree: Object,
    exam: {
        type: String,
        required: true,
    },
    feedback: {
        type: String,
        required: false,
        default: "",
    },
    hasMultipleAnswers: {
        type: Boolean,
        required: true,
    },
    lastQuestion: {
        type: Boolean,
        required: true,
    },
    getPercentComplete: {
        type: Number,
        required: true,
    },
    paused: {
        type: Boolean,
        required: true,
    },
    position: {
        type: Number,
        required: true,
    },
    question: {
        type: String,
        required: true,
    },
    questionFlagged: {
        type: Boolean,
        required: true,
    },
    questionId: {
        type: Number,
        required: true,
    },
    questionUuid: {
        type: String,
        required: true,
    },
    questionImage: {
        type: String,
        required: true,
    },
    getResponse: {
        type: Array,
        required: true,
    },
    set: {
        type: Boolean,
        required: true,
    },
    showFeedback: {
        type: Boolean,
        required: true,
        default: true,
    },
    status: {
        type: String,
        required: true,
    },
    totalQuestions: {
        type: Number,
        required: true,
    },
    examPath: {
        type: String,
        required: true,
    },
    resultsPath: {
        type: String,
        required: true,
    },
    reviewPath: {
        type: String,
        required: true,
    },
});

onMounted(() => {
    answers.value = props.answers;
    correct.value = props.correct;
    feedback.value = props.feedback;
    response.value = props.getResponse;
    percentComplete.value = props.getPercentComplete;
    set.value = props.set;
    exam.value = props.exam;
});
</script>
