<template>
    <div>
        <slot v-bind="{ openAddItemModal }"></slot>
        <BaseModal
            :heading="`Save “${title}” for later`"
            :opened="isOpen"
            @close="close"
            close-text="Close"
        >
            <template v-slot:body>
                <h3 class="text-lg font-medium mb-3">Folders</h3>
                <label
                    v-for="folder in internalFolders"
                    class="flex items-center space-x-3"
                >
                    <input
                        type="checkbox"
                        @change="submit(folder)"
                        :checked="folder.item"
                    />
                    <div>
                        {{ folder.name }}
                    </div>
                </label>
                <div
                    v-if="internalFolders.length === 0"
                    class="flex items-center bg-gray-50 rounded-lg p-3 space-x-3"
                >
                    <SvgIcon
                        class="text-gray-500"
                        icon="error"
                        size="base"
                        weight="medium"
                    />
                    <div class="text-sm">You have no folders</div>
                </div>
                <button
                    v-if="!showFolderInput"
                    type="button"
                    class="flex items-center small flat button space-x-2 mt-6"
                    @click="showFolderInput = true"
                >
                    <SvgIcon icon="add" size="xs" weight="bold" />
                    <div class="text-sm">Create new folder</div>
                </button>
                <div
                    v-if="showFolderInput"
                    class="flex items-center mt-6 space-x-2"
                >
                    <input
                        type="text"
                        placeholder="Folder name"
                        v-model="folderName"
                    />
                    <button class="small main button" @click="createFolder">
                        Create
                    </button>
                    <button class="small flat button" @click="resetForm">
                        Cancel
                    </button>
                </div>
            </template>
        </BaseModal>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import BaseModal from "./BaseModal.vue";
import { defineComponent } from "vue";

export default defineComponent({
    components: { BaseModal },
    props: {
        enrollmentId: {
            required: true,
        },
        id: {
            required: true,
        },
        lessonId: {
            required: false,
        },
        moduleId: {
            required: true,
        },
        savedItems: {
            type: Array,
            default: [],
        },
        title: {
            required: true,
        },
        type: {
            required: true,
        },
    },
    data(): {
        folderName: string;
        internalSavedItems: any[];
        internalFolders: any[];
        isOpen: boolean;
        isSaving: boolean;
        showFolderInput: boolean;
        folderId: string;
        folders: any[];
    } {
        return {
            folderName: "",
            internalSavedItems: this.savedItems,
            internalFolders: [],
            isOpen: false,
            isSaving: false,
            showFolderInput: false,
            folderId: "",
            folders: [],
        };
    },
    methods: {
        open() {
            this.isOpen = true;
        },
        close() {
            this.isOpen = false;
            this.reset();
        },
        reset() {
            this.folderId = "";
        },
        openAddItemModal() {
            Promise.all([this.getSavedItems(), this.getFolders()]).then(() => {
                this.processFolders();
                this.open();
            });
        },
        createFolder(e: Event) {
            e.preventDefault();
            axios
                .post(
                    `/api/learn/enrollments/${this.enrollmentId}/saved-items-folders`,
                    {
                        name: this.folderName,
                    }
                )
                .then((response) => {
                    this.resetForm();
                    this.internalFolders.push(response.data);
                });
        },
        getSavedItems() {
            return axios
                .get(`/api/learn/enrollments/${this.enrollmentId}/saved-items`)
                .then((response) => {
                    this.internalSavedItems = response.data;
                });
        },
        getFolders() {
            return axios
                .get(
                    `/api/learn/enrollments/${this.enrollmentId}/saved-folders`
                )
                .then((response) => {
                    this.folders = response.data;
                });
        },
        refreshInternalFolders() {
            this.internalFolders = this.internalFolders.map((folder) => {
                folder.item = this.internalSavedItems.find(
                    (item) => item.folder_id === folder.id
                );
                return folder;
            });
        },
        removeItem(folder) {
            this.isSaving = true;
            axios
                .delete(
                    `/api/learn/enrollments/${this.enrollmentId}/saved-items-folders/${folder.id}/items/${folder.item.id}`
                )
                .then(() => {
                    this.internalSavedItems.splice(
                        this.internalSavedItems.indexOf(folder.item),
                        1
                    );
                    this.isSaving = false;
                });
        },
        resetForm(e?: Event) {
            e?.preventDefault();
            this.showFolderInput = false;
            this.folderName = "";
        },
        saveItem(folder) {
            this.isSaving = true;
            axios
                .post(
                    `/api/learn/enrollments/${this.enrollmentId}/saved-items-folders/${folder.id}/items`,
                    {
                        id: this.id,
                        lesson_id: this.lessonId,
                        module_id: this.moduleId,
                        type: this.type,
                        save_for_later: true,
                    }
                )
                .then((response) => {
                    this.internalSavedItems.push(response.data);
                    this.isSaving = false;
                });
        },
        submit(folder) {
            folder.item ? this.removeItem(folder) : this.saveItem(folder);
        },
        processFolders() {
            this.internalFolders = this.folders
                .map((folder) => {
                    folder.item = this.internalSavedItems.find(
                        (item) => item.folder_id === folder.id
                    );
                    return folder;
                })
                .filter((folder) => folder.name != "My Favorites");
        },
    },
    mounted() {
        this.processFolders();
    },
    watch: {
        internalSavedItems() {
            this.refreshInternalFolders();
        },
    },
});
</script>
