<script lang="ts">
export default {
    props: {
        completed: Boolean,
    },
};
</script>
<template>
    <div
        v-if="completed"
        class="w-5 h-5 mx-auto leading-tight rounded-full bg-emerald-450"
    >
        <SvgIcon color="white" icon="check-line" weight="bold" size="sm" />
    </div>
    <div
        v-else
        class="w-5 h-5 mx-auto leading-tight rounded-full bg-gray-200 flex justify-center"
    >
        <div
            class="w-[10px] h-[3px] mx-auto my-auto leading-tight bg-white"
        ></div>
    </div>
</template>
