<template>
    <div>
        <PulseActivityTable
            :columns="columns"
            :filters="viewFilters"
            :loading="loading"
            :rows="data"
            :rowAs="rowForView"
            :sort="currentSort"
            :has-views="true"
            :pagination="pagination"
            @page-updated="handlePageUpdated"
        >
            <template v-slot:filters>
                <!--                <PulseActivityTypeFilter/>-->
                <!--                <PulseActivityTableCourseFilter/>-->
            </template>
            <template v-slot:views>
                <PulseActivityTableViewToggle />
            </template>
        </PulseActivityTable>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import {
    getLearnerReport,
    getStructuredReport,
} from "../../../../api/pulse-requests";

export default {
    data(): any {
        return {
            data: [],
            currentFilters: {},
            currentSort: {},
            pagination: {},
            loading: true,
            viewColumns: [
                {
                    header: "Learner",
                    primary: true,
                    sort: true,
                    views: ["learners", "timeline"],
                },
                {
                    header: "Type",
                    sort: true,
                    views: ["learners", "timeline"],
                    width: 10,
                },
                {
                    header: "Lesson",
                    sort: false,
                    views: ["learners", "timeline"],
                    width: 20,
                },
                {
                    header: "Course",
                    sort: true,
                    views: ["learners", "timeline"],
                    width: 25,
                },
                {
                    header: "Score",
                    sort: false,
                    views: ["learners", "timeline"],
                    width: 5,
                },
                {
                    header: "Date",
                    sort: true,
                    views: ["learners"],
                },
                {
                    header: "Time",
                    sort: false,
                    views: ["timeline"],
                },
                {
                    header: "Course Name",
                    primary: true,
                    sort: true,
                    views: ["structured"],
                    width: 20,
                },
                {
                    header: "Module Name",
                    sort: false,
                    views: ["structured"],
                },
            ],
            viewFilters: [],
        };
    },
    computed: {
        ...mapGetters(["context", "watchContext"]),
        ...mapState([
            "subjectGroup",
            "subject",
            "period",
            "partner",
            "group",
            "view",
            "type",
            "selectedLearners",
        ]),
        columns() {
            return this.viewColumns.filter((column) =>
                column.views.includes(this.view)
            );
        },
        rowForView() {
            switch (this.view) {
                case "structured":
                    return "pulse-learn-courses-structured-row";
                case "timeline":
                    return "";
                case "learners":
                default:
                    return "pulse-learn-courses-learners-row";
            }
        },
    },
    methods: {
        getData() {
            console.log("Reload Data!!");
            this.loading = true;
            // Get parameters from the URL
            let params = new URLSearchParams(window.location.search);
            const partner = this.partner;
            const group = this.group;
            const course = params.get("course") ?? null;
            const period = params.get("period") ?? null;
            const event_type = params.get("event_type") ?? "all";
            const page = params.get("page") ?? 1;
            if (this.view === "structured") {
                getStructuredReport(
                    partner,
                    group,
                    period,
                    course,
                    this.selectedLearners,
                    page,
                    event_type
                )
                    .then((response) => {
                        this.data = response.data.data;
                        this.pagination = response.data.meta;
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            if (this.view === "learners") {
                debugger;
                getLearnerReport(
                    partner,
                    group,
                    period,
                    course,
                    null,
                    this.selectedLearners,
                    page,
                    event_type
                ).then((response) => {
                    this.data = response.data.data;
                    this.pagination = response.data.meta;
                    this.loading = false;
                });
            }
        },
        setData() {
            this.data = this.activity.data;
            this.pagination = this.activity.meta;
        },
        handlePageUpdated(page) {
            // push new page param to url history
            let url = new URL(window.location.href);
            url.searchParams.set("page", page);
            window.history.pushState({}, "", url);
            this.getData();
        },
        setCurrentSort() {
            this.currentSort = {};

            this.viewColumns
                .filter(
                    (column) =>
                        column.primary && column.views.includes(this.view)
                )
                .map(
                    (column) =>
                        (this.currentSort[column.header.toLowerCase()] = "asc")
                );
        },
        refreshCurrentSort(changedColumn) {
            if (changedColumn.isPrimary) {
                return;
            }

            Object.entries(this.currentSort).map(([key, value]) => {
                // maintain primary
                if (
                    this.viewColumns.some(
                        (col) => col.header.toLowerCase() === key && col.primary
                    )
                ) {
                    this.currentSort[key] = value;
                    return;
                }

                // update secondary
                if (changedColumn.column !== key) {
                    delete this.currentSort[key];
                }
            });
        },
    },
    watch: {
        view(value, prev) {
            if (value === prev) {
                return;
            }

            this.setCurrentSort();
        },
        watchContext: {
            handler() {
                this.getData();
            },
            deep: true,
            immediate: true,
        },
    },
};
</script>
