<template>
    <span
        v-html="formatScore(score)"
        class="font-normal"
        :class="{ 'text-red-650': hasFailed, 'text-gray-600': !score || empty }"
    ></span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        score: [Number, String],
        empty: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasFailed() {
            return this.getScoreAsNumber < 75 && !this.empty;
        },
        getScoreAsNumber() {
            return Number(this.score);
        },
    },
    methods: {
        formatScore(score) {
            return score ? score.toFixed(2) : "&mdash;";
        },
    },
});
</script>
