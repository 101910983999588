<template>
    <Pill
        :background-color="typeMap[type].background"
        :text-color="typeMap[type].text"
        class="inline-flex"
    >
        {{ typeMap[type]?.label ?? "" }}
    </Pill>
</template>

<script lang="ts">
export default {
    props: {
        type: Number,
    },
    data(): any {
        return {
            typeMap: {
                1: {
                    dbLabel: "PostCourseStarted",
                    label: "Post Course Started",
                },
                2: {
                    dbLabel: "ModuleAssessmentStarted",
                    label: "Module Assessment Started",
                },
                3: {
                    dbLabel: "LessonQuizStarted",
                    label: "Lesson Quiz Started",
                    background: "blue-150",
                },
                4: {
                    dbLabel: "PostCourseCompleted",
                    label: "Post Course Completed",
                },
                5: {
                    dbLabel: "ModuleAssessmentCompleted",
                    label: "Assessment",
                    background: "butter-300",
                },
                6: {
                    dbLabel: "LessonQuizCompleted",
                    label: "Quiz",
                    background: "blue-150",
                },
                7: {
                    dbLabel: "LessonVideoPlayed",
                    label: "Lesson Video Played",
                    background: "green-150",
                },
                8: {
                    dbLabel: "LessonPageViewed",
                    label: "Lesson",
                    background: "green-150",
                },
                9: {
                    dbLabel: "LessonVideoMilestone",
                    label: "Lesson Video Milestone",
                    background: "green-150",
                },
                11: {
                    dbLabel: "LessonVideoView",
                    label: "Lesson",
                    background: "green-150",
                },
            },
            typeColorMap: {
                assessment: {
                    background: "butter-300",
                },
                lesson: {
                    background: "green-150",
                },
                quiz: {
                    background: "blue-150",
                },
                exam: {
                    background: "yellow-100",
                    text: "yellow-800",
                },
                assignment: {
                    background: "indigo-100",
                    text: "indigo-800",
                },
                active: {
                    background: "green-100",
                    text: "green-800",
                },
                past: {
                    background: "red-100",
                    text: "red-800",
                },
                completed: {
                    background: "green-100",
                    text: "green-800",
                },
                started: {
                    background: "gray-100",
                    text: "gray-800",
                },
                not_started: {
                    background: "red-100",
                    text: "red-800",
                },
            },
        };
    },
};
</script>
