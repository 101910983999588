<template>
    <div>
        <PulseExploreLayout :legacy="legacy" v-bind="$attrs">
            <PulseExplorePrepTable
                :legacy="legacy"
                :getAssignment="assignment"
                :assignments="assignments"
                :getExam="exam"
                :exams="exams"
            />
        </PulseExploreLayout>
    </div>
</template>

<script lang="ts">
import axios from "axios";

export default {
    props: {
        assignment: String,
        exam: String,
        legacy: Boolean,
    },
    data(): any {
        return {
            assignments: [],
            exams: [],
        };
    },
    methods: {
        getAssignments() {
            axios
                .get("/api/prep/assignments?type=pulse-assignments")
                .then((response) => {
                    this.assignments = response.data;
                });
        },
        getExams() {
            axios
                .get("/api/prep/assignments?type=pulse-exams")
                .then((response) => {
                    this.exams = response.data;
                });
        },
    },
    mounted() {
        this.getAssignments();
        this.getExams();
    },
};
</script>
