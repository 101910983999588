<template>
    <div
        class="flex items-start bg-gray-200 rounded-md shadow-inner justify-start p-6 mb-8"
    >
        <div class="flex flex-col mr-4">
            <label class="font-semibold uppercase leading-5 text-xs mb-1"
                >Status</label
            >
            <SelectDropdown
                v-model="modelValue.status"
                variant="status"
                @input="(e) => updateModelValue('status', e.target.value)"
            >
                <option value="">All</option>
                <option value="published">Published</option>
                <option value="draft">Draft</option>
                <option value="closed">Closed</option>
            </SelectDropdown>
        </div>
        <div class="flex flex-col mr-4">
            <label class="font-semibold uppercase leading-5 text-xs mb-1"
                >Access</label
            >
            <SelectDropdown
                v-model="modelValue.access"
                variant="access"
                @input="(e) => updateModelValue('access', e.target.value)"
            >
                <option value="open">Open</option>
                <option value="reserved">Reserved</option>
            </SelectDropdown>
        </div>
        <div class="flex flex-col mr-4">
            <label class="font-semibold uppercase leading-5 text-xs mb-1"
                >Flagged</label
            >
            <SelectDropdown
                v-model="modelValue.flagged"
                @input="($e) => updateModelValue('flagged', $e.target.value)"
            >
                <option value="all">All</option>
                <option value="flagged">Yes</option>
                <option value="not_flagged">No</option>
            </SelectDropdown>
        </div>
        <div class="flex flex-col w-64">
            <label class="font-semibold uppercase leading-5 text-xs mb-1"
                >Keyword</label
            >
            <input
                class="leading-5 rounded p-3 shadow"
                type="text"
                v-model="modelValue.keyword"
                @input="($e:any) => updateModelValue('keyword', $e.target.value)"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
import SelectDropdown from "@/components/_elements/SelectDropdown.vue";
import { nextTick, onMounted } from "vue";

export type FilterOptions = {
    access: string;
    flagged: "all" | "flagged" | "not_flagged";
    keyword: string;
    status: string;
};

// we want to make a component with custom v-model
const props = defineProps<{
    modelValue: FilterOptions;
}>();

const emit = defineEmits<{
    "update:modelValue": [value: FilterOptions];
}>();

const cacheKey = "clover.admin.content.question.filter";

function updateModelValue(key: keyof FilterOptions, value: any) {
    const newModelValue = {
        ...props.modelValue,
        [key]: value,
    };
    localStorage.setItem(cacheKey, JSON.stringify(newModelValue));
    emit("update:modelValue", {
        ...props.modelValue,
        [key]: value,
    });
}

onMounted(() => {
    const cachedFilters = localStorage.getItem(cacheKey);
    if (cachedFilters) {
        // for each key in cachedFilters, update the modelValue

        const parsedFilters = JSON.parse(cachedFilters ?? "{}");
        nextTick(() => {
            emit("update:modelValue", {
                ...props.modelValue,
                ...parsedFilters,
            });
        });
    }
});
</script>
