<template>
    <div class="relative bg-white overflow-hidden shadow rounded-md">
        <div class="h-160" ref="cloudpdf" />
    </div>
</template>

<script lang="ts">
import PDFViewer from "@openbook/cloudpdf-viewer";

export default {
    props: {
        id: {
            type: String,
            required: true,
        },
    },
    mounted() {
        PDFViewer(
            {
                documentId: this.id,
                darkMode: true,
                // @ts-ignore
                search: true,
                themeColor: "#ffffff",
                appBarColored: true,
            },
            this.$refs.cloudpdf
        );
    },
};
</script>
