<template>
    <div>
        <slot v-if="showIfBlade === bladePage"></slot>
    </div>
</template>
<script lang="ts">
import { mapState } from "vuex";

export default {
    props: {
        showIfBlade: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapState(["bladePage"]),
    },
};
</script>
