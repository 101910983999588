<template>
    <div class="flex flex-wrap items-center">
        <PulsePrepCategoryCircle
            v-for="(children, parent) in row?.categories"
            :parent="parent"
            :categories="children"
            :complete="row?.categories_complete"
            :key="parent"
        />
        <div
            class="hidden text-orange-600 text-indigo-600 text-pink-600 bg-orange-100 bg-indigo-100 bg-pink-100 bg-cyan-100 text-cyan-600"
        ></div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        row: Object,
    },
};
</script>
