<template>
    <div>
        <div class="flex justify-center max-h-116">
            <div
                class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 p-4 overflow-x-visible overflow-y-auto w-full"
            >
                <div v-for="response in responses" class="last:pb-4">
                    <a
                        class="relative flex items-center justify-center hover:no-underline rounded-full w-12 h-12 transition duration-200"
                        :class="{
                            'bg-green-100 hover:bg-green-200 text-green-700 hover:text-green-700 border-green-500':
                                response.correct === true,
                            'bg-red-50 hover:bg-red-100 text-red-600 hover:text-red-600 border-red-500':
                                response.correct === false,
                            'bg-gray-50 hover:bg-gray-100 border-gray-300':
                                response.correct === null &&
                                response.response.length === 0,
                            'bg-blue-100 hover:bg-blue-200 text-blue-700 hover:text-blue-700 border-blue-500':
                                response.correct === null &&
                                response.response.length > 0,
                            'font-bold border-2': response.order == active,
                            unanswered:
                                response.set && response.response.length === 0,
                        }"
                        :href="path + '/' + response.order"
                    >
                        <span class="z-20">
                            {{ response.order }}
                        </span>
                        <div
                            v-if="response.question_flagged"
                            class="flex items-center justify-center bg-white absolute top-0 right-0 rounded-full shadow -mt-2 -mr-2 w-5 h-5"
                        >
                            <SvgIcon
                                class="text-red-600"
                                type="line fill"
                                icon="flag"
                                size="xxs"
                            />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
    props: {
        active: Number,
        path: String,
        responses: Array as PropType<any[]>,
    },
});
</script>

<style scoped>
.unanswered {
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23F98080' fill-opacity='0.4' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}
</style>
