<template>
	<svg
		width="578"
		height="251"
		viewBox="0 0 578 251"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<rect width="578" height="251" fill="#004EC0" />
		<mask
			id="mask0_1504_229"
			style="mask-type: alpha"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="578"
			height="251">
			<rect width="578" height="251" fill="#004EC0" />
		</mask>
		<g mask="url(#mask0_1504_229)">
			<path
				d="M268.327 398.792C388.541 403.685 503.953 321.367 506.846 229.168C511.298 87.2706 418.435 50.5728 315.395 50.5728C236.951 50.5728 57.7943 128.046 69.2432 245.478C77.874 334.004 148.114 393.899 268.327 398.792Z"
				fill="#2369CE" />
			<path
				d="M104.226 78.1066C104.226 75.8974 106.017 74.1066 108.226 74.1066H477.404C479.613 74.1066 481.404 75.8974 481.404 78.1066V297.177C481.404 299.386 479.613 301.177 477.404 301.177H108.226C106.017 301.177 104.226 299.386 104.226 297.177V78.1066Z"
				fill="#1F233C" />
			<rect
				x="121.399"
				y="98.2765"
				width="340.923"
				height="10.8129"
				fill="#343957" />
			<rect
				x="121.399"
				y="120.538"
				width="192.723"
				height="10.8129"
				fill="#343957" />
			<path
				d="M124.035 156.657H455.87C456.699 156.657 457.37 157.329 457.37 158.157V175.157C457.37 175.986 456.699 176.657 455.87 176.657H124.035C123.207 176.657 122.535 175.986 122.535 175.157V158.157C122.535 157.329 123.207 156.657 124.035 156.657Z"
				stroke="#535878" />
			<rect
				x="143.035"
				y="163.032"
				width="102.5"
				height="7.25"
				fill="#343957" />
			<rect
				x="130.535"
				y="164.157"
				width="5"
				height="5"
				stroke="#535878" />
			<path
				d="M124.035 185.657H455.87C456.699 185.657 457.37 186.329 457.37 187.157V204.157C457.37 204.986 456.699 205.657 455.87 205.657H124.035C123.207 205.657 122.535 204.986 122.535 204.157V187.157C122.535 186.329 123.207 185.657 124.035 185.657Z"
				stroke="#535878" />
			<rect
				x="143.035"
				y="192.032"
				width="102.5"
				height="7.25"
				fill="#343957" />
			<rect
				x="130.535"
				y="193.157"
				width="5"
				height="5"
				stroke="#535878" />
			<path
				d="M124.035 214.657H455.87C456.699 214.657 457.37 215.329 457.37 216.157V233.157C457.37 233.986 456.699 234.657 455.87 234.657H124.035C123.207 234.657 122.535 233.986 122.535 233.157V216.157C122.535 215.329 123.207 214.657 124.035 214.657Z"
				stroke="#535878" />
			<rect
				x="143.035"
				y="221.032"
				width="102.5"
				height="7.25"
				fill="#343957" />
			<rect
				x="130.535"
				y="222.157"
				width="5"
				height="5"
				stroke="#535878" />
			<path
				d="M124.035 243.657H455.87C456.699 243.657 457.37 244.329 457.37 245.157V262.157C457.37 262.986 456.699 263.657 455.87 263.657H124.035C123.207 263.657 122.535 262.986 122.535 262.157V245.157C122.535 244.329 123.207 243.657 124.035 243.657Z"
				stroke="#535878" />
			<rect
				x="143.035"
				y="250.032"
				width="102.5"
				height="7.25"
				fill="#343957" />
			<rect
				x="130.535"
				y="251.157"
				width="5"
				height="5"
				stroke="#535878" />
			<path
				d="M104.226 34.6714L114.813 51.8927L131.823 40.9703L133.889 61.0796L153.954 58.6194L147.091 77.6338L166.236 84.123L151.803 98.2765L166.236 112.43L147.091 118.919L153.954 137.934L133.889 135.473L131.823 155.583L114.813 144.66L104.226 161.882L93.6391 144.66L76.6287 155.583L74.5624 135.473L54.4975 137.934L61.3609 118.919L42.2156 112.43L56.6493 98.2765L42.2156 84.123L61.3609 77.6338L54.4975 58.6194L74.5624 61.0796L76.6287 40.9703L93.6391 51.8927L104.226 34.6714Z"
				fill="#F9CB58" />
			<path
				d="M119.605 71.6642L130.696 99.774L127.206 101.789L114.673 86.6302L114.6 86.6721L121.498 105.084L118.008 107.099L99.2095 83.4395L103.136 81.1726L114.477 96.5346L114.55 96.4927L107.971 78.3809L110.807 76.7437L123.542 91.8341L123.615 91.7921L115.678 73.9311L119.605 71.6642Z"
				fill="#373839" />
			<path
				d="M102.771 115.896L87.8259 90.0116L98.8779 83.6307L100.893 87.1208L93.5492 91.3607L97.936 98.959L104.335 95.2648L106.35 98.7549L99.951 102.449L104.338 110.047L111.682 105.807L113.823 109.516L102.771 115.896Z"
				fill="#373839" />
			<path
				d="M87.3802 124.782L72.4355 98.8973L75.9984 96.8404L90.6016 109.204L90.6743 109.162L81.6698 93.566L85.378 91.425L100.323 117.31L96.8325 119.325L82.1776 107.039L82.1048 107.081L91.0884 122.641L87.3802 124.782Z"
				fill="#373839" />
		</g>
	</svg>
</template>
