<template>
    <div class="h-full">
        <section class="section-title flex flex-wrap justify-between mb-4">
            <div class="flex items-center flex-wrap justify-start max-w-full">
                <PulseExploreReportSubject :legacy="legacy" />
                <PulseExploreReportType :legacy="legacy" />
            </div>
            <PulseExploreReportPeriod :legacy="legacy" />
        </section>
        <section class="section-main relative">
            <slot />
        </section>
        <slot name="placeholder" />
    </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";

export default {
    props: {
        course: {
            type: String,
            default: null,
        },
        instanceId: {
            type: String,
            default: null,
        },
        legacy: Boolean,
        period: String,
        settings: Object,
        subject: String,
        subjectGroup: String,
        subjectTree: Array,
        type: {
            type: String,
            default: null,
        },
        typeId: {
            type: String,
            default: null,
        },
    },
    created() {
        // @ts-ignore
        this.$store.commit("registerState", {
            instanceId: this.instanceId,
            period: this.period,
            subject: this.subject,
            subjectGroup: this.subjectGroup,
            subjectTree: this.subjectTree,
            settings: this.settings,
            type: this.type,
            typeId: this.typeId,
        });
    },
};
</script>
