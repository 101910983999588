import { defineStore } from "pinia";
import { ref, watch } from "vue";
import axios from "axios";

// You can name the return value of `defineStore()` anything you want,
// but it's best to use the name of the store and surround it with `use`
// and `Store` (e.g. `useUserStore`, `useCartStore`, `useProductStore`)
// the first argument is a unique id of the store across your application
export const usePrepExamStore = defineStore("prep-exam", () => {
    const exam = ref<any>(null);
    const paused = ref(false);
    const answers = ref<any[]>([]);
    const correct = ref<any>(null);
    const feedback = ref("");
    const percentComplete = ref(0);
    const response = ref<any[]>([]);
    const responses = ref<any[]>([]);
    const score = ref<any>(null);
    const set = ref(false);
    const status = ref("started");
    const toast = ref(false);
    const setPaused = (value) => {
        paused.value = value;
        if (paused.value) {
            pauseExam();
        } else {
            resumeExam();
        }
    };
    const resumeExam = () => {
        axios
            .get("/api/prep/exams/" + exam.value + "/resume")
            .then((response) => {
                paused.value = false;
            });
    };
    const pauseExam = () => {
        axios
            .get("/api/prep/exams/" + exam.value + "/pause")
            .then((response) => {
                paused.value = true;
            });
    };
    return {
        exam,
        paused,
        answers,
        correct,
        feedback,
        percentComplete,
        response,
        responses,
        score,
        set,
        status,
        toast,
        setPaused,
    };
});
