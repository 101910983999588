<template>
    <PulseActivityTable
        :columns="viewColumns"
        :filters="viewFilters"
        :loading="loading"
        :pagination="pagination"
        :rows="data"
        rowAs="pulse-assignments-table-row"
        table-name="Assigned Exams"
    />
</template>
<script lang="ts">
import axios from "axios";
export default {
    data(): any {
        return {
            data: [],
            loading: true,
            pagination: {},
            viewColumns: [
                {
                    header: "Due Date",
                    width: 15,
                },
                {
                    header: "Assignment Name",
                    width: 15,
                },
                {
                    header: "Status",
                    width: 8,
                },
                {
                    header: "Exam Name",
                    width: 15,
                },
                {
                    header: "Access",
                    width: 6,
                },
                {
                    header: "Questions",
                    width: 6,
                },
                {
                    header: "Assignee",
                    width: 19,
                },
                {
                    header: "Attempts",
                    width: 6,
                },
                {
                    header: "Average Score",
                    width: 10,
                },
            ],
            viewFilters: [
                {
                    name: "status",
                },
                {
                    name: "bank",
                },
                {
                    name: "access",
                },
            ],
        };
    },
    mounted() {
        axios
            .get(
                "https://my.api.mockaroo.com/pulse_prep_assigned_exams_data.json?key=189cc610"
            )
            .then((response) => {
                this.data = response.data;
                this.loading = false;
            });
    },
};
</script>
