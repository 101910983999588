<template>
    <div>
        <div class="flex items-center h-11">
            <VueSlider
                v-if="parents.length > 0"
                :contained="true"
                :dot-options="dotOptions"
                :enable-cross="false"
                :min-range="1"
                :process="process"
                tooltip="none"
                width="100%"
                v-model="value"
            >
            </VueSlider>
        </div>
        <div class="flex flex-wrap items-center justify-center mt-4">
            <div
                v-for="(parent, i) in parents"
                class="flex items-center mr-6 mb-6"
            >
                <div class="rounded-l leading-8 px-2" :class="colors.labels[i]">
                    {{ parent.name }}
                </div>
                <div
                    class="font-mono text-sm leading-8 text-center rounded-r px-2 w-12"
                    :class="colors.values[i]"
                >
                    {{ ranges[i] | integer }}%
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
// @ts-nocheck
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

export default {
    components: {
        VueSlider,
    },
    props: {
        parents: Array,
    },
    data(): any {
        return {
            value: [],
        };
    },
    computed: {
        breakdown() {
            let breakdown = [];
            for (let i = 0; i < this.parents.length; i++) {
                breakdown.push((this.weights[i] / this.total) * 100);
            }
            return breakdown;
        },
        colors() {
            return {
                labels: [
                    "bg-indigo-100 text-indigo-700",
                    "bg-cyan-100 text-cyan-700",
                    "bg-pink-100 text-pink-700",
                    "bg-orange-100 text-orange-700",
                ],
                rails: ["#c7d2fe", "#a5f3fc", "#fbcfe8", "#fed7aa"],
                values: [
                    "bg-indigo-600 text-white",
                    "bg-cyan-600 text-white",
                    "bg-pink-600 text-white",
                    "bg-orange-600 text-white",
                ],
            };
        },
        dotOptions() {
            let dots = [];
            for (let i = 0; i <= this.parents.length; i++) {
                if (i == 0 || i == this.parents.length) {
                    dots.push({ disabled: true });
                } else {
                    dots.push({ disabled: false });
                }
            }
            return dots;
        },
        process() {
            return (dots) => {
                let process = [];
                for (let i = 0; i < this.parents.length; i++) {
                    process.push([
                        dots[i],
                        dots[i + 1],
                        { backgroundColor: this.colors.rails[i] },
                    ]);
                }
                return process;
            };
        },
        ranges() {
            let ranges = [];
            for (let i = 0; i < this.parents.length; i++) {
                ranges.push(this.value[i + 1] - this.value[i]);
            }
            return ranges;
        },
        total() {
            return this.weights.reduce((a, b) => a + b);
        },
        values() {
            let values = [0];
            for (let i = 0; i < this.parents.length; i++) {
                values.push(values[values.length - 1] + this.breakdown[i]);
            }
            return values;
        },
        weights() {
            return this.parents.map((o) => o.weight);
        },
    },
    methods: {
        resetValues() {
            this.value = this.values;
        },
    },
    mounted() {
        this.$events.$on("resetCategoryBreakdown", () => {
            this.resetValues();
        });
    },
    watch: {
        ranges(value) {
            this.$emit("updateBreakdown", value);
        },
        value(value) {
            if (value != this.values) {
                this.$events.$emit("showCategoryReset");
            } else {
                this.$events.$emit("hideCategoryReset");
            }
        },
        values(newValue, oldValue) {
            if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                this.value = newValue;
            }
        },
    },
};
</script>

<style>
.vue-slider-dot-handle-disabled {
    background-color: white !important;
}
</style>
