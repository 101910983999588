<template>
    <div
        class="min-w-[90px]"
        :class="{ 'p-1 bg-white rounded-full shadow': outline }"
    >
        <div
            class="inline-flex items-center justify-center w-full px-3 space-x-2 text-xs font-semibold leading-6 tracking-wide text-center uppercase rounded-full"
            :class="[`bg-${backgroundColor}`, `text-${textColor}`]"
        >
            <SvgIcon v-if="icon" :icon="icon" size="sm" weight="medium" />
            <div>
                <slot />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    name: "Pill",
    props: {
        backgroundColor: {
            type: String,
            default: "gray-50",
        },
        icon: {
            type: String,
            required: false,
        },
        outline: {
            type: Boolean,
            required: false,
            default: false,
        },
        textColor: {
            type: String,
            default: "gray-700",
        },
    },
};
</script>
