<template>
    <div
        class="relative hover:bg-gray-50 border-b border-gray-100 last:border-b-0 first:rounded-t-md last:rounded-b-md"
        v-click-away="hide"
    >
        <a
            class="flex items-center justify-between w-full leading-tight p-4 hover:no-underline cursor-pointer"
            @click="show = true"
        >
            <div class="flex items-center">
                <SvgIcon class="mr-2" :icon="icon" size="sm" />
                <span>{{ label }}</span>
            </div>
            <SvgIcon icon="chevron-right" size="xs" weight="extra-bold" />
        </a>
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-class="opacity-0 translate-x-3"
            enter-to-class="opacity-100 translate-x-0"
            leave-active-class="transition ease-in duration-200"
            leave-class="opacity-100 translate-x-0"
            leave-to-class="opacity-0 translate-x-3"
        >
            <div
                v-if="show"
                class="absolute top-0 left-0 flex flex-col bg-white rounded-md shadow ml-50 transform"
            >
                <slot />
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        icon: String,
        label: String,
        path: String,
    },
    data(): any {
        return {
            show: false,
        };
    },
    methods: {
        hide() {
            this.show = false;
        },
    },
};
</script>
