<script lang="ts">
import { Doughnut } from "vue-chartjs";

export default {
    components: {
        Doughnut,
    },
    props: {
        chartData: Object,
        options: Object,
        centerText: {
            type: String,
            required: false,
        },
    },
    data(): any {
        return {
            loaded: false,
            plugins: [
                {
                    beforeDraw: (chart) => {
                        let width = chart.width,
                            height = chart.height,
                            ctx = chart.ctx;

                        ctx.restore();
                        let fontSize = (height / 114).toFixed(2);
                        ctx.font = fontSize + "em sans-serif";
                        ctx.textBaseline = "middle";

                        let text = this.centerText,
                            textX = Math.round(
                                (width - ctx.measureText(text).width) / 2
                            ),
                            textY = height / 2;

                        // ctx.fillText(text, textX, textY);
                        ctx.save();
                    },
                },
            ],
        };
    },
};
</script>
<template>
    <Doughnut :data="chartData" :options="options" :plugins="plugins" />
</template>
