<script setup lang="ts">
import { cva } from "class-variance-authority";
import { defineProps, computed } from "vue";

const styles = cva(["flex-1 rounded py-4 px-8"], {
    variants: {
        type: {
            info: "bg-blue-100",
            warning: "bg-yellow-100",
            error: "bg-red-100",
            success: "bg-green-100",
        },
    },
});

const titleStyles = cva([""], {
    variants: {
        type: {
            info: "text-gray-800",
            warning: "text-yelow-800",
            error: "text-red-800",
            success: "text-green-800",
        },
    },
});

const props = defineProps<{
    message?: string;
    title?: string;
    type: "info" | "warning" | "error" | "success";
}>();

const classes = computed(() => {
    return styles({
        type: props.type,
    });
});

const titleClasses = computed(() => {
    return titleStyles({
        type: props.type,
    });
});
</script>
<template>
    <div :class="classes">
        <p>
            <strong v-if="title" class="mr-2" :class="titleClasses">{{
                title
            }}</strong>
            <slot name="message">
                {{ message }}
            </slot>
        </p>
    </div>
</template>
