<template>
    <div
        class="fixed top-8 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-top"
        v-show="active"
    >
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div class="fixed inset-0 transition-opacity" v-if="active">
                <div class="absolute inset-0 bg-gray-900 opacity-75"></div>
            </div>
        </transition>
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="transition ease-in duration-200"
            leave-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
            <div
                class="bg-white rounded-md px-4 pt-5 pb-4 overflow-hidden shadow-md transform transition-all sm:max-w-md sm:w-full sm:p-6"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
                v-if="active"
            >
                <template v-if="status !== 'finished'">
                    <div class="sm:flex sm:items-start">
                        <div
                            class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"
                        >
                            <h3
                                class="text-lg leading-6 font-medium text-gray-900"
                                id="modal-headline"
                            >
                                Did we miss something?
                            </h3>
                            <p class="text-sm leading-5 text-gray-500">
                                Add ideas, feedback, or any thoughts to send to
                                our editors. Please include a timestamp from the
                                video if applicable.
                            </p>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 sm:pl-4">
                        <div class="mb-4 flex">
                            <label>
                                Timestamp
                                <input type="text" v-model="timestamp" />
                            </label>
                        </div>
                        <div>
                            <label>
                                Feedback on video lesson: <br />
                                {{ lessonName }}
                                <textarea
                                    class="bg-gray-100 text-base leading-5 rounded p-3 w-full h-32"
                                    v-model="description"
                                ></textarea>
                            </label>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-2 sm:pl-4">
                        <p class="text-xs italic leading-4 mb-6 text-gray-500">
                            Your user profile and information identifying the
                            lesson will be included in your feedback
                        </p>
                        <div class="flex gap-2 justify-end">
                            <span
                                class="mt-3 flex rounded sm:mt-0 sm:ml-3 sm:w-auto"
                            >
                                <button
                                    type="button"
                                    class="underline px-4 hover:bg-gray-100 rounded transition duration-200"
                                    @click="close"
                                >
                                    Cancel
                                </button>
                            </span>
                            <span class="flex rounded sm:w-auto">
                                <button
                                    v-if="status == 'ready'"
                                    type="button"
                                    class="main button px-4 py-2 text-base sm:text-sm leading-6 w-32 rounded-md"
                                    @click="submit"
                                >
                                    Submit
                                </button>
                                <button
                                    v-if="status == 'processing'"
                                    type="button"
                                    class="flex items-center justify-center primary button px-4 py-2 text-base sm:text-sm leading-6 w-32 rounded-md"
                                >
                                    <SvgIcon
                                        class="animate-spin"
                                        icon="loader"
                                    />
                                </button>
                                <button
                                    v-if="status == 'finished'"
                                    type="button"
                                    class="flex items-center justify-center primary button px-4 py-2 text-base sm:text-sm leading-6 w-32 rounded-md"
                                    @click="close"
                                >
                                    Thank you
                                </button>
                            </span>
                        </div>
                    </div>
                </template>
                <template v-if="status === 'finished'">
                    <div class="sm:flex sm:items-start">
                        <div
                            class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left space-y-2"
                        >
                            <h3
                                class="text-lg leading-6 font-medium text-gray-900"
                                id="modal-headline"
                            >
                                Thank you!
                            </h3>
                            <p class="text-sm leading-5 text-gray-500">
                                Than you for your feedback! Your input is valued
                                and will be used to improve our platform
                                content.
                            </p>
                            <p class="text-sm leading-5 text-gray-500">
                                For any issues not related to platform content
                                please contact us at
                                <a
                                    href="mailto:support@cloverlearning.com"
                                    class="text-blue-700 curosr:pointer hover:text-blue-700"
                                    >support@cloverlearning.com</a
                                >
                            </p>
                            <span class="flex rounded sm:w-auto justify-end">
                                <Button
                                    intent="flat"
                                    type="button"
                                    @click="close"
                                >
                                    Close
                                </Button>
                            </span>
                        </div>
                    </div>
                </template>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import Button from "@/components/ui/ButtonLinks/Button.vue";
import ButtonDialog from "@/components/ui/dialogs/ButtonDialog.vue";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        active: Boolean,
        initTimestamp: Number,
        lessonName: String,
        lessonId: String,
        moduleId: String,
        courseId: String,
    },
    data(): any {
        return {
            issue: "",
            description: "",
            status: "ready",
            timestamp: null,
            submitted: false,
        };
    },
    computed: {
        path() {
            return `/api/learn/lessons/feedback`;
        },
    },
    mounted() {},
    watch: {
        active() {
            if (this.active) {
                this.$nextTick(() => {
                    this.$refs.issue.focus();
                });
                // initTimestamp is in seconds convert to string like 02:30
                const minutes = Math.floor(this.initTimestamp / 60);
                const seconds = this.initTimestamp - minutes * 60;
                this.timestamp = `${minutes}:${seconds
                    .toString()
                    .padStart(2, "0")}`;
            }
        },
    },
    methods: {
        close() {
            this.reset();
            this.$emit("close");
        },
        reset() {
            this.issue = "";
            this.description = "";
            this.status = "ready";
            this.submitted = false;
        },
        submit(e) {
            e.preventDefault();
            if (!this.description) {
                return;
            }
            this.status = "processing";
            axios
                .post(this.path, {
                    lessonId: this.lessonId,
                    moduleId: this.moduleId,
                    courseId: this.courseId,
                    description: this.description,
                    timestamp: this.timestamp,
                })
                .then((response) => {
                    this.$events.$emit("toastNotification", {
                        action: "sent",
                        heading: "Success",
                        status: "sent",
                    });
                    this.status = "finished";
                })
                .catch((error) => {
                    if (error.response) {
                        console.log("RESPONSE ERROR");
                        console.log(error.response);
                    } else if (error.request) {
                        console.log("REQUEST ERROR");
                        console.log(error.request);
                    } else {
                        console.log("Error", error.message);
                    }
                    console.log("------");
                    console.log(error.config);
                });
        },
    },
    components: { ButtonDialog, Button },
});
</script>
