<template>
    <div>
        <template v-if="!loading">
            <div v-if="rows.length > 0">
                <div class="flex items-center justify-between text-center px-4">
                    <div class="text-lg font-medium underline">
                        Assessment Detail
                    </div>
                    <div class="flex">
                        <div
                            class="text-sm uppercase font-medium text-gray-600 w-22"
                        >
                            #
                        </div>
                        <div
                            class="text-sm uppercase font-medium text-gray-600 w-28"
                        >
                            Avg Score
                        </div>
                        <div
                            class="text-sm uppercase font-medium text-gray-600 w-36"
                        >
                            Avg Duration
                        </div>
                    </div>
                </div>
                <h3
                    class="flex items-center justify-between bg-gray-200 p-4 rounded-md shadow-inner mt-4 mb-12"
                >
                    <div class="flex flex-col py-2">
                        <div class="flex items-center text-sm mb-2">
                            <div>{{ assessment.module }}</div>
                            <SvgIcon
                                class="text-gray-400 mx-1"
                                icon="chevron-right"
                                size="sm"
                                weight="bold"
                            />
                            <div>{{ assessment.lesson }}</div>
                            <SvgIcon
                                class="text-gray-400 mx-1"
                                icon="chevron-right"
                                size="sm"
                                weight="bold"
                            />
                        </div>
                        <div class="font-semibold">{{ assessment.title }}</div>
                    </div>
                    <div class="flex items-center">
                        <div class="text-center w-22">
                            <div class="font-medium">
                                {{ assessment.taken }}
                            </div>
                        </div>
                        <div class="text-center w-28">
                            <div
                                class="font-medium"
                                v-html="
                                    $filters.grade(
                                        assessment.averageScore,
                                        settings.pulse_grade
                                    )
                                "
                            />
                        </div>
                        <div class="text-center w-36">
                            <div
                                class="font-medium"
                                v-html="
                                    $filters.elapsed(assessment.averageDuration)
                                "
                            />
                        </div>
                    </div>
                </h3>
                <!--                 <PulseAssessmentLearners
                    v-if="subject != 'learner'"
                    :rows="rows"
                />
                <PulseAssessmentAttempts
                    v-if="subject == 'learner'"
                    :rows="rows"
                />
                <PulseAssessmentQuestions
                    :assessment="assessment"
                    :total="assessmentData.taken"
                />
 -->
            </div>
            <div class="card p-4" v-if="rows.length == 0">No Assessments</div>
        </template>
        <Loader :show="loading" />
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState, mapGetters } from "vuex";

export default {
    data(): any {
        return {
            assessmentsAvgDuration: null,
            assessmentsAvgScore: null,
            assessmentsTaken: null,
            loading: true,
            rows: [],
        };
    },
    computed: {
        ...mapState(["id", "period", "subject", "settings", "type", "typeId"]),
        ...mapGetters(["context"]),
        typeContext() {
            return "&type=" + this.type + "&typeId=" + this.typeId;
        },
        typeIcon() {
            switch (this.type) {
                case "quizzes":
                    return "checklist";
                    break;
                case "assessments":
                    return "exam";
                    break;
            }
        },
        typeTitle() {
            switch (this.type) {
                case "quizzes":
                    return "Quiz";
                    break;
                case "assessments":
                    return "Assessment";
                    break;
            }
        },
    },
    methods: {
        getData() {
            axios
                .get("/api/pulse/assessments" + this.context + this.typeContext)
                .then((response) => {
                    this.assessment = response.data.assessment;
                    this.rows = response.data.rows;
                    this.loading = false;
                });
        },
    },
    mounted() {
        this.getData();
    },
};
</script>
