<template>
    <PulsePrepTabs>
        <template #exams>
            <PulsePrepExamActivity />
        </template>
        <template #assignments>
            <PulsePrepAssignmentActivity />
        </template>
    </PulsePrepTabs>
</template>
