<template>
    <div class="space-y-6">
        <input
            class="h-12"
            type="text"
            :name="name"
            :id="name"
            :value="videoPath"
            @input="handleInput"
        />
        <div v-if="videoId !== ''" class="shadow rounded-lg overflow-hidden">
            <WistiaSimple :id="videoId" :key="componentKey">
                <div
                    id="wistia-player"
                    class="wistia_embed shadow"
                    :class="'wistia_async_' + videoId"
                ></div>
            </WistiaSimple>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        name: String,
        videoPath: {
            type: String,
            default: "",
        },
    },
    data(): any {
        return {
            componentKey: 0,
            videoId: "",
        };
    },
    watch: {
        videoPath(val) {
            this.componentKey++;
            this.videoId = this.getVideoId(val);
        },
    },
    methods: {
        getVideoId(path) {
            return path ? path.substring(path.length - 10) : "";
        },
        handleInput(e) {
            this.$emit(
                "path-changed",
                e.target.value,
                this.getVideoId(e.target.value)
            );
        },
    },
};
</script>
