<template>
    <div
        class="flex items-center flex-wrap bg-white sm:bg-transparent sm:border-b border-gray-100 last:border-b-0 rounded-md sm:rounded-none shadow sm:shadow-none mb-4 sm:mb-0 sm:h-20"
    >
        <div class="flex items-center flex-shrink-0 px-4 w-full sm:w-48 h-20">
            <div>
                <div
                    :class="labels[row.type]"
                    class="inline-block text-xs font-medium rounded-md leading-6 mt-2 px-2"
                >
                    {{ types[row.type] }}
                </div>
                <div class="flex items-center text-xs leading-6">
                    <div v-text="$filters.dateTime(row.date, user.timezone)" />
                </div>
            </div>
        </div>
        <div
            class="flex items-center flex-shrink-0 text-sm truncate px-4 w-52 h-20"
        >
            <div class="space-y-1">
                <div class="capitalize">
                    <a class="cta" :href="link(row.learner.id)">
                        {{ row.learner.name }}
                    </a>
                </div>
                <div
                    v-for="group in row.learner.groups"
                    class="text-xs text-gray-600"
                >
                    <div>
                        <span>&ndash;</span>
                        <a
                            class="cta"
                            :href="link(group.id)"
                            v-text="group.name"
                        />
                    </div>
                </div>
            </div>
        </div>
        <template v-if="row.type == 'lesson'">
            <div class="text-sm mr-8 px-4 lg:w-60 xl:w-120">
                <div class="space-y-1">
                    <a
                        class="cta truncate"
                        :href="subjectLink"
                        v-text="row.subject"
                    />
                    <div
                        v-for="item in row.belongs_to"
                        class="text-xs text-gray-500"
                    >
                        <strong class="font-medium">{{ item.course }}</strong> -
                        {{ item.module }}
                    </div>
                </div>
            </div>
        </template>
        <template v-if="row.type != 'lesson'">
            <div class="flex flex-grow items-center justify-between">
                <div class="text-sm mr-8 px-4 lg:w-60 xl:w-120">
                    <div class="space-y-1">
                        <a
                            class="cta truncate"
                            :href="subjectLink"
                            v-text="row.subject"
                        />
                        <div
                            v-for="item in row.belongs_to"
                            class="text-xs text-gray-500"
                        >
                            <strong class="font-medium">{{
                                item.course
                            }}</strong>
                            <span v-if="item.module"> - {{ item.module }}</span>
                            <span v-if="item.lesson"> - {{ item.lesson }}</span>
                        </div>
                    </div>
                </div>
                <div class="flex items-center">
                    <div
                        class="flex items-center justify-center text-sm w-24 h-20"
                    >
                        <div v-html="$filters.elapsed(row.time)" />
                    </div>
                    <div
                        class="flex items-center justify-center text-sm w-28 h-20"
                    >
                        <div
                            class="font-medium"
                            v-html="
                                $filters.grade(row.grade, settings.pulse_grade)
                            "
                        />
                    </div>
                    <div class="w-20 flex text-sm items-center justify-center">
                        <a
                            class="flex items-center justify-center bg-gray-50 hover:bg-cyan-50 rounded-lg w-9 h-9"
                            :href="instanceLink"
                            v-tippy
                            content="View item"
                            target="_blank"
                        >
                            <SvgIcon icon="view" />
                        </a>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { mapState, mapGetters } from "vuex";

export default {
    props: {
        legacy: Boolean,
        row: Object,
    },
    data(): any {
        return {
            labels: {
                postCourse: "bg-yellow-100 text-yellow-700",
                assessment: "bg-green-100 text-green-700",
                builder: "bg-orange-100 text-orange-700",
                lesson: "bg-gray-100 text-gray-700",
                prep: "bg-red-100 text-red-700",
                quiz: "bg-indigo-100 text-indigo-700",
            },
            types: {
                postCourse: "Post-Course",
                assessment: "Assessment",
                builder: "Quiz Builder",
                lesson: "Lesson view",
                prep: "Prep",
                quiz: "Quiz",
            },
            typeSlugs: {
                postCourse: "postCourses",
                assessment: "assessments",
                lesson: "lessons",
                quiz: "quizzes",
            },
        };
    },
    computed: {
        ...mapState(["period", "settings", "subjectGroup", "user"]),
        ...mapGetters(["context"]),
        baseLink() {
            return this.legacy ? "/pulse/explore-legacy/" : "/pulse/explore/";
        },
        subjectLink() {
            return (
                this.baseLink +
                this.typeSlugs[this.row.type] +
                this.context +
                "&id=" +
                this.row.subject_id
            );
        },
        instanceLink() {
            if (this.row.type == "prep") {
                return "/prep/exams/" + this.row.uuid;
            }

            return (
                this.baseLink +
                this.typeSlugs[this.row.type] +
                "?subject=" +
                this.row.learner.id +
                "&period=" +
                this.period +
                "&id=" +
                this.row.subject_id +
                "&instance=" +
                this.row.instance_id
            );
        },
        subject() {
            if (this.row.subject) {
                return this.row.subject;
            }

            if (this.row.type == "quiz") {
                return "Untitled &ldquo;Quiz Builder&rdquo; quiz";
            }
        },
    },
    methods: {
        link(subject) {
            return (
                this.baseLink +
                "timeline" +
                "?subject=" +
                subject +
                "&period=" +
                this.period
            );
        },
    },
};
</script>
