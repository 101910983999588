<template>
    <div>
        <section class="section-main" :class="{ 'mx-4 mb-0': inModal }">
            <div class="card" :class="{ 'relative pt-16': question.flagged }">
                <div
                    class="absolute top-0 left-0 bg-red-600 rounded-t-md w-full py-6 px-8"
                    v-if="question.flagged"
                >
                    <div class="flex items-center leading-4 text-red-50">
                        <SvgIcon
                            class="text-red-50 mr-4"
                            icon="flag"
                            size="sm"
                            weight="bold"
                        />
                        <div>Flagged</div>
                    </div>
                </div>
                <input
                    type="hidden"
                    name="referrer"
                    :value="referrer"
                    v-if="referrer"
                />
                <div class="p-8">
                    <div class="flex flex-wrap">
                        <div
                            :class="{
                                'w-full': inModal,
                                'w-full xl:w-1/2 xl:pr-4': !inModal,
                            }"
                        >
                            <div class="field">
                                <label>Question</label>
                                <input
                                    type="hidden"
                                    name="question"
                                    :value="question.question"
                                />
                                <ContentEditor
                                    v-if="editors"
                                    v-model="question.question"
                                    :key="'question' + question.uuid"
                                    placeholder="Type question here…"
                                />
                            </div>
                            <div class="field image">
                                <label>Image</label>
                                <input
                                    type="hidden"
                                    name="question_image"
                                    :value="question.question_image"
                                />
                                <ImageSelector
                                    v-model="question.question_image"
                                />
                            </div>
                            <div v-if="questionType == 'prep'" class="field">
                                <label>Categories</label>
                                <input
                                    type="hidden"
                                    name="categories"
                                    :value="JSON.stringify(question.categories)"
                                />
                                <PrepCategoryFilter
                                    look="flat"
                                    v-model="question.categories"
                                />
                            </div>
                            <div class="field">
                                <label>Feedback</label>
                                <input
                                    type="hidden"
                                    name="feedback"
                                    :value="question.feedback"
                                />
                                <ContentEditor
                                    v-if="editors"
                                    v-model="question.feedback"
                                    :key="'feedback' + question.uuid"
                                    placeholder="Type feedback here…"
                                />
                            </div>
                            <div class="field">
                                <label>Internal Notes</label>
                                <textarea
                                    type="hidden"
                                    name="internal_notes"
                                    :value="question.internal_notes"
                                ></textarea>
                            </div>
                        </div>
                        <div
                            :class="{
                                'w-full mt-8': inModal,
                                'w-full mt-8 xl:w-1/2 xl:pl-4 xl:mt-0':
                                    !inModal,
                            }"
                        >
                            <div class="field">
                                <label>Answers</label>
                                <div>
                                    <div>
                                        <input
                                            type="hidden"
                                            name="answers"
                                            :value.json="
                                                JSON.stringify(question.answers)
                                            "
                                        />
                                        <draggable
                                            v-model="question.answers"
                                            item-key="uuid"
                                            handle=".drag-handle"
                                            group="answers"
                                            @start="drag = true"
                                            @end="drag = false"
                                        >
                                            <template
                                                #item="{ element: answer }"
                                            >
                                                <div
                                                    class="flex items-center mb-4 answer-wrapper"
                                                >
                                                    <div
                                                        class="relative flex-grow border-t-6 rounded-t-lg"
                                                        :class="{
                                                            'border-green-500':
                                                                answer.checked,
                                                            'border-red-500':
                                                                !answer.checked,
                                                        }"
                                                    >
                                                        <div
                                                            class="flex items-center absolute top-0 left-0 ml-2 h-13"
                                                        >
                                                            <div
                                                                class="drag-handle text-gray-300 -mt-px"
                                                            >
                                                                <SvgIcon
                                                                    icon="dots"
                                                                    size="sm"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="flex items-center absolute top-0 right-0 mr-3"
                                                            style="
                                                                margin-top: 15px;
                                                            "
                                                        >
                                                            <div
                                                                class="flex items-center"
                                                            >
                                                                <SvgIcon
                                                                    class="mr-2 -mt-px"
                                                                    :class="{
                                                                        'text-gray-300':
                                                                            answer.checked,
                                                                        'text-red-600':
                                                                            !answer.checked,
                                                                    }"
                                                                    icon="wrong"
                                                                    size="base"
                                                                    weight="medium"
                                                                />
                                                                <ToggleSwitch
                                                                    look="correct"
                                                                    v-model="
                                                                        answer.checked
                                                                    "
                                                                />
                                                                <SvgIcon
                                                                    class="ml-2 -mt-p"
                                                                    :class="{
                                                                        'text-green-600':
                                                                            answer.checked,
                                                                        'text-gray-300':
                                                                            !answer.checked,
                                                                    }"
                                                                    icon="check"
                                                                    size="base"
                                                                    weight="medium"
                                                                />
                                                            </div>
                                                            <button
                                                                type="button"
                                                                class="text-sm bg-white hover:bg-gray-100 text-red-600 border rounded-full p-1 shadow-none button ml-4"
                                                                @click="
                                                                    removeAnswer(
                                                                        answer.uuid
                                                                    )
                                                                "
                                                            >
                                                                <SvgIcon
                                                                    icon="minus"
                                                                    size="xs"
                                                                    weight="bold"
                                                                />
                                                            </button>
                                                        </div>
                                                        <ContentEditor
                                                            v-if="editors"
                                                            v-model="
                                                                answer.answer
                                                            "
                                                            :key="
                                                                'answer' +
                                                                answer.uuid
                                                            "
                                                            placeholder="Type answer here…"
                                                        />
                                                    </div>
                                                </div>
                                            </template>
                                        </draggable>
                                        <div
                                            class="flex items-center justify-between answer-wrapper mt-2"
                                        >
                                            <button
                                                type="button"
                                                class="transition duration-300 flex items-center bg-gray-100 hover:bg-gray-50 border border-gray-200 text-gray-800 py-2 px-4 text-sm leading-7 rounded-lg"
                                                @click="addAnswer()"
                                            >
                                                <SvgIcon
                                                    icon="add"
                                                    size="sm"
                                                    weight="medium"
                                                />
                                                <div class="ml-4">
                                                    Add Answer
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex items-start border-t p-8">
                    <div class="mr-6 xl:mr-8">
                        <label>Status</label>
                        <input
                            type="hidden"
                            name="status"
                            :value="question.status"
                        />
                        <SelectDropdown
                            v-model="question.status"
                            theme="flat"
                            variant="status"
                        >
                            <option value="published">Published</option>
                            <option value="draft">Draft</option>
                            <option value="closed">Closed</option>
                        </SelectDropdown>
                    </div>
                    <div class="mr-6 xl:mr-8">
                        <label>Access</label>
                        <input
                            type="hidden"
                            name="access"
                            :value="question.access"
                        />
                        <SelectDropdown
                            v-model="question.access"
                            theme="flat"
                            variant="access"
                        >
                            <option value="open">Open</option>
                            <option value="reserved">Reserved</option>
                        </SelectDropdown>
                    </div>
                    <div class="mr-6 xl:mr-8">
                        <label>Edit Notes - Used to add reason for edit.</label>
                        <textarea
                            class="border border-gray-200 p-4 rounded-lg h-24"
                            :class="{
                                'w-96': inModal,
                                'w-96 xl:w-160': !inModal,
                            }"
                            name="note"
                            v-model="note"
                        >
                        </textarea>
                    </div>
                    <div>
                        <label>Flag Question</label>
                        <input
                            type="hidden"
                            name="flagged"
                            :value="question.flagged"
                        />
                        <ToggleSwitch look="flag" v-model="question.flagged" />
                    </div>
                </div>
                <div
                    class="flex items-center justify-between bg-gray-50 border-t border-gray-100 rounded-b-md p-8"
                >
                    <div class="flex items-center">
                        <button
                            v-if="!inModal"
                            type="button"
                            class="main button mr-8"
                            @click="saveCheck"
                        >
                            Save Question
                        </button>
                        <button
                            type="button"
                            class="main button"
                            @click="save"
                            v-if="inModal"
                        >
                            <span v-if="!saving">Save Question</span>
                            <span v-if="saving">Saving&hellip;</span>
                        </button>
                        <a
                            class="cta"
                            :href="
                                '/admin/content/' + questionType + '/questions'
                            "
                            v-if="!inModal"
                            >Cancel</a
                        >
                    </div>
                    <div
                        class="flex items-center"
                        v-if="type == 'edit' && !inModal"
                    >
                        <button
                            class="flex items-center justify-center bg-white transition-all hover:bg-red-50 border border-red-400 rounded-full shadow w-12 h-12"
                            type="button"
                            @click="showDelete = true"
                        >
                            <SvgIcon
                                class="text-red-700"
                                icon="remove"
                                size="sm"
                            />
                        </button>
                        <ConfirmDeleteModal
                            :active="showDelete"
                            @close="showDelete = false"
                            @confirm="confirmDelete"
                        />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, nextTick, onMounted, ref } from "vue";
import axios from "axios";
import draggable from "vuedraggable";
import { v4 as uuidv4 } from "uuid";
import $events from "@/event-bus";
import ContentEditor from "@/components/_elements/ContentEditor.vue";

export default defineComponent({
    components: {
        draggable,
        ContentEditor,
    },
    props: {
        data: {
            type: Object,
            default: () => {},
        },
        questionType: {
            type: String,
            default: "prep",
        },
        type: {
            type: String,
            default: "create",
        },
        inModal: {
            type: Boolean,
            default: false,
        },
        category: {
            type: Object,
            default: () => null,
        },
        referrer: {
            type: String,
            default: null,
        },
    },
    setup(props) {
        const data: any = ref(props.data || null);
        const questionType: any = ref(props.questionType || "prep");
        const type: any = ref(props.type || "create");
        const inModal: any = ref(props.inModal || false);
        const drag = ref(false);
        const emptyQuestion: any = {
            access: "open",
            answers: [],
            categories: [],
            feedback: null,
            flagged: false,
            history: [],
            question: null,
            question_image: null,
            status: "closed",
            uuid: null,
        };
        const editors: any = ref(false);
        const note: any = ref(null);
        const question: any = ref({});
        const saving: any = ref(false);
        const showDelete: any = ref(false);
        const path: any = computed(
            () => "/api/" + questionType.value + "/questions/"
        );
        const referrer = computed(() => {
            const params = new URLSearchParams(window.location.search);
            return params.get("referrer");
        });
        const confirmDelete = () => {
            (window.location as any) = window.location.href + "/delete";
        };
        const addAnswer = () => {
            question.value.answers.push({
                uuid: uuidv4(),
                answer: "",
                checked: false,
            });
        };
        const registerListeners = () => {
            $events.$on("slideoverDismissed", () => {
                setTimeout(() => resetQuestion(), 500);
            });
            $events.$on("editQuestion", (question) => {
                question.value = question;
            });
            $events.$on("prepQuestionHistoryItemRemoved", (historyItem) => {
                question.value.history.splice(
                    question.value.history.indexOf(historyItem),
                    1
                );
            });
        };
        const removeAnswer = (uuid: string) => {
            // find answer with uuid and remove it
            question.value.answers.splice(
                question.value.answers.findIndex(
                    (answer) => answer.uuid == uuid
                ),
                1
            );
        };
        const resetQuestion = () => {
            question.value = { ...emptyQuestion };
            note.value = null;
        };
        const save = () => {
            if (!saving.value) {
                saving.value = true;
                axios
                    .put(path.value + question.value.uuid, {
                        question: question.value,
                        note: note.value,
                        referrer: "/admin-portal/prep/banks/18/categories/2419",
                    })
                    .then((response) => {
                        saving.value = false;
                        $events.$emit("questionEdited", response.data);
                    });
            }
        };
        const saveCheck = () => {
            (document.getElementById("questionForm") as any)?.submit();
        };
        onMounted(() => {
            if (data.value) {
                question.value = data.value;
                console.log("value");
            } else {
                question.value = { ...emptyQuestion };
            }
            if (props.category) {
                question.value.categories.push(props.category);
            }
            registerListeners();
            nextTick(() => (editors.value = true));
        });
        return {
            data,
            drag,
            questionType,
            type,
            inModal,
            emptyQuestion,
            editors,
            note,
            question,
            saving,
            showDelete,
            path,
            referrer,
            confirmDelete,
            addAnswer,
            registerListeners,
            removeAnswer,
            resetQuestion,
            save,
            saveCheck,
        };
    },
});
</script>

<style scoped>
.image img {
    max-height: 240px;
}

.image input {
    @apply bg-gray-100 border leading-5 !important;
}

.answer {
    @apply bg-white border leading-5 p-3 !important;
}
</style>
