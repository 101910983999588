<template>
    <div>
        <PulseCourseEngagementCard
            :course="course"
            :module="null"
            :viewingAs="type"
            class="mb-9"
            v-if="course !== null"
        />

        <PulseLearnTabs>
            <template #overview>
                <PulseLearnCourseOverview />
            </template>
            <template #activity>
                <PulseLearnCourseActivity />
            </template>
        </PulseLearnTabs>
    </div>
</template>

<script lang="ts">
import { mapState } from "vuex";
import axios from "axios";
import { getCoursesEngagement } from "../../../api/pulse-requests";

export default {
    data(): any {
        return {
            course: null,
        };
    },
    methods: {
        getCourse() {
            console.log("Get Course for page.");
            // get url param named 'course' from window.location
            let urlParams = new URLSearchParams(window.location.search);
            let course = urlParams.get("course");
            getCoursesEngagement(
                "courses",
                this.partner,
                this.group,
                this.period,
                course,
                []
            )
                .then((response) => {
                    this.course = response.data.data[0];
                    console.log(this.course);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    computed: {
        ...mapState(["type", "partner", "group", "period"]),
    },
    mounted() {
        this.getCourse();
    },
};
</script>
