<template>
    <li
        class="relative"
        :class="[
            {
                highlighted: highlighted,
            },
        ]"
    >
        <slot />
    </li>
</template>

<script lang="ts">
export default {
    name: "LessonListing",
    props: {
        lessonUuid: String,
        highlightByDefault: {
            type: Boolean,
            default: false,
        },
    },
    data(): any {
        return {
            highlighted: this.highlightByDefault,
            lesson_uuid: this.lessonUuid,
        };
    },
    mounted() {
        this.$events.$on("highlightLesson", (data) => {
            if (this.lesson_uuid == data.uuid) {
                this.highlighted = true;
            } else {
                this.highlighted = false;
            }
        });
    },
};
</script>

<style>
.highlighted > a {
    @apply ring ring-cyan-300 transition duration-200;
}
</style>
