<template>
    <div class="bg-white rounded-md shadow w-full max-w-3xl">
        <div class="bg-gray-50 border-b border-gray-100 rounded-t-md p-8">
            <div class="flex items-center justify-between">
                <h3 class="text-xl font-bold">{{ date }}</h3>
                <div class="flex items-center rounded-lg">
                    <div
                        class="text-sm font-medium leading-8 rounded-l-lg px-3"
                        :class="productTheme.product"
                    >
                        {{ product }}
                    </div>
                    <div
                        class="text-sm leading-8 rounded-r-lg px-3"
                        :class="productTheme.release"
                    >
                        v{{ release }}
                    </div>
                </div>
            </div>
        </div>
        <div class="p-8">
            <div class="prose">
                <slot />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    props: {
        date: String,
        product: String,
        release: String,
    },
    data(): any {
        return {
            themes: {
                Platform: {
                    product: "bg-cyan-700 text-white",
                    release: "bg-cyan-100 text-cyan-800",
                },
                Prep: {
                    product: "bg-pink-700 text-white",
                    release: "bg-pink-100 text-pink-800",
                },
                Pulse: {
                    product: "bg-blue-700 text-white",
                    release: "bg-blue-100 text-blue-800",
                },
            },
        };
    },
    computed: {
        productTheme() {
            return this.themes[this.product];
        },
    },
};
</script>
