<script setup lang="ts">
import { defineProps, defineEmits, onMounted, ref, watch } from "vue";

const value = ref("");

const props = defineProps<{
    modelValue: string;
    label: string;
    name: string;
    error?: string[] | null;
    id?: string;
    type: string;
}>();

const emits = defineEmits<{
    (event: "update:modelValue", value: string): void;
}>();

onMounted(() => {
    value.value = props.modelValue;
});

watch(value, (newValue) => {
    emits("update:modelValue", newValue);
});

const id = props.id || `input-${Math.random().toString(36).substr(2, 9)}`;
</script>
<template>
    <div>
        <label class="text-sm mb-2" :for="id">{{ label }}</label>
        <input
            :type="type"
            :id="id"
            :name="name"
            :label="label"
            v-model="value"
            :class="{
                '!border-red-700': error,
            }"
        />
        <span class="text-red-700 text-xs" v-if="error">{{ error?.[0] }}</span>
    </div>
</template>
