<script setup lang="ts">
import { useSearchStore } from "../../stores/searchStore";
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
} from "@headlessui/vue";
import { storeToRefs } from "pinia";

const searchStore = useSearchStore();
const { open, close } = searchStore;
const { isOpen, searchResults } = storeToRefs(searchStore);
const currentRoute = window.location.href;
const isAdminRoute = currentRoute.includes("admin-portal");

const getUrlForResult = function(result) {
     if (!isAdminRoute) {
        return `/learn/courses/${result.fullSlug}`;
     }

     if (result.type === 'course') {
        return `/admin-portal/content/learn/courses/${result.objectID}/edit`;
     }

    if (result.type === 'module') {
        return `/admin-portal/content/learn/modules/${result.objectID}/edit`;
    }

    if (result.type === 'lesson') {
        return `/admin/content/learn/lessons/${result.slug}`;
    }

    return '';
}

const calculateLine2 = (item) => {
    if (item.level === 0) {
        return "";
    }
    if (item.level === 1) {
        return item?.hierarchy?.lvl0 ?? "";
    }
    if (item.level === 2) {
        return `${item?.hierarchy?.lvl0 ?? ""}:  ${
            item?.hierarchy?.lvl1 ?? ""
        }`;
    }
};
</script>
<template>
    <TransitionRoot appear :show="isOpen" as="template">
        <Dialog as="div" @close="close" class="relative z-80">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black bg-opacity-25" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div
                    class="flex flex-col items-center justify-center p-0 md:p-12 text-center h-screen md:h-auto md:max-h-full overflow-hidden"
                >
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                            class="flex flex-col w-full flex-1 md:w-1/2 transform overflow-hidden rounded bg-white text-left align-middle shadow-md transition-all"
                        >
                            <SearchInputBox />
                            <div class="bg-gray-50 flex-1 overflow-y-auto">
                                <SearchList>
                                    <SearchListItem
                                        v-for="(item, index) in searchResults"
                                        :index="index"
                                        :key="item.objectID"
                                        :value="item.objectID"
                                        :line1="item.title"
                                        :line2="calculateLine2(item)"
                                        :type="item.type"
                                        :path="getUrlForResult(item)"
                                    />
                                </SearchList>
                                <template v-if="searchResults.length === 0">
                                    <div
                                        class="flex justify-center align-items-center text-gray-500 text-sm h-44"
                                    >
                                        <div class="m-auto">
                                            No results found
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div
                                class="hidden md:flex items-center px-4 py-2 space-x-4 text-xs"
                            >
                                <div class="flex items-center space-x-1.5">
                                    <div
                                        class="bg-gray-600 text-white w-5 h-5 flex justify-center items-center rounded-sm text-xs"
                                    >
                                        ↩
                                    </div>
                                    <span>to select</span>
                                </div>
                                <div class="flex items-center space-x-1.5">
                                    <div class="space-x-0.5 flex items-center">
                                        <div
                                            class="bg-gray-600 text-white w-5 h-5 flex justify-center items-center rounded-sm"
                                        >
                                            ↑
                                        </div>
                                        <div
                                            class="bg-gray-600 text-white w-5 h-5 flex justify-center items-center rounded-sm"
                                        >
                                            ↓
                                        </div>
                                    </div>
                                    <span>to navigate</span>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
