<script setup lang="ts">
import { defineProps, onMounted, onUnmounted, ref } from "vue";
import CourseBadge from "./CourseBadge.vue";
import ModuleBadge from "./ModuleBadge.vue";
import LessonBadge from "./LessonBadge.vue";
import { useSearchStore } from "../../stores/searchStore";
// list item prop
const props = defineProps({
    value: {
        type: String,
        required: true,
    },
    line1: {
        type: String,
        required: true,
    },
    line2: {
        type: String,
        required: true,
    },
    path: {
        type: String,
        required: true,
    },
    type: {
        type: String,
    },
    index: {
        type: Number,
    },
});
const selected = ref(false);
const searchStore = useSearchStore();

const unselect = () => {
    selected.value = false;
};

const select = () => {
    selected.value = true;
};

onMounted(() => {
    searchStore.addListItemRef(props.value, {
        unselect,
        select,
    });
});

// on unmount
onUnmounted(() => {
    searchStore.removeListItemRef(props.value);
});

const clearFocusIfNotSelected = () => {
    if (!selected.value) {
        searchStore.clearCurrentSelection();
    }
};

const goToResult = (e) => {
    searchStore.goToResult(props.path, props.index, props.value);
};
</script>
<template>
    <li class="px-2 pt-2.5 last:pb-2.5">
        <a
            :href="path"
            @focus="clearFocusIfNotSelected"
            @click.prevent="goToResult"
            @mouseover="clearFocusIfNotSelected"
            :aria-selected="selected"
            class="rounded flex bg-white shadow-sm hover:bg-blue-700 hover:text-white hover:no-underline py-2 px-3 focus:bg-blue-700 focus:text-white aria-selected:bg-blue-700 aria-selected:text-white group items-center space-x-4"
        >
            <div class="flex flex-col w-full">
                <div
                    class="flex flex-row items-start w-full justify-between md:justify-start space-x-2"
                >
                    <div class="font-bold">{{ line1 }}</div>
                    <CourseBadge v-if="type === 'course'" />
                    <ModuleBadge v-if="type === 'module'" />
                    <LessonBadge v-if="type === 'lesson'" />
                </div>
                <div class="text-sm">{{ line2 }}</div>
            </div>
            <div
                class="hidden md:group-hover:flex md:group-focus:flex md:group-aria-selected:flex"
            >
                <SvgIcon
                    class="text-white"
                    icon="arrow-right-2"
                    size="md"
                    weight="light"
                />
            </div>
        </a>
    </li>
</template>
