<template>
    <div
        class="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-top"
        v-show="active"
    >
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-200"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div class="fixed inset-0 transition-opacity" v-if="active">
                <div class="absolute inset-0 bg-gray-900 opacity-75"></div>
            </div>
        </transition>
        <transition
            enter-active-class="transition ease-out duration-200"
            enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="transition ease-in duration-200"
            leave-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
            <div
                class="bg-white rounded-md overflow-hidden shadow-md transform transition-all sm:max-w-2xl sm:w-full"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
                v-if="active"
                v-click-away="close"
            >
                <div class="p-6">
                    <div class="flex items-start justify-between">
                        <div>
                            <h3
                                class="text-lg leading-6 font-medium text-gray-900"
                                id="modal-headline"
                            >
                                Assignees
                            </h3>
                            <p class="text-sm leading-5 text-gray-500">
                                Which groups and individuals have access to this
                                assignment?
                            </p>
                        </div>
                        <button @click="close">
                            <SvgIcon icon="close" size="sm" weight="bold" />
                        </button>
                    </div>
                    <div class="mt-6">
                        <div
                            v-for="assignable in assignment.assignables"
                            class="flex items-center justify-between bg-gray-50 rounded-lg mb-2 last:mb-0 p-4"
                        >
                            <div>{{ assignable.name }}</div>
                            <button
                                class="flex items-center justify-center bg-white hover:bg-red-50 border border-red-400 rounded-lg transition w-7 h-7"
                                @click="removeAssignable(assignable)"
                            >
                                <SvgIcon
                                    class="text-red-600"
                                    icon="close"
                                    size="sm"
                                />
                            </button>
                        </div>
                        <div
                            v-if="assignment.assignables.length === 0"
                            class="mt-6 py-3"
                        >
                            No assignees
                        </div>
                    </div>
                </div>
                <div
                    class="bg-gray-50 border-t border-gray-100 rounded-b-md p-6"
                >
                    <div
                        v-if="!assignableFieldsVisible"
                        class="flex items-center"
                    >
                        <button
                            class="medium primary button mr-6"
                            type="button"
                            @click="assignableFieldsVisible = true"
                        >
                            <SvgIcon icon="add" size="sm" weight="bold" />
                        </button>
                        <div class="leading-12">Add new Assignee</div>
                    </div>
                    <div
                        v-if="assignableFieldsVisible"
                        class="flex items-center justify-between"
                    >
                        <div class="flex items-center">
                            <AssignmentGroupFields
                                class="space-x-6"
                                v-model="assignee"
                            />
                            <div class="leading-12">&nbsp;</div>
                        </div>
                        <button
                            class="medium primary button"
                            type="button"
                            @click="addAssignable"
                        >
                            Add
                        </button>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts">
import axios from "axios";

export default {
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        assignment: Object,
    },
    data(): any {
        return {
            assignableFieldsVisible: false,
            assignee: null,
        };
    },
    computed: {
        assignable() {
            return this.assignee ? this.assignee.split("|") : null;
        },
    },
    methods: {
        close() {
            this.$emit("close");
        },
        addAssignable() {
            axios
                .post(
                    "/api/prep/assignments/" +
                        this.assignment.uuid +
                        "/assignees",
                    {
                        group: this.assignable[0],
                        assignable: this.assignable[1],
                    }
                )
                .then((response) => {
                    window.location.reload();
                });
        },
        removeAssignable(assignable) {
            axios
                .delete(
                    "/api/prep/assignments/" +
                        this.assignment.uuid +
                        "/assignees/" +
                        assignable.pivot.assignable_type
                            .substring(4)
                            .toLowerCase() +
                        "/" +
                        assignable.pivot.assignable_id
                )
                .then((response) => {
                    window.location.reload();
                });
        },
    },
};
</script>
