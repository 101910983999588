<script setup lang="ts">
import { useIsLoading } from "@/components/ui/loaders/loadingStore";

/**
 * Full Page Loading Indicator
 */
const isLoading = useIsLoading();
</script>
<template>
    <div
        v-if="isLoading"
        class="fixed inset-0 bg-white opacity-75 z-100 flex items-center justify-center"
    >
        <div class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</template>

<style scoped>
.spinner-border {
    display: inline-block;
    width: 3rem;
    height: 3rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border 0.75s linear infinite;
    animation: spinner-border 0.75s linear infinite;
}

@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}
</style>
