<template>
    <div>
        <section class="section-head">
            <page-title
                app="Prep"
                section="Categories"
                sectionPath="/prep/categories"
                action="Edit"
                icon="sort"
            />
        </section>
        <section class="section-main">
            <form :action="'/prep/categories/' + category.uuid" method="POST">
                <div class="card">
                    <div class="p-4">
                        <div class="field">
                            <label for="category">Category Name</label>
                            <input
                                id="category"
                                name="category"
                                type="text"
                                required
                                :value="category.category"
                            />
                        </div>
                        <div class="field">
                            <label for="parent">Category Parent</label>
                            <div class="select">
                                <select-dropdown
                                    name="parent_id"
                                    v-model="category.parent_id"
                                >
                                    <option disabled>Select a Parent</option>
                                    <option
                                        v-for="parent in parents"
                                        :value="parent.id"
                                    >
                                        {{ parent.category }}
                                    </option>
                                </select-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="bg-gray-100 shadow-inner rounded-b p-4">
                        <input type="hidden" name="_token" v-model="token" />
                        <input type="hidden" name="_method" value="PATCH" />
                        <button type="submit" class="button primary">
                            Save
                        </button>
                    </div>
                </div>
            </form>
        </section>
    </div>
</template>

<!--
    TODO:
    have the actual parent cat "selected" in dropdown
    It would be so much easier if the 'parent_id' was just the 'uuid'. Very difficult to work with as is.
-->

<script lang="ts">
export default {
    props: {
        category: {
            type: Object,
            default: () => {},
        },
        parents: {
            type: Array,
            default: () => {},
        },
    },
    data(): any {
        return {
            token: document
                .querySelector('meta[name="token"]')
                ?.getAttribute("content"),
        };
    },
};
</script>
