<template>
    <div
        class="flex items-center justify-between"
        :class="{ 'py-3 bg-white lg:py-6': showBg }"
    >
        <div class="flex justify-between flex-1 md:hidden">
            <button
                v-if="hasPrevious"
                @click="pageChanged(currentPage - 1)"
                class="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
                Previous
            </button>
            <button
                v-if="hasNext"
                @click="pageChanged(currentPage + 1)"
                class="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
                Next
            </button>
        </div>

        <div
            class="hidden md:flex-1 md:flex md:items-center md:justify-between"
        >
            <div>
                <p class="text-sm text-gray-700">
                    Showing
                    {{ " " }}
                    <span class="font-medium">{{ from }}</span>
                    {{ " " }}
                    to
                    {{ " " }}
                    <span class="font-medium">{{ to }}</span>
                    {{ " " }}
                    of
                    {{ " " }}
                    <span class="font-medium">{{ total }}</span>
                    {{ " " }}
                    results
                </p>
            </div>
            <div>
                <nav
                    class="relative z-0 inline-flex -space-x-px rounded-md"
                    aria-label="Pagination"
                >
                    <button
                        @click="pageChanged(currentPage - 1)"
                        class="relative inline-flex items-center px-3 py-2 text-sm font-medium bg-white border border-gray-300 rounded-l-md hover:bg-gray-50"
                        :class="[
                            hasPrevious ? 'text-blue-600' : 'text-gray-400',
                            {
                                'pointer-events-none cursor-default':
                                    !hasPrevious,
                            },
                        ]"
                    >
                        <span class="sr-only">Previous</span>
                        <SvgIcon
                            icon="chevron-left"
                            class="w-5 h-5"
                            aria-hidden="true"
                            size="xs"
                            weight="black"
                        />
                    </button>

                    <button
                        v-for="n in paginationPages"
                        :key="n === truncator ? Math.random() : n"
                        @click="pageChanged(n)"
                        :aria-current="n === currentPage"
                        class="relative inline-flex items-center px-4 py-2 text-sm font-medium text-blue-600 border first:rounded-l-md last:rounded-r-md"
                        :class="[
                            {
                                'pointer-events-none cursor-default':
                                    n === truncator,
                            },
                            n === currentPage
                                ? 'z-10 bg-blue-50 border-blue-600'
                                : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50',
                        ]"
                    >
                        {{ n }}
                    </button>

                    <button
                        @click="pageChanged(currentPage + 1)"
                        class="relative inline-flex items-center px-3 py-2 text-sm font-medium bg-white border border-gray-300 rounded-r-md hover:bg-gray-50"
                        :class="[
                            hasNext ? 'text-blue-600' : 'text-gray-400',
                            { 'pointer-events-none cursor-default': !hasNext },
                        ]"
                        :aria-disabled="!hasNext"
                    >
                        <span class="sr-only">Next</span>
                        <SvgIcon
                            icon="chevron-right"
                            class="w-5 h-5"
                            aria-hidden="true"
                            size="xs"
                            weight="black"
                        />
                    </button>
                </nav>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";

export default {
    props: {
        currentPage: {
            type: Number,
            default: 1,
            required: false,
        },
        from: Number,
        to: Number,
        lastPage: Number,
        perPage: Number,
        total: Number,
        showBg: {
            type: Boolean,
            default: true,
        },
    },
    data(): any {
        return {
            truncator: "...",
        };
    },
    computed: {
        paginationPages: function () {
            if (this.total / this.perPage <= 5) {
                return this.lastPage;
            }

            let pages: any[] = [];

            if (this.currentPage < 5) {
                for (let i = 1; i < 6; i++) {
                    pages.push(i);
                }

                this.appendLastPages(pages);
            } else if (this.lastPage - this.currentPage < 4) {
                this.appendFirstPages(pages);

                for (let i = -4; i < 1; i++) {
                    pages.push(this.lastPage + i);
                }
            } else {
                this.appendFirstPages(pages);

                for (let i = -1; i < 2; i++) {
                    pages.push(this.currentPage + i);
                }

                this.appendLastPages(pages);
            }

            return pages;
        },
        hasPrevious: function () {
            return this.currentPage > 1;
        },
        hasNext: function () {
            return this.currentPage < this.lastPage;
        },
    },
    methods: {
        pageChanged(page) {
            this.$emit("page-updated", page);
        },
        appendLastPages(subjectArray) {
            subjectArray.push(this.truncator, this.lastPage);
        },
        appendFirstPages(subjectArray) {
            subjectArray.push(1, this.truncator);
        },
    },
};
</script>
