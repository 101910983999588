<template>
    <div class="flashcards w-80">
        <div
            type="button"
            class="flashcard relative cursor-pointer transition duration-500 h-120 w-80"
            :class="{
                flipped: flipped,
            }"
            @click="flipCard"
        >
            <div
                class="front flex items-center justify-center absolute inset-0 overflow-hidden bg-white border-2 border-gray-200 text-2xl font-medium rounded-md p-8 w-full h-full"
                ref="front"
            >
                <div v-html="flashcard.front" />
            </div>
            <div
                class="back flex items-center justify-center absolute inset-0 overflow-hidden bg-blue-50 border-2 border-blue-200 text-2xl font-medium rounded-md p-8 w-full h-full"
                ref="back"
            >
                <div class="prose" v-html="flashcard.back" />
            </div>
        </div>
        <div v-if="false" class="flex justify-around items-center mt-4">
            <div @click="markIncorrect">
                <svg-icon
                    icon="wrong"
                    size="md"
                    type="line"
                    weight="normal"
                    class="cursor-pointer stroke-current text-red-400"
                ></svg-icon>
            </div>
            <div @click="markCorrect">
                <svg-icon
                    icon="check"
                    size="md"
                    type="line"
                    weight="normal"
                    class="cursor-pointer stroke-current text-green-400"
                ></svg-icon>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    name: "Flashcard",

    props: {
        flashcard: {
            type: Object,
            required: true,
        },
    },

    data(): any {
        return {
            flipped: false,
            didFlip: false,
        };
    },

    methods: {
        flipCard() {
            this.flipped = !this.flipped;
            this.didFlip = true;
            this.$emit("flip");
        },
        markCorrect() {
            this.emitResult("correct");
        },
        markIncorrect() {
            this.emitResult("incorrect");
        },
        emitResult(status) {
            this.$emit("result", { status, didFlip: this.didFlip });
        },
    },
};
</script>

<style scoped>
.flashcards {
    perspective: 1800px;
}

.flashcard {
    transform-style: preserve-3d;
}

.flashcard > div {
    backface-visibility: hidden;
}

.flashcard.flipped,
.flashcard .back {
    transform: rotateY(180deg);
}
</style>
