<template>
    <dropdown
        :modelValue="selectedCourse"
        variant="white"
        :context-string="''"
        width-class="w-72"
    >
        <dropdown-item variant="white" @click.native="handleClick('all')">
            <span
                class="text-sm capitalize"
                :class="{ 'font-bold': isActive('all') }"
                >All</span
            >
        </dropdown-item>

        <dropdown-item
            v-for="(value, index) in values"
            :key="index"
            variant="white"
            @click.native="handleClick(value)"
        >
            <span
                class="text-sm capitalize"
                :class="{ 'font-bold': isActive(value.uuid) }"
            >
                {{ value.title }}
            </span>
        </dropdown-item>
    </dropdown>
</template>

<script lang="ts">
import axios from "axios";
import { mapGetters, mapState } from "vuex";

export default {
    data(): any {
        return {
            values: undefined,
            selected: "all",
        };
    },
    computed: {
        ...mapState(["course"]),
        selectedCourse() {
            if (this.selected === null) {
                return "All Courses";
            }
            return this.values?.find(
                (course) => course.uuid === this.selected?.uuid
            )?.title;
        },
    },
    methods: {
        isActive(value) {
            return value === this.selected;
        },
        handleClick(value) {
            // if value not changed
            if (value?.uuid === this.selected?.uuid) {
                return;
            }

            if (value === "all") {
                this.selected = null;
                this.$store.commit("registerState", { course: null });
            } else {
                this.selected = value;
                this.$store.commit("registerState", {
                    course: this.selected?.uuid,
                });
            }
        },
    },
    mounted() {
        if (!this.values) {
            axios.get("/api/courses").then((response) => {
                this.values = response.data;
                this.values.sort((a, b) => a.title.localeCompare(b.title));
                this.selected =
                    this.values.find((course) => course.uuid === this.course) ||
                    null;
            });
        } else {
            this.selected =
                this.values.find((course) => course.uuid === this.course) ||
                null;
        }
    },
};
</script>
