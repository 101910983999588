<script lang="ts">
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import { getStructuredReport } from "../../../api/pulse-requests";
import PulseLearnCoursesStructuredRow from "../../Pulse/learn/courses/rows/PulseLearnCoursesStructuredRow.vue";
import PulseActivityTabs from "../PulseActivityTabs.vue";

export default {
    components: {
        PulseLearnCoursesStructuredRow,
        PulseActivityTabs,
    },
    data(): any {
        return {
            data: [],
            rowComponent: PulseLearnCoursesStructuredRow,
            currentFilters: {},
            currentSort: {},
            pagination: {},
            loading: true,
            viewColumns: [
                {
                    header: "Course Name",
                    primary: true,
                    sort: false,
                    width: 20,
                },
                {
                    header: "Module Name",
                    sort: false,
                    views: ["structured"],
                },
            ],
            viewFilters: [],
        };
    },
    computed: {
        ...mapGetters(["watchContext"]),
        ...mapState([
            "subjectGroup",
            "subject",
            "period",
            "view",
            "type",
            "partner",
            "group",
            "course",
            "selectedLearners",
        ]),
        columns() {
            return this.viewColumns;
        },
        basePath() {
            const courseId = this.$route.params.courseId;
            return `/course/${courseId}/activity`;
        },
    },
    methods: {
        getData() {
            if (this.partner || this.group) {
                console.log("Reload Data");
                this.loading = true;
                // Get parameters from the URL
                let params = new URLSearchParams(window.location.search);
                const partner = this.partner;
                const group = this.group;
                const course = this.course;
                const period = this.period ?? "1";
                const event_type = params.get("event_type") ?? "all";
                const page = params.get("page") ?? 1;
                getStructuredReport(
                    partner,
                    group,
                    period,
                    course,
                    this.selectedLearners,
                    page,
                    event_type
                )
                    .then((response) => {
                        this.data = response.data.data;
                        this.pagination = response.data.meta;
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        handlePageUpdated(page) {
            // push new page param to url history
            let url = new URL(window.location.href);
            url.searchParams.set("page", page);
            window.history.pushState({}, "", url);
            this.getData();
        },
        setCurrentSort() {
            this.currentSort = {};

            this.viewColumns.map(
                (column) =>
                    (this.currentSort[column.header.toLowerCase()] = "asc")
            );
        },
        refreshCurrentSort(changedColumn) {
            if (changedColumn.isPrimary) {
                return;
            }

            Object.entries(this.currentSort).map(([key, value]) => {
                // maintain primary
                if (
                    this.viewColumns.some(
                        (col) => col.header.toLowerCase() === key && col.primary
                    )
                ) {
                    this.currentSort[key] = value;
                    return;
                }

                // update secondary
                if (changedColumn.column !== key) {
                    delete this.currentSort[key];
                }
            });
        },
    },
    watch: {
        watchContext: {
            handler: function (val, oldVal) {
                this.getData();
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        this.setCurrentSort();
    },
};
</script>
<template>
    <div>
        <PulseActivityTable
            :columns="columns"
            :filters="viewFilters"
            :loading="loading"
            :rows="data"
            :rowAs="rowComponent"
            :sort="currentSort"
            :pagination="pagination"
            @page-updated="handlePageUpdated"
        >
            <template v-slot:filters>
                <!--                <PulseActivityTypeFilter/>-->
                <PulseActivityTableCourseFilter />
            </template>
            <template v-slot:views>
                <PulseActivityTabs basePath="/courses/activity" />
            </template>
        </PulseActivityTable>
    </div>
</template>
