<template>
    <PulseLearnTabs>
        <template #overview>
            <PulseLearnCoursesOverview />
        </template>
        <template #activity>
            <PulseLearnCoursesActivity />
        </template>
    </PulseLearnTabs>
</template>
