import { onMounted, ref } from "vue";
import { TaxonomyItem } from "@/types/content";
import { getCategories, getDisciplines } from "@/api/admin-content";

export function useDisciplines() {
    const disciplines = ref<TaxonomyItem[]>([]);

    onMounted(async () => {
        disciplines.value = await getDisciplines();
    });

    return disciplines;
}

export function useCategories() {
    const categories = ref<TaxonomyItem[]>([]);

    onMounted(async () => {
        categories.value = await getCategories();
    });

    return categories;
}
