<template>
    <div>
        <fade
            classes="rounded-md shadow"
            :class="{
                'border-red-500': score < 75,
                'border-green-500': score >= 75,
            }"
            :show="['completed', 'expired'].includes(status)"
        >
            <div
                class="flex flex-col sm:flex-row flex-wrap sm:items-center bg-gray-800 text-gray-50 rounded-t-md p-4 sm:p-8 w-full"
            >
                <div class="sm:mr-16 mb-4 sm:mb-0">
                    <h1 class="text-xl font-medium">Exam Completed</h1>
                    <p class="text-gray-300 text-sm leading-5 mt-2">
                        {{ taker?.first_name }} {{ taker?.last_name }}
                    </p>
                    <p class="text-gray-400 text-xs leading-5">
                        {{ timeCompleted }}
                    </p>
                </div>
                <div class="flex flex-col flex-grow">
                    <h2
                        class="flex flex-grow items-center justify-between leading-8"
                    >
                        <div class="text-xs uppercase font-bold">Score</div>
                        <div
                            class="border-b border-dashed border-gray-400 flex-grow mx-5"
                        ></div>
                        <div
                            class="text-2xl"
                            :class="{
                                'text-green-500': score >= 75,
                                'text-red-500': score < 75,
                            }"
                        >
                            <span>{{ score.toFixed(1) }}</span>
                            <span class="text-xl">%</span>
                        </div>
                    </h2>
                    <h2 class="flex items-center justify-between leading-8">
                        <div class="text-xs uppercase font-bold">Time</div>
                        <div
                            class="border-b border-dashed border-gray-400 flex-grow mx-5"
                        ></div>
                        <div class="text-lg">
                            {{ timeToComplete }}
                        </div>
                    </h2>
                </div>
            </div>
            <div class="bg-white rounded-b-md">
                <PrepCategoryReport
                    :subject="user.uuid"
                    :bank="bank"
                    :exam="examId"
                    :rows="rows"
                />
            </div>
        </fade>
    </div>
</template>

<script lang="ts">
import { format, parse } from "date-fns";
import { mapState } from "vuex";
import { defineComponent, PropType } from "vue";
import { convertSecondsToMinutes } from "@/utils";

export default defineComponent({
    props: {
        bank: String,
        completedAt: String,
        completedAtDate: {
            type: Date as PropType<Date | null>,
            required: false,
        },
        examId: Number,
        rows: Array,
        score: {
            type: Number,
            required: true,
        },
        status: {
            type: String,
            required: true,
        },
        taker: Object,
        time: String,
        timeSeconds: Number,
    },
    computed: {
        ...mapState(["user"]),
        timeToComplete() {
            return this.timeSeconds
                ? convertSecondsToMinutes(this.timeSeconds)
                : this.time;
        },
        timeCompleted() {
            if (this.completedAtDate) {
                return format(this.completedAtDate, "MMM d, yyyy 'at' h:mm a");
            }
            try {
                return format(
                    parse(
                        this?.completedAt ?? "",
                        "yyyy-MM-dd HH:mm:ss",
                        new Date()
                    ),
                    "MMM d, yyyy 'at' h:mm a"
                );
            } catch (e) {
                return "";
            }
        },
    },
});
</script>
