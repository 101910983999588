<template>
    <div>
        <div class="lg:flex lg:items-end lg:justify-end pb-6">
            <!--            <PulseLearnOverviewSort :options="sortBy" @sort="handleSort" />-->
        </div>

        <PulsePrepAssignmentsTable v-if="!assignmentUuid" />
        <PulsePrepAssignmentTable v-else :assignment-uuid="assignmentUuid" />
    </div>
</template>

<script lang="ts">
import { mapGetters } from "vuex";

export default {
    data(): any {
        return {
            assignmentUuid: null,
            sortBy: ["my assignments", "all assignments"],
        };
    },
    computed: {
        ...mapGetters(["context"]),
    },
    methods: {
        handleSort(sortBy) {
            // Actually handle updating the results list based on the selected sort option
        },
    },
    mounted() {
        this.$events.$on("updatedAssignmentUuid", (data) => {
            this.assignmentUuid = data;
        });
    },
};
</script>
