<template>
    <button
        class="w-full flex items-center border-b last:border-b-0 border-gray-200 text-sm space-x-3 py-4 px-2 cursor-pointer hover:underline focus-visible:ring"
        @click="navigateToLesson"
    >
        <div class="flex">
            <SvgIcon icon="crossroad" size="base" />
        </div>
        <div class="flex flex-col items-start">
            <div class="text-blue-600 font-medium">
                Resume where you left off
            </div>
            <div class="text-gray-600 text-xs" v-html="lastViewedAt"></div>
        </div>
    </button>
</template>

<script lang="ts">
import $events from "../../event-bus";

export default {
    props: {
        lessonUuid: String,
        lastViewedAt: String,
        moduleSlug: String,
    },
    methods: {
        navigateToLesson() {
            if (this.moduleSlug) {
                $events
                    .$emit("highlightLesson", {
                        uuid: this.lessonUuid,
                        module_div_id: this.moduleSlug,
                    })(
                        document.getElementById(
                            this.moduleSlug
                        ) as HTMLDivElement
                    )
                    .scrollIntoView({ behavior: "smooth", block: "center" });
            }
        },
    },
};
</script>
