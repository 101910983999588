<template>
    <SvgIcon
        :icon="accessMap[access].icon"
        v-tippy
        :content="accessMap[access].tooltip"
        size="sm"
        weight="bold"
        :class="accessMap[access].text"
    />
</template>

<script lang="ts">
export default {
    props: {
        access: {
            type: String,
            default: "open",
        },
    },
    data(): any {
        return {
            accessMap: {
                open: {
                    icon: "unlock",
                    tooltip: "Open",
                    text: "text-gray-400",
                },
                reserved: {
                    icon: "lock",
                    tooltip: "Reserved",
                    text: "text-gray-600",
                },
            },
        };
    },
};
</script>
