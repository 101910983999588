<template>
    <TitleBar
        :action="''"
        :actionPath="''"
        :app="'Lesson'"
        :icon="'chalkboard'"
        :moduleTitle="'moduleTitle'"
        :resourceTitle="'resourceTitle'"
        :searchable="[]"
        :section="''"
        :sectionPath="''"
        :showBilling="false"
        :impersonating="false"
    />
</template>

<script lang="ts">
import { mapState } from "vuex";

export default {
    props: {},
    computed: {
        ...mapState(["activePath", "user"]),
    },
    methods: {},
};
</script>
