<template></template>

<script lang="ts">
export default {
    props: {
        action: String,
        message: String,
        heading: String,
        status: String,
    },
    data(): any {
        return {
            messages: {
                correct: ["Awesome!", "Great job!", "Incredible!"],
                deleted: [
                    "Hope you know what you’re doing",
                    "I didn’t like that one anyway",
                    "May it rest in peace",
                ],
                error: ["Shoot!"],
                incorrect: [
                    "Better luck next time",
                    "Bummer!",
                    "That’s too bad",
                ],
                saved: [
                    "Get on with yo bad self!",
                    "I was hoping you would do that",
                    "Impressive!",
                    "Nice going!",
                    "Oh yeah!",
                    "Woot woot!",
                ],
                sent: ["Message sent"],
            },
        };
    },
    methods: {
        getMessage() {
            if (this.message) {
                return this.message;
            }

            if (!this.action) {
                return null;
            }

            return this.messages[this.action][
                Math.floor(Math.random() * this.messages[this.action].length)
            ];
        },
    },
    mounted() {
        let message = this.getMessage();
        this.$events.$emit("toastNotification", {
            action: this.action,
            heading: this.heading,
            status: this.status,
            message: message,
        });
    },
};
</script>
