<template>
    <div>
        <template v-if="!loading">
            <template v-if="items.length > 0">
                <div class="hidden md:flex items-center justify-between mb-2">
                    <div class="flex items-center">
                        <div
                            class="flex justify-start text-gray-600 text-sm font-medium uppercase pl-6 w-80"
                        >
                            Assignment name
                        </div>
                        <div
                            class="flex justify-start text-gray-600 text-sm font-medium uppercase w-56"
                        >
                            Exam
                        </div>
                        <div
                            class="flex justify-start text-gray-600 text-sm font-medium uppercase w-48"
                        >
                            Dates
                        </div>
                        <div
                            class="flex justify-start text-gray-600 text-sm font-medium uppercase pl-6 w-60"
                        >
                            Assignee
                        </div>
                        <div
                            class="flex justify-center text-gray-600 text-sm font-medium uppercase w-24"
                        >
                            Attempts
                        </div>
                    </div>
                    <div class="flex items-center">
                        <div class="w-14"></div>
                    </div>
                </div>
                <div class="md:bg-white md:rounded-md md:shadow">
                    <div class="flex flex-col space-y-4 md:space-y-0">
                        <PrepAssignmentsCreatedCollectionResource
                            v-for="assignment in items"
                            :assignment="assignment"
                            :key="assignment.uuid"
                        />
                    </div>
                </div>
                <div v-if="items.length < count" class="mt-8">
                    <nav
                        class="border-t border-gray-200 px-4 flex items-center justify-between sm:px-0"
                    >
                        <div class="-mt-px w-0 flex-1 flex">
                            <button
                                class="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                                @click="prev"
                            >
                                <svg
                                    class="mr-3 h-5 w-5 text-gray-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                                Previous
                            </button>
                        </div>
                        <div class="hidden md:-mt-px md:flex">
                            <button
                                v-for="i in pages"
                                class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
                                :class="{
                                    'border-blue-500 text-blue-600': i == page,
                                    'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
                                        i != page,
                                }"
                                @click="page = i"
                            >
                                {{ i }}
                            </button>
                        </div>
                        <div class="-mt-px w-0 flex-1 flex justify-end">
                            <button
                                class="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                                @click="next"
                            >
                                Next
                                <svg
                                    class="ml-3 h-5 w-5 text-gray-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>
                    </nav>
                </div>
            </template>
            <template v-else>
                <div
                    class="flex items-center justify-center text-gray-500 text-2xl md:text-3xl font-light h-40"
                >
                    {{ emptyLabel }}
                </div>
            </template>
        </template>
        <div v-else>
            <Loader />
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";

export default {
    props: {
        emptyLabel: String,
        type: String,
        total: {
            type: Number,
            default: 0,
        },
    },
    data(): any {
        return {
            count: null,
            items: [],
            loading: true,
            page: 1,
        };
    },
    computed: {
        pages() {
            return Math.ceil(this.count / 10);
        },
    },
    methods: {
        loadData() {
            this.loading = true;
            axios
                .get(
                    "/api/prep/assignments?type=" +
                        this.type +
                        "&page=" +
                        this.page
                )
                .then((response) => {
                    this.items = response.data.data;
                    this.loading = false;
                });
        },
        next() {
            if (this.page < this.pages) {
                this.page++;
            }
        },
        prev() {
            if (this.page > 1) {
                this.page--;
            }
        },
    },
    mounted() {
        this.count = this.total;

        this.loadData();
    },
    watch: {
        page() {
            this.loadData();
        },
    },
};
</script>
