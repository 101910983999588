export async function getRecentActivity() {
    let url = `/api/recent-activity`;
    // use fetch instead of axios
    const response = await fetch(url, {
        method: "GET",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
        },
    });
    return await response.json();
}
