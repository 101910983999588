<template>
    <SearchableSelectDropdown
        icon="pulse"
        :selectable-label="label"
        :searchable="groupNames"
        @searchable-filtered="updateGroups"
    >
        <SearchableSelectDropdownItem
            v-for="group in groups"
            v-show="showGroup(group)"
            :key="group.uuid"
            :class="indent[group.indent]"
        >
            <a
                :href="calculateUrl(group)"
                :class="{
                    'text-black font-semibold': isSelected(group),
                    'truncate pr-1 max-w-[90%]': Boolean(group.learner_count),
                }"
            >
                {{ group.name }}
            </a>
            <span :class="{ 'text-black font-semibold': isSelected(group) }">
                ({{ group.learner_count }})
            </span>
        </SearchableSelectDropdownItem>
    </SearchableSelectDropdown>
</template>

<script lang="ts">
import { mapGetters, mapState } from "vuex";
import { getUsersGroups } from "../../../../api";

export default {
    data(): any {
        return {
            groups: [],
            filteredGroups: [],
            indent: {
                1: "pl-auto",
                2: "pl-6",
                3: "pl-12",
            },
            label: "Groups: All",
        };
    },
    computed: {
        ...mapState(["subjectTree", "subject", "activePath", "type", "typeId"]),
        ...mapGetters(["context"]),
        partner() {
            const url = new URL(window.location.href);
            const partner = url.searchParams.get("partner");
            return partner;
        },
        group() {
            const url = new URL(window.location.href);
            const group = url.searchParams.get("group");
            return group;
        },
        groupNames() {
            return this.groups?.map((group) => group.name) || [];
        },
        partnerObject() {
            return this.groups ? this.groups[0] : null;
        },
    },
    methods: {
        updateGroups(data) {
            this.filteredGroups = data;
        },
        calculateUrl(group) {
            // Get the current url path and url parameters from window.location
            const url = new URL(window.location.href);
            if (group.type === "group") {
                // replace the group parameter in the url with the selected group
                url.searchParams.delete("partner");
                url.searchParams.set("group", group.uuid);
            }
            if (group.type === "partner") {
                // remove the group parameter from the url
                url.searchParams.delete("group");
                url.searchParams.set("partner", group.uuid);
            }
            return url.href;
        },
        setGroupSelection(group) {
            this.$store.commit("registerState", {
                subject: group.uuid,
                subjectGroup: !group.parent_name ? "partner" : "group",
            });

            window.location.replace(
                `/${this.activePath}${this.searchUrlContext}`
            );
        },
        setInitialSelection() {
            if (!this.groups) return;
            this.label =
                this.groups.length > 1
                    ? this.groups.find((group) => this.isSelected(group))?.name
                    : this.groups?.[0]?.name ?? "";
        },
        isSelected(group) {
            if (this.group) {
                return this.group === group.uuid;
            }
            if (this.partner) {
                return this.partner === group.uuid;
            }
            return false;
        },
        findSelectedSubject() {
            const group =
                this.groups > 1
                    ? this.groups.find((group) => this.isSelected(group))
                    : this.groups?.[0];
            return group;
        },
        showGroup(group) {
            return (
                this.filteredGroups.includes(this.partnerObject.name) ||
                this.filteredGroups.includes(group.name) ||
                this.filteredGroups.includes(group.parent_name)
            );
        },
        getPartnerAndGroupUuids() {
            const url = new URL(window.location.href);
            const partnerUuid = url.searchParams.get("partner");
            const groupUuid = url.searchParams.get("group");
            return { partnerUuid, groupUuid };
        },
    },
    mounted() {
        let { partnerUuid, groupUuid } = this.getPartnerAndGroupUuids();

        // if partnerUuid and groupUuid are both empty, then we are on the pulse page
        // and we need to get the partnerUuid and groupUuid from the subjectTree
        getUsersGroups(partnerUuid, groupUuid).then((groups) => {
            // Flatten the groups array and add an indent number to each group
            const flattenGroups = (groups, indent = 0) => {
                return groups.reduce((acc, group) => {
                    group.indent = indent;
                    acc.push(group);
                    if (group.children.length) {
                        acc.push(...flattenGroups(group.children, indent + 1));
                    }
                    return acc;
                }, []);
            };

            this.groups = flattenGroups(groups);

            const first = this.groups[0];

            if (first && partnerUuid === null && groupUuid === null) {
                if (first.type === "partner") {
                    partnerUuid = first.uuid;
                    // set the partnerUuid in the url
                    const url = new URL(window.location.href);
                    url.searchParams.set("partner", partnerUuid);
                    window.history.replaceState({}, "", url.href);
                }
                if (first.type === "group") {
                    groupUuid = first.uuid;
                    // set the groupUuid in the url
                    const url = new URL(window.location.href);
                    url.searchParams.set("group", groupUuid);
                    window.history.replaceState({}, "", url.href);
                }
            }
            this.$store.commit("registerState", {
                subjectTree: this.groups,
                partner: partnerUuid,
                group: groupUuid,
            });
            this.setInitialSelection();
        });
    },
};
</script>
