<template>
    <div class="flex items-center justify-between">
        <span v-if="label" class="flex flex-col flex-grow mr-4">
            <span class="text-sm">{{ label }}</span>
        </span>

        <button
            @click="handle"
            type="button"
            class="relative inline-flex items-center justify-center flex-shrink-0 w-10 h-5 rounded-full cursor-pointer group"
            role="switch"
            :aria-checked="enabled"
        >
            <span class="sr-only">Use setting</span>
            <span
                aria-hidden="true"
                class="absolute w-full h-full bg-white rounded-md pointer-events-none"
            ></span>

            <span
                aria-hidden="true"
                :class="[
                    enabled
                        ? variantClasses.slideActiveBgClass
                        : variantClasses.slideInactiveBgClass,
                    variantClasses.heightClass,
                ]"
                class="absolute mx-auto transition-colors duration-200 ease-in-out rounded-full pointer-events-none w-9"
            >
            </span>

            <span
                aria-hidden="true"
                :class="[
                    enabled
                        ? ['translate-x-5', variantClasses.circleActiveBgClass]
                        : [
                              'translate-x-0',
                              variantClasses.circleInactiveBgClass,
                          ],
                    variantClasses.borderClass,
                ]"
                class="absolute left-0 inline-block w-5 h-5 transition-transform duration-200 ease-in-out transform translate-x-0 rounded-full shadow pointer-events-none ring-0"
            ></span>
        </button>
    </div>
</template>

<script lang="ts">
export const PulseToggleVariants = {
    blue: {
        slideActiveBgClass: "bg-blue-600",
        slideInactiveBgClass: "bg-gray-200",
        circleActiveBgClass: "bg-white",
        circleInactiveBgClass: "bg-white",
        heightClass: "h-4",
        borderClass: "border border-gray-200",
    },
    green: {
        slideActiveBgClass: "bg-emerald-200",
        slideInactiveBgClass: "bg-gray-200",
        circleActiveBgClass: "bg-emerald-500",
        circleInactiveBgClass: "bg-white",
        heightClass: "h-5",
        borderClass: "",
    },
};

export default {
    props: {
        initial: {
            type: Boolean,
            default: false,
        },
        label: String,
        variant: {
            type: String,
            default: "blue",
        },
    },
    data(): any {
        return {
            enabled: this.initial,
            variantClasses: PulseToggleVariants[this.variant],
        };
    },
    methods: {
        handle() {
            this.enabled = !this.enabled;
            this.$emit("toggle", this.enabled);
        },
    },
    watch: {
        initial: function (newVal) {
            this.enabled = newVal;
        },
    },
};
</script>
