import { createStore } from "vuex";

const urlParams = new URLSearchParams(window.location.search);

const state = {
    __storeName: "pulse-store",
    location: {
        app:
            window.location.pathname.split("/").length > 1
                ? "/" + window.location.pathname.split("/")[1]
                : "",
        page:
            window.location.pathname.split("/").length > 2
                ? "/" + window.location.pathname.split("/")[2]
                : "",
    },
    token: null,
    user: null,
    crumbs: null,
    pageSearch: ["PulseGroupSearch", "PulseLearnerSearch"],
    settings: {},
    showAnalytics: true,
    period: urlParams.get("period") || "1y",
    partner: urlParams.get("partner") || null,
    group: urlParams.get("group") || null,
    course: null,
    module: null,
    learners: [],
    selectedLearners: [],
    subjectTree: [],
    subjects: [
        "organization",
        "account",
        "cohort",
        "learner",
        "partner",
        "group",
    ],
    tab: null,
    view: null,
    views: ["learners", "structured", "timeline"],
    type: null,
    typeId: null,
    bladePage: true,
};

const getters = {
    appPath: (state) => {
        return state.location.app + state.location.page;
    },
    baseLink: (state, getters) => {
        return getters.appPath + getters.context;
    },
    groupUuid: (state) => {
        // if partner is null, then we are on the group page
        return state.partner === null ? state.group : state.partner;
    },
    context: (state, getters) => {
        switch (getters.viewContext) {
            case "learn":
                let context =
                    "?view=" +
                    state.view +
                    "&partner=" +
                    state.partner +
                    "&group=" +
                    (state.group ?? "") +
                    "&period=" +
                    state.period;

                state.type === "module"
                    ? (context += "&tab=activity")
                    : (context += "&tab=" + state.tab);

                return context;
            case "prep":
            default:
                return (
                    "?partner=" +
                    state.partner +
                    "&period=" +
                    state.period +
                    "&tab=" +
                    state.tab
                );
        }
    },
    viewContext: (state) => {
        return state.location.page.replace("/", "");
    },
    viewSubject: (state) => {
        return state.subjectTree.find(
            (item) => item.uuid === state.partner || item.uuid === state.group
        );
    },
    watchContext: (state) => {
        return {
            partner: state.partner,
            group: state.group,
            period: state.period,
            course: state.course,
            selectedLearners: JSON.stringify(state.selectedLearners),
        };
    },
    viewType: (state) => {
        switch (state.type) {
            case "courses":
                return "courses";
            case "course":
                return "course";
            case "module":
                return "module";
            case "exams":
                return "exams";
            case "assignments":
                return "assignments";
            default:
                return null;
        }
    },
};

const mutations = {
    registerState(state, payload) {
        for (const [key, value] of Object.entries(payload)) {
            state[key] = value;
        }

        // Sync url store to url params
        let params = new URLSearchParams(window.location.search);
        if (state.course) {
            params.set("course", state.course);
        }
        // set the new search params
        window.history.replaceState(
            {},
            "",
            `${window.location.pathname}?${params.toString()}`
        );
    },
};

const actions = {};

export default createStore({
    state,
    getters,
    mutations,
    actions,
});
