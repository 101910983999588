<template>
    <CourseForm :course="course" @save="saveCourse" :errors="errors" />
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import axios from "axios";
import { Course } from "@/types";
import { toast } from "@/components/utils/toast";
import CourseForm from "./CourseForm.vue";
import { useRouter } from "vue-router";
import $events from "@/event-bus.ts";

const router = useRouter();
const course = ref<Partial<Course> | null>({});
const props = defineProps({
    uuid: String,
});

const errors = ref([]);

function createNewCourse(updatedCourse: Course) {
    errors.value = [];
    return axios
        .post(`/api/admin/content/learn/new-course`, updatedCourse, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            if (response.status < 400) {
                return response.data;
            }
        })
        .catch((err) => {
            const errs = Object.entries(err.response.data.errors)
            errors.value = Object.fromEntries(errs.map((e) => e.flat()));
            $events.$emit("toastNotification", {
              action: "error",
              heading: "Error creating course",
              status: "error",
            });
        });
}

const saveCourse = async (newCourse: Course) => {
    const result = await createNewCourse(newCourse);
    router.push(`/content/learn/courses/${result.uuid}/edit`);
    $events.$emit("toastNotification", {
      action: "saved",
      heading: "Course saved",
      status: "saved",
    });
};
</script>
