import * as Vue from "vue";

import { createRouter, createWebHistory } from "vue-router";
import CoursesReportView from "../components/Reporting/courses/CoursesReportView.vue";
import StructuredViewCourses from "../components/Reporting/courses/StructuredViewCourses.vue";
import SingleCourseReportView from "../components/Reporting/course/SingleCourseReportView.vue";
import EmptyRouterView from "../components/_elements/EmptyRouterView.vue";
import ModuleReportView from "../components/Reporting/module/ModuleReportView.vue";
import MainVueLayout from "../components/MainVueLayout.vue";
import PulseLearnCoursesOverview from "../components/Pulse/learn/courses/PulseLearnCoursesOverview.vue";
import LearnerViewCourses from "../components/Reporting/courses/LearnerViewCourses.vue";
import PulseLearnCourseOverview from "../components/Pulse/learn/course/PulseLearnCourseOverview.vue";
import StructuredViewSingleCourse from "../components/Reporting/course/StructuredViewSingleCourse.vue";
import LearnerViewSingleCourse from "../components/Reporting/course/LearnerViewSingleCourse.vue";
import LearnerViewModule from "../components/Reporting/module/LearnerViewModule.vue";
import StructuredViewModule from "../components/Reporting/module/StructuredViewModule.vue";

const routes: any = [
    {
        path: "/",
        component: MainVueLayout,
        meta: {
            title: "Reporting",
        },
        children: [
            {
                path: "courses",
                name: "Courses Reporting",
                component: CoursesReportView,
                children: [
                    {
                        path: "",
                        component: PulseLearnCoursesOverview,
                    },
                    {
                        path: "activity",
                        component: EmptyRouterView,
                        children: [
                            {
                                path: "structured",
                                component: StructuredViewCourses,
                            },
                            {
                                path: "learner",
                                component: LearnerViewCourses,
                            },
                        ],
                    },
                ],
            },
            {
                path: "course/:courseId",
                component: SingleCourseReportView,
                children: [
                    {
                        path: "",
                        component: PulseLearnCourseOverview,
                    },
                    {
                        path: "activity",
                        component: EmptyRouterView,
                        children: [
                            {
                                path: "structured",
                                component: StructuredViewSingleCourse,
                            },
                            {
                                path: "learner",
                                component: LearnerViewSingleCourse,
                            },
                        ],
                    },
                ],
            },
            {
                path: "module/:moduleId",
                component: ModuleReportView,
                children: [
                    {
                        path: "",
                        component: StructuredViewModule,
                    },
                    {
                        path: "structured",
                        component: StructuredViewModule,
                    },
                    {
                        path: "learner",
                        component: LearnerViewModule,
                    },
                ],
            },
        ],
    },
];

export default () => {
    return createRouter({
        history: createWebHistory("/reporting"),
        routes: routes,
    });
};
