<template>
    <div class="bg-white rounded shadow w-full sm:w-96 sm:mr-8 mb-4 sm:mb-8">
        <div class="p-2">
            <div
                class="p-6 rounded-t flex items-center justify-between"
                :class="
                    'bg-' +
                    options[examOwner].light +
                    ' text-' +
                    options[examOwner].dark
                "
            >
                <SvgIcon
                    :icon="options[examOwner].icon"
                    size="xl"
                    weight="light"
                />
                <div class="text-right">
                    <div class="text-base capitalize ml-2">{{ examOwner }}</div>
                    <p
                        :content="$filters.dateTime(examTime, user.timezone)"
                        v-tippy
                        class="text-sm font-medium"
                        v-text="$filters.relative(examTime, user.timezone)"
                    />
                </div>
            </div>
        </div>
        <div class="pt-4 px-6 pb-6">
            <h2 class="text-lg font-medium leading-5">
                {{ exam.name || "Unnamed" }}
            </h2>
        </div>
        <div
            class="flex items-center justify-between border-t border-gray-100 p-6"
        >
            <div>
                <h3 class="text-xs uppercase font-medium mb-3">Categories</h3>
                <PrepCategoryCircles
                    class="justify-start"
                    :categories="exam.categories"
                    options="stacked"
                />
            </div>
            <div
                class="flex flex-col items-center justify-center border-l border-gray-100 text-center pl-6 ml-6"
            >
                <h3 class="text-xs uppercase font-medium mb-2">Questions</h3>
                <div
                    class="flex items-center justify-center bg-gray-50 text-xl font-bold tracking-tighter rounded-full shadow h-12 w-12"
                >
                    {{ exam.questions_total }}
                </div>
            </div>
        </div>
        <div
            class="flex items-center flex-row-reverse justify-between bg-gray-50 border-t border-gray-100 rounded-b p-4"
        >
            <a
                class="button"
                :class="{
                    primary: exam.status == 'completed',
                    alert: exam.status == 'started',
                }"
                :href="'/prep/exams/' + exam.uuid"
            >
                <span v-if="exam.status == 'completed'">View results</span>
                <span v-if="exam.status == 'started'">Resume</span>
            </a>
            <h3
                class="flex-grow text-sm font-medium uppercase mr-12"
                v-if="exam.status == 'started'"
            >
                <ProgressBar
                    label="Progress"
                    :percent="exam.progress"
                    size="md"
                />
            </h3>
            <h3
                class="flex items-center text-lg font-medium leading-6"
                :class="{
                    'text-red-600': examScore < 75,
                    'text-green-600': examScore >= 75,
                }"
                v-if="exam.status == 'completed'"
            >
                <SvgIcon
                    v-if="examScore >= 75"
                    class="mr-2"
                    icon="check"
                    size="base"
                />
                <SvgIcon
                    v-if="examScore < 75"
                    class="mr-2"
                    icon="wrong"
                    size="base"
                />
                <div>{{ $filters.grade(examScore) }}</div>
            </h3>
        </div>
    </div>
</template>

<script lang="ts">
import { mapState } from "vuex";

export default {
    props: {
        exam: {
            type: Object,
            default: () => {},
        },
    },
    data(): any {
        return {
            options: {
                learner: {
                    light: "blue-50",
                    dark: "blue-700",
                    icon: "user",
                },
                educator: {
                    light: "red-50",
                    dark: "red-700",
                    icon: "school",
                },
                clover: {
                    light: "pear-100",
                    dark: "pear-700",
                    icon: "clover-filled",
                },
            },
        };
    },
    computed: {
        ...mapState(["user"]),
        examScore() {
            return (
                (this.exam.questions_correct / this.exam.questions_total) * 100
            );
        },
        examTime() {
            return this.exam.time_completed
                ? this.exam.time_completed
                : this.exam.time_started;
        },
        examOwner() {
            return this.exam.blueprint ? this.exam.blueprint.owner : "learner";
        },
    },
};
</script>
