<template>
    <div class="pt-2" v-show="pagination.total > pagination.per_page">
        <PulsePagination
            :last-page="pagination.last_page"
            :per-page="pagination.per_page"
            :total="pagination.total"
            :show-bg="false"
            :current-page="pagination.current_page"
            :from="pagination.from"
            :to="pagination.to"
            @page-updated="(e) => $emit('page-updated', e)"
        />
    </div>
</template>

<script lang="ts">
export default {
    props: {
        pagination: {
            type: Object,
            required: true,
        },
    },
};
</script>
