<script setup lang="ts">
import { computed, defineProps, ref } from "vue";
import { NextSteps } from "../../api/lessons";
import { Course, Lesson, Module } from "../../types";

// TODO this is a horrible hack.
const token =
    (document.querySelector('[name="_csrf_token"]') as any)?.value ?? "";

const phrasesList = [
    "Keep it going!",
    "You can do it!",
    "You are amazing!",
    "You got this!",
    "Well done!",
    "Excellent work!",
    "You've got the prescription for success!",
    "Seeing results through the lens of success!",
    "Shine bright like an X-ray!",
    "Radiate positivity!",
];

const pickRandomPhrase = () => {
    return phrasesList[Math.floor(Math.random() * phrasesList.length)];
};

const props = defineProps<{
    nextSteps: NextSteps;
}>();

const emits = defineEmits<{
    (e: "rewatch"): void;
}>();

interface ComputedNextActions {
    primary: { label: string; path: string; method: "get" | "post" } | null;
    secondary: {
        label: string;
        title: string;
        path: string;
        method: "get" | "post";
    } | null;
}

const getUrl = (
    course: Course,
    module: Module,
    lesson?: Lesson | null,
    suffix: string = ""
) => {
    const base = `/learn/courses/${course.slug}/${module.slug}`;
    if (lesson) {
        return `${base}/${lesson.slug}${suffix}`;
    }
    return `${base}${suffix}`;
};

const nextActions = computed((): ComputedNextActions | null => {
    // if there is a quiz the next step is the quiz
    if (props.nextSteps.current.hasQuiz) {
        const secondary: ComputedNextActions["secondary"] = props.nextSteps
            ?.next?.lesson
            ? {
                  label: "Next Lesson:",
                  title: props.nextSteps.next.lesson.title,
                  path: getUrl(
                      props.nextSteps.course,
                      props.nextSteps.next.module!,
                      props.nextSteps.next.lesson
                  ),
                  method: "get",
              }
            : props.nextSteps.current.hasModuleAssessment
            ? {
                  label: "Take Module Assessment",
                  title: props.nextSteps.current.module.title,
                  path: getUrl(
                      props.nextSteps.course,
                      props.nextSteps.current.module,
                      null,
                      "/assessment"
                  ),
                  method: "post",
              }
            : null;

        return {
            primary: {
                label: "Take Quiz",
                path: getUrl(
                    props.nextSteps.course,
                    props.nextSteps.current.module,
                    props.nextSteps.current.lesson,
                    "/quiz"
                ),
                method: "post",
            },
            secondary,
        };
    }

    if (props.nextSteps.next.module?.id != props.nextSteps.current.module.id) {
        // if the next lesson is in a different module check to make see if there is a module assessment
        if (props.nextSteps.current.hasModuleAssessment) {
            return {
                primary: {
                    label: "Take Module Assessment",
                    path: getUrl(
                        props.nextSteps.course,
                        props.nextSteps.current.module,
                        null,
                        "/assessment"
                    ),
                    method: "post",
                },
                secondary: props.nextSteps.next.module
                    ? {
                          label: "Next Module: ",
                          title: props.nextSteps.next.lesson?.title ?? "",
                          path: getUrl(
                              props.nextSteps.course,
                              props.nextSteps.next.module,
                              props.nextSteps.next.lesson
                          ),
                          method: "get",
                      }
                    : null,
            };
        } else {
            if (props.nextSteps.next.module) {
                return {
                    primary: {
                        label:
                            "Next Module: " + props.nextSteps.next.module.title,
                        path: getUrl(
                            props.nextSteps.course,
                            props.nextSteps.next.module,
                            props.nextSteps.next.lesson
                        ),
                        method: "get",
                    },
                    secondary: null,
                };
            }
            return {
                primary: null,
                secondary: null,
            };
        }
    }

    if (props.nextSteps.next && props.nextSteps.next.lesson) {
        return {
            primary: {
                label: "Next Lesson: " + props.nextSteps.next.lesson.title,
                path: getUrl(
                    props.nextSteps.course,
                    props.nextSteps.next.module,
                    props.nextSteps.next.lesson
                ),
                method: "get",
            },
            secondary: null,
        };
    }

    return null;
});
</script>
<template>
    <div
        data-cl-container="video-overlay"
        class="absolute z-[2] bg-slate-900 text-white w-full h-full flex gap-6 md:gap-4 lg:gap-8 flex-col justify-center items-center"
    >
        <div class="absolute -left-2 w-auto h-[110%] z-0 flex opacity-10">
            <svg
                class="w-full h-full rotate-45 animate-spin-slow"
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M76.964,42.607c7.207,-1.743 12.486,-8.352 12.297,-16.092c-0.216,-8.908 -7.594,-15.964 -16.463,-15.746c-8.87,0.217 -15.896,7.627 -15.679,16.535c0.033,1.352 0.161,6.62 0.262,10.775c0.035,1.424 -0.495,2.803 -1.473,3.834c-0.978,1.031 -2.324,1.63 -3.742,1.665c-0.008,-0 -0.017,-0 -0.025,0c-2.951,0.072 -5.402,-2.272 -5.474,-5.236c-0.084,-3.455 -0.19,-7.834 -0.262,-10.775c-0.361,-14.847 11.348,-27.196 26.131,-27.559c14.783,-0.363 27.078,11.397 27.44,26.244c0.209,8.626 -3.655,16.409 -9.83,21.488c-3.768,-2.802 -8.287,-4.633 -13.182,-5.133Z"
                    style="fill: #2b76e2"
                />
                <path
                    d="M23.036,57.393c-7.207,1.743 -12.486,8.352 -12.297,16.092c0.216,8.908 7.594,15.964 16.463,15.746c8.87,-0.217 15.896,-7.627 15.679,-16.535c-0.033,-1.352 -0.161,-6.62 -0.262,-10.775c-0.035,-1.424 0.495,-2.803 1.473,-3.834c0.978,-1.031 2.324,-1.63 3.742,-1.665c0.008,0 0.017,0 0.025,-0c2.951,-0.072 5.402,2.272 5.474,5.236c0.084,3.455 0.19,7.834 0.262,10.775c0.361,14.847 -11.348,27.196 -26.131,27.559c-14.783,0.363 -27.078,-11.397 -27.44,-26.244c-0.209,-8.626 3.655,-16.409 9.83,-21.488c3.768,2.802 8.287,4.633 13.182,5.133Z"
                    style="fill: #f9cb58"
                />
                <path
                    d="M57.577,76.869c1.735,7.237 8.315,12.54 16.022,12.35c8.87,-0.217 15.896,-7.626 15.679,-16.535c-0.217,-8.908 -7.594,-15.964 -16.464,-15.746c-1.346,0.033 -6.591,0.162 -10.728,0.263c-1.418,0.035 -2.792,-0.497 -3.818,-1.48c-1.027,-0.982 -1.623,-2.334 -1.658,-3.758c0,-0.008 0,-0.017 -0,-0.025c-0.071,-2.964 2.263,-5.425 5.214,-5.497c3.44,-0.085 7.799,-0.192 10.728,-0.264c14.783,-0.363 27.079,11.397 27.44,26.244c0.361,14.847 -11.348,27.196 -26.131,27.559c-8.589,0.211 -16.338,-3.67 -21.395,-9.872c2.79,-3.784 4.613,-8.323 5.111,-13.239Z"
                    style="fill: #fb5f5f"
                />
                <path
                    d="M42.423,23.131c-1.735,-7.237 -8.315,-12.54 -16.022,-12.35c-8.87,0.217 -15.896,7.626 -15.679,16.535c0.217,8.908 7.594,15.964 16.464,15.746c1.346,-0.033 6.591,-0.162 10.728,-0.263c1.418,-0.035 2.792,0.497 3.818,1.48c1.027,0.982 1.623,2.334 1.658,3.758c-0,0.008 -0,0.017 0,0.025c0.071,2.964 -2.263,5.425 -5.214,5.497c-3.44,0.085 -7.799,0.192 -10.728,0.264c-14.783,0.363 -27.079,-11.397 -27.44,-26.244c-0.361,-14.847 11.348,-27.196 26.131,-27.559c8.589,-0.211 16.338,3.67 21.395,9.872c-2.79,3.784 -4.613,8.323 -5.111,13.239Z"
                    style="fill: #33c18e"
                />
            </svg>
        </div>

        <h2
            class="font-heading hidden md:flex text-lg md:text-2xl font-bold text-center"
        >
            {{ pickRandomPhrase() }}
        </h2>
        <div
            class="flex flex-wrap items-center justify-center gap-4 gap-y-2 z-10 relative"
        >
            <button
                data-cl-event="watch-again"
                class="flex items-center gap-2 bg-slate-transparent hover:bg-white/10 text-white border border-white rounded-md px-4 py-2 md:py-3 md:px-8 transition text-sm md:text-base"
                @click="emits('rewatch')"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                    />
                </svg>
                Watch Again
            </button>
            <template v-if="nextActions?.primary?.method === 'get'">
                <a
                    data-cl-event="primary-action"
                    class="flex items-center gap-2 bg-blue-700 hover:bg-blue-800 text-white rounded-md px-4 py-2 transition text-sm md:text-base md:py-3 md:px-8 hover:text-white hover:no-underline"
                    v-if="nextActions?.primary"
                    :href="nextActions?.primary?.path"
                >
                    {{ nextActions?.primary?.label }}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                        />
                    </svg>
                </a>
            </template>
            <template v-if="nextActions?.primary?.method === 'post'">
                <form :action="nextActions?.primary?.path" method="post">
                    <input type="hidden" name="_token" :value="token" />
                    <button
                        data-cl-event="primary-action"
                        class="flex items-center gap-2 bg-blue-700 hover:bg-blue-800 text-white rounded-md px-4 py-2 transition text-sm md:text-base md:py-3 md:px-8"
                        v-if="nextActions?.primary"
                        type="submit"
                    >
                        {{ nextActions?.primary?.label }}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                            />
                        </svg>
                    </button>
                </form>
            </template>
        </div>
        <template v-if="nextActions?.secondary">
            <div
                class="flex flex-col sm:gap-1 justify-center items-center z-10 relative"
                v-if="nextActions?.secondary"
            >
                <span class="text-sm text-gray-400 uppercase font-semibold">
                    {{ nextActions.secondary.label }}
                </span>
                <a
                    data-cl-event="secondary-action"
                    class="text-sm md:text-lg hover:text-white hover:underline flex items-center gap-2"
                    :href="nextActions.secondary.path"
                    v-if="nextActions.secondary.method === 'get'"
                >
                    {{ nextActions.secondary.title }}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                        />
                    </svg>
                </a>
                <form
                    :action="nextActions.secondary.path"
                    method="post"
                    v-if="nextActions.secondary.method === 'post'"
                >
                    <input type="hidden" name="_token" :value="token" />
                    <button
                        data-cl-event="secondary-action"
                        class="text-sm md:text-lg hover:text-white hover:underline flex items-center gap-2"
                        type="submit"
                    >
                        {{ nextActions.secondary.title }}\
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-4 h-4"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                            />
                        </svg>
                    </button>
                </form>
            </div>
        </template>
    </div>
</template>
