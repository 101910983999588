<template>
    <div class="card">
        <h4
            class="flex items-center justify-end bg-gray-50 text-gray-600 text-sm font-medium rounded-t-md py-3 px-6 w-full"
        >
            <div class="flex items-center">
                <div
                    class="text-2xl font-light"
                    v-html="grade(row.correct, settings.pulse_grade)"
                />
            </div>
        </h4>
        <div class="flex flex-col p-6">
            <div class="text-lg" v-html="row.question"></div>
            <div class="mt-8">
                <div
                    v-for="(answer, key) in ordered"
                    class="flex flex-col sm:flex-row items-center border-b last:border-b-0 border-gray-100 leading-none pt-4 pb-4"
                >
                    <div class="flex items-center pr-6 w-full sm:w-1/2">
                        <SvgIcon
                            class="flex-shrink-0 text-pear-600 mr-6"
                            v-if="answer.correct"
                            icon="check"
                            size="base"
                            weight="medium"
                        />
                        <SvgIcon
                            class="flex-shrink-0 text-red-500 mr-6"
                            v-if="!answer.correct"
                            icon="wrong"
                            size="base"
                            weight="medium"
                        />
                        <div
                            class="leading-6"
                            v-html="
                                answer.answer === ''
                                    ? 'Question left blank'
                                    : answer.answer
                            "
                        ></div>
                    </div>
                    <div class="mt-4 w-full sm:w-1/2">
                        <div class="flex items-center">
                            <div
                                class="flex items-center bg-gray-50 border border-gray-100 rounded-lg w-full h-3"
                            >
                                <div
                                    class="rounded-l-lg last:rounded-r-lg h-full"
                                    :class="{
                                        'bg-gray-300': !answer.correct,
                                        'bg-pear-500': answer.correct,
                                    }"
                                    :style="{
                                        width:
                                            (answer.count / answerCount) * 100 +
                                            '%',
                                    }"
                                />
                            </div>
                            <div class="text-sm ml-4" v-text="answer.count" />
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="flex items-center border-t text-sm text-gray-600 border-gray-100 pt-4 mt-4"
            >
                <div>
                    This question was answered a total of
                    {{ answerCount }} times
                </div>
                <div
                    v-tippy
                    content="We routinely update questions to improve grammer/understanding. Due to this, at times learners may encounter differing questions on the same assessment."
                    class="ml-3"
                >
                    <SvgIcon
                        class="text-gray-400"
                        icon="info"
                        size="sm"
                        weight="bold"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import { defineComponent, PropType } from "vue";
import { filters } from "../../../../../vue-filters";

export default defineComponent({
    props: {
        count: Number,
        legacy: Boolean,
        row: Object as PropType<any>,
    },
    methods: {
        ...filters,
    },
    computed: {
        ...mapState(["settings"]),
        answerCount() {
            return _.sumBy(this.row.answers, "count");
        },
        correct() {
            return _.filter(this.row.answers, "correct");
        },
        correctCount() {
            if (this.correct.length > 0) {
                return this.correct[0].count;
            }
            return 0;
        },
        ordered() {
            return _.orderBy(
                this.row.answers,
                ["count", "correct"],
                ["desc", "desc"]
            );
        },
        score() {
            return (this.correctCount / this.answerCount) * 100;
        },
    },
});
</script>
