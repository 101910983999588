<template>
    <div
        class="flex flex-wrap items-center justify-between border-b border-gray-100 last:border-b-0"
    >
        <a class="cta p-4" :href="path">
            {{ $attrs.name }}
        </a>
        <div
            class="flex flex-wrap sm:flex-wrap-none items-center justify-center border sm:border-none border-dashed border-gray-400 rounded-lg sm:rounded-none mb-4 mx-4 sm:m-0 p-4 sm:p-0 w-full sm:w-auto"
        >
            <div
                class="flex flex-col sm:flex-row items-center justify-center leading-6 sm:border-l border-gray-100 px-4 w-1/3 sm:w-22 h-16"
            >
                <span class="sm:hidden text-sm font-bold underline">Count</span>
                <span v-text="quantity($attrs.count)" />
            </div>
            <template v-if="type != 'lessons'">
                <div
                    class="flex flex-col sm:flex-row items-center justify-center leading-6 sm:border-l border-gray-100 px-4 w-1/3 sm:w-32 h-16"
                >
                    <span class="sm:hidden text-sm font-bold underline"
                        >Avg Score</span
                    >
                    <span v-html="grade($attrs.score, settings.pulse_grade)" />
                </div>
                <div
                    class="flex flex-col sm:flex-row items-center justify-center leading-6 sm:border-l border-gray-100 px-4 w-1/3 sm:w-24 h-16"
                >
                    <span class="sm:hidden text-sm font-bold underline"
                        >Avg Time</span
                    >
                    <span v-html="elapsed($attrs.duration)" />
                </div>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
import { mapState } from "vuex";
import { filters } from "../../../../../vue-filters";

export default {
    props: {
        legacy: Boolean,
    },
    methods: {
        ...filters,
    },
    computed: {
        ...mapState(["period", "settings", "type", "typeId"]),
        basePath() {
            return this.legacy ? "/pulse/explore-legacy/" : "/pulse/explore/";
        },
        path() {
            return (
                this.basePath +
                this.type +
                "?subject=" +
                this.$attrs.uuid +
                "&period=" +
                this.period +
                "&id=" +
                this.typeId
            );
        },
    },
};
</script>
