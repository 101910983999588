<template>
    <div>
        <multichoice :options="options" v-model="test" />
    </div>
</template>

<script lang="ts">
export default {
    props: {
        options: {
            type: Array,
            default: () => [],
        },
    },
    data(): any {
        return {
            test: [
                {
                    label: "Patient Care",
                    value: "a01ebf1f-3c8a-403d-94e0-179bb281e66a",
                },
                {
                    label: "Safety",
                    value: "b3dfc544-ae33-4ccf-a589-6868bd188b54",
                },
            ],
        };
    },
};
</script>
