<template>
    <BillingSection title="Add Payment Method">
        <form id="payment-form" v-on:submit="submit">
            <div id="payment-element" class="payment-element-design">
                <!-- Elements will create form elements here -->
            </div>
            <div id="error-message">
                <!-- Display error message to your customers here -->
            </div>
            <!--            <div class="flex items-center my-2">-->
            <!--                <label>-->
            <!--                    <input-->
            <!--                        type="checkbox"-->
            <!--                        class="form-checkbox main"-->
            <!--                        v-model="isDefaultPaymentMethod"/>-->
            <!--                    <span class="leading-6 mx-2">Use as default payment method.</span>-->
            <!--                </label>-->
            <!--            </div>-->
            <button id="submit" class="button main">Submit</button>
        </form>
    </BillingSection>
</template>

<script lang="ts">
import { mapState } from "vuex";
import BillingSection from "./BillingSection.vue";
import axios from "axios";

export default {
    components: { BillingSection },
    data(): any {
        return {
            isDefaultPaymentMethod: false,
            elements: null,
            stripe: new Stripe(window.stripe_key),
        };
    },
    computed: {
        ...mapState(["user"]),
    },
    methods: {
        async submit(event) {
            event.preventDefault();
            const { error } = await this.stripe.confirmSetup({
                //`Elements` instance that was used to create the Payment Element
                elements: this.elements,
                confirmParams: {
                    return_url:
                        window.location.origin +
                        `/account/billing?isDefault=${this.isDefaultPaymentMethod}`,
                },
            });

            if (error) {
                // This point will only be reached if there is an immediate error when
                // confirming the payment. Show error to your customer (for example, payment
                // details incomplete)
                const messageContainer =
                    document.querySelector<HTMLDivElement>("#error-message");
                // @ts-ignore
                messageContainer.textContent = error.message;
            } else {
                // Your customer will be redirected to your `return_url`. For some payment
                // methods like iDEAL, your customer will be redirected to an intermediate
                // site first to authorize the payment, then redirected to the `return_url`.
            }
        },
        setupStripe() {
            axios
                .get(`/api/users/${this.user.uuid}/stripe/setup-intent`)
                .then((response) => {
                    let clientSecret = response.data.client_secret;
                    this.elements = this.stripe.elements({
                        clientSecret,
                        appearance: {
                            theme: "stripe",
                            variables: {},
                        },
                    });
                    // Create and mount the Payment Element
                    const paymentElement = this.elements.create("payment");
                    paymentElement.mount("#payment-element");
                });
        },
    },
    mounted() {
        this.setupStripe();
    },
};
</script>
<style scoped>
.payment-element-design {
    width: 560px;
}
</style>
