import { Extension } from "@tiptap/core";

export const SourceView = Extension.create({
    name: "sourceview",

    addKeyboardShortcuts() {
        return {
            "Mod-Escape": () => {
                this.options?.onOpenSource?.();
                return true;
            },
        };
    },

    keys({ type }) {
        let self = this;
        const command = function () {
            // @ts-ignore
            self.editor.emit("openSourceView");
            return true;
        };
        return {
            "Mod-Escape": command,
        };
    },
});
