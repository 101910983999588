<template>
    <a
        class="flex items-center overflow-hidden no-underline whitespace-nowrap rounded-full p-1 mx-4 my-2 hover:shadow xl:w-auto"
        :rel="external ? 'noopener' : ''"
        :class="{ 'bg-white shadow-inner-hard': active }"
        :href="path"
        :target="target"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
    >
        <div
            class="rounded-full p-2"
            :class="[
                {
                    'animate-wiggle': hover,
                },
                'text-' + color + '-600',
            ]"
        >
            <SvgIcon :icon="icon" />
        </div>
        <div class="ml-3">
            {{ label }}
        </div>
    </a>
</template>

<script lang="ts">
export default {
    props: {
        external: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        path: {
            type: String,
            default: "#",
        },
        active: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: "gray",
        },
    },
    data(): any {
        return {
            hover: false,
        };
    },
    computed: {
        target() {
            return this.external ? "_blank" : "_self";
        },
    },
};
</script>
