<script setup lang="ts">
import { onMounted, ref } from "vue";
import {
  getCourseModules,
  updateCourseModuleOrder
} from "@/api/admin.ts";
import { Module } from "@/types";
import draggable from "vuedraggable";
import Button from "@/components/ui/ButtonLinks/Button.vue";
import $events from "@/event-bus.ts";

const props = defineProps<{
    uuid: string;
}>();

const modules = ref<Module[]>([]);
const drag = ref(false);

const getModules = () => {
    getCourseModules(props.uuid).then((response) => {
        modules.value = response.data;
    });
};

onMounted(() => {
    getModules();
});

const saveOrder = () => {
    // extract the lesson ids from the modules array
    const moduleIds = modules.value.map((module) => module.id);
    // send the lesson ids to the server
    updateCourseModuleOrder(props.uuid, moduleIds).then((response) =>{
      if (response.status < 400) {
        $events.$emit("toastNotification", {
          action: "saved",
          heading: "Course module order successfully updated!",
          status: "saved",
        });
      }
    }).catch((e) => {
      console.error(e);
      $events.$emit("toastNotification", {
        action: "saved",
        heading: "There was a problem saving the course module order",
        status: "error",
        message: e.response.data.message
      });
    }).finally(() => getModules());
};
</script>
<template>
    <div class="flex flex-row items-center justify-between">
        <div class="flex flex-col gap-2">
          <router-link :to="{ name: 'course', params: { uuid: props.uuid }}" class="text-sm text-gray-500"
          >&larr; Back to Course</router-link>
          <h2 class="text-2xl font-semibold tracking-tight">
              Change Module Order
          </h2>
        </div>
        <Button size="sm" @click="saveOrder">Save Order</Button>
    </div>
    <div class="flex flex-col space-y-4 mt-6">
        <draggable
            v-model="modules"
            handle=".drag-handle"
            @start="drag = true"
            @end="drag = false"
        >
            <template #item="{ element: module, index }">
                <div
                    class="bg-white rounded shadow-sm flex flex-row items-center my-2"
                >
                    <div
                        class="py-6 flex items-center justify-center flex-shrink-0 drag-handle text-gray-400 w-9 h-9"
                    >
                        <SvgIcon icon="drag" size="sm" />
                    </div>
                    {{ index + 1 }}. {{ module.title }}
                </div>
            </template>
        </draggable>
    </div>
</template>
