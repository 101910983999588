<template>
    <div
        class="flex items-center justify-between border-b border-gray-100 last:border-b-0 w-full"
    >
        <div class="flex items-center">
            <div class="flex items-center">
                <div class="p-6 w-80">
                    <h2 class="font-medium leading-6 truncate">
                        {{ blueprint.name }}
                    </h2>
                    <div v-if="!shared" class="text-xs">
                        Created
                        <span
                            v-text="
                                $filters.relative(
                                    blueprint.created_at,
                                    user.timezone
                                )
                            "
                        />
                    </div>
                </div>
            </div>
            <div class="flex p-6 w-36">
                <div v-if="!shared" class="text-sm">
                    {{ blueprint.bank_label }}
                </div>
            </div>
            <div class="flex items-center p-6 w-48">
                <PrepCategoryCircles
                    v-if="!shared"
                    class="justify-start"
                    :categories="blueprint.categoryList"
                    options="stacked"
                />
            </div>
            <div class="flex justify-center p-6 w-20">
                {{ blueprint.questions_total }}
            </div>
            <div class="flex justify-center p-6 w-36">
                <div
                    v-if="blueprint.timed"
                    class="ml-2 text-sm text-gray-700 leading-6"
                    v-text="blueprint.time_total"
                />
                <div v-else class="text-xl text-gray-400">⃠</div>
            </div>
        </div>
        <div class="flex items-center">
            <div
                v-if="blueprint.status != 'archived'"
                class="flex items-center justify-end p-6 pl-0 space-x-6 w-32"
            >
                <form action="/prep/exams" method="post">
                    <input type="hidden" name="_token" :value="token" />
                    <input type="hidden" name="type" value="saved" />
                    <input
                        type="hidden"
                        name="blueprint_id"
                        :value="blueprint.uuid"
                    />
                    <button class="small primary button">Start Exam</button>
                </form>
            </div>
            <div v-if="!shared" class="flex items-center w-14">
                <ActionMenu>
                    <ActionMenuAction
                        v-if="blueprint.status != 'archived'"
                        type="button"
                        label="Archive"
                        icon="archive"
                        iconColor="text-blue-200"
                        :action="archive"
                    />
                    <ActionMenuAction
                        v-if="blueprint.status == 'archived'"
                        type="button"
                        label="Restore"
                        icon="restore"
                        iconColor="text-blue-200"
                        :action="restore"
                    />
                    <ActionMenuAction
                        v-if="false"
                        type="button"
                        label="Delete"
                        icon="remove"
                        iconColor="text-red-200"
                        :action="showDelete"
                    />
                </ActionMenu>
                <ConfirmDeleteModal
                    v-if="false"
                    :active="deleteModalVisible"
                    @close="deleteModalVisible = false"
                    @confirm="confirmDelete"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState } from "vuex";

export default {
    props: {
        assignment: Boolean,
        blueprint: {
            type: Object,
            default: () => {},
        },
        shared: Boolean,
    },
    data(): any {
        return {
            deleteModalVisible: false,
            formStatus: "ready",
        };
    },
    computed: {
        ...mapState(["token", "user"]),
        canDelete() {
            return this.blueprint.owner == "learner";
        },
    },
    methods: {
        archive() {
            axios
                .post(
                    "/api/prep/blueprints/" + this.blueprint.uuid + "/archive"
                )
                .then(() => window.location.reload());
        },
        confirmDelete() {
            axios
                .delete("/prep/exams/blueprints/" + this.blueprint.uuid)
                .then((response) => {
                    window.location.reload();
                });
        },
        restore() {
            axios
                .post(
                    "/api/prep/blueprints/" + this.blueprint.uuid + "/restore"
                )
                .then(() => window.location.reload());
        },
        showDelete() {
            this.deleteModalVisible = true;
        },
        takeExam() {
            this.formStatus = "processing";
            axios
                .post("/prep/exams", {
                    type: "saved",
                    blueprint_id: this.blueprint.uuid,
                })
                .then((response) => {
                    window.location = response.data.examPath;
                });
        },
    },
};
</script>
