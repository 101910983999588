<template>
    <div>
        <template v-if="!loading">
            <div class="hidden sm:flex items-center justify-end">
                <div
                    class="text-center font-bold text-base mb-2 lg:w-48 xl:w-64"
                >
                    Activity
                </div>
                <div
                    class="text-center font-bold text-base mb-2 lg:w-48 xl:w-64"
                >
                    Quizzes
                </div>
                <div
                    class="text-center font-bold text-base mb-2 lg:w-48 xl:w-64"
                >
                    Assessments
                </div>
            </div>
            <div
                class="hidden sm:flex items-center justify-between"
                v-if="rows.length > 0"
            >
                <div class="pr-8">
                    <div
                        class="flex items-center text-sm lg:text-xs xl:text-sm font-medium uppercase ml-4"
                        v-if="subject != 'learner'"
                    >
                        <div v-text="nextSubject" />
                        <SortButton
                            emit="sortActivity"
                            initial="asc"
                            variable="name"
                        />
                    </div>
                </div>
                <div class="flex items-center justify-end">
                    <div
                        class="flex items-center justify-center text-sm lg:text-xs xl:text-sm font-medium uppercase lg:w-24 xl:w-32"
                    >
                        <div>Visits</div>
                        <SortButton
                            emit="sortActivity"
                            initial="asc"
                            variable="visits"
                        />
                    </div>
                    <div
                        class="flex items-center justify-center text-sm lg:text-xs xl:text-sm font-medium uppercase lg:w-24 xl:w-32"
                    >
                        <div>Views</div>
                        <SortButton
                            emit="sortActivity"
                            initial="asc"
                            variable="lessonViews"
                        />
                    </div>
                    <div
                        class="flex items-center justify-center text-sm lg:text-xs xl:text-sm font-medium uppercase lg:w-24 xl:w-32"
                    >
                        <div>Taken</div>
                        <SortButton
                            emit="sortActivity"
                            initial="asc"
                            variable="quizzesTaken"
                        />
                    </div>
                    <div
                        class="flex items-center justify-center text-sm lg:text-xs xl:text-sm font-medium uppercase lg:w-24 xl:w-32"
                    >
                        <div>Avg</div>
                        <SortButton
                            emit="sortActivity"
                            initial="asc"
                            variable="avgQuizScore"
                        />
                    </div>
                    <div
                        class="flex items-center justify-center text-sm lg:text-xs xl:text-sm font-medium uppercase lg:w-24 xl:w-32"
                    >
                        <div>Taken</div>
                        <SortButton
                            emit="sortActivity"
                            initial="asc"
                            variable="assessmentsTaken"
                        />
                    </div>
                    <div
                        class="flex items-center justify-center text-sm lg:text-xs xl:text-sm font-medium uppercase lg:w-24 xl:w-32"
                    >
                        <div>Avg</div>
                        <SortButton
                            emit="sortActivity"
                            initial="asc"
                            variable="avgAssessmentScore"
                        />
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="p-4 italic" v-if="empty">
                    No {{ nextSubject }} data
                </div>
                <PulseExploreOverviewTableRow
                    v-for="row in sorted"
                    @rowSync="updateRow"
                    :key="row.uuid"
                    :row="row"
                    :legacy="legacy"
                />
            </div>
        </template>
        <div class="h-52" v-if="loading">
            <Loader :show="loading" />
        </div>
    </div>
</template>

<script lang="ts">
import axios from "axios";
import { mapState, mapGetters } from "vuex";
import _ from "lodash";

export default {
    props: {
        legacy: Boolean,
    },
    data(): any {
        return {
            empty: false,
            loading: true,
            rows: [],
            sortBy: "name",
            sortOrder: "asc",
        };
    },
    computed: {
        ...mapState(["subject"]),
        ...mapGetters(["context", "nextSubject"]),
        path() {
            return (
                "/api/pulse/entity" + this.context + "&legacy=" + this.legacy
            );
        },
        sorted() {
            return _.orderBy(
                this.rows,
                [
                    (row: any) => {
                        return row[this.sortBy] || "";
                    },
                ],
                this.sortOrder
            );
        },
    },
    methods: {
        getData() {
            axios.get(this.path).then((response) => {
                this.loading = false;
                this.rows = response.data;
                if (response.data.length == 0) {
                    this.empty = true;
                }
            });
        },
        updateRow(newData) {
            this.rows.find((row, i) => {
                if (row.uuid === newData.uuid) {
                    Object.assign(this.rows[i], newData.payload);
                }
            });
        },
    },
    mounted() {
        this.getData();

        this.$events.$on("sortActivity", (response) => {
            this.sortBy = response.sortBy;
            this.sortOrder = response.sortOrder;
        });
    },
};
</script>
